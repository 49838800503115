import { TFunction } from 'react-i18next';

export const getDistance = (distance: number, type: 'km' | 'miles' = 'km'): number => {
	const divider = type === 'km' ? 1000 : 1609;
	return Math.round((distance / divider) * 10) / 10;
};

export const IMPERIAL_DISTANCE = ['en-US', 'en-GB'];

export const getLocalizationKey = (distance: number, units: 'km' | 'miles'): string => {
	switch (units) {
		case 'km':
			return 'distanceKms';
		case 'miles':
			return distance === 1 ? 'oneMile' : 'distanceMiles';
	}
};

export const createDistance = (distance: number, t: TFunction<'translation'>): string => {
	const { language } = window.navigator;
	const distanceType = IMPERIAL_DISTANCE.includes(language) ? 'miles' : 'km';

	const distanceNumber = getDistance(distance, distanceType);
	const localizationKey = getLocalizationKey(distanceNumber, distanceType);
	return t(localizationKey, { distance: distanceNumber });
};
