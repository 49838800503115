import { filter as filterArray, last, map as mapAray } from 'fp-ts/lib/Array';
import { flow, not } from 'fp-ts/lib/function';
import { map, toUndefined } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';

import {
	CreateAccountPayload,
	GetConversationPayload,
	UpdateProfilePayload,
} from '../../context/api-service/api-service.model';
import { PatientInfo } from '../../models/app.model';
import { ConversationHistory, historyMessageToMessage } from '../../models/conversation.model';
import { localizationModelToLocale, toLocale } from '../../models/languages.model';
import {
	isDeliveryStatusMessage,
	isErrorMessage,
	isMessageWithPostMessageMetadata,
	isQuickResponseMessage,
	isTextMessage,
	LiveChatCommand,
	LiveChatCommandMessage,
	Message,
	MessageFromSocket,
	ParsedData,
} from '../../models/message.model';
import { ClientMessage, ClientMessageAttachment, ClientMessageType } from '../../models/socket.model';
import { WebTrackerAction } from '../../models/trackers.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { lastIndexOf, reduceToLastElement } from '../../utils/array.utils';
import { Effect, Lazy } from '../../utils/function.utils';
import { isUserMessage } from '../../utils/renderer-utils/renderer.utils';
import { isEmpty } from '../../utils/string.utils';
import { ResponseError } from '../../utils/types.utils';
import { LIVE_CHAT_COMMANDS } from '../live-chat/live-chat.model';

const DEFAULT_MAX_UTTERANCE = 50;
export const SESSION_EXPIRED_COMMAND = 'GYANT_SESSION_EXPIRED';

const WEB_CLIENT_VERSION = '4.0.0';
const HANDSHAKE_SOCKET_MESSAGE = '👋';

interface SpecialActionsCallbacks {
	setLocale: Effect<string>;
}

export interface DelayedMessageToSocket {
	text: string;
	originalText: string;
	shouldShowInWidget: boolean;
}

export type OnSenMessageFunc = (
	text: string,
	originalText?: string,
	shouldShowInWidget?: boolean,
	isUndoAction?: boolean,
	attachments?: ClientMessageAttachment[],
	type?: ClientMessageType,
	payload?: string,
) => void;

interface SplittedLiveChatWizardMessages {
	liveChatMessages: Message[];
	widgetMessages: Message[];
}

export const toGetConversationPayload = (maxUtterances = DEFAULT_MAX_UTTERANCE): GetConversationPayload => ({
	maxUtterances,
});

export const toCreateAccountPayload = (
	client: string,
	language: string,
	gyTesting = false,
	gyDebugging = false,
	patientInfo?: Partial<PatientInfo>,
): CreateAccountPayload => {
	const aditionalParameters = {
		...(gyTesting ? { testing: true } : {}),
		...(gyDebugging ? { debugging: true } : {}),
	};

	return {
		client,
		hostname: window.location.hostname,
		locale: toLocale(language),
		startUrl: window.location.href,
		version: WEB_CLIENT_VERSION,
		...aditionalParameters,
		...patientInfo,
	};
};

export const toUpdateProfilePayload = (sessionToken: string): UpdateProfilePayload => ({
	sessionToken,
	openedChatWidget: true,
	version: WEB_CLIENT_VERSION,
	currentUrl: window.location.href,
});

export const getInitialStartingFlow = (startingFlowStep: string, customStartingFlow?: string): string =>
	customStartingFlow ?? `gyant start ${isEmpty(startingFlowStep) ? 'over' : `flow ${startingFlowStep}`}`;

export const toSocketClientMessage = (
	text: string,
	originalText?: string,
	type: ClientMessageType = 'message',
	payload?: string,
	attachments?: ClientMessageAttachment[],
	messageId?: string,
): ClientMessage => {
	const aditionalParameters = {
		...(messageId ? { messageId } : {}),
	};
	return {
		type,
		text,
		originalText,
		attachments,
		payload,
		...aditionalParameters,
	};
};

export const toUserCommand = (commandType: LiveChatCommand): LiveChatCommandMessage => ({
	originalText: '👋',
	type: 'command',
	value: commandType,
});

export const toUserMessage = (text: string, messageId?: string): Message => ({
	type: 'text',
	flowStep: '',
	content: '',
	text,
	incoming: true,
	timeStamp: new Date(),
	messageId,
});

export const filterHandshakeMessage = (message: Message): boolean => message.text !== HANDSHAKE_SOCKET_MESSAGE;

export const getAutocompleteUri = (messages: Message[]): string | undefined =>
	pipe(
		messages,
		last,
		map((message) => message.autocompleteUri),
		toUndefined,
	);

export const getTimeRemaining = (messages: Message[]): number | undefined =>
	pipe(
		messages,
		last,
		map((message) => message.timeRemaining),
		toUndefined,
	);

const checkSpecialActions = (message: Message, actions: SpecialActionsCallbacks): void => {
	message.specialActions?.forEach((specialAction) => {
		switch (specialAction.action) {
			case 'setlocale': {
				const language = localizationModelToLocale(specialAction.value);
				actions.setLocale(language);
			}
		}
	});
};

const specialActionsPredicate = (m: Message): boolean => (m.specialActions ? m.specialActions.length > 0 : false);

export const conversationHistoryToHistoryMessages = (conversationHistory: ConversationHistory): Message[] =>
	conversationHistory.utterances.map((message) => historyMessageToMessage(message, conversationHistory.context));

const addDeliveryStatusToMessage =
	(undeliveredStatusMessageIds: string[]) =>
	(message: Message): Message => ({
		...message,
		deliveredStatus:
			message.messageId && undeliveredStatusMessageIds.includes(message.messageId) ? 'undelivered' : 'delivered',
	});

export const historyMessageToMesage = (messages: Message[]): Message[] => {
	const filteredHandshakeMessages = messages.filter(filterHandshakeMessage);

	const undeliveredStatusMessageIds = filteredHandshakeMessages.flatMap((message) =>
		message.type === 'messageStatus' && message.messageStatus?.status === 'undelivered'
			? message.messageStatus.messageId
			: [],
	);

	return filteredHandshakeMessages
		.filter(not(isDeliveryStatusMessage))
		.map(addDeliveryStatusToMessage(undeliveredStatusMessageIds));
};

export const checkAndExecuteSpecialActions =
	(cb: Effect<string>) =>
	(messages: Message[]): void =>
		messages.forEach((message) => checkSpecialActions(message, { setLocale: cb }));

export const findLastMessageSpecialActions = (cb: Effect<string>): ((fa: Message[]) => void) =>
	flow(filterArray(specialActionsPredicate), reduceToLastElement, checkAndExecuteSpecialActions(cb));

export const setLastClientMessageUndoable = (messages: Message[], shouldOnlyClear = false): Message[] => {
	const lastClientMessageIndex = lastIndexOf(messages, isUserMessage);
	if (lastClientMessageIndex !== -1) {
		messages.forEach((message) => {
			if (isUserMessage(message)) {
				message.isUndoable = false;
			}
		});
		if (!shouldOnlyClear) {
			messages[lastClientMessageIndex].isUndoable = true;
		}
	}
	return messages;
};

export const prepareDataForGAEvent = (message: Message[]): string[] =>
	message.map((message) => {
		if (message.type === 'quickResponses') {
			const responses = message.responses.map((response) => response.content).join(', ');
			return `${message.text} ${responses}`;
		}
		return message.text;
	});

const filterMessagesByLastQRMessages = (messages: Message[], sessionToken: string): Message[] => {
	const lastMessage = messages[messages.length - 1];

	if (lastMessage && (isQuickResponseMessage(lastMessage) || isTextMessage(lastMessage))) {
		const lastAnsweredBotTextMessage = window.localStorage.getItem(`${sessionToken}_lastBotMessage`);
		const prevQRMessageIndex = lastIndexOf(messages, isQuickResponseMessage, messages.length - 2);

		const messagesWithoutDuplicatedQRs =
			prevQRMessageIndex === -1 ? messages : messages.slice(prevQRMessageIndex + 1);

		const lastAnsweredBotTextMessageIndex = lastIndexOf(
			messagesWithoutDuplicatedQRs,
			(message) => message.text === lastAnsweredBotTextMessage,
		);

		return lastAnsweredBotTextMessageIndex === -1 ||
			lastAnsweredBotTextMessageIndex === messagesWithoutDuplicatedQRs.length - 1
			? messagesWithoutDuplicatedQRs
			: messagesWithoutDuplicatedQRs.slice(lastAnsweredBotTextMessageIndex + 1);
	}
	return messages;
};

export const getMessagesShownInWidget = (messages: Message[], sessionToken: string): Message[] => {
	const lastMessage = messages[messages.length - 1];

	if (lastMessage) {
		if (lastMessage.incoming) {
			return [lastMessage];
		}
		const lastClientMessageIndex = lastIndexOf(messages, isUserMessage);

		const messagesToShow =
			lastClientMessageIndex === -1 || lastClientMessageIndex === 0
				? messages
				: messages.slice(lastClientMessageIndex);

		return filterMessagesByLastQRMessages(messagesToShow, sessionToken);
	}
	return [];
};

export const processClientOnBotMessageCallback = (receivedMessages: Message[], callback: Effect<string>): void => {
	receivedMessages.map((message) => {
		if (isTextMessage(message)) {
			callback(message.content);
		}
	});
};

export const sendTrackerBotMessageEvent = (
	receivedMessages: Message[],
	callback: (action: WebTrackerAction, event: string | EventType) => void,
): void => {
	pipe(
		receivedMessages,
		prepareDataForGAEvent,
		mapAray((element) => {
			callback('onBotMessage', element);
		}),
	);
};

export const sendPostMessageToParent = (postMessageToParent: boolean, data: unknown): void => {
	const origin = postMessageToParent ? window.parent : window;
	origin.postMessage(data, '*');
};

export const checkMetaDataAndSendToParent = (receivedMessages: Message[], postMessageToParent: boolean): void => {
	pipe(
		receivedMessages,
		mapAray((message) => {
			if (isMessageWithPostMessageMetadata(message)) {
				sendPostMessageToParent(postMessageToParent, message.metadata.data);
			} else if (message.metadata) {
				// NOTE: This should be removed after the flows be updated to use the postMessage type only
				sendPostMessageToParent(postMessageToParent, message.metadata);
			}
		}),
	);
};

export const getLatestBotMessages = (messages: Message[]): Message[] => {
	const lastNonTextBotMessageIndex = lastIndexOf(messages, (message: Message) => message.type !== 'text');
	return messages.slice(lastNonTextBotMessageIndex);
};

export const isMessageWithDoctorInfo = (message?: Message): boolean =>
	!!message && 'doctorInfo' in message && !!message.doctorInfo;

export const findLastAgentMessageIndex = (messages: Message[]): number =>
	lastIndexOf(
		messages,
		(message: Message) =>
			!message.incoming &&
			message.type !== 'command' &&
			message.type !== 'messageStatus' &&
			message.metadata?.status !== 'startTyping' &&
			message.metadata?.status !== 'endTyping',
	);

export const checkHistoryForLiveChatSession = (messages: Message[]): boolean => {
	const lastAgentMessageIndex = findLastAgentMessageIndex(messages);

	// Edge case: when user is in live chat queue and do a restart
	const isLastMessageIsStartLiveChat = messages[messages.length - 1].metadata?.status === 'startLiveChat';
	return isLastMessageIsStartLiveChat || isMessageWithDoctorInfo(messages[lastAgentMessageIndex]);
};

export const splitMessagesForLiveChatAndWidget = (messages: Message[]): SplittedLiveChatWizardMessages => {
	const startChatMessageIndex = lastIndexOf(
		messages,
		(message: Message) => message?.metadata?.status === 'startLiveChat',
	);
	const startLiveChatMessagesFromIndex = startChatMessageIndex === -1 ? 0 : startChatMessageIndex;

	const liveChatMessages = messages.slice(startLiveChatMessagesFromIndex);
	const widgetMessages = messages.slice(0, startChatMessageIndex);

	return {
		liveChatMessages,
		widgetMessages,
	};
};

export const addTimeStampToMessages = (messages: Message[]): Message[] =>
	messages.map((message) => ({ ...message, timeStamp: new Date() }));

export const filterOutliveChatMessages = (messages: Message[]): Message[] =>
	messages
		.filter((m) => {
			const metadata = m.metadata?.status || '';
			return ![...LIVE_CHAT_COMMANDS, 'startLiveChat'].includes(metadata);
		})
		.filter(
			(message) =>
				!(message.type === 'command' && (message.text === 'endLiveChat' || message.text === 'leaveQueue')),
		);

export type LivechatVisibilityStatus = 'SHOWN' | 'LEAVING' | 'HIDDEN';

export const getLiveChatStatus = (isLiveChatShown: boolean, isLeavingLiveChat: boolean): LivechatVisibilityStatus => {
	if (isLiveChatShown && !isLeavingLiveChat) {
		return 'SHOWN';
	}
	if (isLeavingLiveChat) {
		return 'LEAVING';
	}

	return 'HIDDEN';
};

export const addHotKeyForInputVisibility = (cb: Lazy<void>, document?: Document): void => {
	document?.addEventListener('keydown', (e) => {
		if (e.ctrlKey && e.key === '\\') {
			cb();
		}
	});
};

export const addPostMessageListener = (cb: Effect<ParsedData<MessageFromSocket>[]>): void => {
	window.addEventListener('GYANTEvents', (event: any) => {
		if (event.type === 'GYANTEvents' && event.detail.messages && event.detail.messages.length > 0) {
			const messages: ParsedData<MessageFromSocket>[] = event.detail.messages.map(
				(message: MessageFromSocket) => ({
					parsingInfo: { status: 'success' },
					data: message,
				}),
			);
			cb(messages);
		}
	});
};

export const isSessionExpiredMessage = (message: MessageFromSocket): boolean =>
	isErrorMessage(message) && message.details.code === 'AccessDenied';

export const handleUnauthorizedSessionError =
	(onSessionExpiredCallback: Lazy<void>) =>
	(error: ResponseError): void => {
		if (error.status === 401) {
			onSessionExpiredCallback();
		}
	};

export const checkMessagesForExpiredSession = (
	messages: MessageFromSocket[],
	onSessionExpiredCallback: Lazy<void>,
): boolean => {
	if (messages.some(isSessionExpiredMessage)) {
		onSessionExpiredCallback();
		return true;
	}
	return false;
};
