import { useTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FC } from 'react';

import { ColorVariables } from '../../models/app.model';
import { NotificationStatus } from '../../models/notification.model';
import { ConfirmationAltIcon } from '../icons/confirmationAlt';
import { ErrorIcon } from '../icons/error.icon';
import { NoDisturbIcon } from '../icons/no-disturb.icon';
import { WarningIcon } from '../icons/warning.icon';
import { useInfoStyles } from './info.styles';

export interface InfoColorSettings {
	bgColor: string;
	iconColor: string;
	textColor: string;
}

interface InfoProps {
	status: NotificationStatus;
	label: string;
	className?: string;
	dataTestingLabel?: string;
	isSimple?: boolean;
}
// TODO: add different icons when will be ready in design
const getInfoColorSettings = (status: NotificationStatus, colorVariables: ColorVariables): InfoColorSettings => {
	const bgKey = `colorSysBg${status}` as keyof ColorVariables;
	const iconKey = `colorSys${status}` as keyof ColorVariables;
	const textKey = `colorSysText${status}` as keyof ColorVariables;
	return {
		bgColor: colorVariables[bgKey],
		iconColor: colorVariables[iconKey],
		textColor: colorVariables[textKey],
	};
};

const getInfoIcon = (status: NotificationStatus, className: string) => {
	switch (status) {
		case 'Success':
			return <ConfirmationAltIcon className={className} />;
		case 'Warning':
			return <WarningIcon className={className} />;
		case 'Alert':
			return <ErrorIcon className={className} />;
		default:
			return <NoDisturbIcon className={className} />;
	}
};

export const Info: FC<InfoProps> = ({ status, label, isSimple = false, className, dataTestingLabel }) => {
	const {
		palette: {
			colorScheme: { colorVariables },
		},
	} = useTheme();
	const infoColorSettings = getInfoColorSettings(status, colorVariables);
	const classes = useInfoStyles(infoColorSettings);
	const rootClassName = classNames(classes.root, isSimple && classes.clear, className);
	const notificationIcon = getInfoIcon(status, classes.icon);

	return (
		<div className={rootClassName} data-testing-label={dataTestingLabel}>
			{notificationIcon}
			<span className={classes.label}>{label}</span>
		</div>
	);
};
