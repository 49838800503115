import { createStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';

import { ANIMATIONS } from '../../utils/animations.utils';
import { InputWithValidationProps } from './input-with-validation.component';

export const inputWithValidationStyles = ({
	palette: { sizes },
}: Theme): StyleRules<InputWithValidationProps, 'wrapper' | 'input' | 'errorMessage'> =>
	createStyles({
		...ANIMATIONS['gyant-animation-heartbeat'],
		wrapper: {
			display: 'flex',
		},
		input: {
			width: '100%',
		},
		errorMessage: {
			paddingTop: sizes.size_2,
			'& > svg': {
				animation: '$gyant-animation-heartbeat 3s linear infinite',
			},
		},
	});
