import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const StarRatingIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} {...props}>
		<path
			d={
				'M12 17.2752L7.85002 19.7752C7.66668 19.8919 7.47502 19.9419 7.27501 19.9252C7.07501 19.9085 6.90001 19.8419 6.75002 19.7252C6.60001 19.6085 6.48335 19.4627 6.40002 19.2877C6.31668 19.1127 6.30002 18.9169 6.35002 18.7002L7.45002 13.9752L3.77502 10.8002C3.60835 10.6502 3.50418 10.4794 3.46252 10.2877C3.42085 10.096 3.43335 9.90853 3.50002 9.72519C3.56668 9.54186 3.66668 9.39186 3.80002 9.2752C3.93335 9.15853 4.11668 9.08353 4.35002 9.0502L9.20002 8.6252L11.075 4.1752C11.1583 3.9752 11.2875 3.8252 11.4625 3.7252C11.6375 3.6252 11.8167 3.5752 12 3.5752C12.1833 3.5752 12.3625 3.6252 12.5375 3.7252C12.7125 3.8252 12.8417 3.9752 12.925 4.1752L14.8 8.6252L19.65 9.0502C19.8833 9.08353 20.0667 9.15853 20.2 9.2752C20.3333 9.39186 20.4333 9.54186 20.5 9.72519C20.5667 9.90853 20.5792 10.096 20.5375 10.2877C20.4958 10.4794 20.3917 10.6502 20.225 10.8002L16.55 13.9752L17.65 18.7002C17.7 18.9169 17.6833 19.1127 17.6 19.2877C17.5167 19.4627 17.4 19.6085 17.25 19.7252C17.1 19.8419 16.925 19.9085 16.725 19.9252C16.525 19.9419 16.3333 19.8919 16.15 19.7752L12 17.2752Z'
			}
		/>
	</SvgIcon>
);
