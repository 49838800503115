import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmojiGreatIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32'} height={'32'} {...props}>
		<g fill={'none'} fillRule={'evenodd'}>
			<path d={'m0 0h32v32h-32z'} />
			<path
				d={
					'm15.9866667 2.66666667c7.3733333 0 13.3466666 5.96 13.3466666 13.33333333 0 7.3733333-5.9866666 13.3333333-13.3466666 13.3333333-7.36000003 0-13.32000003-5.96-13.32000003-13.3333333 0-7.37333333 5.96-13.33333333 13.32000003-13.33333333zm.0133333 2.66666666c-5.8933333 0-10.66666667 4.77333337-10.66666667 10.66666667s4.77333337 10.6666667 10.66666667 10.6666667 10.6666667-4.7733334 10.6666667-10.6666667-4.7733334-10.66666667-10.6666667-10.66666667zm5.7466667 13.33333337c.52 0 .84.56.5866666 1-1.2666666 2.1866666-3.6266666 3.6666666-6.3333333 3.6666666s-5.0666667-1.48-6.33333333-3.6666666c-.25333334-.44.08-1 .58666663-1zm-2.09-8.94333337c.24-.52.9733333-.52 1.2133333 0l.8666667 1.87999997 1.88.8666667c.52.2266667.52.9733333 0 1.2l-1.88.8666667-.8533334 1.88c-.24.52-.9733333.52-1.2133333 0l-.8666667-1.88-1.88-.8533334c-.52-.24-.52-.9733333 0-1.2133333l1.88-.8666667zm-8.5333334 0c.24-.52.9733334-.52 1.2133334 0l.8666666 1.87999997 1.88.8666667c.52.2266667.52.9733333 0 1.2l-1.88.8666667-.8533333 1.88c-.24.52-.9733333.52-1.2133333 0l-.8666667-1.88-1.88-.8533334c-.52-.24-.52-.9733333 0-1.2133333l1.88-.8666667z'
				}
				fill={'currentColor'}
				fillRule={'nonzero'}
			/>
		</g>
	</SvgIcon>
);
