import classNames from 'classnames';
import { FC, memo } from 'react';

import { useLoadingListMaskStyles } from './loading-list-mask.styles';

interface LoadingAppointmentsMaskProps {
	className?: string;
}

export const LoadingListMask: FC<LoadingAppointmentsMaskProps> = memo(({ className }) => {
	const classes = useLoadingListMaskStyles();

	const rootClassName = classNames(classes.root, className);

	const baseLineClass = classNames(classes.item, classes.line, classes.blinking, classes.shortLine);
	const baseBlockClass = classNames(classes.item, classes.block, classes.blinking);

	const longLineClass = classNames(baseLineClass, classes.longLine);
	const shortLineClass = classNames(baseLineClass, classes.biggerMargin);

	const secondBlockClass = classNames(baseBlockClass, classes.opacityMedium);
	const thirdBlockClass = classNames(baseBlockClass, classes.opacityLow);

	return (
		<div className={rootClassName} data-testing-label={'loading-list-mask'}>
			<div className={longLineClass} />
			<div className={shortLineClass} />
			<div className={baseLineClass} />
			<div className={baseBlockClass} />
			<div className={secondBlockClass} />
			<div className={thirdBlockClass} />
		</div>
	);
});
