import { makeStyles } from '@material-ui/core';

export const buttonStyles = makeStyles(({ palette: { colorScheme, sizes }, typography: { smallViewPort } }) => ({
	loading: {
		'& $text': { visibility: 'hidden' },
	},
	text: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	fullWidth: {
		width: '100%',
	},
	linkVariant: {
		...smallViewPort.typeLink2,
		padding: sizes.size_1,
		width: 94,
		height: sizes.size_6,
		textDecoration: 'underline',
		'&:hover': {
			textDecoration: 'underline',
			backgroundColor: 'transparent',
			textDecorationColor: colorScheme.colorVariables.colorPrimary,
			filter: 'brightness(96%)',
			transition: 'all .2s',
		},
	},
}));

export const rootButtonStyles = makeStyles(
	({ palette: { colorScheme, borderRadius }, typography: { smallViewPort } }) => ({
		root: {
			fontFamily: colorScheme.fontFamilyRegular,
			fontSize: 18,
			lineHeight: 1.4,
			minWidth: 'auto',
			padding: '11px 16px',
			borderRadius: borderRadius.button,
			transition: 'all 0.8s',
			textTransform: 'none',
			'&:hover': {
				transition: 'all 0.1s',
				backgroundColor: 'none',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
		contained: {
			color: colorScheme.colorVariables.colorPrimary,
			'&:hover': {
				boxShadow: 'none',
			},
			'&:active': {
				boxShadow: 'none',
			},
			'&:disabled': {
				color: colorScheme.colorVariables.colorNote,
				backgroundColor: colorScheme.colorVariables.colorInactive,

				'& svg': {
					color: colorScheme.colorVariables.colorBorder,
				},
			},
			'&:focus-visible': {
				boxShadow: 'none',
			},
		},
		outlined: {
			padding: '0 16px',
		},
		textPrimary: {
			'&:hover': {
				backgroundColor: colorScheme.colorVariables.colorPrimary,
				filter: 'brightness(92%)',
				boxShadow: `0px 0px 0px 1px ${colorScheme.colorVariables.colorPrimary}`,
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
		},
		containedPrimary: {
			...smallViewPort.typeButton1,
			backgroundColor: colorScheme.colorVariables.colorPrimary,
			'&:hover': {
				transition: 'background-color 0.1s, box-shadow 0.1s',
				backgroundColor: colorScheme.colorVariables.colorPrimary,
				filter: 'brightness(92%)',
				backgroundImage: 'none',
			},
			'&:active': {
				filter: 'brightness(80%)',
				backgroundImage: 'none',
			},
		},
		containedSecondary: {
			...smallViewPort.typeButton1Alt,
			backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
			boxShadow: 'none',
			'&:hover': {
				backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
				filter: 'brightness(96%)',
			},
			'&:active': {
				filter: 'brightness(88%)',
			},
		},
		sizeLarge: {
			height: 52,
			fontSize: 21,
		},
		sizeSmall: {
			height: 36,
		},
	}),
);
