import { pipe } from 'fp-ts/lib/function';
import { mapWithIndex } from 'fp-ts/lib/Record';

import {
	isGoogleAnalyticsTracker,
	Tracker,
	WebTrackerAction,
	WebTrackerConfig,
	WebTrackerEvent,
} from '../../models/trackers.model';
import { initGoogleAnalytics, sendGoogleAnalyticsEvent } from './google-analytics';
import { sendPostToParentEvent } from './post-to-parent';
import { initSiteImprove, isSiteImproveTracker, sendSiteImproveEvent } from './site-improve';
import { prepareEventData } from './web-tracker-service.model';

declare global {
	interface Window {
		dataLayer: any;
		ga: any;
		_sz: any;
	}
}

export type EventType =
	| React.MouseEvent<HTMLButtonElement, MouseEvent>
	| React.MouseEvent<HTMLLIElement, MouseEvent>
	| React.MouseEvent<HTMLAnchorElement, MouseEvent>
	| React.MouseEvent<HTMLDivElement, MouseEvent>
	| React.KeyboardEvent<HTMLDivElement>;

export interface WebTrackerService {
	sendEvent(action: WebTrackerAction, event: EventType | string, response?: string): void;
}

export const createWebTrackerService = (webTrackerConfig: WebTrackerConfig): WebTrackerService => {
	const { events, category, trackers, logEventsToConsole } = webTrackerConfig;

	trackers.forEach((tracker) => {
		isGoogleAnalyticsTracker(tracker) && initGoogleAnalytics(tracker.id);
		isSiteImproveTracker(tracker) && initSiteImprove(tracker.id);
	});

	const sendTrackerEvent = (trackers: Tracker[], logEvent: WebTrackerEvent, action: string, label: string) => {
		trackers.forEach((tracker) => {
			const basicEventConfig = {
				category,
				action,
				label,
				logEventsToConsole,
			};
			switch (tracker.type) {
				case 'googleAnalytics':
					return sendGoogleAnalyticsEvent({
						...basicEventConfig,
						trackerId: tracker.id,
					});
				case 'postToParent':
					return sendPostToParentEvent({
						...basicEventConfig,
						logEvent,
					});
				case 'siteImprove':
					return sendSiteImproveEvent({
						...basicEventConfig,
						trackerId: tracker.id,
					});
			}
		});
	};

	const prepareAndSendTrackerEvent = (logEvent: WebTrackerEvent, event: EventType | string) => {
		const { eventAction, eventLabel } = prepareEventData(event, logEvent.action, logEvent.label);
		sendTrackerEvent(trackers, logEvent, eventAction, eventLabel);
	};

	const sendEvent = (action: WebTrackerAction, event: EventType | string, response?: string) => {
		const logEvent = events.find((event) => event.type === action);
		if (logEvent && logEvent.enabled) {
			if (logEvent.matchResponses && response) {
				return pipe(
					logEvent.matchResponses,
					mapWithIndex((nodeId, data) => {
						if (response.includes(nodeId)) {
							const { eventAction, eventLabel } = prepareEventData(event, data.action, data.label);
							sendTrackerEvent(trackers, logEvent, eventAction, eventLabel);
						}
					}),
				);
			}
			prepareAndSendTrackerEvent(logEvent, event);
		}
	};

	return {
		sendEvent,
	};
};
