import { ElementType, FC } from 'react';

import { ButtonConfig, GenericQuickResponse, QuickResponseMessage } from '../../../models/message.model';
import { IconButton } from '../../icon-button/icon-button.component';
import { Icon } from '../../icon/icon.component';
import { EmojiGoodIcon } from '../../icons/emoji-good.icon';
import { EmojiGreatIcon } from '../../icons/emoji-great.icon';
import { EmojiNeutralIcon } from '../../icons/emoji-neutral.icon';
import { EmojiSadIcon } from '../../icons/emoji-sad.icon';
import { EmojiTerribleIcon } from '../../icons/emoji-terrible.icon';
import { useSatisfactionSurveyStyles } from './satisfaction-survey.styles';

export interface SatisfactionSurveyProps {
	quickResponsesMessage: QuickResponseMessage;
	satisfactionConfigButtons: ButtonConfig[];
	onAction(
		response: GenericQuickResponse,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		flowStep: string,
	): void;
}

const getEmoji = (type: string): ElementType => {
	const emojis = {
		TERRIBLE: EmojiTerribleIcon,
		SAD: EmojiSadIcon,
		NEUTRAL: EmojiNeutralIcon,
		GOOD: EmojiGoodIcon,
		GREAT: EmojiGreatIcon,
	};
	return emojis[type] || emojis['NEUTRAL'];
};

export const SatisfactionSurvey: FC<SatisfactionSurveyProps> = ({
	quickResponsesMessage,
	satisfactionConfigButtons,
	onAction,
}) => {
	const { responses, flowStep } = quickResponsesMessage;
	const customClasses = useSatisfactionSurveyStyles({ numberOfButtons: satisfactionConfigButtons.length });

	const createEmojiButtons = () =>
		satisfactionConfigButtons.map((button, index) => (
			<IconButton
				key={index}
				className={customClasses.buttonWidth}
				aria-label={responses[index].content}
				onClick={(e) => onAction(responses[index], e, flowStep)}
			>
				<Icon
					viewBox={'0 0 32 32'}
					icon={getEmoji(button.emojiType)}
					size={'large'}
					iconType={'systemTextDefault'}
					alt={button.emojiType}
				/>
			</IconButton>
		));

	return <div className={customClasses.container}>{createEmojiButtons()}</div>;
};
