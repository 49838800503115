import { pipe } from 'fp-ts/lib/function';
import { fromNullable, map, toNullable } from 'fp-ts/lib/Option';
import { memo, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { SchedulingWrapper } from '../scheduling-wrapper/scheduling-wrapper';
import { BlockItSchedulingComponent } from './blockit-scheduling.component';

interface BlockItSettings {
	slug: string;
	specialtyId: string;
	locationId?: string;
	visitTypeId?: string;
}

interface BlockItSchedulingData {
	clientLogoURL: string;
	blockIt: BlockItSettings;
}

export const BlockItSchedulingContainer = memo(() => {
	const { clientName, clientUserId } = useParams<{ clientUserId: string; clientName: string }>();
	const { getBlockItSchedulingSettings } = useContext(APIServiceContext);

	const [blockItData, setblockItData] = useState<BlockItSchedulingData>();

	useEffect(() => {
		getBlockItSchedulingSettings({ clientName, clientUserId })
			.then(setblockItData)
			.catch(() => console.error('Error while fetching BlockIt Scheduling data.'));
	}, []);

	return pipe(
		fromNullable(blockItData),
		map(({ clientLogoURL, blockIt }) => (
			<SchedulingWrapper clientLogoURL={clientLogoURL}>
				<BlockItSchedulingComponent blockItSettings={blockIt} />
			</SchedulingWrapper>
		)),
		toNullable,
	);
});
