import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { CARD_MAX_WIDTH } from '../../../utils/sizes.utils';

export const useVideoStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			boxShadow,
			colorScheme: {
				colorVariables: { colorMediumShadow, colorPrimary },
			},
		},
	}) => ({
		root: {
			maxWidth: CARD_MAX_WIDTH,
			maxHeight: 280,
		},
		video: {
			display: 'block',
			borderRadius: sizes.size_1,
			maxWidth: '100%',
			maxHeight: 280,
			boxShadow: boxShadow.angle.xsmall,
			border: `1px solid ${colorMediumShadow}`,
			'&:focus-visible': {
				outline: `2px solid ${colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
	}),
);
