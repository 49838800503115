/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-prototype-builtins */
export const isNonEmpty = (obj: Record<string, any>): boolean => Object.keys(obj).length > 0;

export const mergeObjects = <T>(obj1: T, obj2: T): T => {
	const result: any = { ...obj1 };

	for (const key in obj2) {
		if (!result.hasOwnProperty(key)) {
			result[key] = obj2[key];
		} else if (Array.isArray(obj2[key])) {
			result[key] = (obj2[key] as any).map((r: any, i: number) =>
				typeof r === 'object' ? mergeObjects(r, result[key][i]) : r,
			);
		} else if (obj2[key] !== null && obj2[key] !== undefined) {
			if (typeof obj2[key] === 'object') {
				result[key] = mergeObjects(result[key], obj2[key]);
			}
		}
	}
	return result;
};
