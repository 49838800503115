import { ProviderAction, ProviderResource } from '../../context/api-service/api-service.model';
import { Provider } from '../../models/message.model';
import {
	DoctorSearchFilterGroup,
	DoctorSearchFilterType,
} from './doctor-search-filters/doctor-search-filters.component';

type DoctorSearchFilterTypeModel = 'check' | 'option' | 'select' | 'list';

export type FetchingDoctorsState = 'INITIAL' | 'NEW' | 'LOAD_MORE';
export type FiltersTypeUpdate = 'RESET' | 'TRY_AGAIN' | 'FILTERS_APPLY';

export type DoctorSearchProvider = Omit<
	Provider,
	'url' | 'state' | 'city' | 'address' | 'latitude' | 'longitude' | 'link' | 'gender'
>;

export interface DoctorSearchFilterItem {
	label: string;
	value: string;
	selected?: boolean;
}

export interface DoctorSearchFilter {
	name: string;
	type: DoctorSearchFilterTypeModel;
	label: string;
	multi?: boolean;
	required?: boolean;
	group?: string;
	value?: string;
	items?: DoctorSearchFilterItem[];
	placeholder?: string;
}

export type ProviderResponseType = 'flowStep' | 'data' | 'webUrlExternal';
export interface ProviderResponse {
	content: string;
	responseContext: string;
	type: ProviderResponseType;
	link?: string;
}
export interface ProviderInfo {
	responses: ProviderResponse[];
	data: DoctorSearchProvider;
}

export const EMPTY_DOCTORS_LIST = {
	paging: null,
	items: [],
};

export const buildQueryString = (updatedFilters: DoctorSearchFilterGroup[], queryString: string): string => {
	const searchParams = updatedFilters.reduce((query, filter) => {
		let filterResultQuery: string;
		if (filter.name) {
			filterResultQuery = filter.items.reduce(
				(acc, item) => (item.isSelected ? `${acc}&${filter.name}=${item.key}` : acc),
				'',
			);
		} else {
			filterResultQuery = filter.items.reduce(
				(acc, item) => (item.isSelected ? `${acc}&${item.key}=true` : acc),
				'',
			);
		}
		return `${query}${filterResultQuery}`;
	}, '');
	return `${queryString}${searchParams}`;
};

const getResponseType = (action: ProviderAction): ProviderResponseType => {
	switch (action.type) {
		case 'link':
			return 'webUrlExternal';
		case 'message':
			return 'flowStep';
		case 'data':
			return 'data';
	}
};

const getResponseContext = (action: ProviderAction): string => {
	switch (action.type) {
		case 'data':
			return action.value;
		case 'link':
			return action.label;
		case 'message':
			return action.flowStep || action.value;
	}
};

export const mapProviderResourceToProviderInfo = (providerResources: ProviderResource[]): ProviderInfo[] =>
	providerResources.map((providerResource) => ({
		data: providerResource.provider,
		responses: providerResource.actions.map((action) => ({
			content: action.label,
			responseContext: getResponseContext(action),
			link: action.type === 'link' ? action.value : undefined,
			type: getResponseType(action),
		})),
	}));

const createFiltergroupFromMultipleFilters = (filtersGroup: DoctorSearchFilter[]): DoctorSearchFilterGroup =>
	filtersGroup.reduce(
		(acc, filter) => {
			if (!acc.title && filter.group) {
				acc.title = filter.group;
			}
			acc.items.push({
				key: filter.name,
				label: filter.label,
				isSelected: filter.type === 'check' ? filter.value === 'true' : false,
				isDisabled: !!filter.required,
			});
			acc.required = !!filter.required;
			return acc;
		},
		{ title: '', items: [], type: 'CHECK_LIST', required: false, isMulti: true } as DoctorSearchFilterGroup,
	);

export const mapFilterModelToUIFilter = (filterModel: DoctorSearchFilter): DoctorSearchFilterGroup => {
	const type: DoctorSearchFilterType =
		filterModel.type === 'select' && !filterModel.multi
			? 'SELECT'
			: filterModel.type === 'select' && filterModel.multi
				? 'MULTISELECT'
				: filterModel.type === 'option' || !filterModel.multi
					? 'LIST'
					: 'CHECK_LIST';

	const filterItems = filterModel.items || [];
	const items = filterItems.map((filterItem) => ({
		key: filterItem.value,
		label: filterItem.label,
		isSelected: !!filterItem.selected,
		isDisabled: false,
	}));
	return {
		title: filterModel.label,
		items,
		type,
		name: filterModel.name,
		placeholder: filterModel.placeholder,
		required: !!filterModel.required,
		isMulti: !!filterModel.multi,
	};
};

export const createUIFiltersGroups = (filters: DoctorSearchFilter[]): DoctorSearchFilterGroup[] => {
	const result: DoctorSearchFilterGroup[] = [];
	let filtersGroup: DoctorSearchFilter[] = [];

	filters.forEach((filter, index) => {
		if (filter.type === 'check' && filter.group) {
			if (filtersGroup.length === 0 || filtersGroup[filtersGroup.length - 1].group === filter.group) {
				filtersGroup.push(filter);
			} else {
				// another group is coming
				// process current items for group
				const mappedFiltersGroup = createFiltergroupFromMultipleFilters(filtersGroup);
				result.push(mappedFiltersGroup);
				filtersGroup = [];
				filtersGroup.push(filter);
			}
		} else {
			if (filtersGroup.length !== 0) {
				const mappedFiltersGroup = createFiltergroupFromMultipleFilters(filtersGroup);
				result.push(mappedFiltersGroup);
				filtersGroup = [];
			}
			const filterGroup = mapFilterModelToUIFilter(filter);
			result.push(filterGroup);
		}
		// If the last element is of group type
		// Group should be built and pushed to the result
		if (index === filters.length - 1 && filtersGroup.length !== 0) {
			const mappedFiltersGroup = createFiltergroupFromMultipleFilters(filtersGroup);
			result.push(mappedFiltersGroup);
		}
	});

	return result;
};

export const getEventNameByFiltersUpdateType = (
	type: FiltersTypeUpdate,
): 'doctorSearchApplyFilters' | 'doctorSearchResetFilters' | 'doctorSearchTryAgain' => {
	switch (type) {
		case 'FILTERS_APPLY':
			return 'doctorSearchApplyFilters';
		case 'RESET':
			return 'doctorSearchResetFilters';
		case 'TRY_AGAIN':
			return 'doctorSearchTryAgain';
	}
};
