import classNames from 'classnames';
import { FC, useState } from 'react';

import { LocationResponses, QuickResponseLocation } from '../../../../models/message.model';
import { createLocationAttachment } from '../../../../models/socket.model';
import { Chip } from '../../../../ui-kit/chip/chip.component';
import { Icon } from '../../../../ui-kit/icon/icon.component';
import { MyLocationIcon } from '../../../../ui-kit/icons/my-location.icon';
import { getUserLocation } from '../../../../utils/location.utils';
import { OnSenMessageFunc } from '../../../widget-wrapper/widget-wrapper.model';
import { LocationFinderIndicator } from './location-finder-indicator/location-finder-indicator.component';
import { useLocationFinderrendererStyles } from './location-finder-renderer.styles';

interface LocationFinderProps {
	responses: LocationResponses[];
	onResponse: OnSenMessageFunc;
	step?: FindingLocationStep;
	className?: string;
}

type FindingLocationStep = 'INITIAL' | 'FINDING' | 'COMPLETE';

export const LocationFinderQuickResponseRenderer: FC<LocationFinderProps> = ({
	responses,
	step,
	className,
	onResponse,
}) => {
	const classes = useLocationFinderrendererStyles();
	const [locationStep, setLocationStep] = useState<FindingLocationStep>('INITIAL');

	const findLocation = (response: QuickResponseLocation) => {
		setLocationStep('FINDING');
		getUserLocation().then((location) => {
			const attachment =
				location.status === 'SUCCESS'
					? createLocationAttachment(location.latitude, location.longitude)
					: createLocationAttachment();
			onResponse(response.responseContext, response.content, true, false, [attachment]);
			setLocationStep('COMPLETE');
		});
	};

	const renderResponses = (responses: LocationResponses[]): JSX.Element[] =>
		responses.map((response) => {
			switch (response.type) {
				case 'text':
					return (
						<Chip
							dataTestingLabel={'quick-response-location-text-btn'}
							key={response.responseContext}
							onClick={() => onResponse(response.responseContext, response.content)}
							label={response.content}
						/>
					);
				case 'location':
					return (
						<Chip
							icon={
								<Icon
									size={'large'}
									iconType={'chipIcon'}
									icon={MyLocationIcon}
									className={classes.locationIcon}
								/>
							}
							dataTestingLabel={'quick-response-location-find-location-btn'}
							key={response.responseContext}
							onClick={() => findLocation(response)}
							label={response.content}
						/>
					);
			}
		});
	// TODO: add same animation as for steps transition
	const renderContent = (step: FindingLocationStep) => {
		switch (step) {
			case 'INITIAL':
				return <div className={classNames(classes.buttonWrapper, className)}>{renderResponses(responses)}</div>;
			case 'FINDING':
				return <LocationFinderIndicator />;
			case 'COMPLETE':
				return null;
		}
	};

	return renderContent(step || locationStep);
};
