import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../../models/dimensions.model';

export const useAppointmentSummaryInfoStyles = makeStyles<Theme>(
	({ palette: { sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		mainSubSection: {
			padding: `${sizes.size_2}px ${sizes.size_1}px`,
		},
		scheduleTime: {
			...smallViewPort.typeHeading1,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading1,
			},
		},
		providerName: {
			paddingTop: sizes.size_1,
			...smallViewPort.body,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.body,
			},
		},
	}),
);
