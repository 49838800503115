import React, { FC, memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuTextModel } from '../../context/api-service/api-service.model';
import { ConfigContext } from '../../context/config.context';
import { LiveChatContext } from '../../context/live-chat-context';
import { ServicesContext } from '../../context/services.context';
import { SessionContext } from '../../context/session.context';
import {
	LanguageAbbreviation,
	setLanguageOnLocalStorage,
	toLanguageAbbreviation,
	toLanguageNameLowerCase,
} from '../../models/languages.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Effect } from '../../utils/function.utils';
import { clearLiveChatStorageValues } from '../live-chat/live-chat.model';
import { Menu } from './widget-menu.component';
import { getLeaveLiveChatCommand, getMenuItemsByLanguage } from './widget-menu.model';

interface MenuContainerProps {
	rootRef?: React.RefObject<HTMLDivElement>;
	onStateChange?: Effect<boolean>;
}

export const MenuContainer: FC<MenuContainerProps> = memo(({ rootRef, onStateChange }) => {
	const { i18n } = useTranslation();

	const {
		appConfig: { menu, languages, settings },
		appOptions: { client },
	} = useContext(ConfigContext);

	const {
		state: { onSendMessage, sessionToken, isWidgetDisabled },
	} = useContext(SessionContext);
	const { state, onUpdate } = useContext(LiveChatContext);

	const {
		webTrackerService: { sendEvent },
	} = useContext(ServicesContext);

	const menuItems = getMenuItemsByLanguage(i18n.language, menu);

	const handleMenuSelect =
		(isLiveChatOn: boolean) => (text: string, orininalText: string, shouldShowInWidget?: boolean) => {
			const leaveChatCommand = getLeaveLiveChatCommand(
				state.status,
				state.isLiveChatSessionOver,
				state.config?.leaveQueue.commandValue,
			);

			return isLiveChatOn
				? onUpdate({
						type: 'CONFIRMATION_MODAL',
						payload: {
							action: () => {
								leaveChatCommand && onSendMessage(leaveChatCommand, leaveChatCommand, true);
								onUpdate({ type: 'LEAVE' });
								setTimeout(() => onSendMessage(text, orininalText, shouldShowInWidget), 500);
								clearLiveChatStorageValues(client);
							},
						},
					})
				: onSendMessage(text, orininalText, shouldShowInWidget);
		};

	const onLanguageChange = (language: LanguageAbbreviation) => {
		const selectedLanguage = toLanguageNameLowerCase(language);
		i18n.changeLanguage(language.toLowerCase());
		setLanguageOnLocalStorage(language.toLowerCase(), sessionToken);
		onSendMessage(selectedLanguage, selectedLanguage, false);
	};

	const selectedLanguage = toLanguageAbbreviation(i18n.language);

	const handleStateChange = (flag: boolean, e?: EventType) => {
		if (state.isLiveChatShown) {
			onUpdate({ type: 'SET_ACTIVE_STATUS', payload: !flag });
		}
		if (state.isLiveChatShown && state.newMessagesIndex && flag) {
			onUpdate({ type: 'SET_NEW_MESSAGE_INDEX', payload: null });
		}
		onStateChange && onStateChange(flag);
		e && sendEvent('onMenuIconClick', e);
	};

	const getMenuText = (language: string): MenuTextModel => {
		const localizedMenuText = settings.menuTextSetting.find((localization) => localization.locale === language);
		return localizedMenuText?.menu ?? settings.menuTextSetting[0].menu;
	};

	return menuItems.length || languages.length ? (
		<Menu
			isDisabled={isWidgetDisabled}
			menuText={getMenuText(i18n.language)}
			menuItems={menuItems}
			onMenuSelect={handleMenuSelect(state.isLiveChatShown)}
			languages={languages}
			selectedLanguage={selectedLanguage}
			onLanguageChange={onLanguageChange}
			onStateChange={handleStateChange}
			rootRef={rootRef}
			isLiveChatOn={state.isLiveChatShown}
		/>
	) : null;
});
