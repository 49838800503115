import { MenuItem, MenuProps } from '@material-ui/core';
import { default as MaterialSelect } from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';

import { Effect } from '../../utils/function.utils';
import { Icon } from '../icon/icon.component';
import { ArrowDownIcon } from '../icons/arrow-down.icon';
import { DoneWhiteIcon } from '../icons/done-white.icon';
import { useDropdownStyles } from './dropdown.styles';

export interface SelectOption {
	label: string;
	value: string;
	isSelected: boolean;
}

export interface DropdownProps {
	options: SelectOption[];
	dataTestingLabel?: string;
	placeholder?: string;
	multiple?: boolean;
	required?: boolean;
	onChange: Effect<string[]>;
}

type DropdownValue = string | string[];

const getSelectedFromProps = (options: SelectOption[]): string[] =>
	options.filter((option) => option.isSelected).map((option) => option.value);

export const Dropdown: FC<DropdownProps> = ({
	placeholder,
	options,
	dataTestingLabel,
	multiple,
	required,
	onChange,
}) => {
	const selectRef = useRef<HTMLInputElement>();
	const [selectedOptionsInState, setSelectedOptionsInState] = useState<string[]>(getSelectedFromProps(options));
	const classes = useDropdownStyles();

	const handleChange: SelectInputProps['onChange'] = (event) => {
		const selectedOptions = event.target.value as DropdownValue;
		const isSelectedOptionsArray = Array.isArray(selectedOptions);
		if (isSelectedOptionsArray) {
			if (required && selectedOptions.length === 0) {
				return event.preventDefault();
			} else {
				setSelectedOptionsInState(selectedOptions);
			}
		} else {
			setSelectedOptionsInState(selectedOptions ? [selectedOptions] : []);
		}

		onChange(isSelectedOptionsArray ? selectedOptions : [selectedOptions]);
	};

	const renderValues = (selected: string[]): string => {
		if (Array.isArray(selected) && selected.length > 0 && !selected.includes('')) {
			return options
				.filter((option) => selected.includes(option.value))
				.map((option) => option.label)
				.join(', ');
		}
		return placeholder || '';
	};

	const getMenuProps: Partial<MenuProps> = {
		classes: {
			paper: classes.paper,
			list: multiple ? classes.listMultiple : classes.list,
		},
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'left',
		},
		getContentAnchorEl: null,
		disableScrollLock: true,
	};

	const className = classNames(selectedOptionsInState.length === 0 && classes.placeholderTextColor);

	useEffect(() => {
		setSelectedOptionsInState(options.filter((option) => option.isSelected).map((option) => option.value));
	}, []);

	const getMultiselectIcon = (isSelected: boolean): JSX.Element => (
		<div className={classNames(classes.checkbox, isSelected ? classes.checked : classes.unchecked)}>
			{isSelected ? <Icon icon={DoneWhiteIcon} iconType={'buttonIcon'} /> : null}
		</div>
	);

	return (
		<MaterialSelect
			ref={selectRef}
			displayEmpty={placeholder !== undefined}
			disableUnderline
			value={selectedOptionsInState}
			onChange={handleChange}
			data-testing-label={dataTestingLabel}
			MenuProps={getMenuProps}
			multiple={multiple}
			renderValue={(selected) => renderValues(selected as string[])}
			IconComponent={(props) => (
				<div className={classes.iconWrapper}>
					<Icon icon={ArrowDownIcon} alt={`select ${placeholder || ''}`} {...props} />
				</div>
			)}
			classes={{ root: classes.root, select: classes.select }}
			className={className}>
			{!multiple && placeholder && (
				<MenuItem value={''}>
					<em>{placeholder}</em>
				</MenuItem>
			)}
			{options.map((option) => (
				<MenuItem
					value={option.value}
					selected
					key={option.value}
					data-testing-label={`option-item-${option.value}`}>
					{option.label}
					{multiple && getMultiselectIcon(selectedOptionsInState.indexOf(option.value) > -1)}
				</MenuItem>
			))}
		</MaterialSelect>
	);
};
