import { createContext, FC, ReactNode, useEffect, useState } from 'react';

import { EnvironmentType } from '../models/app.model';
import { Message } from '../models/message.model';
import { OnSenMessageFunc } from '../ui/widget-wrapper/widget-wrapper.model';
import { getClientCookieName, getCookie } from '../utils/cookies.utils';
import { constVoid, Effect } from '../utils/function.utils';
import { ResponseError } from '../utils/types.utils';
import { getUrlParameters } from '../utils/url-parameters';

export interface SessionContextState {
	isWidgetDisabled: boolean;
	sessionToken: string;
	autocompleteUri?: string;
	onSendMessage: OnSenMessageFunc;
	handleUnauthorizedSessionError: Effect<ResponseError>;
	isLoading: boolean;
	environment: EnvironmentType;
	shownMessages: Message[];
}

const DEFAULT_SESSION_CONTEXT_VALUE: SessionContextState = {
	isWidgetDisabled: false,
	sessionToken: '',
	onSendMessage: constVoid,
	handleUnauthorizedSessionError: constVoid,
	isLoading: false,
	environment: 'local',
	shownMessages: [],
};
interface SessionContextProviderProps {
	children: ReactNode;
	client?: string;
}
export interface SessionContextType {
	state: SessionContextState;
	onUpdate(data: Partial<SessionContextState>): void;
}

export const SessionContext = createContext<SessionContextType>({
	state: DEFAULT_SESSION_CONTEXT_VALUE,
	onUpdate: constVoid,
});

export const SessionContextProvider: FC<SessionContextProviderProps> = ({ client: clientFromProps, children }) => {
	const { client: clientFromUrl } = getUrlParameters(window.location.search);
	const clientName = clientFromProps || clientFromUrl || 'gy-fd';

	const [state, setState] = useState(DEFAULT_SESSION_CONTEXT_VALUE);

	useEffect(() => {
		if (state.sessionToken === '') {
			const clientCookieName = getClientCookieName(clientName);
			const clientToken = getCookie(clientCookieName);

			if (clientToken) {
				setState((state) => ({ ...state, sessionToken: clientToken }));
			}
		}
	}, [state.sessionToken]);

	const handleStateUpdate = (data: Partial<SessionContextState>) => {
		const updatedState = {
			...state,
			...data,
		};
		setState(updatedState);
	};

	return <SessionContext.Provider value={{ state, onUpdate: handleStateUpdate }}>{children}</SessionContext.Provider>;
};
