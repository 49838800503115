import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ThumbsDownOutlineIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} height={20} width={20} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<path d={'M0,0 L20,0 L20,20 L0,20 L0,0 Z M0,0 L20,0 L20,20 L0,20 L0,0 Z'}></path>
				<path
					d={
						'M12.4545455,2 L5.09090909,2 C4.41181818,2 3.83090909,2.425 3.58545455,3.037 L1.11454545,9.0295 C1.04090909,9.225 1,9.429 1,9.65 L1,11.35 C1,12.285 1.73636364,13.05 2.63636364,13.05 L7.79909091,13.05 L7.02181818,16.9345 L6.99727273,17.2065 C6.99727273,17.555 7.13636364,17.878 7.35727273,18.1075 L8.22454545,19 L13.6163636,13.3985 C13.9109091,13.0925 14.0909091,12.6675 14.0909091,12.2 L14.0909091,3.7 C14.0909091,2.765 13.3545455,2 12.4545455,2 Z M12.4545455,12.2 L8.90363636,15.889 L10,11.35 L2.63636364,11.35 L2.63636364,9.65 L5.09090909,3.7 L12.4545455,3.7 L12.4545455,12.2 Z M16.7272727,2 C17.2795575,2 17.7272727,2.44771525 17.7272727,3 L17.7272727,11.2 C17.7272727,11.7522847 17.2795575,12.2 16.7272727,12.2 C16.174988,12.2 15.7272727,11.7522847 15.7272727,11.2 L15.7272727,3 C15.7272727,2.44771525 16.174988,2 16.7272727,2 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
