import { FC, useEffect, useRef } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import {
	formatPhoneNumber,
	isInternationalPhoneUSFormat,
	PHONE_NUMBER_DIGITS,
	sanitizePhoneNumber,
	US_INTERNATIONAL_PHONE_NUMBER_DIGITS,
} from '../../utils/validations.utils';
import { InputValidationResult, InputWithValidation } from '../input-with-validation/input-with-validation.component';

const validate = (value: string, t: TFunction<'translation'>): InputValidationResult => {
	const containsInvalidChars = value.replace(/[^\d\s\\+().-]/g, '').length < value.length;

	const sanitized = sanitizePhoneNumber(value);
	const isInternationalFormat = value.slice(0, 1) === '+';
	const isInternationalUSNumber = isInternationalPhoneUSFormat(value);

	if (containsInvalidChars) {
		return {
			isValid: false,
			error: t(
				'phoneInputInvalidCharsError',
				'The Phone number contains invalid characters. Characters allowed ()+-.',
			),
			displayWhileTyping: true,
		};
	}
	if (isInternationalFormat && !isInternationalUSNumber) {
		return {
			isValid: false,
			error: t(
				'phoneInputNotInternationalUSError',
				'The Phone number value is not a US phone number. Please enter a US phone number.',
			),
			displayWhileTyping: true,
		};
	}
	if (isInternationalUSNumber && sanitized.length > US_INTERNATIONAL_PHONE_NUMBER_DIGITS) {
		return {
			isValid: false,
			error: t(
				'phoneInputInternationalUSTooLongError',
				'The Phone number value is too long. Please enter an 11-digit phone number.',
			),
			displayWhileTyping: true,
		};
	}
	if (isInternationalUSNumber && sanitized.length < US_INTERNATIONAL_PHONE_NUMBER_DIGITS) {
		return {
			isValid: false,
			error: t(
				'phoneInputInternationalUSTooShortError',
				'The Phone number value is too short. Please enter an 11-digit phone number.',
			),
			displayWhileTyping: false,
		};
	}
	if (!isInternationalUSNumber && sanitized.length > PHONE_NUMBER_DIGITS) {
		return {
			isValid: false,
			error: t(
				'phoneInputTooLongError',
				'The Phone number value is too long. Please enter a 10-digit phone number.',
			),
			displayWhileTyping: true,
		};
	}
	if (!isInternationalUSNumber && sanitized.length < PHONE_NUMBER_DIGITS) {
		return {
			isValid: false,
			error: t(
				'phoneInputTooShortError',
				'The Phone number value is too short. Please enter a 10-digit phone number.',
			),
			displayWhileTyping: false,
		};
	}
	return {
		isValid: true,
	};
};

interface PhoneInputProps {
	shouldAutoFocus?: boolean;
	className?: string;
	onSubmit: Effect<string>;
}

export const PhoneInput: FC<PhoneInputProps> = ({ className, shouldAutoFocus, onSubmit }) => {
	const { t } = useTranslation();
	const inputRef = useRef<Nullable<HTMLInputElement>>(null);
	useEffect(() => {
		if (shouldAutoFocus) {
			inputRef.current?.focus();
		}
	}, [shouldAutoFocus]);

	return (
		<InputWithValidation
			inputRef={inputRef}
			type={'tel'}
			label={t('phoneNumberInputLabel', 'Phone number')}
			placeholder={'(555) 555-5555'}
			className={className}
			onSubmit={onSubmit}
			onValidationSuccess={formatPhoneNumber}
			validation={validate}
			testingLabels={{
				root: 'phone-input-root',
				input: 'phone-input',
				error: 'phone-input-error',
			}}
		/>
	);
};
