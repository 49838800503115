import { makeStyles, Theme } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles/withStyles';

import { StackPanelCustomStyle } from './stack-panel.component';

interface StackPanelStyleProps {
	isMobile: boolean;
	isFullScreen: boolean;
}
export const useStackPanelStyles = (
	customStyle?: Partial<Pick<StackPanelCustomStyle, 'main' | 'overlay'>>,
): ((props: StackPanelStyleProps) => ClassNameMap<string>) =>
	makeStyles<Theme, StackPanelStyleProps>(({ palette: { colorScheme } }) => ({
		stackPanelStyle: {
			width: '80%',
			maxWidth: 336,
			display: 'flex',
			flexDirection: 'column',
			top: 0,
			bottom: 0,
			left: 0,
			zIndex: 10,
			position: 'absolute',
			background: colorScheme.colorVariables.colorBgSurface,
			borderRight: `1px solid ${colorScheme.colorVariables.colorMediumShadow}`,
			...customStyle?.main,
		},
		overlay: {
			background: colorScheme.colorVariables.colorMediumShadow,
			position: 'absolute',
			width: '100%',
			height: '100%',
			zIndex: 9,
			top: 0,
			...customStyle?.overlay,
		},
	}));

export const overlayEffect = makeStyles(({ palette: { transitionConfig } }) => ({
	enter: {
		opacity: 0,
	},
	enterActive: {
		opacity: 1,
		transition: `all ${transitionConfig.transitionInDuration}ms`,
	},
	exit: {
		opacity: 1,
	},
	exitActive: {
		opacity: 0,
		transition: `all ${transitionConfig.transitionOutDuration}ms`,
	},
}));

export const panelAnimationStyles = (
	customStyle?: Partial<Pick<StackPanelCustomStyle, 'transition'>>,
): (() => ClassNameMap<string>) =>
	makeStyles(({ palette: { transitionConfig } }) => ({
		enter: {
			transform: 'translateX(-100%)',
		},
		enterActive: {
			transform: 'translateX(0%)',
			transition: `all ${transitionConfig.transitionInDuration}ms`,
		},
		exit: {
			transform: 'translateX(0%)',
		},
		exitActive: {
			transform: 'translateX(-100%)',
			transition: `all ${transitionConfig.transitionOutDuration}ms`,
		},
		...customStyle?.transition,
	}));
