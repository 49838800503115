import { FC, memo } from 'react';

import { useImageStyles } from './image.styles';

interface ImageProps {
	src: string;
}

export const Image: FC<ImageProps> = memo(({ src }) => {
	const classes = useImageStyles();

	return (
		<div className={classes.root}>
			<img alt={'media'} src={src} className={classes.image} />
		</div>
	);
});
