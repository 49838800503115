import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@material-ui/core';
import { forwardRef } from 'react';

export type IconButtonProps = MuiIconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(({ children, ...props }, ref) => (
	<MuiIconButton disableRipple ref={ref} {...props}>
		{children}
	</MuiIconButton>
));
