import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames';
import { FC, Fragment, RefObject, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfigContext } from '../../context/config.context';
import { SessionContext } from '../../context/session.context';
import { MOBILE_MEDIA_QUERY, SMALL_HEIGHT_MEDIA_QUERY } from '../../models/dimensions.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Avatar } from '../../ui-kit/avatar/avatar.component';
import { EnvironmentLabel } from '../../ui-kit/environment-label/environment-label.component';
import { IconButton } from '../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { CloseIcon } from '../../ui-kit/icons/close.icon';
import { isEmbedWidget } from '../../utils/embed.utils';
import { Effect } from '../../utils/function.utils';
import { MenuContainer } from '../widget-menu/widget-menu.container';
import { LivechatVisibilityStatus } from '../widget-wrapper/widget-wrapper.model';
import { useWidgetHeaderStyles } from './widget-header.styles';

interface WidgetHeaderProps {
	onWidgetClose: Effect<EventType>;
	isOpen: boolean;
	liveChatVisibilityStatus: LivechatVisibilityStatus;
	rootRef?: RefObject<HTMLDivElement>;
}

export const WidgetHeader: FC<WidgetHeaderProps> = ({ onWidgetClose, isOpen, rootRef, liveChatVisibilityStatus }) => {
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);

	const {
		appConfig: {
			settings: { addFullScreenCloseBtn },
		},
		appOptions: { fullScreen, hideCloseBtn },
	} = useContext(ConfigContext);
	const isEmbed = isEmbedWidget();

	const {
		state: { isWidgetDisabled, environment },
	} = useContext(SessionContext);
	const { t } = useTranslation();

	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
	const isSmallHeight = useMediaQuery(SMALL_HEIGHT_MEDIA_QUERY);
	const isLiveChatShown = liveChatVisibilityStatus === 'SHOWN';
	const isLeavingLiveChatShown = liveChatVisibilityStatus === 'LEAVING';

	const classes = useWidgetHeaderStyles({ isMobile, isSmallHeight });

	const shouldShowCloseButton = !hideCloseBtn && (!fullScreen || addFullScreenCloseBtn || isEmbed);

	const rootClasses = classNames(classes.root, isOpen && classes.openedWidget);

	const avatarClassNames = classNames(classes.avatar, isLiveChatShown && classes.hidden);
	const liveChatTitleClassNames = classNames(
		classes.liveChatTitle,
		isLiveChatShown && classes.shown,
		isLeavingLiveChatShown && classes.hidden,
	);
	const centerComponent = (
		<Fragment>
			<Avatar dataTestingLabel={'widget-header-logo'} className={avatarClassNames} />
			<h4 className={liveChatTitleClassNames}>{t('liveChatHeaderTitle', 'Chat')}</h4>
		</Fragment>
	);

	return (
		<header className={rootClasses}>
			<EnvironmentLabel environment={environment} className={classes.environmentLabel} />
			<div className={classes.menu}>
				<MenuContainer rootRef={rootRef} onStateChange={setIsButtonDisabled} />
				{centerComponent}
			</div>
			{shouldShowCloseButton && (
				<IconButton
					disabled={isButtonDisabled || isWidgetDisabled}
					aria-label={'close assistant'}
					id={'widget-header-close-widget-button'}
					disableRipple
					className={classes.closeButton}
					onClick={onWidgetClose}
					data-testing-label={'widget-header-close-btn'}
				>
					<Icon icon={CloseIcon} size={'large'} alt={'close assistant'} />
				</IconButton>
			)}
		</header>
	);
};
