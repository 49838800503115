import { makeStyles } from '@material-ui/core';

export const useLLMLabelStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
			sizes,
			borderRadius,
		},
	}) => ({
		root: {
			borderRadius: borderRadius.commonRadius,
			width: 'fit-content',
			'&:focus-visible': {
				outline: 'none',
			},
		},
		chip: {
			backgroundColor: colorVariables.colorInactive,
			fontSize: 14,
			color: colorVariables.colorText,
			minHeight: sizes.size_4,
			'& > span': {
				padding: 0,
			},
			'&:hover': {
				backgroundColor: colorVariables.colorInactive,
			},
		},
	}),
);

export const useLLMLabelTooltipStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
			borderRadius,
			sizes,
		},
		typography: { smallViewPort },
	}) => ({
		popper: {
			width: 219,
		},
		arrow: {
			'&:before': {
				backgroundColor: colorVariables.colorSysBgOff,
			},
		},
		tooltip: {
			borderRadius: borderRadius.commonRadius,
			backgroundColor: colorVariables.colorSysBgOff,
			...smallViewPort.typeNoteParagraph,
			color: colorVariables.colorHeadOnDark,
			padding: sizes.size_2,
			'& a, & a:visited, & a:hover': {
				fontWeight: 'bold',
				fontSize: 14,
				color: colorVariables.colorHeadOnDark,
				textDecorationColor: colorVariables.colorHeadOnDark,
				filter: 'none',
			},
		},
	}),
);

export const useLLMLabelContainerStyles = makeStyles(({ palette: { sizes } }) => ({
	root: {
		padding: `1px ${sizes.size_1}%`,
		marginTop: '0 !important',
		marginBottom: sizes.size_2,
		display: 'flex',
		flex: 1,
		alignItems: 'flex-start',
	},
}));
