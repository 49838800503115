import { addMinutes } from 'date-fns';

import { getOrEmtyString } from '../../../utils/option.utils';
import { Nullable } from '../../../utils/types.utils';

export interface ICSEventData {
	name: string;
	url: string;
	location: string;
	details?: string;
	startsAt: string;
	endsAt: string;
}

export interface AppointmentDateTimeValues {
	appointmentDate: string;
	appointmentTime: string;
	appointmentDuration?: number;
}

export interface SummaryTemplates {
	additionalInformation?: string;
}

export interface SummaryLocation {
	name?: string;
	street?: string;
	city?: string;
	stateCode?: string;
	zip?: string;
}

export const EVENT_DURATION = 15;
const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/?api=1&query=';

export const copyContentValue = (value: string): Promise<void> => navigator.clipboard.writeText(value);

export const generateMapsURL = (address: string): string => `${GOOGLE_MAPS_URL}${encodeURIComponent(address)}`;

export const formatDateTimeToEventDateString = (date: Date): string => date.toISOString().replace(/[-:]|\.\d{3}/g, '');

export const createICSCalendarUrl = (data: ICSEventData): string => {
	const components = [
		'BEGIN:VCALENDAR',
		'VERSION:2.0',
		'BEGIN:VEVENT',
		`URL:${data.url}`,
		`DTSTART:${data.startsAt}`,
		`DTEND:${data.endsAt}`,
		`SUMMARY:${data.name}`,
		`DESCRIPTION:${data.details}`,
		`LOCATION:${data.location}`,
		'END:VEVENT',
		'END:VCALENDAR',
	];

	return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
};

export const getFormattedLocation = (location?: Nullable<SummaryLocation>): string => {
	const locationAddres = `${getOrEmtyString(location?.city)} ${getOrEmtyString(
		location?.stateCode,
	)} ${getOrEmtyString(location?.zip)}`;

	return `${getOrEmtyString(location?.name)} ${getOrEmtyString(location?.street)} ${locationAddres}`;
};

export const generateICSEventData = (
	name: string,
	appointmentDateTimeValues: AppointmentDateTimeValues,
	templates: SummaryTemplates,
	location?: Nullable<SummaryLocation>,
): ICSEventData => {
	const { appointmentDate, appointmentDuration } = appointmentDateTimeValues;
	const { additionalInformation } = templates;
	const locationInfoText = getFormattedLocation(location);
	const locationMapsHref = generateMapsURL(locationInfoText);

	const startDateTime = new Date(`${appointmentDate.replace(/(T.*)(Z|[+-](\d{2}:?\d{2}$)|([+-]\d{2}$))/, '$1')}`);
	const endDateTime = appointmentDuration
		? addMinutes(startDateTime, appointmentDuration)
		: addMinutes(startDateTime, EVENT_DURATION);

	return {
		name,
		url: locationMapsHref,
		location: locationInfoText,
		details: additionalInformation,
		startsAt: formatDateTimeToEventDateString(startDateTime),
		endsAt: formatDateTimeToEventDateString(endDateTime),
	};
};
