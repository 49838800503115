/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isDate } from 'date-fns';
import { isBoolean } from 'fp-ts/lib/boolean';
import { isNumber } from 'fp-ts/lib/number';
import { isString } from 'fp-ts/lib/string';

import {
	BasicMessage,
	Card,
	CardResponse,
	CarouselMessage,
	ClinicCard,
	DiagnosisCard,
	isClinicCards,
	isDiagnosisCards,
	isLocationCards,
	isProviderCards,
	LocationCard,
	ProviderCard,
	QuickResponseMessage,
	SimpleCard,
	TextMessage,
	VisitType,
} from '../models/message.model';

const NOT_FOUND_LABEL = 'Not found';

const getStringOrNotFound = <T>(value?: any, defaultValue?: T) => {
	if (value || value === '') {
		return isString(value) ? value : value.toString();
	}
	return defaultValue || NOT_FOUND_LABEL;
};

const getBooleanOrUndefined = (message: any, key: string): boolean | undefined => {
	if (key in message) {
		return isBoolean(message[key]) ? message[key] : !!message[key];
	}
	return undefined;
};

const isUndefinedOrNull = (v: any): boolean => v === undefined || v === null;
const mapNullOrUndefinedToString = (v: any) => (isUndefinedOrNull(v) ? v : v.toString());
const mapNullOrUndefinedToNumber = (v: any) => (isUndefinedOrNull(v) ? v : +v);
const mapNullOrUndefinedToBoolean = (v: any) => (isUndefinedOrNull(v) ? v : !!v);

const fallbackBasicMessage = (message: any): BasicMessage => ({
	text: getStringOrNotFound(message.text),
	incoming: false,
	isUndoable: getBooleanOrUndefined(message, 'isUndoable'),
	talkingtodoctor: getBooleanOrUndefined(message, 'talkingtodoctor'),
	metadata: message.metadata,
	isInputHidden: getBooleanOrUndefined(message, 'isInputHidden'),
	timeRemaining: isNumber(message.timeRemaining) ? message.timeRemaining : message.timeRemaining,
	hasHelpfulnessSurvey: getBooleanOrUndefined(message, 'hasHelpfulnessSurvey'),
	isExternal: getBooleanOrUndefined(message, 'isExternal'),
	timeStamp: isDate(message.timeStamp) ? message.timeStamp : undefined,
	messageId: isString(message.messageId) ? message.messageId : undefined,
	specialActions: Array.isArray(message.specialActions)
		? message.specialActions.map((specialAction: any) => ({
				...specialAction,
				action: specialAction.action.toString(),
				value: specialAction.value.toString(),
			}))
		: undefined,
	deliveredStatus: isString(message.deliveredStatus) ? message.deliveredStatus.toString() : undefined,
});

export const fallbackTextMessage = (message: any): TextMessage => ({
	...message,
	...fallbackBasicMessage(message),
	responseType: getStringOrNotFound<ResponseType>(message.responseType, '' as ResponseType),
	type: message.type,
	content: getStringOrNotFound(message?.content),
});

export const fallbackQuickResponseMessage = (message: any): QuickResponseMessage => ({
	...message,
	...fallbackBasicMessage(message),
	type: 'quickResponses',
	flowStep: getStringOrNotFound(message.flowStep),
	responseType: getStringOrNotFound<ResponseType>(message.responseType, '' as ResponseType),
	responses: message.responses
		? message.responses.map((response: any) => ({
				content: getStringOrNotFound(response.content),
				responseContext: getStringOrNotFound(response.responseContext),
				evidenceExists: false,
				type: 'text',
				buttonType: getStringOrNotFound(response.buttonType, 'button'),
			}))
		: [],
});

const mapToCardResponse = (response: any): CardResponse => ({
	...response,
	content: getStringOrNotFound(response.content),
	responseContext: getStringOrNotFound(response.responseContext),
	type: isString(response.type) ? response.type : ('quickResponse' as const),
});

const mapToVisitType = (value: any[]): VisitType[] => {
	if (isUndefinedOrNull(value)) {
		return value;
	}
	return value.map((valueItem) => ({
		id: getStringOrNotFound(valueItem.id),
		label: mapNullOrUndefinedToString(valueItem.label),
		name: mapNullOrUndefinedToString(valueItem.name),
		type: getStringOrNotFound(valueItem.type),
	}));
};

const mapToLocationCard = (card: any): LocationCard => ({
	...card,
	type: 'card' as const,
	title: getStringOrNotFound(card.title),
	nodeId: 'location_confirm' as const,
	responses: card.responses ? card.responses.map(mapToCardResponse) : [],
});

const mapToDiagnosysCard = (card: any): DiagnosisCard => ({
	...card,
	type: 'card' as const,
	cardType: 'diagnosis' as const,
	nodeId: getStringOrNotFound(card.nodeId),
	imageUrl: getStringOrNotFound(card.imageUrl),
	title: getStringOrNotFound(card.title),
	responses: card.responses ? card.responses.map(mapToCardResponse) : [],
	data: card.data
		? {
				...card.data,
				imageUrl: getStringOrNotFound(card.data.imageUrl),
				title: getStringOrNotFound(card.data.title),
				type: 'card' as const,
				cardType: 'diagnosis' as const,
			}
		: {
				imageUrl: NOT_FOUND_LABEL,
				title: NOT_FOUND_LABEL,
				type: 'card' as const,
				cardType: 'diagnosis' as const,
			},
});

const mapToClinicCard = (card: any): ClinicCard => ({
	...card,
	type: 'clinic' as const,
	title: getStringOrNotFound(card.title),
	nodeId: getStringOrNotFound(card.nodeId),
	responses: card.responses ? card.responses.map(mapToCardResponse) : [],
	data: card.data
		? {
				...card.data,
				address: getStringOrNotFound(card.data.address),
				addressPlaceholder: mapNullOrUndefinedToString(card.data.addressPlaceholder),
				clientOrganization: mapNullOrUndefinedToString(card.data.clientOrganization),
				disposition: mapNullOrUndefinedToString(card.data.disposition),
				distance: mapNullOrUndefinedToNumber(card.data.distance),
				externalDepartmentId: mapNullOrUndefinedToNumber(card.data.externalDepartmentId),
				externalProviderId: mapNullOrUndefinedToNumber(card.data.externalProviderId),
				fax: mapNullOrUndefinedToString(card.data.fax),
				latitude: mapNullOrUndefinedToNumber(card.data.latitude),
				longitude: mapNullOrUndefinedToNumber(card.data.longitude),
				link: mapNullOrUndefinedToString(card.data.link),
				city: mapNullOrUndefinedToString(card.data.city),
				state: mapNullOrUndefinedToString(card.data.state),
				mapImage: getStringOrNotFound(card.data.mapImage),
				name: getStringOrNotFound(card.data.name),
				phone: mapNullOrUndefinedToString(card.data.phone),
				phonePlaceholder: mapNullOrUndefinedToString(card.data.phonePlaceholder),
				scheduleUrl: mapNullOrUndefinedToString(card.data.scheduleUrl),
				schedulingType: mapNullOrUndefinedToString(card.data.schedulingType),
				sourceId: mapNullOrUndefinedToString(card.data.sourceId),
				type: 'clinic',
				url: mapNullOrUndefinedToString(card.data.url),
				entityUrl: mapNullOrUndefinedToString(card.data.entityUrl),
				visitTypes: mapToVisitType(card.data.visitTypes),
				waitingTime: mapNullOrUndefinedToNumber(card.data.waitingTime),
				waitingTimeString: mapNullOrUndefinedToString(card.data.waitingTimeString),
				waitingTimeTitleString: mapNullOrUndefinedToString(card.data.waitingTimeTitleString),
				operationHoursTitleString: mapNullOrUndefinedToString(card.data.operationHoursTitleString),
				operationHoursString: mapNullOrUndefinedToString(card.data.operationHoursString),
				operationHoursUrl: mapNullOrUndefinedToString(card.data.operationHoursUrl),
				operationHoursUrlString: mapNullOrUndefinedToString(card.data.operationHoursUrlString),
				nextAppointmentSlotString: mapNullOrUndefinedToString(card.data.nextAppointmentSlotString),
				nextAppointmentSlotTitleString: mapNullOrUndefinedToString(card.data.nextAppointmentSlotTitleString),
				availabilityString: mapNullOrUndefinedToString(card.data.availabilityString),
				availabilityTitleString: mapNullOrUndefinedToString(card.data.availabilityTitleString),
				openingHours: mapNullOrUndefinedToString(card.data.openingHours),
			}
		: {
				type: 'clinic' as const,
				name: NOT_FOUND_LABEL,
				mapImage: NOT_FOUND_LABEL,
				latitude: 0,
				longitude: 0,
			},
});

const mapToProviderCard = (card: any): ProviderCard => ({
	...card,
	type: 'provider' as const,
	title: getStringOrNotFound(card.title),
	nodeId: getStringOrNotFound(card.nodeId),
	imageUrl: getStringOrNotFound(card.imageUrl),
	responses: card.responses ? card.responses.map(mapToCardResponse) : [],
	data: card.data
		? {
				...card.data,
				acceptingNewPatients: isBoolean(card.data.acceptingNewPatients) ? card.data.acceptingNewPatients : null,
				acceptingVirtualVisits: isBoolean(card.data.acceptingVirtualVisits)
					? card.data.acceptingVirtualVisits
					: null,
				addressPlaceholder: getStringOrNotFound(card.data.addressPlaceholder),
				entityUrl: getStringOrNotFound(card.data.entityUrl),
				name: getStringOrNotFound(card.data.name),
				phonePlaceholder: getStringOrNotFound(card.data.phonePlaceholder),
				url: getStringOrNotFound(card.data.url),
				isPcp: isBoolean(card.data.isPcp) ? card.data.isPcp : false,
				type: 'provider',
				specialties: card.data.specialties || [],
				languages: card.data.languages || [],
				locations: card.data.locations || [],
				allowsOpenScheduling: mapNullOrUndefinedToBoolean(card.data.allowsOpenScheduling),
				canBookOnline: mapNullOrUndefinedToBoolean(card.data.canBookOnline),
				canBookOnlineAuth: mapNullOrUndefinedToBoolean(card.data.canBookOnlineAuth),
				distance: mapNullOrUndefinedToNumber(card.data.distance),
				latitude: mapNullOrUndefinedToNumber(card.data.latitude),
				longitude: mapNullOrUndefinedToNumber(card.data.longitude),
				id: mapNullOrUndefinedToNumber(card.data.id),
				city: mapNullOrUndefinedToString(card.data.city),
				fax: mapNullOrUndefinedToString(card.data.fax),
				externalProviderId: mapNullOrUndefinedToString(card.data.externalProviderId),
				address: mapNullOrUndefinedToString(card.data.address),
				appointmentUrl: mapNullOrUndefinedToString(card.data.appointmentUrl),
				clientOrganization: mapNullOrUndefinedToString(card.data.clientOrganization),
				mapImage: mapNullOrUndefinedToString(card.data.mapImage),
				imageUrl: mapNullOrUndefinedToString(card.data.imageUrl),
				phone: mapNullOrUndefinedToString(card.data.phone),
				sourceId: mapNullOrUndefinedToString(card.data.sourceId),
				link: mapNullOrUndefinedToString(card.data.link),
				state: mapNullOrUndefinedToString(card.data.state),
				visitTypes: mapToVisitType(card.data.visitTypes),
				reviews: card.data.reviews || null,
				availability: card.data.availability || null,
			}
		: {
				acceptingNewPatients: false,
				acceptingVirtualVisits: false,
				addressPlaceholder: NOT_FOUND_LABEL,
				entityUrl: NOT_FOUND_LABEL,
				name: NOT_FOUND_LABEL,
				phonePlaceholder: NOT_FOUND_LABEL,
				url: NOT_FOUND_LABEL,
				isPcp: false,
				type: 'provider' as const,
				specialties: [],
				languages: [],
				locations: [],
			},
});

const mapToSimpleCard = (card: any): SimpleCard => ({
	...card,
	type: 'card' as const,
	title: getStringOrNotFound(card.title),
	nodeId: getStringOrNotFound(card.nodeId),
	responses: card.responses ? card.responses.map(mapToCardResponse) : [],
});

const fallbackCarouselCard = (cards: any[]): Card[] => {
	if (isLocationCards(cards)) {
		return cards.map(mapToLocationCard);
	}
	if (isDiagnosisCards(cards)) {
		return cards.map(mapToDiagnosysCard);
	}
	if (isProviderCards(cards)) {
		return cards.map(mapToProviderCard);
	}
	if (isClinicCards(cards)) {
		return cards.map(mapToClinicCard);
	}
	return cards.map(mapToSimpleCard);
};

export const fallbackCarouselMessage = (message: any): CarouselMessage => ({
	...message,
	...fallbackBasicMessage(message),
	responseType: getStringOrNotFound<ResponseType>(message.responseType, '' as ResponseType),
	type: 'carousel',
	flowStep: getStringOrNotFound(message.flowStep),
	cards: message.cards ? fallbackCarouselCard(message.cards) : [],
});
