import { memo, RefObject, useContext, useEffect, useRef, useState } from 'react';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { FutureAppointment } from '../../context/api-service/api-service.model';
import { SessionContext } from '../../context/session.context';
import { AppointmentManager } from './appointment-manager.component';
import { AppointmentGroup, mapToAppointmentGroups } from './appointment-manager.model';

interface AppointmentManagerContainerProps {
	rootRef?: RefObject<HTMLDivElement>;
	emptySlotsFlowStep: string;
}

export const AppointmentManagerContainer = memo(({ rootRef, emptySlotsFlowStep }: AppointmentManagerContainerProps) => {
	const {
		state: { onSendMessage, handleUnauthorizedSessionError },
	} = useContext(SessionContext);
	const { getFutureAppointments, cancelAppointment } = useContext(APIServiceContext);
	const [futureAppointments, setFutureAppointments] = useState<FutureAppointment[]>([]);
	const [appointmentGroups, setAppointmentGroups] = useState<AppointmentGroup[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const ref = useRef<HTMLDivElement>(null);

	const jumpToEmptySlotsFlowStep = () => {
		onSendMessage(`gyant start flow ${emptySlotsFlowStep}`, undefined, false, false, undefined, 'message');
		setTimeout(() => {
			// Avoid the glitch from removing the loading component before jumping to the next step
			setIsLoading(false);
		}, 2500);
	};

	const updateAppointmentList = () => {
		setIsLoading(true);
		getFutureAppointments()
			.then((data) => {
				if (data.appointments.length === 0) {
					jumpToEmptySlotsFlowStep();
				} else {
					setIsLoading(false);
				}
				setFutureAppointments(data.result === 'ok' ? data.appointments : []);
			})
			.catch((error) => {
				handleUnauthorizedSessionError(error);
				setFutureAppointments([]);
				jumpToEmptySlotsFlowStep();
			});
	};

	useEffect(() => {
		updateAppointmentList();
	}, []);

	// TODO: Review this effect. Probably it's not needed. mapToAppointmentGroups can be done in updateAppointmentList
	useEffect(() => {
		setAppointmentGroups(mapToAppointmentGroups(futureAppointments, onSendMessage, cancelAppointment));
	}, [futureAppointments]);

	return (
		<div ref={ref}>
			<AppointmentManager
				appointmentList={appointmentGroups}
				rootRef={rootRef}
				isLoading={isLoading}
				updateAppointmentList={updateAppointmentList}
			/>
		</div>
	);
});
