import { addMinutes } from 'date-fns';
import React, { FC, memo, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EventsServiceContext } from '../../../context/events-service/events-service.context';
import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { CalendarIcon } from '../../../ui-kit/icons/calendar.icon';
import { CancelIcon } from '../../../ui-kit/icons/cancel.icon';
import { ConfirmationAltIcon } from '../../../ui-kit/icons/confirmationAlt';
import { Nullable } from '../../../utils/types.utils';
import { AppointmentSumaryTrackerElementName, createTrackingEventFunc } from '../appointment-scheduling.model';
import {
	AppointmentDateTimeValues,
	EVENT_DURATION,
	formatDateTimeToEventDateString,
	generateMapsURL,
	getFormattedLocation,
	ICSEventData,
	SummaryLocation,
	SummaryTemplates,
} from './appointment-summary.model';
import { useAppointmentSummaryStyles } from './appointment-summary.styles';
import { AppointmentSummaryActions } from './summary-actions/summary-actions.component';
import { AppointmentSummaryAdditionalInfo } from './summary-additional-info/summary-additional-info.component';
import { AppointmentSummaryContact } from './summary-contact/summary-contact.component';
import { AppointmentSummaryInfo } from './summary-info/summary-info.component';
import { AppointmentSummaryLocation } from './summary-location/summary-location.component';

interface AppointmentSummaryProps {
	providerName: string;
	makeChangeFlowStep: string;
	cancelAppointmentFlowStep?: string;
	appointmentValues: AppointmentDateTimeValues;
	location?: Nullable<SummaryLocation>;
	contact?: Nullable<string>;
	scope: string;
	templates: SummaryTemplates;
	flowId: string;
	stepName: string;
	onChangeAppointment(originalText: string, text: string): void;
	onClickLink?(url: string, event: EventType): void;
}

export const AppointmentSummary: FC<AppointmentSummaryProps> = memo(
	({
		providerName,
		appointmentValues: { appointmentDate, appointmentTime },
		location,
		contact,
		scope,
		templates: { additionalInformation },
		flowId,
		stepName,
		makeChangeFlowStep,
		cancelAppointmentFlowStep,
		onChangeAppointment,
		onClickLink,
	}) => {
		const { sendEvent } = useContext(EventsServiceContext);

		const { t } = useTranslation();
		const classes = useAppointmentSummaryStyles();

		const trackInteractionEvent = useCallback(
			createTrackingEventFunc<AppointmentSumaryTrackerElementName>(
				{
					component: 'APPOINTMENT_SUMMARY',
					scope,
					flowId,
					stepName,
				},
				sendEvent,
			),
			[],
		);

		const providerNameLabel = `${t('appointmentSummaryWithLabel', 'Appointment with')} ${providerName}`;
		const locationInfoText = getFormattedLocation(location);
		const locationMapsHref = generateMapsURL(locationInfoText);

		const handleClickLink = (link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			onClickLink && onClickLink(link, e);
		};

		const handleChange = (type: 'change' | 'cancel', originalText: string) => {
			const flowStep = `gyant start flow ${type === 'change' ? makeChangeFlowStep : cancelAppointmentFlowStep}`;
			onChangeAppointment(flowStep, originalText);
		};

		const createOptions = () => {
			const changelLabel = t('changeAppointment', 'Change appointment');
			const options = [
				{
					label: changelLabel,
					action: () => {
						trackInteractionEvent('MAKE_CHANGE_BUTTON');
						handleChange('change', changelLabel);
					},
					icon: <Icon icon={CalendarIcon} size={'small'} iconType={'chipIcon'} />,
					testLabel: 'change',
				},
			];
			if (cancelAppointmentFlowStep) {
				const cancelLabel = t('cancelAppointment', 'Cancel appointment');
				options.push({
					label: cancelLabel,
					action: () => handleChange('cancel', cancelLabel),
					icon: <Icon icon={CancelIcon} size={'small'} iconType={'systemIconAlert'} />,
					testLabel: 'cancel',
				});
			}
			return options;
		};

		const startDateTime = new Date(`${appointmentDate} ${appointmentTime}`);
		const endDateTime = addMinutes(startDateTime, EVENT_DURATION);

		const eventData: ICSEventData = {
			name: providerNameLabel,
			url: locationMapsHref,
			location: locationInfoText,
			details: additionalInformation,
			startsAt: formatDateTimeToEventDateString(startDateTime),
			endsAt: formatDateTimeToEventDateString(endDateTime),
		};

		return (
			<div className={classes.root} data-testing-label={'appointment-summary'}>
				<Icon icon={ConfirmationAltIcon} size={'xLarge'} iconType={'systemIconSuccess'} />
				<div>
					<AppointmentSummaryInfo
						appointmentDate={appointmentDate}
						appointmentTime={appointmentTime}
						providerNameLabel={providerNameLabel}
					/>
					{location && (
						<AppointmentSummaryLocation
							location={location}
							onClickLink={handleClickLink}
							onTrackevent={trackInteractionEvent}
						/>
					)}
					{contact && (
						<AppointmentSummaryContact
							contact={contact}
							onClickLink={handleClickLink}
							onTrackevent={trackInteractionEvent}
						/>
					)}
					{additionalInformation && (
						<AppointmentSummaryAdditionalInfo additionalInformation={additionalInformation} />
					)}
				</div>
				<AppointmentSummaryActions
					eventData={eventData}
					options={createOptions()}
					onTrackevent={trackInteractionEvent}
				/>
			</div>
		);
	},
);
