export type TrackerType = 'googleAnalytics' | 'postToParent';

export interface GoogleAnalyticsTracker {
	type: 'googleAnalytics';
	id: string;
}
export interface SiteImproveTracker {
	type: 'siteImprove';
	id: string;
}
export interface PostToParentTracker {
	type: 'postToParent';
}

export type Tracker = GoogleAnalyticsTracker | PostToParentTracker | SiteImproveTracker;

export type WebTrackerAction =
	| 'onCloseChat'
	| 'onMenuIconClick'
	| 'onMenuOptionsClick'
	| 'onOpenChat'
	| 'onBotMessage'
	| 'onUserClickLink'
	| 'onUserClickPhone'
	| 'onUserMessage'
	| 'onUserSelect'
	| 'onUserSelectCarousel';

interface WebTrackerActionData {
	action: string;
	label: string;
}
export interface WebTrackerEvent extends WebTrackerActionData {
	type: WebTrackerAction;
	enabled: boolean;
	matchResponses?: Record<string, WebTrackerActionData>;
}

export interface WebTrackerConfig {
	category: string;
	logEventsToConsole: boolean;
	trackers: Tracker[];
	events: WebTrackerEvent[];
}

export const DEFAULT_WEB_TRACKER_CONFIG: WebTrackerConfig = {
	category: 'Chatbot',
	logEventsToConsole: false,
	trackers: [],
	events: [],
};

export const isGoogleAnalyticsTracker = (tracker: Tracker): tracker is GoogleAnalyticsTracker =>
	tracker.type === 'googleAnalytics';
