import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const FullArrowUpIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M11 8.7999L8.09999 11.6999C7.91665 11.8832 7.68332 11.9749 7.39999 11.9749C7.11665 11.9749 6.88332 11.8832 6.69999 11.6999C6.51665 11.5166 6.42499 11.2832 6.42499 10.9999C6.42499 10.7166 6.51665 10.4832 6.69999 10.2999L11.3 5.6999C11.5 5.4999 11.7333 5.3999 12 5.3999C12.2667 5.3999 12.5 5.4999 12.7 5.6999L17.3 10.2999C17.4833 10.4832 17.575 10.7166 17.575 10.9999C17.575 11.2832 17.4833 11.5166 17.3 11.6999C17.1167 11.8832 16.8833 11.9749 16.6 11.9749C16.3167 11.9749 16.0833 11.8832 15.9 11.6999L13 8.7999V16.9999C13 17.2832 12.9042 17.5207 12.7125 17.7124C12.5208 17.9041 12.2833 17.9999 12 17.9999C11.7167 17.9999 11.4792 17.9041 11.2875 17.7124C11.0958 17.5207 11 17.2832 11 16.9999V8.7999Z'
			}
		/>
	</SvgIcon>
);
