import classNames from 'classnames';
import { FC } from 'react';

import { Effect } from '../../utils/function.utils';
import { Chip } from '../chip/chip.component';
import { Icon } from '../icon/icon.component';
import { DoneWhiteIcon } from '../icons/done-white.icon';
import { VectorWhiteIcon } from '../icons/vector-white.icon';
import { useChipMultiSelectStyles } from './chip-multiselect.styles';

interface ChipMultiselectProps {
	onClick: Effect<boolean>;
	isSelected: boolean;
	isDisabled?: boolean;
	className?: string;
	label?: string;
	isFullWidth?: boolean;
	dataTestingLabel?: string;
}

export const ChipMultiselect: FC<ChipMultiselectProps> = ({
	isSelected,
	isDisabled,
	label,
	isFullWidth,
	className,
	onClick,
	dataTestingLabel,
}) => {
	const classes = useChipMultiSelectStyles();
	const checkedIconClassName = classNames(isSelected ? classes.checked : classes.unchecked);

	const chipClassName = classNames(classes.root, isFullWidth && classes.fullWidth, className);

	const renderCheckIcon = isDisabled ? (
		<div className={classes.disabled}>
			<Icon icon={VectorWhiteIcon} iconType={'buttonIcon'} />
		</div>
	) : (
		<div className={checkedIconClassName}>
			{isSelected ? <Icon icon={DoneWhiteIcon} iconType={'buttonIcon'} /> : null}
		</div>
	);

	const handleClick = () => onClick(!isSelected);

	return (
		<Chip
			label={label}
			isDisabled={isDisabled}
			isSelected={isSelected}
			icon={renderCheckIcon}
			className={chipClassName}
			onClick={handleClick}
			dataTestingLabel={dataTestingLabel}
		/>
	);
};
