import { makeStyles } from '@material-ui/core';

export const useDateInputStyles = makeStyles(({ palette: { sizes } }) => ({
	wrapper: {
		display: 'flex',
	},
	input: {
		maxWidth: '30%',
		marginRight: sizes.size_1,
	},
	yearInput: {
		flex: 1,
	},
	errorMessage: {
		paddingTop: sizes.size_2,
	},
}));
