import { WithStyles, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';

import { ButtonProps } from '../button/button.component';
import { styles } from './button-progress.styles';

export type ButtonProgressProps = Pick<ButtonProps, 'color' | 'variant' | 'size'> & WithStyles<typeof styles>;

export const ButtonProgress = withStyles(styles)((props: ButtonProgressProps) => {
	const { color, variant, classes, size } = props;

	const progressClassName = clsx(
		classes.progress,
		(color === 'primary' || variant === 'outlined') && classes.progressOnLight,
		color === 'secondary' && variant !== 'outlined' && classes.progressOnDark,
	);

	return <CircularProgress className={progressClassName} size={size === 'large' ? 16 : 12} />;
});
