import classNames from 'classnames';
import { FC, memo } from 'react';

import { CardResponse, LocationCard } from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { Chip } from '../../../../../ui-kit/chip/chip.component';
import { useLocationCardStyles } from './location-card.styles';

interface LocationCardProps {
	card: LocationCard;
	onResponse: (response: CardResponse, e: EventType) => void;
	buttonsClassName?: string;
}

export const LocationCardComponent: FC<LocationCardProps> = memo(({ card, buttonsClassName, onResponse }) => {
	const classes = useLocationCardStyles();

	const mainActionResponse = card.responses[0];
	const secondaryActionResponse = card.responses[1];

	const handleAction = (response: CardResponse) => (e: EventType) => {
		onResponse(response, e);
	};

	const carouselCardClasses = {
		root: classes.carouselCardRoot,
	};

	return (
		<div className={classes.root}>
			<CarouselCard
				actionLabel={mainActionResponse.content}
				onAction={handleAction(mainActionResponse)}
				classes={carouselCardClasses}
			>
				<div className={classes.imageWrapper}>
					<img
						className={classes.image}
						src={card.imageUrl}
						alt={'googlemaps'}
						data-testing-label={'carousel-location-card-map-image'}
					/>
				</div>
				<h3 className={classes.title} data-testing-label={'carousel-location-card-title'}>
					{card.title}
				</h3>
			</CarouselCard>
			{secondaryActionResponse && (
				<div className={classNames(classes.secondaryButtonContainer, buttonsClassName)}>
					<Chip
						onClick={handleAction(secondaryActionResponse)}
						label={secondaryActionResponse.content}
						dataTestingLabel={'carousel-card-secondary-action'}
					/>
				</div>
			)}
		</div>
	);
});
