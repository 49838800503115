import { memo, useContext, useState } from 'react';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { isApiResponseWithErrorMessage } from '../../context/api-service/api-service.model';
import { SessionContext } from '../../context/session.context';
import { PasswordResetMetaData, PasswordResetValidation } from '../../models/message.model';
import { checkValidRegExp, encodeToBase64 } from '../../utils/string.utils';
import { PasswordReset } from './password-reset.component';

type ActionResult = 'success' | 'error' | 'canceled';
interface PasswordresetContainerProps {
	data: PasswordResetMetaData['data'];
}

const getPayload = (result: ActionResult, apiResponse?: unknown): string =>
	encodeToBase64(JSON.stringify({ result, response: apiResponse }));

const mapToUIValidation = (validation: PasswordResetValidation) => ({
	label: validation.message,
	rule: new RegExp(validation.expression),
});

export const PasswordResetContainer = memo<PasswordresetContainerProps>(({ data }) => {
	const {
		state: { onSendMessage },
	} = useContext(SessionContext);
	const { changePasswordApi } = useContext(APIServiceContext);

	const [isLoading, setIsLoading] = useState(false);

	const triggerNewFlowStep = (type: ActionResult, text: string, data?: unknown) => {
		const payload = getPayload(type, data);

		onSendMessage(text, undefined, true, false, undefined, 'data', payload);
	};

	const handlePasswordChange = async (password: string, text: string) => {
		setIsLoading(true);

		try {
			const apiResponse = await changePasswordApi(password);
			const changePasswordActionResult: ActionResult = isApiResponseWithErrorMessage(apiResponse)
				? 'error'
				: 'success';
			triggerNewFlowStep(changePasswordActionResult, text, apiResponse);
		} catch (error) {
			setIsLoading(false);
			triggerNewFlowStep('error', text, error);
		}
	};

	const handleCancel = (text: string): void => {
		triggerNewFlowStep('canceled', text);
	};
	const checkedValidations = data.validations.filter((validation) => checkValidRegExp(validation.expression));
	const basicValidations = checkedValidations.filter((validation) => !validation.isGeneral).map(mapToUIValidation);
	const generalValidations = checkedValidations.filter((validation) => validation.isGeneral).map(mapToUIValidation);

	return (
		<PasswordReset
			validationTitle={data.constraintsMessage}
			onCancel={handleCancel}
			onPasswordChange={handlePasswordChange}
			isLoading={isLoading}
			validations={basicValidations}
			generalValidations={generalValidations}
		/>
	);
});
