import { FC, memo } from 'react';

import { useAudioStyles } from './audio.styles';

interface AudioProps {
	src: string;
}

export const Audio: FC<AudioProps> = memo(({ src }) => {
	const classes = useAudioStyles();

	return (
		<audio
			className={classes.audio}
			controls
			preload={'metadata'}
			controlsList={'nodownload nofullscreen noremoteplayback'}
		>
			<track kind={'captions'} />
			<source src={src} type={'audio/mpeg'} />
		</audio>
	);
});
