import { makeStyles } from '@material-ui/core';

export const useInputInputPasswordStyles = makeStyles({
	root: {
		padding: 0,
		'& > input': {
			paddingRight: 0,
		},
	},
	adornment: {
		padding: '18px 16px',
		marginRight: 2,
	},
});
