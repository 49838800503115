import { useTheme } from '@material-ui/core';
import React, { FC, Fragment, memo, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { NotificationContext } from '../../context/notification.context';
import { SessionContext } from '../../context/session.context';
import { IconButton } from '../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { ArrowRightIcon } from '../../ui-kit/icons/arrow-right.icon';
import { LoadingListMask } from '../../ui-kit/loading-list-mask/loading-list-mask.component';
import { StackPanel } from '../../ui-kit/stack-panel /stack-panel.component';
import { sizes } from '../../ui-kit/theme/theme.model';
import { Effect } from '../../utils/function.utils';
import { Nullable, ResponseError } from '../../utils/types.utils';
import { AppointmentButton } from './appointment-button/appointment-button.component';
import { AppointmentDetailsComponent } from './appointment-details/appointment-details.component';
import { Appointment, AppointmentGroup } from './appointment-manager.model';
import { useAppointmentManagerStyles } from './appointment-manager.styles';

interface AppointmentManagerProps {
	appointmentList: AppointmentGroup[];
	rootRef?: React.RefObject<HTMLDivElement>;
	isLoading: boolean;
	updateAppointmentList: Effect<void>;
}

const NOTIFICATION_DISMISS_TIMER = 5000;

export const AppointmentManager: FC<AppointmentManagerProps> = memo(
	({ appointmentList, rootRef, isLoading, updateAppointmentList }) => {
		const { onUpdate } = useContext(NotificationContext);
		const eventsService = useContext(EventsServiceContext);
		const {
			state: { handleUnauthorizedSessionError },
		} = useContext(SessionContext);

		const [selectedAppointment, setSelectedAppointment] = useState<Nullable<Appointment>>(null);
		const { t } = useTranslation();
		const {
			palette: { transitionConfig },
		} = useTheme();
		const classes = useAppointmentManagerStyles();

		const handleClearSelectedAppointment = () => setSelectedAppointment(null);

		const cancelAndDismissDetails = (appointment: Appointment) => async (reason: string) => {
			try {
				const cancelSuccess = await appointment.onCancel(reason);

				handleClearSelectedAppointment();
				updateAppointmentList();
				onUpdate({
					isNotificationShown: true,
					notificationType: cancelSuccess ? 'Success' : 'Warning',
					notificationMessage: cancelSuccess
						? t(
								'appointmentCancelNotificationSuccess',
								'The appointment "{{appointmentTitle}}" was cancelled.',
								{ appointmentTitle: appointment.description },
							)
						: t(
								'appointmentCancelNotificationWarning',
								'We were unable to cancel your appointment. Please contact the provider directly.',
							),
				});
				eventsService.sendEvent({
					eventName: cancelSuccess ? 'appointmentManagerCancelSuccess' : 'appointmentManagerCancelFailure',
					data: {
						value: {
							reason,
							status: cancelSuccess ? 'Success' : 'Failure',
						},
					},
				});
				setTimeout(
					() =>
						onUpdate({
							isNotificationShown: false,
						}),
					NOTIFICATION_DISMISS_TIMER,
				);
			} catch (e) {
				handleUnauthorizedSessionError(e as ResponseError);
			}
		};
		const trackAddToCalendarEvent = () => eventsService.sendEvent({ eventName: 'appointmentManagerAddToCalendar' });

		const rescheduleAppointment = (appointment: Appointment) => async () => {
			appointment.onReschedule(t('rescheduleAppointment', 'Reschedule Appointment'));
		};

		const renderAppointmentGroups = (appointmentGroups: AppointmentGroup[]) => (
			<ul className={classes.container} data-testing-label={'appointment-manager-container'}>
				{appointmentGroups.map(
					(appointmentGroup, index): JSX.Element => (
						<li className={classes.appointmentGroup} key={index}>
							<span
								className={classes.groupDescription}
								data-testing-label={'appointment-manager-group-description'}>
								{appointmentGroup.title}
							</span>
							<ul className={classes.slotsContainer}>
								{appointmentGroup.appointments.map((appointment, index) => (
									<li key={index}>
										<AppointmentButton
											title={appointment.title}
											description={appointment.description}
											onClick={() => setSelectedAppointment(appointment)}
										/>
									</li>
								))}
							</ul>
						</li>
					),
				)}
			</ul>
		);

		const stackPanelCustomStyles = {
			main: {
				padding: `${sizes.size_4}px ${sizes.size_5}px ${sizes.size_5}px`,
				width: '100%',
				maxWidth: '100%',
				top: 0,
				overflowY: 'auto',
				borderRight: 'none',
			},
			transition: {
				enter: {
					transform: 'translateX(100%)',
				},
				enterActive: {
					transform: 'translateX(0%)',
					transition: `all ${transitionConfig.transitionInDuration}ms`,
				},
				exit: {
					transform: 'translateX(0%)',
				},
				exitActive: {
					transform: 'translateX(100%)',
					transition: `all ${transitionConfig.transitionOutDuration}ms`,
				},
			},
		};

		const stackPanelHeader = (
			<div className={classes.navigationContainer}>
				<IconButton
					data-testing-label={'stack-panel-close-button'}
					className={classes.backButton}
					aria-label={'close-details'}
					onClick={handleClearSelectedAppointment}>
					<Icon size={'large'} icon={ArrowRightIcon} alt={'close details'} className={classes.backIcon} />
				</IconButton>
				<span>{t('appointmentDetails', 'Appointment Details')}</span>
			</div>
		);

		const renderStackPanel = (selectedAppointment: Nullable<Appointment>): JSX.Element => (
			<StackPanel
				hasOverlay={false}
				isOpen={!!selectedAppointment}
				onClose={handleClearSelectedAppointment}
				customStyle={stackPanelCustomStyles}
				rootRef={rootRef}>
				{stackPanelHeader}
				{selectedAppointment && (
					<AppointmentDetailsComponent
						title={selectedAppointment.description}
						appointmentValues={selectedAppointment.appointmentValues}
						templates={selectedAppointment.templates}
						contact={selectedAppointment.contact}
						location={selectedAppointment.location}
						isCancelable={selectedAppointment.isCancelable}
						onCancel={cancelAndDismissDetails(selectedAppointment)}
						isRescheduable={selectedAppointment.isReschedulable}
						onReschedule={rescheduleAppointment(selectedAppointment)}
						onAddToCalendar={trackAddToCalendarEvent}
					/>
				)}
			</StackPanel>
		);

		return (
			<Fragment>
				{isLoading ? <LoadingListMask /> : renderAppointmentGroups(appointmentList)}
				{renderStackPanel(selectedAppointment)}
			</Fragment>
		);
	},
);
