import classNames from 'classnames';
import { FC } from 'react';

import { usePoweredByFabricStyles } from './powered-by-fabric.styles';

interface PoweredByFabricLabelProps {
	isWidgetOpen: boolean;
}

export const PoweredByFabricLabel: FC<PoweredByFabricLabelProps> = ({ isWidgetOpen }) => {
	const classes = usePoweredByFabricStyles({ isWidgetOpen });
	const poweredByClassName = classNames(classes.poweredByLabel, isWidgetOpen && classes.customStyles);
	return (
		<span className={poweredByClassName}>
			Powered by
			<a
				className={classes.fabricLink}
				href={'https://www.fabrichealth.com'}
				target={'_blank'}
				rel={'noreferrer'}
			>
				Fabric
			</a>
		</span>
	);
};
