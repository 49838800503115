import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TFunction } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { InputValidationResult, InputWithValidation } from '../input-with-validation/input-with-validation.component';
import { UnitSelector, UnitSelectorRenderType } from './unit-selector.component';

export interface InputWithSelectTestingLabels {
	root: string;
	input: string;
	error: string;
	select: string;
}

export type InputWithSelectOutputType = { value: string; unit: string };

interface InputWithSelectProps {
	shouldAutoFocus?: boolean;
	type: UnitSelectorRenderType;
	label: string;
	testingLabels?: InputWithSelectTestingLabels;
	units: string[];
	placeholder: string;
	className?: string;
	onSubmit: Effect<InputWithSelectOutputType>;
	onUnitChange: Effect<string>;
	validation(value: string, t: TFunction<'translation'>): InputValidationResult;
}

export const InputWithSelect: FC<InputWithSelectProps> = ({
	className,
	shouldAutoFocus,
	placeholder,
	label,
	units,
	type,
	testingLabels,
	onUnitChange,
	onSubmit,
	validation,
}) => {
	const inputRef = useRef<Nullable<HTMLInputElement>>(null);

	const [unit, setUnit] = useState<string>(units[0]);
	const [isFocused, setIsFocused] = useState<boolean>(false);

	const handleUnitChange = useCallback((value: string): void => {
		onUnitChange(value);
		setUnit(value);
	}, []);
	const handleSubmit = useCallback((value: string): void => onSubmit({ value, unit }), [onSubmit, unit]);

	useEffect(() => {
		if (shouldAutoFocus) {
			inputRef.current?.focus();
		}
	}, [shouldAutoFocus]);

	const { select, ...inputTestingLabels } = testingLabels || {};

	return (
		<InputWithValidation
			inputRef={inputRef}
			type={'text'}
			placeholder={placeholder}
			label={label}
			className={className}
			onSubmit={handleSubmit}
			onFocusChange={setIsFocused}
			endAdornment={
				<UnitSelector
					testingLabel={select}
					isFocused={isFocused}
					type={type}
					units={units}
					onChange={handleUnitChange}
				/>
			}
			testingLabels={inputTestingLabels}
			validation={validation}
			onValidationSuccess={(value) => value}
		/>
	);
};
