import classNames from 'classnames';
import React, { FC } from 'react';

import { Effect } from '../../utils/function.utils';
import { useLinkStyles } from './link.component.styles';

export type LinkType = 'typeLink1' | 'typeLink2' | 'typeNoteLink' | 'typeSysLink1' | 'typeSysLink2';

export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'onClick' | 'href'> & {
	onClick?: Effect<React.MouseEvent<HTMLAnchorElement, MouseEvent>>;
	href: string;
	linkType?: LinkType;
	variant?: 'link' | 'button';
	ariaLabel?: string;
};

export const Link: FC<LinkProps> = ({
	href,
	linkType = 'typeLink1',
	className,
	children,
	onClick,
	variant = 'link',
	ariaLabel,
	...props
}) => {
	const classes = useLinkStyles();
	const linkButtonClassName = variant === 'button' ? classes.linkButton : '';
	const linkClassName = classNames(classes[linkType], linkButtonClassName, className);
	const ariaLabelProp = ariaLabel ? { 'aria-label': ariaLabel } : {};

	return (
		<a {...props} className={linkClassName} href={href} onClick={onClick} {...ariaLabelProp}>
			{children}
		</a>
	);
};
