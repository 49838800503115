import { FC, memo } from 'react';

import { CardResponse, DiagnosisCard } from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../../../ui-kit/button/button.component';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { useCausesCardStyles } from './causes-card.styles';

interface CausesProps {
	card: DiagnosisCard;
	onResponse: (response: CardResponse, e: EventType) => void;
}

export const CausesCard: FC<CausesProps> = memo(({ card, onResponse }) => {
	const classes = useCausesCardStyles();
	const { title, responses } = card;

	const createActions = (responses: CardResponse[]): JSX.Element => (
		<div className={classes.actionsRoot}>
			{responses.map((response, index) => (
				<Button
					key={response.responseContext}
					className={classes.actionButton}
					color={index === 0 ? 'primary' : 'secondary'}
					onClick={(e) => onResponse(response, e)}
					data-testing-label={`carousel-causes-card-${index === 0 ? 'main-' : ''}action`}
				>
					{response.content}
				</Button>
			))}
		</div>
	);

	return (
		<CarouselCard>
			<div className={classes.root}>
				<img src={card.imageUrl} alt={`${title} diagnosis`} className={classes.image} />
				<h2 className={classes.title}>{title}</h2>
			</div>
			{createActions(responses)}
		</CarouselCard>
	);
});
