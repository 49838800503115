import classNames from 'classnames';
import { FC } from 'react';

import { Effect } from '../../../utils/function.utils';
import { dateToFormattedDateString } from '../../../utils/time.utils';
import { Chip } from '../../chip/chip.component';
import { useCalendarDayStyles } from './calendar-day.styles';

export type DayStatus = 'ACTIVE' | 'INACTIVE' | 'TODAY' | 'SELECTED' | 'EMPTY';

interface CalendarDayProps {
	status: DayStatus;
	date: Date;
	onClick: Effect<Date>;
}

export const CalendarDay: FC<CalendarDayProps> = ({ date, status, onClick }) => {
	const label = date.getDate();
	const classes = useCalendarDayStyles();

	const chipClassName = classNames(classes.root, status === 'INACTIVE' && classes.inActive);
	const isDisabled = status === 'INACTIVE';

	return status === 'EMPTY' ? (
		<div className={classes.empty} />
	) : (
		<Chip
			size={'small'}
			label={label}
			dataTestingLabel={`calendar-day-${isDisabled ? 'disaled' : 'available'}-${dateToFormattedDateString(date)}`}
			isSelected={status === 'SELECTED'}
			isDisabled={isDisabled}
			onClick={() => onClick(date)}
			className={chipClassName}
		/>
	);
};
