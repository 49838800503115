import { Modal, Slide, useTheme } from '@material-ui/core';
import { FC } from 'react';

import { Lazy } from '../../utils/function.utils';
import { Z_INDEX_TOP } from '../../utils/sizes.utils';
import { IconButton } from '../icon-button/icon-button.component';
import { Icon } from '../icon/icon.component';
import { CloseIcon } from '../icons/close.icon';
import { useCloseButtonStyles, useModalStyles } from './fancy-box.styles';

interface FancyBoxProps {
	isOpen: boolean;
	url: string;
	onClose: Lazy<void>;
}

export const FancyBox: FC<FancyBoxProps> = ({ isOpen, url, onClose }) => {
	const buttonClasses = useCloseButtonStyles();
	const modalClasses = useModalStyles();
	const {
		palette: { transitionConfig },
	} = useTheme();

	return (
		<Modal
			style={{ zIndex: Z_INDEX_TOP + 1 }}
			disablePortal
			disableAutoFocus
			open={isOpen}
			className={modalClasses.root}
			onClose={onClose}
			aria-labelledby={'simple-modal-title'}
			aria-describedby={'simple-modal-description'}
		>
			<Slide
				in={isOpen}
				direction={'up'}
				timeout={{
					enter: transitionConfig.transitionInDuration,
					exit: transitionConfig.transitionOutDuration,
				}}
			>
				<div className={modalClasses.contentWrapper}>
					<div className={modalClasses.header}>
						<IconButton
							classes={buttonClasses}
							onClick={onClose}
							data-testing-label={'fancy-box-close-button'}
						>
							<Icon icon={CloseIcon} size={'large'} iconType={'inputIcon'} />
						</IconButton>
					</div>
					<iframe
						title={'gyantWidget'}
						style={{
							width: '100%',
							height: '100%',
							backgroundColor: '#FFFFFF',
						}}
						src={url}
						frameBorder={'0'}
					/>
				</div>
			</Slide>
		</Modal>
	);
};
