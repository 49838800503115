import { makeStyles, Theme } from '@material-ui/core';

interface PickerStyleProps {
	isPickerOpen: boolean;
	isDisabled: boolean;
}

export const useInlineDatePickerStyles = makeStyles<Theme, PickerStyleProps>(
	({ palette: { colorScheme, borderRadius, sizes }, typography: { smallViewPort } }) => ({
		noDateSelected: {
			opacity: 0.5,
		},
		pickerContainer: {
			position: 'relative',
		},
		disabled: {
			backgroundColor: colorScheme.colorVariables.colorInactive,
			borderRadius: borderRadius.commonRadius,
		},
		calendarIcon: {
			position: 'absolute',
			left: sizes.size_2,
			top: sizes.size_2,
			zIndex: 0,
		},
		arrowIcon: {
			position: 'absolute',
			right: sizes.size_2,
			top: 20,
			zIndex: 0,
			transition: 'transform 100ms',
		},
		arrowUpIcon: {
			transform: 'rotate(180deg)',
		},
		disabledMonthButton: {
			pointerEvents: 'none',
			'& svg': {
				color: colorScheme.colorVariables.colorInactive,
				fill: colorScheme.colorVariables.colorInactive,
			},
		},
		monthArrow: {
			padding: 0,
			width: 32,
			height: 32,
			background: 'none',
			'&:hover': {
				transition: 'all 0.1s',
				backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
				filter: 'brightness(96%)',
			},
		},
		arrowRight: {
			transform: 'rotate(-90deg)',
			paddingRight: 0,
		},
		arrowLeft: {
			transform: 'rotate(90deg)',
			paddingLeft: 0,
		},
		buttonArrowLeft: {
			marginLeft: sizes.size_2,
		},
		buttonArrowRight: {
			marginRight: sizes.size_2,
		},
		datePickerRoot: {
			width: '100%',
			zIndex: 2,
			'& > div': {
				borderRadius: borderRadius.commonRadius,
				border: (props) =>
					`1px solid ${props.isPickerOpen ? 'transparent' : colorScheme.colorVariables.colorBorder}`,
				outline: (props) =>
					props.isPickerOpen ? `2px solid ${colorScheme.colorVariables.colorPrimary}` : 'none',
				'&:hover': {
					border: (props) =>
						`1px solid ${props.isDisabled ? 'transparent' : colorScheme.colorVariables.colorNote}`,
				},
			},
			'& input': {
				...smallViewPort.body,
				padding: `${sizes.size_4}px ${sizes.size_5}px 10px ${sizes.size_8}px`,
				color: colorScheme.colorVariables.colorText,
				cursor: 'pointer',
				'&.Mui-disabled': {
					cursor: 'auto',
					'-webkit-text-fill-color': colorScheme.colorVariables.colorNote,
					color: colorScheme.colorVariables.colorNote,
				},
			},
		},
		datePickerEmptyRoot: {
			'& input': {
				padding: `21px ${sizes.size_5}px 21px ${sizes.size_8}px`,
			},
		},
		calendarLoading: {
			width: '100%',
			height: '100%',
			padding: `0px ${sizes.size_2}px ${sizes.size_4}px`,
			animation: '$blinker 1s linear infinite',
		},
		'@keyframes blinker': {
			'50%': {
				opacity: 0.3,
			},
		},
	}),
);

export const usePopoverStyles = makeStyles<Theme>(
	({
		palette: {
			borderRadius,
			sizes,
			boxShadow,
			colorScheme: { colorVariables },
		},
	}) => ({
		paper: {
			boxShadow: boxShadow.angle.large,
			overflow: 'hidden',
			maxHeight: '100%',
			height: 'auto',
			marginTop: sizes.size_1,
			borderRadius: borderRadius.commonRadius,
			minWidth: 358,
			outline: `1px solid ${colorVariables.colorLightShadow}`,
		},
	}),
);
