import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const VisibilityOnIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M11.9999 16C13.2499 16 14.3124 15.5625 15.1874 14.6875C16.0624 13.8125 16.4999 12.75 16.4999 11.5C16.4999 10.25 16.0624 9.1875 15.1874 8.3125C14.3124 7.4375 13.2499 7 11.9999 7C10.7499 7 9.6874 7.4375 8.8124 8.3125C7.9374 9.1875 7.4999 10.25 7.4999 11.5C7.4999 12.75 7.9374 13.8125 8.8124 14.6875C9.6874 15.5625 10.7499 16 11.9999 16ZM11.9999 14.2C11.2499 14.2 10.6124 13.9375 10.0874 13.4125C9.5624 12.8875 9.2999 12.25 9.2999 11.5C9.2999 10.75 9.5624 10.1125 10.0874 9.5875C10.6124 9.0625 11.2499 8.8 11.9999 8.8C12.7499 8.8 13.3874 9.0625 13.9124 9.5875C14.4374 10.1125 14.6999 10.75 14.6999 11.5C14.6999 12.25 14.4374 12.8875 13.9124 13.4125C13.3874 13.9375 12.7499 14.2 11.9999 14.2ZM11.9999 19C9.76657 19 7.72907 18.4 5.8874 17.2C4.04574 16 2.59157 14.4167 1.5249 12.45C1.44157 12.3 1.37907 12.1458 1.3374 11.9875C1.29574 11.8292 1.2749 11.6667 1.2749 11.5C1.2749 11.3333 1.29574 11.1708 1.3374 11.0125C1.37907 10.8542 1.44157 10.7 1.5249 10.55C2.59157 8.58333 4.04574 7 5.8874 5.8C7.72907 4.6 9.76657 4 11.9999 4C14.2332 4 16.2707 4.6 18.1124 5.8C19.9541 7 21.4082 8.58333 22.4749 10.55C22.5582 10.7 22.6207 10.8542 22.6624 11.0125C22.7041 11.1708 22.7249 11.3333 22.7249 11.5C22.7249 11.6667 22.7041 11.8292 22.6624 11.9875C22.6207 12.1458 22.5582 12.3 22.4749 12.45C21.4082 14.4167 19.9541 16 18.1124 17.2C16.2707 18.4 14.2332 19 11.9999 19ZM11.9999 17C13.8832 17 15.6124 16.5042 17.1874 15.5125C18.7624 14.5208 19.9666 13.1833 20.7999 11.5C19.9666 9.81667 18.7624 8.47917 17.1874 7.4875C15.6124 6.49583 13.8832 6 11.9999 6C10.1166 6 8.3874 6.49583 6.8124 7.4875C5.2374 8.47917 4.03324 9.81667 3.1999 11.5C4.03324 13.1833 5.2374 14.5208 6.8124 15.5125C8.3874 16.5042 10.1166 17 11.9999 17Z'
			}
		/>
	</SvgIcon>
);
