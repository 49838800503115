import classNames from 'classnames';
import { FC } from 'react';

import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../ui-kit/button/button.component';
import { Effect } from '../../utils/function.utils';
import { useCallToActionButtonStyles } from './call-to-action-button.styles';

interface CallToActionButtonProps {
	title: string;
	onClick: Effect<EventType>;
	style?: Record<string, unknown>;
	className?: string;
}
export const CallToActionButton: FC<CallToActionButtonProps> = ({ onClick, title, style, className }) => {
	const classes = useCallToActionButtonStyles(style)();
	const rootClassName = classNames(classes.root, className);
	return (
		<Button
			color={'primary'}
			className={rootClassName}
			onClick={onClick}
			data-testing-label={`CTA-button-${title}`}
		>
			{title}
		</Button>
	);
};
