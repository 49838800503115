import { map } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/pipeable';

import { constVoid, Effect, Lazy } from '../utils/function.utils';
import { parseMessage } from '../utils/message-parser';
import { Nullable } from '../utils/types.utils';
import { LiveChatCommandMessage, MessageFromSocket, ParsedData } from './message.model';

export type SocketStatus = 'CLOSED' | 'OPEN' | 'CONNECTING' | 'CLOSING';
interface ClientLocationAttachment {
	type: 'location';
	payload: {
		coordinates: {
			lat: Nullable<number>;
			long: Nullable<number>;
		};
	};
}
export type ClientMessageAttachment = ClientLocationAttachment;
export interface WebSocketClient {
	disconnect: Lazy<void>;
	send: Effect<MessagePayload>;
}

export const DEFAULT_WEB_SOCKET_CLIENT: WebSocketClient = {
	disconnect: constVoid,
	send: constVoid,
};

export type ClientMessageType = 'message' | 'data' | 'live_chat';

export interface ClientMessage {
	originalText?: string;
	text: string;
	type: ClientMessageType;
	attachments?: ClientMessageAttachment[];
	payload?: string;
	messageId?: string;
}

export interface PingMessage {
	type: 'ping';
}
export type MessagePayload = ClientMessage | PingMessage | LiveChatCommandMessage;

export const isClientSocketMessageType = (message: MessagePayload): message is ClientMessage =>
	message.type === 'message';

export const createLocationAttachment = (latitude?: number, longitude?: number): ClientLocationAttachment => ({
	type: 'location',
	payload: {
		coordinates: {
			lat: latitude ?? null,
			long: longitude ?? null,
		},
	},
});

export const parseReceivedSocketData = (receivedData: any[]): ParsedData<MessageFromSocket>[] =>
	pipe(receivedData, map(parseMessage));
