import { makeStyles, Theme } from '@material-ui/core';

export const useAvatarButtonStyles = makeStyles<Theme, { customLogo: string; isCollapsed: boolean }>(
	({ palette: { borderRadius, colorScheme } }) => ({
		root: {
			padding: 0,
			borderRadius: borderRadius.widgetButton,
			transition: 'all 0.4s',
			boxShadow: 'none',
			backgroundColor: 'transparent',
			'&:hover': {
				boxShadow: 'none',
				transition: 'all 0.2s',
				'& div:not(.minimized)': {
					filter: 'brightness(92%)',
					boxShadow: 'none',
				},
			},
			'&:focus-visible': {
				outline: `2px solid ${colorScheme.colorVariables.colorPrimary} !important`,
				outlineOffset: 2,
				transition: 'outline 0s',
			},
			'&:focus:not(:focus-visible)': {
				outline: 'none !important',
				boxShadow: 'none',
				backgroundColor: 'transparent',
				border: 'none',
			},
		},
	}),
);

export const useAdditionalAvatarButtonStyles = makeStyles<Theme, { customLogo: string; isCollapsed: boolean }>(
	({
		palette: {
			sizes,
			boxShadow,
			borderRadius,
			colorScheme: { customStyle, colorVariables },
		},
	}) => ({
		backgroundWrapper: {
			borderRadius: borderRadius.widgetButton,
			transition: 'all 0.2s',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		collapsed: {
			backgroundColor: colorVariables.colorPrimary,
			backgroundImage: (props) => props.customLogo,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			boxShadow: boxShadow.angle.xlarge,
			opacity: (props) => (props.isCollapsed ? 1 : 0),
			...customStyle.collapsedChatButton,
		},
		uncollapsed: {
			boxShadow: 'none',
			position: 'absolute',
			opacity: (props) => (props.isCollapsed ? 0 : 1),
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			backgroundColor: colorVariables.colorPrimarySoft,
			...customStyle.openedChatButton,
		},
		tall: {
			width: sizes.size_10,
			height: sizes.size_10,

			'& svg': {
				width: sizes.size_6,
				height: sizes.size_6,
			},

			'& div#collapsed:not(.minimized), & div#uncollapsed:not(.minimized)': {
				backgroundSize: 72,
				width: sizes.size_10,
				height: sizes.size_10,
			},
			'& div#collapsed:not(.minimized)': {
				...customStyle.collapsedChatButton,
			},
			'& div#uncollapsed:not(.minimized)': {
				...customStyle.openedChatButton,
			},
		},
		short: {
			width: sizes.size_10,
			height: 60,

			'& svg': {
				width: sizes.size_5,
				height: sizes.size_5,
			},

			'& div#collapsed:not(.minimized), & div#uncollapsed:not(.minimized)': {
				backgroundSize: 64,
				width: sizes.size_10,
				height: 60,
			},
			'& div#collapsed:not(.minimized)': {
				...customStyle.collapsedChatButton,
			},
			'& div#uncollapsed:not(.minimized)': {
				...customStyle.openedChatButton,
			},
		},
		minimized: {
			width: sizes.size_3,
			minWidth: sizes.size_3,
			height: sizes.size_3,
			opacity: 0.64,
			backgroundSize: sizes.size_2,
			transition: 'all 0.6s',
			'& svg': {
				width: sizes.size_2,
				height: sizes.size_2,
				transition: 'all 0.6s',
			},
			'& div': {
				width: sizes.size_3,
				minWidth: sizes.size_3,
				height: sizes.size_3,
				backgroundSize: sizes.size_2,
				transition: 'all 0.6s',
			},
			'&:hover': {
				opacity: 1,
				width: sizes.size_4,
				minWidth: sizes.size_4,
				height: sizes.size_4,
				transition: 'all 0.2s',
				'& svg': {
					width: 22,
					height: 22,
					transition: 'width 0.2s, height 0.2s',
				},
				'& div': {
					transition: 'all 0.2s',
					width: sizes.size_4,
					minWidth: sizes.size_4,
					height: sizes.size_4,
					backgroundSize: sizes.size_3,
				},
			},
		},
		hasNotifications: {
			'&:after': {
				content: '""',
				position: 'absolute',
				width: 17,
				height: 17,
				borderRadius: '50%',
				left: 0,
				top: 0,
				backgroundColor: colorVariables.colorSysWarning,
			},
		},
	}),
);
