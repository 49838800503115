import makeStyles from '@material-ui/styles/makeStyles';

interface SchedulingWrapperStylesProps {
	clientLogoURL: string;
}

export const useSchedulingWrapperStyles = makeStyles<string, SchedulingWrapperStylesProps>({
	root: {
		position: 'absolute',
		bottom: 0,
		top: 0,
		left: 0,
		right: 0,
	},
	header: {
		height: 50,
		boxShadow: '0 5px 10px 0 rgb(160 160 160 / 50%)',
		padding: '0 15px',
		position: 'fixed',
		top: 0,
		left: 0,
		right: 0,
		zIndex: 1,
		display: 'flex',
		alignItems: 'center',
	},
	logo: {
		backgroundImage: (props) => `url(${props.clientLogoURL})`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'contain',
		marginLeft: 17,
		padding: '14px 0',
		flex: 1,
	},
	buttonRoot: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
});
