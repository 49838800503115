import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const AvatarIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 40 40'} {...props}>
		<g id={'Icons'} stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g id={'Icon/Avatar/Header/#'} transform={'translate(-10.000000, -10.000000)'}>
				<g id={'smart_toy_black_24dp'} transform={'translate(10.000000, 10.000000)'}>
					<rect id={'Rectangle'} x={'0'} y={'0'} width={'40'} height={'40'}></rect>
					<path
						d={
							'M33.8181818,13.7894737 L33.8181818,10.4210526 C33.8181818,8.56842105 32.2636364,7.05263158 30.3636364,7.05263158 L25.1818182,7.05263158 C25.1818182,4.25684211 22.8672727,2 20,2 C17.1327273,2 14.8181818,4.25684211 14.8181818,7.05263158 L9.63636364,7.05263158 C7.73636364,7.05263158 6.18181818,8.56842105 6.18181818,10.4210526 L6.18181818,13.7894737 C3.31454545,13.7894737 1,16.0463158 1,18.8421053 C1,21.6378947 3.31454545,23.8947368 6.18181818,23.8947368 L6.18181818,30.6315789 C6.18181818,32.4842105 7.73636364,34 9.63636364,34 L30.3636364,34 C32.2636364,34 33.8181818,32.4842105 33.8181818,30.6315789 L33.8181818,23.8947368 C36.6854545,23.8947368 39,21.6378947 39,18.8421053 C39,16.0463158 36.6854545,13.7894737 33.8181818,13.7894737 Z M12.2272727,18 C12.2272727,16.6021053 13.3845455,15.4736842 14.8181818,15.4736842 C16.2518182,15.4736842 17.4090909,16.6021053 17.4090909,18 C17.4090909,19.3978947 16.2518182,20.5263158 14.8181818,20.5263158 C13.3845455,20.5263158 12.2272727,19.3978947 12.2272727,18 Z M25.1818182,27.2631579 C23.8421758,27.9458246 22.1265353,28.2871579 20.0348967,28.2871579 C17.9432581,28.2871579 16.2043532,27.9458246 14.8181818,27.2631579 C14.066241,26.8869433 13.0909091,26.5052632 13.0909091,25.5789474 C13.0909091,24.6526316 13.8681818,23.8947368 14.8181818,23.8947368 C17.119254,24.5774035 18.8450581,24.9187368 19.9955941,24.9187368 C21.1480884,24.9187368 22.8768297,24.5774035 25.1818182,23.8947368 C26.1318182,23.8947368 26.9090909,24.6526316 26.9090909,25.5789474 C26.9090909,26.5052632 26.1237986,26.7629699 25.1818182,27.2631579 Z M25.1818182,20.5263158 C23.7481818,20.5263158 22.5909091,19.3978947 22.5909091,18 C22.5909091,16.6021053 23.7481818,15.4736842 25.1818182,15.4736842 C26.6154545,15.4736842 27.7727273,16.6021053 27.7727273,18 C27.7727273,19.3978947 26.6154545,20.5263158 25.1818182,20.5263158 Z'
						}
						id={'Shape'}
						fill={'#FFFFFF'}
						fillRule={'nonzero'}
					></path>
				</g>
			</g>
		</g>
	</SvgIcon>
);
