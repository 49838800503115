import { makeStyles } from '@material-ui/core';

export const useLiveChatSystemInfoStyles = makeStyles(
	({
		typography: { smallViewPort },
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			textAlign: 'center',
			position: 'relative',
		},
		text: {
			...smallViewPort.typeCaption1,
			marginBottom: 0,
			marginTop: 0,
		},
		actionButton: {
			...smallViewPort.typeNoteLink,
			padding: 0,
			textDecoration: 'underline',
			transition: 'all .8s',
			'&:hover': {
				textDecoration: 'underline',
				background: 'transparent',
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
		},
		unreadMessage: {
			height: 1,
			backgroundColor: colorVariables.colorSysWarning,
			position: 'absolute',
			left: sizes.size_1,
			right: sizes.size_1,
			margin: 0,
			top: -(sizes.size_1 + sizes.sizeHalf),
		},
	}),
);
