import { FC, memo } from 'react';

import { CardResponse, SimpleCard } from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../../../ui-kit/button/button.component';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { useCareVenueCardStyles } from './care-venue-card.styles';

interface CareVenueProps {
	card: SimpleCard;
	onResponse: (response: CardResponse, e: EventType) => void;
}

export const CareVenueCard: FC<CareVenueProps> = memo(({ card, onResponse }) => {
	const classes = useCareVenueCardStyles();
	const { title, subtitle, responses, imageUrl } = card;

	const createActions = (responses: CardResponse[]): JSX.Element => (
		<div className={classes.actionsRoot} data-testing-label={'carousel-care-venue-card-action-container'}>
			{responses.map((response, index) => (
				<Button
					key={index}
					className={classes.actionButton}
					color={index === 0 ? 'primary' : 'secondary'}
					onClick={(e) => onResponse(response, e)}
					data-testing-label={`carousel-care-venue-card-${index === 0 ? 'main' : 'sub'}-action`}>
					{response.content}
				</Button>
			))}
		</div>
	);

	return (
		<CarouselCard>
			<div className={classes.root} data-testing-label={'carousel-care-venue-card'}>
				{imageUrl && <img src={imageUrl} alt={`${title} card`} className={classes.image} />}
				<div className={classes.content}>
					<h2
						className={classes.title}
						data-testing-label={'carousel-care-venue-card-title'}
						dangerouslySetInnerHTML={{ __html: title }}
					/>
					{subtitle && (
						<p
							className={classes.subTitle}
							data-testing-label={'carousel-care-venue-card-subTitle'}
							dangerouslySetInnerHTML={{ __html: subtitle }}
						/>
					)}
				</div>
			</div>
			{createActions(responses)}
		</CarouselCard>
	);
});
