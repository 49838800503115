import { FC, memo } from 'react';

import { useVideoStyles } from './video.styles';

interface VideoProps {
	src: string;
}

export const Video: FC<VideoProps> = memo(({ src }) => {
	const classes = useVideoStyles();

	return (
		<div className={classes.root}>
			<video className={classes.video} controls preload={'metadata'} controlsList={'nodownload noremoteplayback'}>
				<track kind={'captions'} />
				<source src={src} type={'video/mp4'} />
			</video>
		</div>
	);
});
