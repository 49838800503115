import { LocaleModel } from '../context/api-service/api-service.model';
import { Nullable } from '../utils/types.utils';

export const BASE_LANGUAGE_PREFIX_LOCAL_STORAGE = 'gyant_language_';

export type LanguageName = 'Arabic' | 'German' | 'English' | 'Spanish' | 'Russian' | 'Portuguese';
export type LanguageNameLowerCase = 'arabic' | 'german' | 'english' | 'spanish' | 'russian' | 'portuguese';
export type LanguageAbbreviation = 'AR' | 'DE' | 'EN' | 'ES' | 'RU' | 'PT';
export type LocaleLocalization = 'ar' | 'de' | 'en' | 'es' | 'ru' | 'pt' | 'fr';
export type LocalizationModel = 'ar_AR' | 'de_DE' | 'en_US' | 'es_LA' | 'ru_RU' | 'pt_PT' | 'pt_BR' | 'fr_FR' | 'zh_CN';

export interface Language {
	label: LanguageName;
	language: LanguageAbbreviation;
}

export const setLanguageOnLocalStorage = (language: string, token: string): void => {
	const languageLocalStorageKey = `${BASE_LANGUAGE_PREFIX_LOCAL_STORAGE}_${token}`;
	localStorage.setItem(languageLocalStorageKey, language);
};

export const getLanguageFromLocalStorage = (token: string): LocaleLocalization | null => {
	const languageLocalStorageKey = `${BASE_LANGUAGE_PREFIX_LOCAL_STORAGE}_${token}`;
	return localStorage.getItem(languageLocalStorageKey) as LocaleLocalization;
};

export const getFirstBrowserLanguage = (): Nullable<string> => {
	const nav = window.navigator;
	const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

	let language = '';
	let len = 0;
	let shortLanguage = null;

	// support for HTML 5.1 "navigator.languages"
	if (Array.isArray(nav.languages)) {
		for (let i = 0; i < nav.languages.length; i++) {
			language = nav.languages[i];
			len = language.length;
			if (!shortLanguage && len) {
				shortLanguage = language;
			}
			if (language && len > 1) {
				return language;
			}
		}
	}

	// support for other well known properties in browsers
	for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
		language = nav[browserLanguagePropertyKeys[i]];
		len = language.length;
		if (!shortLanguage && len) {
			shortLanguage = language;
		}
		if (language && len > 1) {
			return language;
		}
	}

	return shortLanguage;
};

export const localizationModelToLocale = (language: LocalizationModel): LocaleLocalization => {
	switch (language) {
		case 'ar_AR':
			return 'ar';
		case 'de_DE':
			return 'de';
		case 'en_US':
			return 'en';
		case 'es_LA':
			return 'es';
		case 'ru_RU':
			return 'ru';
		case 'pt_PT':
		case 'pt_BR':
			return 'pt';
		case 'fr_FR':
			return 'fr';
		case 'zh_CN':
			return 'en';
	}
};

export const toLanguageLocale = (language: LanguageNameLowerCase): LocaleLocalization => {
	switch (language) {
		case 'arabic':
			return 'ar';
		case 'german':
			return 'de';
		case 'english':
			return 'en';
		case 'spanish':
			return 'es';
		case 'russian':
			return 'ru';
		case 'portuguese':
			return 'pt';
	}
};

export const toLanguageNameLowerCase = (language: LanguageAbbreviation): LanguageNameLowerCase => {
	switch (language) {
		case 'AR':
			return 'arabic';
		case 'DE':
			return 'german';
		case 'EN':
			return 'english';
		case 'ES':
			return 'spanish';
		case 'RU':
			return 'russian';
		case 'PT':
			return 'portuguese';
	}
};

export const toLocale = (language: string): LocalizationModel => {
	switch (language) {
		case 'ar':
			return 'ar_AR';
		case 'de':
			return 'de_DE';
		case 'en':
			return 'en_US';
		case 'es':
			return 'es_LA';
		case 'ru':
			return 'ru_RU';
		case 'pt':
			return 'pt_PT';
		default:
			return 'en_US';
	}
};

export const toLanguageAbbreviation = (language: string): LanguageAbbreviation => {
	switch (language) {
		case 'ar':
			return 'AR';
		case 'de':
			return 'DE';
		case 'en':
			return 'EN';
		case 'es':
			return 'ES';
		case 'ru':
			return 'RU';
		case 'pt':
			return 'PT';
		default:
			return 'EN';
	}
};

export const localeModelToLocale = (language: LocaleModel): LocaleLocalization => {
	switch (language) {
		case 'ar_AR':
			return 'ar';
		case 'de_DE':
			return 'de';
		case 'es_LA':
			return 'es';
		case 'ru_RU':
			return 'ru';
		case 'pt_PT':
			return 'pt';
		case 'default':
		case 'en_US':
			return 'en';
	}
};

export const mapProviderLanguage = (language: string): string => {
	const languagesAbbreviationBase = {
		Arabic: 'AR',
		German: 'DE',
		English: 'EN',
		Spanish: 'ES',
		Russian: 'RU',
		Portuguese: 'PT',
		'American Sign Language': 'ASL',
	};
	return languagesAbbreviationBase[language] || language;
};
