import classNames from 'classnames';
import { FC, memo } from 'react';

import { Button } from '../../ui-kit/button/button.component';
import { BotMessageLoading } from '../../ui-kit/icons/bot-message-loading';
import { useWidgetLoadingStyles } from './widget-loading.styles';

interface WidgetLoadingProps {
	isMobile: boolean;
	fullScreen: boolean;
	isShown: boolean;
	className?: string;
}

export const WidgetLoading: FC<WidgetLoadingProps> = memo(({ isMobile, fullScreen, isShown, className }) => {
	const classes = useWidgetLoadingStyles({ isExpanded: !isMobile && fullScreen });
	const rootClassName = classNames(classes.root, className);
	const dataTestingLabel = `bot-messages-container-widget-loading-${isShown ? 'shown' : 'hidden'}`;

	return (
		<div className={rootClassName} data-testing-label={dataTestingLabel}>
			<BotMessageLoading className={classes.botMessage} />
			<div className={classes.buttonContent}>
				<Button className={classes.animatedButton} disabled />
				<Button className={classes.animatedButton} disabled />
			</div>
		</div>
	);
});
