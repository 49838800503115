import { lastIndexOf } from '../utils/array.utils';
import { Nullable } from '../utils/types.utils';
import {
	Card,
	isMessageWithLiveChatAgentInfo,
	LiveChatDoctorInfo,
	Message,
	MessageDeliveryStatus,
	MetaData,
	QuickResponse,
	ResponseType,
	SpecialAction,
} from './message.model';

export interface ConversationMetadata {
	caseId?: string;
	status: string;
}

export interface ConversationContext {
	isUndoable: boolean;
	responseType: ResponseType;
	talkingtodoctor: boolean;
	autocompleteUri?: string;
	isInputHidden?: boolean;
	hasHelpfulnessSurvey?: boolean;
	isExternal?: boolean;
	isLiveChatActive: boolean;
}

export interface ConversationHistory {
	context: ConversationContext;
	metadata: ConversationMetadata;
	treatments?: any[];
	utterances: HistoryMessage[];
}

// ---- Chat history messages------
interface BasicHistoryMessage {
	attachments: string[];
	requestId: Nullable<string>;
	text: string;
	timestamp: string;
	_id: string;
	isInputHidden?: boolean;
	hasHelpfulnessSurvey?: boolean;
	isExternal?: boolean;
	doctorId?: string;
	doctorInfo?: LiveChatDoctorInfo;
	messageId?: string;
	nodeId?: string;
}

export interface LiveChatCommandMessage {
	attachments: string[];
	incoming: true;
	type: 'command';
	value: string;
	originalText: string;
	requestId: Nullable<string>;
	timestamp: string;
	_id: string;
	nodeId?: string;
}
export interface ClientTextHistoryMessage extends BasicHistoryMessage {
	incoming: true;
	originalText?: string;
	sanitizedText?: string;
	nodeId?: string;
}

interface BasicBotHistoryMessage extends BasicHistoryMessage {
	incoming: false;
	timeRemaining?: number;
	metadata?: MetaData;
	flowStep?: string;
	specialActions?: SpecialAction[];
	nodeId?: string;
}

export interface TextHistoryMessage extends BasicBotHistoryMessage {
	content?: string;
	type: 'text' | 'image' | 'video' | 'audio' | 'message' | 'command' | 'messageStatus';
	doctorId?: string;
	doctorInfo?: LiveChatDoctorInfo;
	messageStatus?: MessageDeliveryStatus;
	nodeId?: string;
}

export interface QuickResponseHistoryMessage extends BasicBotHistoryMessage {
	responses: QuickResponse[];
	type: 'quickResponses';
	nodeId?: string;
}

export interface CarouselHistoryMessage extends BasicBotHistoryMessage {
	cards: Card[];
	type: 'carousel';
	nodeId?: string;
}

export type HistoryMessage =
	| ClientTextHistoryMessage
	| TextHistoryMessage
	| QuickResponseHistoryMessage
	| CarouselHistoryMessage
	| LiveChatCommandMessage;

const isHistoryLiveChatCommandMessage = (message: HistoryMessage): message is LiveChatCommandMessage =>
	'type' in message && message.type === 'command';

export const historyMessageToMessage = (historyMessage: HistoryMessage, context: ConversationContext): Message => {
	if (isHistoryLiveChatCommandMessage(historyMessage)) {
		return {
			type: 'command',
			flowStep: '',
			content: '',
			text: historyMessage.value,
			incoming: true,
			isInputHidden: false,
			hasHelpfulnessSurvey: false,
			isExternal: false,
			timeStamp: new Date(historyMessage.timestamp),
			nodeId: historyMessage?.nodeId,
		};
	} else if (historyMessage.incoming) {
		return {
			type: 'text',
			flowStep: '',
			content: '',
			text: historyMessage.originalText ? historyMessage.originalText.trim() : historyMessage.text.trim(),
			incoming: true,
			isInputHidden: !!historyMessage.isInputHidden,
			hasHelpfulnessSurvey: !!historyMessage.hasHelpfulnessSurvey,
			isExternal: !!historyMessage.isExternal,
			timeStamp: new Date(historyMessage.timestamp),
			messageId: historyMessage.messageId,
			nodeId: historyMessage?.nodeId,
		};
	} else {
		switch (historyMessage.type) {
			case 'messageStatus':
			case 'text':
			case 'message':
			case 'command':
			case 'image':
			case 'video':
			case 'audio':
				return {
					type: historyMessage.type,
					flowStep: historyMessage.flowStep,
					content: historyMessage.content || '',
					text: historyMessage.text,
					responseType: context.responseType,
					incoming: historyMessage.incoming,
					specialActions: historyMessage.specialActions,
					timeRemaining: historyMessage.timeRemaining,
					metadata: historyMessage.metadata,
					isInputHidden: !!historyMessage.isInputHidden,
					hasHelpfulnessSurvey: !!historyMessage.hasHelpfulnessSurvey,
					isExternal: !!historyMessage.isExternal,
					timeStamp: new Date(historyMessage.timestamp),
					doctorId: historyMessage.doctorId,
					doctorInfo: historyMessage.doctorInfo,
					messageStatus: historyMessage.messageStatus,
					nodeId: historyMessage?.nodeId,
				};
			case 'quickResponses':
				return {
					type: 'quickResponses',
					flowStep: historyMessage.flowStep || '',
					text: historyMessage.text,
					responseType: context.responseType,
					incoming: historyMessage.incoming,
					isUndoable: context.isUndoable,
					talkingtodoctor: context.talkingtodoctor,
					specialActions: historyMessage.specialActions,
					timeRemaining: historyMessage.timeRemaining,
					responses: historyMessage.responses,
					metadata: historyMessage.metadata,
					isInputHidden: !!historyMessage.isInputHidden,
					hasHelpfulnessSurvey: !!historyMessage.hasHelpfulnessSurvey,
					isExternal: !!historyMessage.isExternal,
					timeStamp: new Date(historyMessage.timestamp),
					nodeId: historyMessage?.nodeId,
				};
			case 'carousel':
				return {
					type: 'carousel',
					flowStep: historyMessage.flowStep || '',
					text: historyMessage.text,
					responseType: context.responseType,
					incoming: historyMessage.incoming,
					isUndoable: context.isUndoable,
					talkingtodoctor: context.talkingtodoctor,
					cards: historyMessage.cards,
					timeRemaining: historyMessage.timeRemaining,
					metadata: historyMessage.metadata,
					isInputHidden: !!historyMessage.isInputHidden,
					hasHelpfulnessSurvey: !!historyMessage.hasHelpfulnessSurvey,
					isExternal: !!historyMessage.isExternal,
					timeStamp: new Date(historyMessage.timestamp),
					nodeId: historyMessage?.nodeId,
				};
		}
	}
};

export const isLongHistoryLiveChat = (messages: Message[]): boolean => {
	const endLiveChatMessageIndex = lastIndexOf(messages, (message: Message) => {
		const isUserEndedLiveChat = message.type === 'command' && message.text === 'endLiveChat';
		const isAgentEndedLiveChat = message.metadata?.status === 'endLiveChat';
		return isUserEndedLiveChat || isAgentEndedLiveChat;
	});

	const doctorInfoMessageIndex = lastIndexOf(messages, isMessageWithLiveChatAgentInfo);
	return messages.length === 50 && endLiveChatMessageIndex === -1 && doctorInfoMessageIndex > -1;
};
