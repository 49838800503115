import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ThumbsUpOutlineIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 20 20'} height={20} width={20} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<path d={'M0,0 L20,0 L20,20 L0,20 L0,0 Z M0,0 L20,0 L20,20 L0,20 L0,0 Z'}></path>
				<path
					d={
						'M7.5,17.5 L15,17.5 C15.6916667,17.5 16.2833333,17.0833333 16.5333333,16.4833333 L19.05,10.6083333 C19.125,10.4166667 19.1666667,10.2166667 19.1666667,10 L19.1666667,8.33333333 C19.1666667,7.41666667 18.4166667,6.66666667 17.5,6.66666667 L12.2416667,6.66666667 L13.0333333,2.85833333 L13.0583333,2.59166667 C13.0583333,2.25 12.9166667,1.93333333 12.6916667,1.70833333 L11.8083333,0.833333333 L6.31666667,6.325 C6.01666667,6.625 5.83333333,7.04166667 5.83333333,7.5 L5.83333333,15.8333333 C5.83333333,16.75 6.58333333,17.5 7.5,17.5 Z M7.5,7.5 L11.1166667,3.88333333 L10,8.33333333 L17.5,8.33333333 L17.5,10 L15,15.8333333 L7.5,15.8333333 L7.5,7.5 Z M3.16666667,7.5 C3.71895142,7.5 4.16666667,7.94771525 4.16666667,8.5 L4.16666667,16.5 C4.16666667,17.0522847 3.71895142,17.5 3.16666667,17.5 C2.61438192,17.5 2.16666667,17.0522847 2.16666667,16.5 L2.16666667,8.5 C2.16666667,7.94771525 2.61438192,7.5 3.16666667,7.5 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
