import classNames from 'classnames';
import { FC, memo } from 'react';

import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { handleKeyPress } from '../../../utils/a11y.utils';
import {
	StartingAnimationUI,
	useAnimationStyles,
	useIncreasedCounterByAnimationConfig,
} from '../../../utils/animations.utils';
import { useTooltipStyles } from './tooltip.styles';

interface TooltipProps {
	className: string;
	tooltipTitle: string;
	tooltipAnimation?: StartingAnimationUI;
	onClick(e: EventType): void;
}

export const Tooltip: FC<TooltipProps> = memo(({ className, tooltipTitle, tooltipAnimation, onClick }) => {
	const classes = useTooltipStyles();
	const tooltipAnimationClass = tooltipAnimation ? useAnimationStyles(tooltipAnimation)().animation : '';
	const shiuldApplyAnimationClassName = useIncreasedCounterByAnimationConfig(tooltipAnimation);
	const tooltipClassName = classNames(
		classes.root,
		className,
		shiuldApplyAnimationClassName && tooltipAnimationClass,
	);

	return (
		<div
			tabIndex={0}
			data-testing-label={'open-toggle-widget-btn-tooltip'}
			role={'button'}
			onClick={onClick}
			onKeyPress={handleKeyPress(onClick)}
			className={tooltipClassName}
			dangerouslySetInnerHTML={{ __html: tooltipTitle }}
		/>
	);
});
