import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../models/dimensions.model';

export const useTextRendererStyles = makeStyles<Theme>(
	({ palette: { colorScheme, sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		wrapper: {
			display: 'flex',
			padding: `1px ${sizes.size_1}%`,
		},
		userMessageWrapper: {
			justifyContent: 'flex-end',
			padding: `0 ${sizes.size_1}%`,
		},
		botMessageWrapper: {
			justifyContent: 'flex-start',
			padding: `0 ${sizes.size_1}%`,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				minWidth: `calc(${LARGE_VIEW_PORT_VALUE_WIDTH}px - ${sizes.size_1})`,
				width: '85%',
				paddingRight: 0,
			},
		},
		root: {
			fontSize: 17,
			overflowWrap: 'anywhere',
			whiteSpace: 'pre-line',
			height: 'auto',
		},
		botMessages: {
			padding: `0 ${sizes.size_1}px`,
			...smallViewPort.typeBot1,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeBot1,
			},
			...colorScheme.customStyle.botMessages,
		},
		userMessage: {
			maxWidth: '100%',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		customUserStyles: {
			...colorScheme.customStyle.userMessage,
		},
	}),
);
