import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

import i18n from './i18.utils';

export const getTimeLocale = (language: string): Locale => (language === 'es' ? es : enUS);

export const dateToFormattedDateString = (date: Date): string => format(date, 'yyyy-MM-dd');
export const dateToFormattedTimeString = (date: Date): string => format(date, 'h:mm aa');
export const dateToFormattedFullHoursTimeString = (date: Date): string => format(date, 'kk:mm:ss');
export const dateToFormattedHoursMinutesTimeString = (date: Date): string => format(date, 'kk:mm');
export const getLastDayOfMonth = (date: Date): Date => new Date(date.getFullYear(), date.getMonth() + 1, 0);
export const getCalendarDayFromDate = (date: Date): string => format(date, 'dd');
export const timeStringToNumber = (time: string): number => parseInt(time.split(':').join(''));

export const getFullDateStringFormatForCurrentLocale = (): string =>
	i18n.language === 'es' ? 'EEE, LLL d, yyyy' : 'EEE, LLL do, yyyy';

export const dateToFullDateStringFormat = (date: Date): string =>
	format(date, getFullDateStringFormatForCurrentLocale(), { locale: getTimeLocale(i18n.language) });

export const dateToFormattedString = (date: Date, stringFormat: string): string =>
	format(date, stringFormat, { locale: getTimeLocale(i18n.language) });

export const getUTCDate = (dateString: string | Date): Date =>
	new Date(
		new Date(dateString).getUTCFullYear(),
		new Date(dateString).getUTCMonth(),
		new Date(dateString).getUTCDate(),
	);

export const getUTCDateHoursMinutes = (dateString: string | Date): Date =>
	new Date(
		new Date(dateString).getUTCFullYear(),
		new Date(dateString).getUTCMonth(),
		new Date(dateString).getUTCDate(),
		new Date(dateString).getUTCHours(),
		new Date(dateString).getUTCMinutes(),
	);
export const stringToDateFormat = (dateString: string): string => {
	const date = getUTCDate(new Date(dateString));
	return format(date, 'LLL d, yyyy', { locale: getTimeLocale(i18n.language) });
};
