import { makeStyles } from '@material-ui/core';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../models/dimensions.model';

export const useMediaStyles = makeStyles(({ palette: { sizes }, typography: { smallViewPort } }) => ({
	root: {
		margin: `calc(${sizes.size_4}px - ${smallViewPort.typeBot1.marginBottom}) calc(${sizes.size_1}% + ${sizes.size_1}px) ${sizes.size_3}px`,
		[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
			minWidth: `calc(${LARGE_VIEW_PORT_VALUE_WIDTH}px - ${sizes.size_1}%)`,
			width: '85%',
			marginRight: 0,
			marginLeft: 0,
			paddingLeft: `calc(${sizes.size_1}% + ${sizes.size_1}px)`,
		},
	},
}));
