import { makeStyles, Theme } from '@material-ui/core';

export const useUnitSelectorStyles = makeStyles<Theme>(({ palette: { colorScheme, sizes } }) => ({
	menuButton: {
		display: 'flex',
		color: colorScheme.colorVariables.colorSysTextDefault,
		minHeight: 44,
		minWidth: 70,
		alignItems: 'center',
		// TODO: Use from config after refacrtoring
		borderRadius: sizes.sizeHalf,
		background: colorScheme.colorVariables.colorSysBgDefault,
		boxShadow: '0px 3px 7px 0px rgba(0, 0, 0, 0.10)', //check box shadow
		textTransform: 'none',
		font: 'inherit',
		'&:hover': {
			background: colorScheme.colorVariables.colorSysBgDefault,
		},
	},
	//TODO : confirm all hard coded values
	menuButtonFocused: {
		color: 'hsl(0, 0%, 3%)',
	},
	paper: {
		borderRadius: 0,
	},
	label: {
		color: colorScheme.colorVariables.colorSysTextDefault,
	},
	labelFocused: {
		color: 'hsl(0, 0%, 3%)',
	},
	customMenuItem: {
		marginInline: 4,
		'&:hover': {
			backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
		},
		'&.Mui-selected': {
			backgroundColor: 'hsl(216, 100%, 86%)',
		},
		'&.Mui-selected:hover': {
			backgroundColor: 'hsl(216, 100%, 86%)',
		},
	},
}));
