import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';

export type Nullable<T> = T | null;

export type NonStruct = string | number | boolean | Date;

export type DeepPartial<T> = {
	[P in keyof T]?: T[P] extends NonStruct ? T[P] : Partial<T[P]>;
};

export const isDefined = <A>(v: A | undefined | null): v is A => typeof v !== 'undefined' && v !== null;

export interface ResponseError {
	status: number;
}

type overridesNameToClassKey = {
	[P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

// Extend theme classnames to override styles of datepicker
declare module '@material-ui/core/styles/overrides' {
	export interface ComponentNameToClassKey extends overridesNameToClassKey {
		MuiPickersStaticWrapper: {
			staticWrapperRoot: {
				minWidth?: string;
			};
		};
		MuiPickersBasePicker: {
			pickerView: {
				minWidth?: string;
				overflow?: string;
			};
			container: {
				borderTop?: string;
				borderRadius?: number;
				marginTop?: number;
				background?: string;
			};
		};
	}
}
