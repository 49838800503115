import { CSSProperties } from 'react';

export const LARGE_VIEW_PORT_VALUE_WIDTH = 624;
export const INPUT_LINE_HEIGHT = 23;

export const MOBILE_MEDIA_QUERY =
	'(max-device-width: 481px) and (orientation: portrait), (max-device-width: 1023px) and (max-height: 500px) and (orientation: landscape)';

export const SMALL_HEIGHT_MEDIA_QUERY = '(max-height: 500px)';

export const SHORT_PDT_LABEL_MEDIA_QUERY = '(max-width: 375px)';

export interface MobileFlagStyleProps {
	isMobile: boolean;
}

export const createMediaQuery = (
	type: 'mobile_landscape' | 'small_height',
	css: CSSProperties,
): Record<string, CSSProperties> => {
	switch (type) {
		case 'mobile_landscape':
			return { '@media screen and (max-height: 500px) and (orientation: landscape)': css };
		case 'small_height':
			return { '@media screen and (max-height: 500px)': css };
	}
};

// Decrease window height to dynamic height size when available height is below the widget window height
// Makes sure it applies to desktop only (mobile uses all available space)
export const getDynamicWidgetHeightMediaQuery = (chatWindowHeight: string, chatWindowBottomPosition: string): string =>
	`@media screen and (max-height: calc(${chatWindowHeight} + ${chatWindowBottomPosition})) and (min-width: 482px)`;
