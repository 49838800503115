import Button from '@material-ui/core/Button';
import classNames from 'classnames';
import { FC } from 'react';

import { Effect } from '../../utils/function.utils';
import { Icon } from '../icon/icon.component';
import { ArrowDownIcon } from '../icons/arrow-down.icon';
import { useStepperStyles } from './stepper.styles';

const MAX_VISIBLE_DOTS = 11;

interface StepperProps {
	steps: number;
	activeStep: number;
	onActiveStepChange: Effect<number>;
}

export const Stepper: FC<StepperProps> = ({ steps, activeStep, onActiveStepChange }) => {
	const classes = useStepperStyles();
	const numberOfVisibleDots = Math.min(steps, MAX_VISIBLE_DOTS);

	const handleBack = () => onActiveStepChange(activeStep - 1);
	const handleNext = () => onActiveStepChange(activeStep + 1);

	const firstVisibleDot =
		Math.max(activeStep - Math.floor(numberOfVisibleDots / 2), 1) > steps - numberOfVisibleDots + 1
			? steps - numberOfVisibleDots + 1
			: Math.max(activeStep - Math.floor(numberOfVisibleDots / 2), 1);

	const lastVisibleDot = Math.min(firstVisibleDot + numberOfVisibleDots - 1, steps);

	const dots = [];

	for (let i = firstVisibleDot; i <= lastVisibleDot; i++) {
		dots.push(
			<button
				data-testing-label={`stepper-dot-${i}`}
				key={i}
				className={classNames(
					classes.dot,
					i === activeStep && classes.active,
					steps > 11 &&
						((i === firstVisibleDot && firstVisibleDot > 1) ||
							(i === lastVisibleDot && lastVisibleDot < steps)) &&
						classes.minimized,
				)}
				onClick={() => onActiveStepChange(i)}
			/>,
		);
	}

	return (
		<div className={classes.root} id={'steps-container'}>
			<Button
				data-testing-label={'carousel-controls-prev-button'}
				aria-label={'previous'}
				variant={'text'}
				size={'medium'}
				onClick={handleBack}
				disabled={activeStep === 1}
				className={classNames(classes.button, activeStep === 1 && classes.hidden)}>
				<Icon
					size={'large'}
					icon={ArrowDownIcon}
					iconType={'inputIcon'}
					className={classes.arrowLeft}
					alt={'previous'}
				/>
			</Button>
			<div className={classes.dotsContainer}>{dots}</div>
			<Button
				data-testing-label={'carousel-controls-next-button'}
				aria-label={'next'}
				size={'medium'}
				onClick={handleNext}
				disabled={activeStep === steps}
				className={classNames(classes.button, activeStep === steps && classes.hidden)}>
				<Icon
					size={'large'}
					icon={ArrowDownIcon}
					iconType={'inputIcon'}
					className={classes.arrowRight}
					alt={'next'}
				/>
			</Button>
		</div>
	);
};
