import { createTheme, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

import { ColorScheme, VisualConfig } from '../../models/app.model';
import { setAlpha } from '../../utils/colors.utils';
import {
	BorderRadiusConfig,
	BoxShadowConfig,
	GeneralStyles,
	getbasicHTMLStyles,
	getBorderRadiusConfig,
	getWidgetBackground,
	GlobalTypographyStyles,
	ScreenSize,
	sizes,
	SizesKeys,
	TransitionConfig,
	WidgetBackground,
} from './theme.model';

declare module '@material-ui/core/styles/createPalette' {
	interface Palette {
		colorScheme: ColorScheme;
		sizes: Record<SizesKeys, number>;
		borderRadius: BorderRadiusConfig;
		widgetBackground: WidgetBackground;
		transitionConfig: TransitionConfig;
		noAnimationTransition: boolean;
		boxShadow: BoxShadowConfig;
		visualConfig: VisualConfig;
	}
	interface PaletteOptions {
		colorScheme: ColorScheme;
		sizes: Record<SizesKeys, number>;
		borderRadius: BorderRadiusConfig;
		widgetBackground: WidgetBackground;
		transitionConfig: TransitionConfig;
		boxShadow: BoxShadowConfig;
		visualConfig: VisualConfig;
	}
}
declare module '@material-ui/core/styles/createTypography' {
	interface Typography {
		smallViewPort: GeneralStyles;
		largeViewPort: GeneralStyles;
		body?: CSSProperties;
		p?: CSSProperties;
	}
	interface TypographyOptions {
		smallViewPort: GeneralStyles;
		largeViewPort: GeneralStyles;
		body?: CSSProperties;
		p?: CSSProperties;
	}
}

const choseScreenSize = (screenSize: string, smallValue: string | number, largeValue: string | number) =>
	screenSize === ScreenSize.SmallViewPort ? smallValue : largeValue;

export const createDefaultStyles = (
	{ colorVariables, fontFamilyRegular }: ColorScheme,
	screenSize: ScreenSize,
): GeneralStyles => {
	const basicHTMLStyles = getbasicHTMLStyles(colorVariables);
	return {
		body: {
			fontFamily: fontFamilyRegular,
			fontWeight: 400,
			fontSize: '1rem',
			color: colorVariables.colorText,
			lineHeight: 1.5,
			letterSpacing: '0.2px',
			fontVariant: 'common-ligatures',
		},
		p: {
			fontFamily: fontFamilyRegular,
			fontWeight: 400,
			fontSize: '1rem',
			color: colorVariables.colorText,
			lineHeight: 1.5,
			letterSpacing: '0.2px',
			marginBottom: '1rem',
			overflowWrap: 'anywhere',
		},
		typeTitle1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.882rem', '3.294rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			lineHeight: choseScreenSize(screenSize, 1.15, 1),
			letterSpacing: choseScreenSize(screenSize, '0.1px', '-0.1px'),
		},
		typeTitle2: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.647rem', '2.824rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			lineHeight: choseScreenSize(screenSize, 1.2, 1),
			letterSpacing: choseScreenSize(screenSize, '0.1px', '-0.1px'),
		},
		typeTitle3: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.412rem', '2.353rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			lineHeight: choseScreenSize(screenSize, 1.25, 1.1),
			letterSpacing: choseScreenSize(screenSize, '0.1px', '-0.1px'),
		},
		typeHeading1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.235rem', '1.882rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			lineHeight: choseScreenSize(screenSize, 1.3, 1.15),
			letterSpacing: '0.1px',
		},
		typeHeading2: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.412rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			lineHeight: choseScreenSize(screenSize, 1.4, 1.25),
			letterSpacing: '0.1px',
		},
		typeSubheading1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.882rem', '0.882rem'),
			fontWeight: 700,
			color: colorVariables.colorHead,
			textTransform: 'uppercase',
			lineHeight: 1.4,
			letterSpacing: '0.5px',
		},
		typeBot1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.235rem'),
			fontWeight: 400,
			color: colorVariables.colorText,
			lineHeight: 1.5,
			letterSpacing: '0.21px',
			marginBottom: '1.059rem',
			'& ul': basicHTMLStyles.ul,
			'& ol': basicHTMLStyles.ol,
			'& em': basicHTMLStyles.em,
			'& strong': basicHTMLStyles.strong,
		},
		typeInput1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1rem', '1rem'),
			fontWeight: 400,
			color: colorVariables.colorText,
			lineHeight: 1.5,
			letterSpacing: '0px',
		},
		typeCaption1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.824rem', '0.824rem'),
			fontWeight: 400,
			color: colorVariables.colorCaption,
			lineHeight: 1.5,
			letterSpacing: '0.3px',
			marginBottom: '4px',
		},
		typeButton1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.059rem'),
			fontWeight: 700,
			color: colorVariables.colorHeadOnDark,
			lineHeight: 1.4,
			letterSpacing: '0.21px',
		},
		typeButton1Alt: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.059rem'),
			fontWeight: 700,
			color: colorVariables.colorPrimary,
			lineHeight: 1.4,
			letterSpacing: '0.21px',
		},
		typeChip1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.059rem'),
			fontWeight: 400,
			color: colorVariables.colorText,
			lineHeight: 1.4,
			letterSpacing: '0.21px',
		},
		typeChip2: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.941rem', '0.941rem'),
			fontWeight: 400,
			color: colorVariables.colorText,
			lineHeight: 1.4,
			letterSpacing: '0.19px',
		},
		typeData1: {
			fontFamily:
				"'IBM Plex Mono', ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono', 'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro', 'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace",
			fontSize: choseScreenSize(screenSize, '1.647rem', '1.647rem'),
			fontWeight: 400,
			color: colorVariables.colorText,
			lineHeight: 1.143,
			letterSpacing: '0.33px',
		},
		typeNoteHeading: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.824rem', '0.824rem'),
			fontWeight: 700,
			color: colorVariables.colorNote,
			lineHeight: 1.429,
			letterSpacing: '0.2px',
			marginBottom: '2.8px',
		},
		typeNoteParagraph: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.824rem', '0.824rem'),
			fontWeight: 400,
			color: colorVariables.colorNote,
			lineHeight: 1.429,
			letterSpacing: '0.16px',
			marginBottom: '2.8px',
		},
		typeNoteLink: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.824rem', '0.824rem'),
			fontWeight: 400,
			color: colorVariables.colorNote,
			lineHeight: 1.4,
			letterSpacing: '0px',
			transition: 'all .8s',
			'&:visited': {
				textDecorationColor: colorVariables.colorNote,
				textDecoration: 'underline',
				transition: 'all 0.8s',
			},
			'&:hover': {
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 4,
				transition: 'outline 0s',
			},
		},
		typeLink1: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '1.059rem', '1.235rem'),
			fontWeight: 700,
			color: colorVariables.colorPrimary,
			lineHeight: 1.4,
			letterSpacing: '0.21px',
			textDecorationColor: setAlpha(colorVariables.colorPrimary, '0.24'),
			transition: 'all .8s',
			'&:visited': {
				color: colorVariables.colorPrimary,
				textDecoration: 'underline',
				transition: 'all 0.8s',
				textDecorationColor: setAlpha(colorVariables.colorPrimary, '0.24'),
			},
			'&:hover': {
				textDecorationColor: colorVariables.colorPrimary,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 4,
				transition: 'outline 0s',
			},
		},
		typeLink2: {
			fontFamily: fontFamilyRegular,
			fontSize: choseScreenSize(screenSize, '0.824rem', '0.824rem'),
			fontWeight: 700,
			color: colorVariables.colorPrimary,
			lineHeight: 1.4,
			letterSpacing: '0px',
			textDecorationColor: setAlpha(colorVariables.colorPrimary, '0.24'),
			transition: 'all .8s',
			'&:visited': {
				color: colorVariables.colorPrimary,
				textDecorationColor: setAlpha(colorVariables.colorPrimary, '0.24'),
				textDecoration: 'underline',
			},
			'&:hover': {
				textDecorationColor: colorVariables.colorPrimary,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorPrimary}`,
				outlineOffset: 4,
				transition: 'outline 0s',
			},
		},
		typeSysText1: {
			fontFamily: fontFamilyRegular,
			fontWeight: 400,
			fontSize: '0.941rem',
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.5,
			letterSpacing: '0.2px',
			fontVariant: 'common-ligatures',
		},
		typeSysText2: {
			fontFamily: fontFamilyRegular,
			fontWeight: 400,
			fontSize: '0.824rem',
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.4,
			letterSpacing: '0.2px',
			fontVariant: 'common-ligatures',
		},
		typeSysHeading1: {
			fontFamily: fontFamilyRegular,
			fontSize: '0.941rem',
			fontWeight: 700,
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.5,
			letterSpacing: '0.1px',
		},
		typeSysHeading2: {
			fontFamily: fontFamilyRegular,
			fontSize: '0.824rem',
			fontWeight: 700,
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.4,
			letterSpacing: '0.1px',
		},
		typeSysLink1: {
			fontFamily: fontFamilyRegular,
			fontSize: '0.941rem',
			fontWeight: 700,
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.5,
			letterSpacing: '0.21px',
			textDecoration: 'underline',
			textDecorationColor: setAlpha(colorVariables.colorSysTextDefault, '0.24'),
			transition: 'all .8s',
			'&:visited': {
				color: colorVariables.colorSysTextDefault,
				textDecoration: 'underline',
				transition: 'all 0.8s',
				textDecorationColor: setAlpha(colorVariables.colorSysTextDefault, '0.24'),
			},
			'&:hover': {
				textDecorationColor: colorVariables.colorSysTextDefault,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorSysTextDefault}`,
				outlineOffset: 4,
				transition: 'outline 0s',
			},
		},
		typeSysLink2: {
			fontFamily: fontFamilyRegular,
			fontSize: '0.824rem',
			fontWeight: 700,
			color: colorVariables.colorSysTextDefault,
			lineHeight: 1.4,
			letterSpacing: '0px',
			textDecorationColor: setAlpha(colorVariables.colorSysTextDefault, '0.24'),
			transition: 'all .8s',
			textDecoration: 'underline',
			'&:visited': {
				color: colorVariables.colorSysTextDefault,
				textDecorationColor: setAlpha(colorVariables.colorSysTextDefault, '0.24'),
				textDecoration: 'underline',
			},
			'&:hover': {
				textDecorationColor: colorVariables.colorSysTextDefault,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
			'&:active': {
				filter: 'brightness(80%)',
			},
			'&:focus-visible': {
				outline: `2px solid ${colorVariables.colorSysTextDefault}`,
				outlineOffset: 4,
				transition: 'outline 0s',
			},
		},
	};
};
interface CreateColorThemeOptions {
	isGlobalStyles: boolean;
	noAnimationTransition: boolean;
}
export const createColorTheme = (
	colorScheme: ColorScheme,
	visualConfig: VisualConfig,
	options: CreateColorThemeOptions,
): Theme => {
	const { isGlobalStyles, noAnimationTransition } = options;
	const borderRadius = getBorderRadiusConfig(visualConfig.radiusType);
	const smallViewPort = createDefaultStyles(colorScheme, ScreenSize.SmallViewPort);
	const largeViewPort = createDefaultStyles(colorScheme, ScreenSize.LargeViewPort);

	const widgetBackground = getWidgetBackground(
		visualConfig.widgetBackground,
		colorScheme.colorVariables.colorPrimary,
		colorScheme.colorVariables.colorPrimarySoft,
	);

	const boxShadow = {
		angle: {
			xsmall: '0px 1px 5px 0px rgba(0, 0, 0, 0.16)',
			small: '0px 3px 7px 0px rgba(0, 0, 0, 0.16)',
			medium: '0px 4px 12px 0px rgba(0, 0, 0, 0.16)',
			large: '0px 4px 16px 0px rgba(0, 0, 0, 0.16)',
			xlarge: '0px 32px 40px 0px rgba(0, 0, 0, 0.16)',
		},
		special: {
			toggle: '0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 1px 1px 0px rgba(0, 0, 0, 0.16), 0px 3px 1px 0px rgba(0, 0, 0, 0.10)',
			hero: '0px 256px 72px 0px rgba(0, 0, 0, 0.00), 0px 164px 65px 0px rgba(0, 0, 0, 0.01), 0px 92px 55px 0px rgba(0, 0, 0, 0.02), 0px 41px 41px 0px rgba(0, 0, 0, 0.04), 0px 10px 23px 0px rgba(0, 0, 0, 0.05)',
		},
	};

	const globalStyles: Record<string, CSSProperties> = isGlobalStyles
		? {
				html: {
					height: '100%',
					width: '100%',
					fontSize: visualConfig.defaultFontSize,
				},
				body: {
					height: '100%',
					width: '100%',
					fontFamily: colorScheme.fontFamilyRegular,
					background: colorScheme.colorVariables.colorDarkShadow,
				},
				p: {
					margin: 0,
				},
				'#root': {
					height: '100%',
				},
			}
		: {};

	const globalTypographyStyles: GlobalTypographyStyles | Record<string, never> = isGlobalStyles
		? {
				h1: {
					fontSize: 24,
				},
				h2: {
					margin: 0,
				},
				p: {
					...smallViewPort.p,
				},
				body: {
					...smallViewPort.body,
				},
				body1: {
					...smallViewPort.body,
				},
				body2: {
					...smallViewPort.body,
					WebkitTextSizeAdjust: '100%',
					background: colorScheme.colorVariables.colorMediumShadow,
				},
			}
		: {};
	return createTheme({
		palette: {
			primary: {
				main: colorScheme.colorVariables.colorPrimary,
			},
			secondary: {
				main: colorScheme.colorVariables.colorPrimarySoft,
			},
			colorScheme,
			sizes,
			borderRadius,
			boxShadow,
			transitionConfig: {
				noAnimationTransition,
				transitionInDuration: noAnimationTransition ? 0 : 600,
				transitionOutDuration: noAnimationTransition ? 0 : 400,
			},
			widgetBackground,
			visualConfig,
		},
		spacing: 5,
		typography: {
			fontSize: 12,
			overline: {
				fontSize: 14,
				color: colorScheme.colorVariables.colorText,
			},
			...globalTypographyStyles,
			smallViewPort,
			largeViewPort,
		},
		props: {
			MuiButtonBase: {
				disableRipple: true,
			},
		},
		overrides: {
			// Date picker
			MuiInputLabel: {
				outlined: {
					transform: 'none !important',
					position: 'absolute',
					top: 10,
					left: sizes.size_8,
					...smallViewPort.typeCaption1,
					fontSize: 14,
					color: colorScheme.colorVariables.colorCaption,
					'&.Mui-focused': {
						color: colorScheme.colorVariables.colorCaption,
					},
					'&.Mui-disabled': {
						color: colorScheme.colorVariables.colorNote,
					},
				},
			},
			MuiOutlinedInput: {
				root: {
					boxShadow: boxShadow.angle.xsmall,
					borderRadius: `${borderRadius.commonRadius}px`,
					'&.Mui-focused': {
						'&:hover': {
							border: '1px solid transparent',
						},
						border: '1px solid transparent',
						outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
					},
					'&.Mui-disabled': {
						boxShadow: 'none',
						border: '1px solid transparent',
					},
				},
				notchedOutline: {
					border: 'none',
				},
			},
			MuiPickersBasePicker: {
				container: {
					borderTop: 'none',
					marginTop: 0,
				},
				pickerView: {
					maxWidth: '100%',
					width: '100%',
					minWidth: '100%',
					overflow: 'visible',
					padding: 0,
					'& > div:nth-child(2)': {
						minHeight: 292,
					},
				},
			},
			MuiPickersCalendarHeader: {
				dayLabel: {
					margin: '0 5px',
				},
				switchHeader: {
					paddingTop: 20,
					marginTop: 0,
					'& p': {
						...smallViewPort.body,
						marginBottom: 0,
					},
				},
				daysHeader: {
					marginTop: sizes.size_4,
					marginBottom: sizes.sizeHalf,
					'& > span': {
						textTransform: 'uppercase',
						...smallViewPort.typeNoteHeading,
						margin: '0 5px',
					},
				},
			},
			MuiPickersStaticWrapper: {
				staticWrapperRoot: {
					minWidth: 'inherit',
				},
			},
			MuiPickersCalendar: {
				week: {
					'& > div:not(:last-child)': {
						marginRight: sizes.sizeHalf,
					},
					paddingBottom: sizes.sizeHalf,
					'& div[role="presentation"] > *': {
						'@media (max-width: 360px)': {
							width: 35,
							height: 35,
							minWidth: 35,
							minHeight: 35,
						},
					},
				},
			},
			MuiCssBaseline: {
				'@global': {
					...globalStyles,
					'input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration':
						{
							WebkitAppearance: 'none',
						},
					/* 
						Removes arrow for the number input
						Chrome, Safari, Edge, Opera
					*/
					'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
						WebkitAppearance: 'none',
					},
					/* Firefox */
					'input[type=number]': {
						MozAppearance: 'textfield',
					},
					...colorScheme.customStyle.global,
				},
			},
			MuiTooltip: {
				tooltip: {
					backgroundColor: 'none',
					fontWeight: 400,
				},
				tooltipPlacementBottom: {
					maxWidth: 400,
					'@media (min-width: 600px)': {
						'&': { marginTop: 14 },
					},
				},
				tooltipPlacementRight: {
					'@media (min-width: 600px)': {
						'&': {
							marginLeft: 15,
							paddingTop: 0,
							paddingBottom: 0,
						},
					},
				},
			},
			MuiIconButton: {
				root: {
					padding: `${sizes.sizeHalf}px ${sizes.size_1}px`,
					color: colorScheme.colorVariables.colorPrimary,
					borderRadius: borderRadius.button,
					transition: 'all 0.8s',
					'&:hover': {
						transition: 'all 0.1s',
						backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
						filter: 'brightness(96%)',
					},
					'&:active': {
						filter: 'brightness(88%)',
						backgroundColor: colorScheme.colorVariables.colorPrimarySoft,
					},
					'&:focus-visible': {
						outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
						outlineOffset: 2,
						transition: 'outline 0s',
					},
					'&:disabled': {
						'& svg': {
							fill: colorScheme.colorVariables.colorBorder,
						},
					},
				},
			},
		},
	});
};
