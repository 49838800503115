import { default as MaterialButton, ButtonProps as MaterialButtonProps } from '@material-ui/core/Button';
import clsx from 'clsx';
import { FC } from 'react';

import { ButtonProgress } from '../button-progress/button-progress.component';
import { buttonStyles, rootButtonStyles } from './button.styles';

export type ButtonProps = Omit<MaterialButtonProps, 'variant'> & {
	loading?: boolean;
	isFullWidth?: boolean;
	variant?: 'text' | 'outlined' | 'contained' | 'link';
};

export const Button: FC<ButtonProps> = ({
	variant = 'contained',
	color,
	size,
	disabled,
	loading,
	classes,
	isFullWidth,
	className,
	onClick,
	children,
	...props
}) => {
	const isDisabled = loading || disabled;
	const aditionalClasses = buttonStyles();
	const rootButtonClasses = rootButtonStyles();
	const buttonClassName = clsx(
		className,
		isFullWidth && aditionalClasses.fullWidth,
		loading && aditionalClasses.loading,
		variant === 'link' && aditionalClasses.linkVariant,
	);

	const buttonClasses = { ...rootButtonClasses, ...classes };
	const buttonVariant: MaterialButtonProps['variant'] = variant === 'link' ? 'text' : variant;

	return (
		<MaterialButton
			disableRipple
			variant={buttonVariant}
			size={size}
			color={color}
			disabled={isDisabled}
			onClick={onClick}
			className={buttonClassName}
			classes={buttonClasses}
			{...props}
		>
			<span className={aditionalClasses.text}>{children}</span>
			{loading && <ButtonProgress variant={variant} color={color} size={size} />}
		</MaterialButton>
	);
};
