import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui-kit/button/button.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { CalendarUnavailableIcon } from '../../../ui-kit/icons/calendar-unavailable.icon';
import { ErrorIcon } from '../../../ui-kit/icons/error.icon';
import { Lazy } from '../../../utils/function.utils';
import { useDoctorSearchEmptyStyles } from './doctor-search-empty.styles';

interface DoctorSearchEmptyProps {
	type: 'ERROR' | 'EMPTY';
	onAction: Lazy<void>;
}

export const DoctorSearchEmpty: FC<DoctorSearchEmptyProps> = ({ type, onAction }) => {
	const classes = useDoctorSearchEmptyStyles();
	const { t } = useTranslation();
	const errorData =
		type === 'EMPTY'
			? {
					icon: CalendarUnavailableIcon,
					alt: 'No matching doctors by filter',
					title: t('doctorSearchEmptyByFiltersTitle', 'No doctors available'),
					description: t(
						'doctorSearchEmptyByFiltersDescription',
						"Unfortunately, we couldn't find any doctors matching your filters.",
					),
					buttonLabel: t('doctorSearchFiltersReset'),
					dataTestingLabel: 'doctor-search-empty-state-button',
				}
			: {
					icon: ErrorIcon,
					alt: 'Error getting doctors',
					title: t('doctorSearchErrorTitle'),
					description: t('doctorSearchErrorDescription'),
					buttonLabel: t('tryAgain'),
					dataTestingLabel: 'doctor-search-error-state-button',
				};

	return (
		<div
			className={classes.root}
			data-testing-label={`doctor-search-${type === 'EMPTY' ? 'empty' : 'error'}-state`}>
			<Icon icon={errorData.icon} size={'large'} iconType={'systemIconDefault'} alt={errorData.alt} />
			<h6 className={classes.title}>{errorData.title}</h6>
			<p className={classes.description}>{errorData.description}</p>
			<Button
				data-testing-label={errorData.dataTestingLabel}
				variant={'text'}
				size={'medium'}
				onClick={onAction}
				className={classes.linkButton}>
				{errorData.buttonLabel}
			</Button>
		</div>
	);
};
