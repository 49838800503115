import { createContext, useContext, useRef, useState } from 'react';

import { constVoid } from '../utils/function.utils';
import { APIServiceContext } from './api-service/api-service.context';
import { TranslationsConfigResponseModel } from './api-service/api-service.model';

export interface TranslationsContextType {
	translations: TranslationsConfigResponseModel;
	updateTranslations(locale: string, client: string): void;
}

export const TranslationsContext = createContext<TranslationsContextType>({
	translations: {},
	updateTranslations: constVoid,
});

interface TranslationsContextProviderProps {
	children: React.ReactNode;
}

export const TranslationsContextProvider = ({ children }: TranslationsContextProviderProps) => {
	const { getTranslations } = useContext(APIServiceContext);

	const [translations, setTranslations] = useState<TranslationsConfigResponseModel>({});
	const lastTranslationLanguageRef = useRef<string>('');

	const handleTranslationsUpdate = (locale: string, client = 'gy-fd') => {
		if (locale !== lastTranslationLanguageRef.current) {
			lastTranslationLanguageRef.current = locale;
			getTranslations(client, locale)
				.then((translations) => {
					setTranslations(translations);
				})
				.catch(() => {
					console.error('Error while fetching translations');
				});
		}
	};

	return (
		<TranslationsContext.Provider value={{ translations, updateTranslations: handleTranslationsUpdate }}>
			{children}
		</TranslationsContext.Provider>
	);
};
