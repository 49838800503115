import { CollapsedWidgetConfigResponseModel } from '../context/api-service/api-service.model';
import { AggregatedEventCollapsedWidget } from '../context/events-service/events-service.model';

interface AggregatedEventCollapasedWidgetComponentData {
	collapsedWidget: AggregatedEventCollapsedWidget;
}

export const toAggregatedComponentsEventData = (
	collapsedButtonsConfig: CollapsedWidgetConfigResponseModel,
	componentName: 'collapsedWidget', // when new component will be added to aggregated events we will extend this type
): AggregatedEventCollapasedWidgetComponentData => ({
	collapsedWidget: {
		componentName,
		enabled: collapsedButtonsConfig.enabled,
		config: collapsedButtonsConfig,
	},
});
