import { addMinutes, addMonths, differenceInCalendarMonths, format } from 'date-fns';
import { FC, memo, useCallback, useContext, useEffect, useState } from 'react';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { SessionContext } from '../../context/session.context';
import { TimeSlotItem } from '../../models/appointment.model';
import { AppointmentSchedulingMetaDataData } from '../../models/message.model';
import { encodeToBase64 } from '../../utils/string.utils';
import {
	dateToFormattedDateString,
	dateToFormattedTimeString,
	dateToFullDateStringFormat,
	getUTCDate,
} from '../../utils/time.utils';
import { Nullable, ResponseError } from '../../utils/types.utils';
import { OnSenMessageFunc } from '../widget-wrapper/widget-wrapper.model';
import { AppointmentScheduler } from './appointment-scheduler/appointment-scheduler.component';
import {
	AppointmentSchedulerTrackerElementName,
	createTrackingEventFunc,
	Dailyslots,
	filterDaysWithAvailableSlots,
	getCalendarDaysWithSlots,
	getSlotsRequestPayload,
	monthHasAvailableSlots,
	SlotsDateBase,
	toSlotsDateBase,
} from './appointment-scheduling.model';

interface AppointmentSchedulerContainerProps {
	data: AppointmentSchedulingMetaDataData;
	onSendMessage: OnSenMessageFunc;
}

export const AppointmentSchedulerContainer: FC<AppointmentSchedulerContainerProps> = memo(({ data, onSendMessage }) => {
	const {
		state: { sessionToken, handleUnauthorizedSessionError },
	} = useContext(SessionContext);
	const { sendEvent } = useContext(EventsServiceContext);
	const { getAppointmentAvailableDates } = useContext(APIServiceContext);

	const trackInteractionEvent = useCallback(
		createTrackingEventFunc<AppointmentSchedulerTrackerElementName>(
			{
				component: 'APPOINTMENT_SCHEDULING',
				scope: data.scope,
				flowId: data.flowId,
				stepName: data.stepName,
			},
			sendEvent,
		),
		[],
	);

	const [selectedDay, setSelectedDay] = useState<string>(dateToFormattedDateString(new Date()));
	const [selectedTime, setSelectedTime] = useState<string>();
	const [selectedTimeSlot, setSelectedTimeSlot] = useState<Nullable<TimeSlotItem>>(null);

	const [monthlySlots, setStoredMonthlySlots] = useState<SlotsDateBase>({});
	const [availableSlots, setAvailableDaysWithSlots] = useState<Dailyslots[]>([]);
	const [calendarDays, setCalendarDays] = useState<string[]>([]);

	const [errorGettingSlots, setErrorGettingSlots] = useState<boolean>(false);
	const [isLoading, setLoadingState] = useState<boolean>(false);
	const [hasMoreSlots, setHasMoreSlots] = useState(false);
	const [isNextMonthAvailable, setIsNextMonthAvailable] = useState(false);

	const numberOfSearchingSlotsRepeating = data.calendarSearchTimeMonths || 18;

	const setCalendarAvailableDays = (daysWithSlots: Dailyslots[]) => {
		const daysWithAvailableSlots = filterDaysWithAvailableSlots(daysWithSlots);
		const calendarDays = getCalendarDaysWithSlots(daysWithAvailableSlots);
		setCalendarDays(calendarDays);
	};

	const fetchInitialData = async (date: Date, numberOfRepeatings: number) => {
		setLoadingState(true);

		try {
			const payload = getSlotsRequestPayload(date, data);
			const response = await getAppointmentAvailableDates(payload);
			const monthHasSlots = monthHasAvailableSlots(response.schedule);
			setHasMoreSlots(response.hasMoreSlots);
			setIsNextMonthAvailable(
				response.hasMoreSlots && numberOfRepeatings < numberOfSearchingSlotsRepeating && response.hasMoreSlots,
			);
			if (!monthHasSlots) {
				if (numberOfRepeatings < numberOfSearchingSlotsRepeating && response.hasMoreSlots) {
					const nextMonthFirstDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
					await fetchInitialData(nextMonthFirstDayDate, numberOfRepeatings + 1);
				} else {
					trackInteractionEvent('NO_AVAILABLE_TIME_SLOTS');
					setLoadingState(false);
				}
			} else {
				setLoadingState(false);
				const monthKey = format(date, 'MM-yyyy');
				const monthWithSlots = toSlotsDateBase(response, monthKey);
				const daysWithAvailableSlots = filterDaysWithAvailableSlots(monthWithSlots[monthKey]);
				setCalendarAvailableDays(monthWithSlots[monthKey]);
				setSelectedDay(daysWithAvailableSlots[0].date);

				setAvailableDaysWithSlots(daysWithAvailableSlots);

				setStoredMonthlySlots((storedSlots) => {
					storedSlots[monthKey] = daysWithAvailableSlots;
					return storedSlots;
				});
			}
		} catch (error) {
			handleUnauthorizedSessionError(error as ResponseError);
			setLoadingState(false);
			setErrorGettingSlots(true);
			trackInteractionEvent('ERROR_FETCH_TIME_SLOTS');
		}
	};

	const handleSelectDay = (day: string) => {
		const newSelectedDayDate = getUTCDate(new Date(day));
		const monthOfNewSelectedDay = newSelectedDayDate.getMonth();
		const monthOfSelectedDay = getUTCDate(new Date(selectedDay)).getMonth();

		if (monthOfSelectedDay !== monthOfNewSelectedDay) {
			const monthKey = format(newSelectedDayDate, 'MM-yyyy');
			const daysWithAvailableSlots = monthlySlots[monthKey];

			setAvailableDaysWithSlots(daysWithAvailableSlots);
		}
		setSelectedDay(day);
		setSelectedTime('');
		setSelectedTimeSlot(null);
		trackInteractionEvent('DATEPICKER_SLOT');
	};

	const handleSelectMonth = async (date: Date) => {
		trackInteractionEvent('CALENDAR_CHANGE_MONTH');
		const monthKey = format(date, 'MM-yyyy');
		const nextMonthDate = addMonths(date, 1);
		const nextMonthKey = format(nextMonthDate, 'MM-yyyy');

		if (!monthlySlots[monthKey]) {
			const payload = getSlotsRequestPayload(date, data);
			const response = await getAppointmentAvailableDates(payload);
			setHasMoreSlots(response.hasMoreSlots);
			setIsNextMonthAvailable(
				response.hasMoreSlots &&
					differenceInCalendarMonths(nextMonthDate, new Date()) < numberOfSearchingSlotsRepeating,
			);

			const monthWithSlots = toSlotsDateBase(response, monthKey);
			const daysWithAvailableSlots = filterDaysWithAvailableSlots(monthWithSlots[monthKey]);

			setCalendarAvailableDays(monthWithSlots[monthKey]);
			setStoredMonthlySlots((storedSlots) => {
				storedSlots[monthKey] = daysWithAvailableSlots;
				return storedSlots;
			});
		} else {
			setCalendarAvailableDays(monthlySlots[monthKey]);
			const isNextMonthAvailableForScheduling =
				!!monthlySlots[nextMonthKey] ||
				(hasMoreSlots &&
					differenceInCalendarMonths(nextMonthDate, new Date()) < numberOfSearchingSlotsRepeating);
			setIsNextMonthAvailable(isNextMonthAvailableForScheduling);
		}
	};

	const onOpenCalendar = () => {
		trackInteractionEvent('DATEPICKER');
		if (selectedDay) {
			const selectedDayDate = new Date(selectedDay);
			const monthKey = format(selectedDayDate, 'MM-yyyy');
			setCalendarAvailableDays(monthlySlots[monthKey]);
		}
	};

	const getAvailableSlots = () => {
		setErrorGettingSlots(false);
		fetchInitialData(getUTCDate(selectedDay), 0);
	};

	const hadleChangeDoctor = (originalText: string) => {
		const text = `gyant start flow ${data.changeProviderFlowStep}`;
		trackInteractionEvent('CHANGE_PROVIDER_BUTTON');
		onSendMessage(text, originalText);
	};

	const handleSchedule = (text: string) => {
		const additionalData = data.scope === 'DIRECT_SCHEDULING' ? { data: selectedTimeSlot?.data } : {};
		const selectedDate = new Date(`${selectedDay} ${selectedTime}`);
		const appointmentDuration = additionalData.data?.duration;
		const timeWindow = appointmentDuration
			? `${dateToFormattedTimeString(selectedDate)} - ${dateToFormattedTimeString(
					addMinutes(selectedDate, appointmentDuration),
				)}`
			: dateToFormattedTimeString(selectedDate);

		const payload = encodeToBase64(
			JSON.stringify({
				appointmentDate: selectedDay,
				appointmentTime: selectedTime,
				formattedDateTime: {
					date: dateToFullDateStringFormat(selectedDate),
					time: timeWindow,
				},
				...additionalData,
			}),
		);
		trackInteractionEvent('SCHEDULE_APPOINTMENT_BUTTON');
		onSendMessage(text, undefined, true, false, undefined, 'data', payload);
	};

	useEffect(() => {
		getAvailableSlots();
	}, [sessionToken]);

	const handleSelectTimeSlot = (timeSlot: Nullable<TimeSlotItem>) => {
		const slot = timeSlot ? timeSlot.time : '';
		setSelectedTime(slot);
		setSelectedTimeSlot(timeSlot);
	};

	const handleTryAgain = () => {
		trackInteractionEvent('REFETCH_TIME_SLOTS');
		getAvailableSlots();
	};

	return (
		<AppointmentScheduler
			selectedDay={selectedDay}
			selectedSlot={selectedTime}
			daysWithSlots={availableSlots}
			availableCalendarDays={calendarDays}
			isSearching={isLoading}
			isError={errorGettingSlots}
			doctorName={data.providerName}
			onOpenCalendar={onOpenCalendar}
			onSelectSlot={handleSelectTimeSlot}
			onSelectDay={handleSelectDay}
			onSelectMonth={handleSelectMonth}
			onChangeDoctor={hadleChangeDoctor}
			onTryAgain={handleTryAgain}
			onSchedule={handleSchedule}
			templates={data.templates}
			canFetchMoreSlots={isNextMonthAvailable}
		/>
	);
});
