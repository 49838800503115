import { render, unmountComponentAtNode } from 'react-dom';

import { AppContainer } from './ui/app/app.container';
import { InitialAppOption } from './ui/app/app.model';

let interval: NodeJS.Timeout;
(function () {
	class GyantWidget {
		hide = () => {
			const iframe = document.getElementById('gyant-web-widget-iframe') as HTMLIFrameElement;
			const closeButton = iframe?.contentDocument?.getElementById('widget-header-close-widget-button');
			closeButton?.click();
		};
		hideAll = () => {
			const rootElement = document.getElementById('widgetRoot');
			if (rootElement) {
				rootElement.style.display = 'none';
			}
		};
		show = () => {
			const iframe = document.getElementById('gyant-web-widget-iframe') as HTMLIFrameElement;
			const toggleButton = document.getElementById('toggle-widget-button');

			if (iframe && toggleButton && toggleButton.classList.contains('closed')) {
				toggleButton.click();
			}
		};
		showAll = () => {
			const rootElement = document.getElementById('widgetRoot');
			if (rootElement) {
				rootElement.style.display = 'block';
			}
		};
		init = (options: InitialAppOption, injectionElement?: HTMLElement) => {
			interval = setInterval(() => {
				if (document.body) {
					clearInterval(interval);
					if (!injectionElement) {
						const rootElement = document.createElement('div');
						rootElement.setAttribute('id', 'widgetRoot');
						document.body.appendChild(rootElement);
					}
					render(
						<AppContainer initTimestamp={new Date().getTime()} {...options} />,
						injectionElement || document.getElementById('widgetRoot'),
					);
				}
			}, 500);
		};
		unmount = (injectionElement?: HTMLElement) => {
			const rootElement = injectionElement || document.getElementById('widgetRoot');
			if (rootElement) {
				unmountComponentAtNode(rootElement);
			}
		};
	}
	/* @ts-expect-error Description: Suppressing TypeScript error because the GyantWidget object is assigned to the global window object. */
	window.GyantWidget = new GyantWidget();
})();
