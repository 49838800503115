import { makeStyles } from '@material-ui/core';

export const useLiveChatMessageStyles = makeStyles(
	({
		typography: { smallViewPort },
		palette: {
			borderRadius,
			sizes,
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			position: 'relative',
			display: 'flex',
		},
		textWrapper: {
			borderRadius: borderRadius.commonRadius,
			padding: `${sizes.size_1}px ${sizes.size_2}px`,
			maxWidth: '80%',
			wordBreak: 'break-word',
		},
		unreadAgentMessage: {
			marginTop: sizes.size_3,
		},
		agentRoot: {
			justifyContent: 'flex-start',
		},
		userRoot: {
			justifyContent: 'flex-end',
		},
		user: {
			textAlign: 'left',
			backgroundColor: colorVariables.colorPrimary,
			...smallViewPort.body,
			color: colorVariables.colorHeadOnDark,
		},
		agent: {
			textAlign: 'left',
			backgroundColor: colorVariables.colorInactive,
			...smallViewPort.typeChip2,
			color: colorVariables.colorText,
		},
		additionalInfo: {
			position: 'absolute',
			paddingTop: 2,
			fontWeight: 500,
			fontSize: 10,
			letterSpacing: 0.21,
			lineHeight: 1,
			color: colorVariables.colorNote,
			top: '100%',
		},
		additionalInfoUser: {
			right: sizes.size_2,
		},
		additionalInfoAgent: {
			left: sizes.size_2,
		},
		withSeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 3px',
			},
		},
		withRetrySeparator: {
			'& > *:not(:last-child):after': {
				content: '"·"',
				padding: '0 8px',
			},
		},
		unreadMessage: {
			height: 1,
			backgroundColor: colorVariables.colorSysWarning,
			position: 'absolute',
			left: sizes.size_1,
			right: sizes.size_1,
			margin: 0,
			top: -(sizes.size_1 + sizes.sizeHalf),
		},
		undeliveredIcon: {
			alignSelf: 'center',
			marginLeft: sizes.sizeHalf,
		},
		messageDeliveredStatus: {
			paddingRight: sizes.size_3,
			marginRight: sizes.sizeHalf,
			paddingTop: sizes.sizeHalf,
			color: colorVariables.colorSysAlert,
		},
		messageStatusText: {
			fontSize: sizes.size_2,
		},
		messageRetryLink: {
			color: colorVariables.colorSysAlert,
			textDecoration: 'underline',
			right: sizes.size_5,
			fontSize: sizes.size_2,
			'&:hover': {
				color: colorVariables.colorSysTextAlert,
				textDecorationColor: colorVariables.colorSysTextAlert,
			},
		},
		messageUndelivered: {
			display: 'flex',
			justifyContent: 'flex-end',
			width: '100%',
		},
		sendingText: {
			opacity: 0.6,
		},
		messageWrapper: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			alignItems: 'end',
		},
		sendingLabel: {
			paddingRight: 0,
			marginRight: 0,
			paddingTop: sizes.sizeHalf,
			color: colorVariables.colorCaption,
		},
	}),
);
