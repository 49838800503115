const DEFAULT_GEOLOCATION_OPTIONS = {
	timeout: 25 * 1000,
};
interface SuccessUserGeolocation {
	status: 'SUCCESS';
	latitude: number;
	longitude: number;
}

interface ErrorUserGeolocation {
	status: 'ERROR';
}

export type ClientGeolocation = SuccessUserGeolocation | ErrorUserGeolocation;

const mapSuccess = (location: GeolocationPosition): SuccessUserGeolocation => ({
	status: 'SUCCESS',
	latitude: location.coords.latitude,
	longitude: location.coords.longitude,
});

const mapError = (error: GeolocationPositionError): ErrorUserGeolocation => {
	// Switch statement is for the case when we will need to show specific location error
	switch (error.code) {
		case 0: // unknown error
		case 1: // permission denied
		case 2: // position unavailable
		case 3: // timeout
		default:
			return {
				status: 'ERROR',
			};
	}
};

export const getUserLocation = (): Promise<ClientGeolocation> =>
	new Promise<GeolocationPosition>((resolve, reject) =>
		navigator.geolocation.getCurrentPosition(resolve, reject, DEFAULT_GEOLOCATION_OPTIONS),
	)
		.then(mapSuccess)
		.catch(mapError);

export const isGeolocationSuccess = (location: ClientGeolocation): location is SuccessUserGeolocation =>
	location.status === 'SUCCESS';
