import { constVoid } from '../../utils/function.utils';
import { EventType, WebTrackerService } from './web-tracker-service';

export const TRACKER_CHAT_EVENTS: string[] = [
	'onBotMessage',
	'onUserClickLink',
	'onUserClickPhone',
	'onUserMessage',
	'onUserSelect',
	'onUserSelectCarousel',
];

interface PreparedTrackingEventData {
	eventAction: string;
	eventLabel: string;
}

export interface BasicEventConfig {
	category: string;
	action: string;
	label: string;
	logEventsToConsole: boolean;
}

export const TRACKER_UI_EVENTS: string[] = ['onCloseChat', 'onMenuIconClick', 'onMenuOptionsClick', 'onOpenChat'];

export const isDirectData = (data: EventType | string): data is string => typeof data === 'string';

export const getDescription = (eventDetail: EventType, description: string): string => {
	switch (description) {
		case 'pageUrl':
			return window.location.href;
		case 'propHref':
			return (
				eventDetail.currentTarget.getAttribute('href') ||
				eventDetail.currentTarget.getAttribute('link') ||
				description
			);
		case 'propTitle':
			return eventDetail.currentTarget.getAttribute('title') || description;
		case 'propHtmlContent':
			return eventDetail.currentTarget.textContent?.replace(/(<([^>]+)>)/gi, '').trim() || description;
		default:
			return description;
	}
};

export const prepareEventData = (
	event: EventType | string,
	action: string,
	label: string,
): PreparedTrackingEventData => ({
	eventAction: isDirectData(event) ? action : getDescription(event, action),
	eventLabel: isDirectData(event) ? event : getDescription(event, label),
});

export const WEB_TRACKER_SERVICE_DEFAULT: WebTrackerService = {
	sendEvent: constVoid,
};
