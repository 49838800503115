export const MOBILE_WIDTH_PORTRAIT = 481;
export const MOBILE_WIDTH_LANDSCAPE = 1023;

// Currently we can rely on user agent, if not work well we can switch to detect screen width
export const isMobileDeviceByUserAgent = (): boolean => {
	const regExMobileDevices = new RegExp(
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i,
	);

	return regExMobileDevices.test(navigator.userAgent);
};
type ScreenOrientation = 'portrait' | 'landscape';

// max-device-width property is deprectaed and can be removed from supported
const getMediaQuery = (width: number, orientation: ScreenOrientation): string =>
	`(max-device-width: ${width}px) and (orientation: ${orientation})`;

export const isMobileDeviceByDeviceWidth = (): boolean => {
	const portraitQuery = getMediaQuery(MOBILE_WIDTH_PORTRAIT, 'portrait');
	const landscapeQuery = getMediaQuery(MOBILE_WIDTH_LANDSCAPE, 'landscape');

	return window.matchMedia(`${portraitQuery},${landscapeQuery}`).matches;
};

export const isMobileDeviceByTouchPoints = (): boolean => {
	let hasTouchScreen = false;
	if ('maxTouchPoints' in navigator) {
		hasTouchScreen = navigator.maxTouchPoints > 0;
	} else if (window.matchMedia) {
		hasTouchScreen = window.matchMedia('(pointer:coarse)').matches;
	} else {
		hasTouchScreen = isMobileDeviceByUserAgent();
	}
	return hasTouchScreen;
};
