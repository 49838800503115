import classNames from 'classnames';
import { FC } from 'react';

import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../ui-kit/button/button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { ArrowDownIcon } from '../../ui-kit/icons/arrow-down.icon';
import { AvatarIcon } from '../../ui-kit/icons/avatar.icon';
import { Effect } from '../../utils/function.utils';
import { useAdditionalAvatarButtonStyles, useAvatarButtonStyles } from './avatar-button.styles';

type AvatarButtonSize = 'short' | 'tall';

interface AvatarButtonProps {
	logo?: string;
	onClick?: Effect<EventType>;
	className?: string;
	size?: AvatarButtonSize;
	isCollapsed?: boolean;
	isDisabled?: boolean;
	isMinimized?: boolean;
	hasNotifications?: boolean;
	id?: string;
	dataTestingLabel?: string;
	tabIndex?: number;
}

export const AvatarButton: FC<AvatarButtonProps> = ({
	size = 'tall',
	hasNotifications,
	isCollapsed = true,
	logo,
	dataTestingLabel,
	isDisabled,
	isMinimized,
	id,
	tabIndex,
	className,
	onClick,
}) => {
	const customLogo = logo ? `url(${logo})` : '';
	const classes = useAvatarButtonStyles({ customLogo, isCollapsed });
	const additionalClasses = useAdditionalAvatarButtonStyles({ customLogo, isCollapsed });
	const additionalClassName = classNames(
		additionalClasses[size],
		className,
		isMinimized && additionalClasses.minimized,
		hasNotifications && additionalClasses.hasNotifications,
	);

	return (
		<Button
			disabled={isDisabled}
			tabIndex={tabIndex}
			id={id}
			data-testing-label={dataTestingLabel}
			aria-label={'virtual assistant'}
			className={additionalClassName}
			classes={classes}
			onClick={onClick}
		>
			<div
				id={'collapsed'}
				className={classNames(
					additionalClasses.collapsed,
					additionalClasses.backgroundWrapper,
					isMinimized && 'minimized',
				)}
			>
				{!logo && <AvatarIcon />}
			</div>
			<div
				id={'uncollapsed'}
				className={classNames(
					additionalClasses.uncollapsed,
					additionalClasses.backgroundWrapper,
					isMinimized && 'minimized',
				)}
			>
				<Icon icon={ArrowDownIcon} iconType={'buttonIcon'} size={'large'} alt={'collapse assistant'} />
			</div>
		</Button>
	);
};
