import { createContext } from 'react';

import { AppConfig, AppOptions, DEFAULT_APP_CONFIG, DEFAULT_APP_OPTIONS } from '../models/app.model';

export interface ConfigContext {
	appOptions: AppOptions;
	appConfig: AppConfig;
}
export const ConfigContext = createContext<ConfigContext>({
	appOptions: DEFAULT_APP_OPTIONS,
	appConfig: DEFAULT_APP_CONFIG,
});
