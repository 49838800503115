import { Fragment, memo, useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { CTAButtonConfigResponseModel } from '../../context/api-service/api-service.model';
import { ConfigContext } from '../../context/config.context';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { CollapsedWidgetButtonConfig } from '../collapsed-widget/collapsed-widget.model';
import { CallToActionButton } from './call-to-action-button.component';

interface CallToActionButtonContainerProps {
	onClick(e: EventType, collapsedWidgetSelectedActionItem?: CollapsedWidgetButtonConfig): void;
}

export const CallToActionButtonContainer = memo<CallToActionButtonContainerProps>(({ onClick }) => {
	const {
		appOptions: { client },
	} = useContext(ConfigContext);
	const { getCTAButtonConfig } = useContext(APIServiceContext);

	const [config, setConfig] = useState<CTAButtonConfigResponseModel[]>([]);

	useEffect(() => {
		getCTAButtonConfig(client)
			.then(setConfig)
			.catch(() => console.error('Error while fetching CTA button config.'));
	}, []);

	const renderPortal = (config: CTAButtonConfigResponseModel) => {
		const rootElement = document.getElementById(config.id);

		if (rootElement) {
			const callToActionItem: CollapsedWidgetButtonConfig | undefined = config.payload
				? {
						payload: config.payload,
						renderedTitle: config.title,
						title: config.title,
						eventCallbackData: {
							eventName: 'CTAButtonClick',
							data: {
								scope: config.title,
							},
						},
					}
				: undefined;

			const clickHandler = (e: EventType) => {
				onClick(e, callToActionItem);
			};

			return ReactDOM.createPortal(
				<CallToActionButton
					onClick={clickHandler}
					style={config.style}
					title={config.title}
					className={config.class}
				/>,
				rootElement,
			);
		}
	};

	return <Fragment>{config.length > 0 && config.map(renderPortal)}</Fragment>;
});
