import { addMinutes } from 'date-fns';

import {
	CancelAppointmentApi,
	FutureAppointment,
	FutureAppointmentCancelData,
	FutureAppointmentData,
	FutureAppointmentRescheduleData,
} from '../../context/api-service/api-service.model';
import { encodeToBase64 } from '../../utils/string.utils';
import { dateToFormattedString, dateToFormattedTimeString, dateToFullDateStringFormat } from '../../utils/time.utils';
import { Nullable } from '../../utils/types.utils';
import {
	AppointmentDateTimeValues,
	SummaryLocation,
	SummaryTemplates,
} from '../appointment-scheduling/appointment-summary/appointment-summary.model';
import { OnSenMessageFunc } from '../widget-wrapper/widget-wrapper.model';

export interface Appointment {
	id: string;
	title: string;
	description: string;
	appointmentValues: AppointmentDateTimeValues;
	contact?: Nullable<string>;
	location?: Nullable<SummaryLocation>;
	templates: SummaryTemplates;
	date: string;
	isCancelable: boolean;
	isReschedulable: boolean;
	onReschedule(rescheduleTemplate: string): void;
	onCancel(reason: string): Promise<boolean>;
}

export interface AppointmentGroup {
	title: string;
	appointments: Appointment[];
}

export const getAppointmentDateTimeValues = (appointment: FutureAppointment): AppointmentDateTimeValues => ({
	appointmentDuration: appointment.appointment.duration,
	appointmentTime: appointment.appointment.time,
	appointmentDate: appointment.appointment.appointmentTime,
});

export const getAppointmentDate = (appointmentTime: string): Date =>
	new Date(`${appointmentTime.replace(/(T.*)(Z|[+-](\d{2}:?\d{2}$)|([+-]\d{2}$))/, '$1')}`);

const onCancelAction = (
	futureAppointmentCancelData: Nullable<FutureAppointmentCancelData>,
	reason: string,
	cancelAppointment: CancelAppointmentApi,
): Promise<boolean> =>
	futureAppointmentCancelData
		? cancelAppointment({
				cancelData: {
					...futureAppointmentCancelData,
					reason,
				},
			})
				.then(({ result }) => result === 'ok')
				.catch(() => false)
		: Promise.resolve(false);

const getAppointmentDescription = (appointment: FutureAppointmentData) =>
	`${appointment.visitType.name} with ${appointment.provider.name}`;

const onRescheduleAction = (
	futureAppointment: FutureAppointment,
	onSendMessage: OnSenMessageFunc,
	rescheduleTemplate: string,
) => {
	const appointmentStartTime = getAppointmentDate(futureAppointment.appointment.appointmentTime);
	const appointmentTimeWindow = `${dateToFormattedTimeString(appointmentStartTime)} - ${dateToFormattedTimeString(
		addMinutes(appointmentStartTime, futureAppointment.appointment.duration),
	)}`;
	const data = {
		description: getAppointmentDescription(futureAppointment.appointment),
		formattedDateTime: {
			date: dateToFullDateStringFormat(appointmentStartTime),
			time: appointmentTimeWindow,
		},
		...futureAppointment,
	} as FutureAppointmentRescheduleData;
	const payload = encodeToBase64(JSON.stringify(data));
	onSendMessage(rescheduleTemplate, undefined, true, false, undefined, 'data', payload);
};

export const mapToAppointmentGroups = (
	futureAppointments: FutureAppointment[],
	onSendMessage: OnSenMessageFunc,
	cancelAppointment: CancelAppointmentApi,
): AppointmentGroup[] => {
	// order appointments by date
	const appointmentsByDate = futureAppointments.sort((a, b) =>
		a.appointment.appointmentTime > b.appointment.appointmentTime ? 1 : -1,
	);

	// map to FutureAppointment
	const mappedAppointments = appointmentsByDate.map((futureAppointment): Appointment => {
		const { appointment } = futureAppointment;
		const description = getAppointmentDescription(appointment);

		return {
			id: appointment.identifier.id,
			title: appointment.time,
			description,
			date: dateToFormattedString(getAppointmentDate(appointment.appointmentTime), 'EEE, LLL d'),
			appointmentValues: getAppointmentDateTimeValues(futureAppointment),
			templates: { additionalInformation: futureAppointment.appointment.patientInstructions },
			location: {
				name: futureAppointment.appointment.location.name,
				street: futureAppointment.appointment.location.address.street,
				city: futureAppointment.appointment.location.address.city,
				stateCode: futureAppointment.appointment.location.address.stateCode,
				zip: futureAppointment.appointment.location.address.postalCode,
			},
			contact: futureAppointment.appointment.location.phoneNumber,
			isCancelable: !!futureAppointment.cancelData,
			isReschedulable: !!futureAppointment.appointment.canReschedule,
			onCancel: (reason: string) => onCancelAction(futureAppointment.cancelData, reason, cancelAppointment),
			onReschedule: (rescheduleTemplate: string) =>
				onRescheduleAction(futureAppointment, onSendMessage, rescheduleTemplate),
		};
	});

	// group appointments by date
	return mappedAppointments.reduce<AppointmentGroup[]>((acc, current: Appointment) => {
		const previousAccEntry = acc[acc.length > 0 ? acc.length - 1 : 0];

		if (!previousAccEntry || previousAccEntry.title !== current.date) {
			acc.push({ title: current.date, appointments: [current] });
		} else {
			acc[acc.length - 1].appointments.push(current);
		}

		return acc;
	}, []);
};
