import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useQuickResponseRendererStyles = makeStyles<Theme, { isWidgetDisabled: boolean }>(
	({ palette: { colorScheme, sizes } }) => ({
		buttonsContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			padding: `${sizes.size_2}px ${sizes.size_1}% ${sizes.size_1}px`,
		},
		stackedButtonsContainer: {
			flexDirection: 'column',
		},
		stackedButtonsSatisfactionContainer: {
			width: '100%',
			'& > div': {
				width: '100%',
			},
		},
		customQuickResponseStyles: {
			marginBottom: sizes.size_1,
			'&:not(:last-child)': {
				marginRight: sizes.sizeHalf,
			},
			...colorScheme.customStyle.quickResponseButtons?.main,
		},
		externalResponse: {
			...colorScheme.customStyle.quickResponseButtons?.external,
		},
		lastQrpadding: {
			paddingTop: sizes.size_2,
		},
		survey: {
			flexDirection: 'column',
			alignItems: 'center',
		},
	}),
);
