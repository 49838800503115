import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../models/dimensions.model';

export const useAppointmentDetailsStyles = makeStyles<Theme>(
	({ palette: { colorScheme, sizes }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			color: colorScheme.colorVariables.colorText,
			position: 'relative',
			display: 'grid',
		},
		title: {
			...smallViewPort.typeHeading1,
			margin: `0 ${sizes.size_1}px ${sizes.size_1}px`,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeHeading1,
			},
		},
		time: {
			...smallViewPort.body,
			margin: `0 ${sizes.size_1}px ${sizes.size_1}px`,
		},
		date: {
			...smallViewPort.body,
			margin: `0 ${sizes.size_1}px`,
		},
		button: {
			width: '100%',
			margin: `${sizes.sizeHalf}px 0`,
		},
		primaryButton: {
			fontWeight: 700,
		},
		cancelButton: {
			fontWeight: 700,
			backgroundColor: colorScheme.colorVariables.colorSysBgWarning,
			color: colorScheme.colorVariables.colorSysAlert,
			'&:hover': {
				backgroundColor: colorScheme.colorVariables.colorSysBgWarning,
			},
		},
	}),
);
