import { MenuItemActionModel } from '../../context/api-service/api-service.model';
import { MenuItem } from '../../models/app.model';
import { LiveChatStatus } from '../../models/live-chat.model';
import { IconType, MenuItemAction } from '../../models/menu.model';

const parseMenuTitleToIconType = (title: string): IconType => {
	if (title.toLowerCase().includes('start_over')) {
		return 'RESTART';
	} else if (title.toLowerCase().includes('suggestions')) {
		return 'INFO';
	} else if (title.toLowerCase().includes('nurse')) {
		return 'CHAT';
	}
	return 'NO_ICON';
};

const addIconTypeByMenuType = (menuItem: MenuItemActionModel): MenuItemAction => {
	switch (menuItem.type) {
		case 'postback':
			return { ...menuItem, iconType: parseMenuTitleToIconType(menuItem.payload) };
		case 'web_url':
			return menuItem;
	}
};

export const getMenuItemsByLanguage = (language: string, menu: MenuItem[]): MenuItemAction[] => {
	const menuByLanuage = menu.find((menuItem) => menuItem.locale === language);
	const menuItems = menuByLanuage ? menuByLanuage.menuItemActions : [];
	return menuItems.map(addIconTypeByMenuType);
};

export const getLeaveLiveChatCommand = (
	status: LiveChatStatus,
	isFeedbackLiveChatSessionOn: boolean,
	leaveQueueCommand = '',
): string => {
	if (isFeedbackLiveChatSessionOn) {
		return '';
	}
	return status === 'CONNECTED' ? 'endLiveChat' : leaveQueueCommand;
};
