import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const DirectionsIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M10 12H13.5V13.3C13.5 13.5333 13.6 13.6917 13.8 13.775C14 13.8583 14.1833 13.8167 14.35 13.65L16.3 11.7C16.5 11.5 16.6 11.2667 16.6 11C16.6 10.7333 16.5 10.5 16.3 10.3L14.35 8.35C14.1833 8.18333 14 8.14167 13.8 8.225C13.6 8.30833 13.5 8.46667 13.5 8.7V10H9C8.71667 10 8.47917 10.0958 8.2875 10.2875C8.09583 10.4792 8 10.7167 8 11V14C8 14.2833 8.09583 14.5208 8.2875 14.7125C8.47917 14.9042 8.71667 15 9 15C9.28333 15 9.52083 14.9042 9.7125 14.7125C9.90417 14.5208 10 14.2833 10 14V12ZM12 22C11.75 22 11.5042 21.95 11.2625 21.85C11.0208 21.75 10.8 21.6 10.6 21.4L2.6 13.4C2.4 13.2 2.25 12.9792 2.15 12.7375C2.05 12.4958 2 12.25 2 12C2 11.75 2.05 11.5042 2.15 11.2625C2.25 11.0208 2.4 10.8 2.6 10.6L10.6 2.6C10.8 2.4 11.0208 2.25 11.2625 2.15C11.5042 2.05 11.75 2 12 2C12.25 2 12.4958 2.05 12.7375 2.15C12.9792 2.25 13.2 2.4 13.4 2.6L21.4 10.6C21.6 10.8 21.75 11.0208 21.85 11.2625C21.95 11.5042 22 11.75 22 12C22 12.25 21.95 12.4958 21.85 12.7375C21.75 12.9792 21.6 13.2 21.4 13.4L13.4 21.4C13.2 21.6 12.9792 21.75 12.7375 21.85C12.4958 21.95 12.25 22 12 22Z'
			}
		/>
	</SvgIcon>
);
