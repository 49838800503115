import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const MyLocationIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M11 21.9498V20.9498C8.91672 20.7165 7.12922 19.854 5.63755 18.3623C4.14588 16.8706 3.28338 15.0831 3.05005 12.9998H2.05005C1.76672 12.9998 1.52922 12.904 1.33755 12.7123C1.14588 12.5206 1.05005 12.2831 1.05005 11.9998C1.05005 11.7165 1.14588 11.479 1.33755 11.2873C1.52922 11.0956 1.76672 10.9998 2.05005 10.9998H3.05005C3.28338 8.91647 4.14588 7.12897 5.63755 5.6373C7.12922 4.14564 8.91672 3.28314 11 3.0498V2.0498C11 1.76647 11.0959 1.52897 11.2875 1.3373C11.4792 1.14564 11.7167 1.0498 12 1.0498C12.2834 1.0498 12.5209 1.14564 12.7125 1.3373C12.9042 1.52897 13 1.76647 13 2.0498V3.0498C15.0834 3.28314 16.8709 4.14564 18.3625 5.6373C19.8542 7.12897 20.7167 8.91647 20.95 10.9998H21.95C22.2334 10.9998 22.4709 11.0956 22.6625 11.2873C22.8542 11.479 22.95 11.7165 22.95 11.9998C22.95 12.2831 22.8542 12.5206 22.6625 12.7123C22.4709 12.904 22.2334 12.9998 21.95 12.9998H20.95C20.7167 15.0831 19.8542 16.8706 18.3625 18.3623C16.8709 19.854 15.0834 20.7165 13 20.9498V21.9498C13 22.2331 12.9042 22.4706 12.7125 22.6623C12.5209 22.854 12.2834 22.9498 12 22.9498C11.7167 22.9498 11.4792 22.854 11.2875 22.6623C11.0959 22.4706 11 22.2331 11 21.9498ZM12 18.9998C13.9334 18.9998 15.5834 18.3165 16.95 16.9498C18.3167 15.5831 19 13.9331 19 11.9998C19 10.0665 18.3167 8.41647 16.95 7.0498C15.5834 5.68314 13.9334 4.9998 12 4.9998C10.0667 4.9998 8.41672 5.68314 7.05005 7.0498C5.68338 8.41647 5.00005 10.0665 5.00005 11.9998C5.00005 13.9331 5.68338 15.5831 7.05005 16.9498C8.41672 18.3165 10.0667 18.9998 12 18.9998ZM12 15.9998C10.9 15.9998 9.95838 15.6081 9.17505 14.8248C8.39172 14.0415 8.00005 13.0998 8.00005 11.9998C8.00005 10.8998 8.39172 9.95814 9.17505 9.1748C9.95838 8.39147 10.9 7.9998 12 7.9998C13.1 7.9998 14.0417 8.39147 14.825 9.1748C15.6084 9.95814 16 10.8998 16 11.9998C16 13.0998 15.6084 14.0415 14.825 14.8248C14.0417 15.6081 13.1 15.9998 12 15.9998Z'
			}
		/>
	</SvgIcon>
);
