import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ArrowDownIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M12 14.9748C11.8667 14.9748 11.7417 14.954 11.625 14.9123C11.5083 14.8706 11.4 14.7998 11.3 14.6998L6.69999 10.0998C6.51665 9.91647 6.42499 9.68314 6.42499 9.3998C6.42499 9.11647 6.51665 8.88314 6.69999 8.6998C6.88332 8.51647 7.11665 8.4248 7.39999 8.4248C7.68332 8.4248 7.91665 8.51647 8.09999 8.6998L12 12.5998L15.9 8.6998C16.0833 8.51647 16.3167 8.4248 16.6 8.4248C16.8833 8.4248 17.1167 8.51647 17.3 8.6998C17.4833 8.88314 17.575 9.11647 17.575 9.3998C17.575 9.68314 17.4833 9.91647 17.3 10.0998L12.7 14.6998C12.6 14.7998 12.4917 14.8706 12.375 14.9123C12.2583 14.954 12.1333 14.9748 12 14.9748Z'
			}
		/>
	</SvgIcon>
);
