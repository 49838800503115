import Cookies, { CookieAttributes } from 'js-cookie';

const BASE_CLIENT_TOKEN_COOKIE_NAME = 'gyant_token_';

export const getCookieExpireTime = (cookieLifetime: number): Date => {
	const expireTimeMilliseconds = new Date().getTime() + cookieLifetime * 1000;
	return new Date(expireTimeMilliseconds);
};

export const getClientCookieName = (client: string): string => `${BASE_CLIENT_TOKEN_COOKIE_NAME}${client}`;

export const setCookie = (cookieName: string, value: string, expireTimeout?: number): void => {
	const cookieOptions: CookieAttributes = {
		expires: expireTimeout ? getCookieExpireTime(expireTimeout) : undefined,
		secure: true,
		sameSite: 'None',
	};
	Cookies.set(cookieName, value, cookieOptions);
};

export const getCookie = (cookieName: string): string | undefined => Cookies.get(cookieName);

export const removeCookie = (cookieName: string): void => Cookies.remove(cookieName);
