import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { setNextSequenceFocusElement } from '../../utils/DOM.utils';
import { Effect } from '../../utils/function.utils';
import { Stepper } from '../stepper/stepper.component';
import { useCarouselStyles } from './carousel.styles';

const addEventListener = (eventType: string, elements: NodeListOf<Element>, cb: Effect<number>): void => {
	elements.forEach((elem, index) => elem.addEventListener(eventType, () => cb(index)));
};
const removeEventListener = (elements: NodeListOf<Element>): void => {
	elements?.forEach((elem) => elem.replaceWith(elem.cloneNode(true)));
};

interface CarouselProps {
	cards: JSX.Element[];
	sourceDocument?: Document;
}

export const Carousel: FC<CarouselProps> = ({ cards, sourceDocument }) => {
	const [activeStep, setActiveStep] = useState(0);
	const classes = useCarouselStyles({ activeStep });
	const ref = useRef<HTMLDivElement>(null);

	const maxSteps = cards.length;
	const stepClick = (index: number) => {
		setActiveStep(index);
	};

	// add steps event listeners to change the cards
	useEffect(() => {
		if (sourceDocument) {
			const cards = sourceDocument?.querySelectorAll('.card-container');

			if (cards) {
				setNextSequenceFocusElement(sourceDocument, '[data-testing-label="user-message"]');
				addEventListener('keyup', cards, stepClick);
				addEventListener('click', cards, stepClick);

				return () => {
					// remove all listeners
					removeEventListener(cards);
					setActiveStep(1);
				};
			}
		}
	}, [maxSteps]);

	return (
		<div
			className={classNames(classes.wrapper, maxSteps > 1 && classes.stepper)}
			data-testing-label={'carousel-container'}
			ref={ref}>
			<SwipeableViews
				disableLazyLoading
				className={classes.root}
				index={activeStep}
				onChangeIndex={setActiveStep}
				enableMouseEvents
				threshold={1}>
				{cards.map((card, index) => (
					<div
						key={index}
						className={classNames(
							classes.card,
							index === activeStep ? classes.activeCard : classes.pointer,
						)}>
						{card}
					</div>
				))}
			</SwipeableViews>
			{maxSteps > 1 && (
				<Stepper
					steps={maxSteps}
					activeStep={activeStep + 1}
					onActiveStepChange={(index) => stepClick(index - 1)}
				/>
			)}
		</div>
	);
};
