import { Theme } from '@material-ui/core';
import { ClassNameMap, makeStyles } from '@material-ui/styles';

import { CollapsedWidgetCallActionItem } from '../../../context/api-service/api-service.model';
import { Lazy } from '../../../utils/function.utils';

export const createAdditionalClasses = (
	action: CollapsedWidgetCallActionItem,
	transition: string,
): Lazy<ClassNameMap<'root'>> => {
	const hoverStyles = action.style?.onMouseOver ? { '&:hover': action.style.onMouseOver } : {};
	return makeStyles<Theme>(
		({
			palette: {
				transitionConfig: { noAnimationTransition },
			},
		}) => ({
			root: {
				fontSize: 18,
				'&:not(:last-child)': {
					marginBottom: 5,
				},
				'&:first-child': {
					marginTop: 8,
				},
				transition: noAnimationTransition ? 'initial' : `${transition}, box-shadow 0.8s, background-color 0.8s`,
				...action.style?.appearance,
				...hoverStyles,
			},
		}),
	);
};
