import { makeStyles, Theme } from '@material-ui/core';

export const useAppointmentButtonStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			borderRadius,
		},
		typography: { smallViewPort },
	}) => ({
		button: {
			position: 'relative',
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			padding: `${sizes.size_2}px ${sizes.size_1}px ${sizes.size_3}px ${sizes.size_3}px`,
			backgroundColor: 'white',
			borderRadius: borderRadius.card,
			border: `1px solid ${colorVariables.colorDarkShadow}`,
			textAlign: 'left',
			cursor: 'pointer',
			transition: 'all 0.8s',
			'& > svg': {
				transition: 'all 0.8s',
			},
			'&:hover': {
				backgroundColor: colorVariables.colorBgSurface,
				border: `1px solid ${colorVariables.colorBorder}`,
				boxShadow: `0 4px 0 -2px ${colorVariables.colorMediumShadow}`,
				borderRadius: borderRadius.card,
				transition: 'all 0.1s',
				'& > svg': {
					fill: colorVariables.colorPrimary,
					transition: 'all 0.1s',
				},
			},
			'&:focus-visible': {
				outline: `${sizes.sizeQuarter}px solid ${colorVariables.colorPrimary}`,
				outlineOffset: sizes.sizeQuarter,
				transition: 'outline 0s',
			},
		},
		titleContainer: {
			width: '100%',
			display: 'flex',
			flexDirection: 'row',
		},
		title: {
			...smallViewPort.typeCaption1,
			width: '100%',
			marginBottom: 0,
			paddingRight: sizes.size_3,
		},
		description: {
			...smallViewPort.typeBot1,
			marginBottom: 0,
			paddingRight: sizes.size_3,
		},
		arrowContainer: {
			position: 'absolute',
			right: sizes.size_2,
			top: 0,
			bottom: 0,
			margin: 'auto 0',
		},
	}),
);
