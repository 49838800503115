import { FC, memo, useContext } from 'react';

import { APIServiceContext } from '../../../context/api-service/api-service.context';
import { ConfigContext } from '../../../context/config.context';
import { SessionContext } from '../../../context/session.context';
import { Message } from '../../../models/message.model';
import { HelpfulnessSurvey } from '../../../ui-kit/user-survey/helpfulness-survey/helpfulness-survey.component';
import { sendFeedbackSurvey } from '../helfulness-survey.model';

interface HelpfulnessSurveyContainerProps {
	message: Message;
}

export const HelpfulnessSurveyContainer: FC<HelpfulnessSurveyContainerProps> = memo(({ message }) => {
	const {
		state: { sessionToken },
	} = useContext(SessionContext);
	const {
		appOptions: { client },
	} = useContext(ConfigContext);

	const { sendEvent, sendAnonymousEvent } = useContext(APIServiceContext);

	const sendFeedbackWrapper = (rating: number): void => {
		sendFeedbackSurvey(
			'HELPFULNESS_SURVEY',
			rating,
			message.flowStep || '',
			client,
			sessionToken,
			sendEvent,
			sendAnonymousEvent,
		);
	};

	return <HelpfulnessSurvey action={sendFeedbackWrapper} />;
});
