import { pipe } from 'fp-ts/lib/function';

import { EnvironmentType } from '../models/app.model';

const getScripElement = (document: Document): HTMLScriptElement | null =>
	document.querySelector('script[src*="gyant-embed.js"]');

export const getGyantEmbedHost = (document: Document): string => {
	const isScriptLocallyHosted = isLocallyHosted();
	const url = getScripElement(document)?.getAttribute('src');
	const match = url?.match(new RegExp('https?://[^/]*'));

	if (isScriptLocallyHosted || !match) {
		return '';
	}
	return match[0];
};

export const getEmbedWidgetSocketHost = (): string => {
	const gyantEmbedHost = getGyantEmbedHost(window.document);
	return gyantEmbedHost ? gyantEmbedHost.split('//')[1] : location.host;
};

const ENVIRONMENTS_FAVICONS: Record<EnvironmentType, string> = {
	production:
		'https://s3.amazonaws.com/assets.gyant.com/testing-pages/test-embed/favicons/web2/fabric/production.ico',
	staging: 'https://s3.amazonaws.com/assets.gyant.com/testing-pages/test-embed/favicons/web2/fabric/staging.ico',
	development:
		'https://s3.amazonaws.com/assets.gyant.com/testing-pages/test-embed/favicons/web2/fabric/development.ico',
	local: 'https://s3.amazonaws.com/assets.gyant.com/testing-pages/test-embed/favicons/web2/fabric/local.ico',
	recovery: 'https://s3.amazonaws.com/assets.gyant.com/testing-pages/test-embed/favicons/web2/fabric/local.ico',
};

export const isEmbedWidget = (): boolean => {
	const embeddedScript = getScripElement(document);
	return embeddedScript !== null;
};

export const isLocallyHosted = (): boolean => {
	const url = getScripElement(document)?.getAttribute('src');
	const match = url?.match(new RegExp('https?://[^/]*'));

	return !!url && !match;
};

export const getFullImageHostPath = (image: string): string => {
	const embedScriptImageHost = image.includes('http') ? image : `${getGyantEmbedHost(document)}/${image}`;
	return isEmbedWidget() ? embedScriptImageHost : image;
};

const getEnvironment = (host: string): EnvironmentType => {
	const splittedHost = host.split('.');

	if (splittedHost.includes('recovery')) {
		return 'recovery';
	}
	if (splittedHost.includes('dev')) {
		return 'development';
	}
	if (splittedHost.includes('staging')) {
		return 'staging';
	}
	if (splittedHost.includes('production')) {
		return 'production';
	}
	return 'production';
};

export const setFaviconsByEnvironmentInGyantHost = ({ location, document }: Window): boolean => {
	if (!isEmbedWidget()) {
		const isLocalHost = location.host.includes('localhost:');
		const environment = getEnvironment(location.host);
		const envKey: EnvironmentType = isLocalHost ? 'local' : environment;

		const link = document.querySelector("link[rel~='icon']");
		if (link) {
			link.setAttribute('href', ENVIRONMENTS_FAVICONS[envKey]);
			return true;
		}
		return false;
	}

	return false;
};

export const getHostEnvironment = ({ location, document }: Window): EnvironmentType => {
	const isGyantHost = location.host.includes('.gyantts.');
	const isLocalHost = location.host.includes('localhost:');

	if (isLocalHost) {
		return 'local';
	}
	if (isGyantHost) {
		return getEnvironment(location.host);
	}
	return pipe(document, getGyantEmbedHost, getEnvironment);
};
