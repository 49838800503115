export const USER_INPUT_HEIGHT = 48;
export const PROGRESS_TIME_LABEL_HEIGHT = 28;

export const HEADER_HEIGHT_DESKTOP = 108;
export const HEADER_HEIGHT_MOBILE = 92;

export const Z_INDEX_TOP = 100000001;
export const MAX_WIDGET_WIDTH = 800;
export const ANIMATED_GRADIENT_ROTATION_ANGLE = 45;

export const CARD_MAX_WIDTH = 410;

export const getGradientLayerPositions = (animateBackground: boolean): number | string =>
	animateBackground ? '-100%' : 0;
