import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useUtilStyles = makeStyles<Theme>(({ palette: { sizes } }) => ({
	carouselAdditionalComponent: {
		display: 'table',
		margin: '0 auto',
		padding: `${sizes.size_2}px 0px 0px`,
	},
	additionalComponent: {
		padding: `0px calc(${sizes.size_1}% + ${sizes.size_1}px) 0`,
	},
	resetMargin: {
		'& + div': {
			// need to use important, otherwise we can't override the styles of the outer component
			marginTop: 'unset !important',
		},
	},
	livechatAdditionalComponent: {
		marginTop: 'auto',
	},
	appointmentManagementAdditionalComponent: {
		padding: `0px ${sizes.size_1}% 0`,
	},
	doctorSearchAdditionalComponent: {
		padding: `0px ${sizes.size_1}% 0`,
		flex: 1,
	},
	responseTypeAdditionalComponent: {
		marginTop: 'auto',
		padding: `0px ${sizes.size_1}%`,
	},
	responseTypeAdditionalComponentWithQR: {
		'& > form': {
			marginBottom: 0,
		},
	},
	AIFeedback: {
		display: 'flex',
		flex: 1,
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
}));
