import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	LiveChatCallToActionConfig,
	LiveChatConfigResponseModel,
} from '../../../../context/api-service/api-service.model';
import { LiveChatContext } from '../../../../context/live-chat-context';
import { SessionContext } from '../../../../context/session.context';
import { isLiveChatWaiting } from '../../../../models/live-chat.model';
import { Lazy } from '../../../../utils/function.utils';
import { Nullable } from '../../../../utils/types.utils';
import {
	LiveChatAction,
	LiveChatStatusControl,
	StatusControlMenuActionType,
	StatusControlProps,
} from './status-control.component';

export interface LiveChatStatusControlContainerProps {
	onClick?: Lazy<void>;
	className?: StatusControlProps['className'];
}

const WAITING_MESSAGE_CHANGE_INTERVAL = 10000;
const END_LIVE_CHAT_COMMAND = 'endLiveChat';

export const LiveChatStatusControlContainer: FC<LiveChatStatusControlContainerProps> = ({ className, onClick }) => {
	const { t } = useTranslation();
	const { state, onUpdate } = useContext(LiveChatContext);
	const {
		state: { onSendMessage },
	} = useContext(SessionContext);

	const [waitingMessage, setWaitingMessage] = useState<string>(t('liveChatWaitingInQueue', "You're in queue..."));
	const waitingMessageTimeout = useRef<NodeJS.Timeout>();
	const wm = useRef<string>('');

	wm.current =
		state.config?.templates.alternativeWaitingMessage ||
		t<string>('liveChatAlternativeWaitingMessage', 'Someone will join shortly...');

	const stillInQueueWaitingMessage = t('liveChatStillInQueueMessage', 'You’re still in queue...');

	const agentData = {
		name: state.agentData.displayName || state.config?.templates.agentNamePlaceholder || 'Operator',
		isConnected: state.agentData.isConnected,
		imageUrl: state.config?.agent?.avatarImageURLPlaceholder,
	};

	useEffect(() => {
		if (state.status === 'WAITING' && !waitingMessageTimeout.current) {
			waitingMessageTimeout.current = setInterval(() => {
				setWaitingMessage((waitingMessage) =>
					waitingMessage === wm.current ? stillInQueueWaitingMessage : wm.current,
				);
			}, WAITING_MESSAGE_CHANGE_INTERVAL);
		} else {
			waitingMessageTimeout.current && clearInterval(waitingMessageTimeout.current);
		}
		return () => {
			waitingMessageTimeout.current && clearInterval(waitingMessageTimeout.current);
		};
	}, [state.status]);

	const getActionTypeByCommand = (command: string): StatusControlMenuActionType => {
		switch (command) {
			case 'endLiveChat':
				return 'LEAVE_CHAT';
			case 'leaveQueue':
				return 'LEAVE_QUEUE';
			default:
				return 'LEAVE_CHAT';
		}
	};

	const findEndLiveChatCommand = (callToActions: LiveChatCallToActionConfig[]): Nullable<LiveChatAction> => {
		const endLiveChatAction = callToActions.find((action) => action.commandValue === END_LIVE_CHAT_COMMAND);

		return endLiveChatAction
			? {
					label: endLiveChatAction.renderedTitle,
					type: getActionTypeByCommand(endLiveChatAction.commandValue),
					onAction: () => {
						onUpdate({
							type: 'CONFIRMATION_MODAL',
							payload: {
								action: () =>
									onSendMessage(endLiveChatAction.commandValue, endLiveChatAction.commandValue, true),
							},
						});
					},
				}
			: null;
	};

	const getAction = (config: LiveChatConfigResponseModel, isWaiting: boolean): Nullable<LiveChatAction> =>
		isWaiting
			? {
					label: config.templates.leaveQueue || '',
					type: getActionTypeByCommand(config.leaveQueue.commandValue),
					onAction: () => {
						onUpdate({
							type: 'CONFIRMATION_MODAL',
							payload: {
								action: () =>
									onSendMessage(config.leaveQueue.commandValue, config.leaveQueue.commandValue, true),
							},
						});
					},
				}
			: findEndLiveChatCommand(config.callToActions);

	const isWaiting = isLiveChatWaiting(state.status);

	return (
		<LiveChatStatusControl
			status={state.status}
			agentData={agentData}
			action={state.config ? getAction(state.config, isWaiting) : null}
			onControlClick={onClick}
			hasNotifications={!!state.newMessagesIndex}
			className={className}
			waitingMessage={waitingMessage}
		/>
	);
};
