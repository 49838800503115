import { jssPreset } from '@material-ui/core/styles/';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StylesProvider } from '@material-ui/styles';
import classNames from 'classnames';
import { create } from 'jss';
import { FC, memo, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ConfigContext } from '../../context/config.context';
import { DocumentContext } from '../../context/document.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { FONTS_URL } from '../app/app.model';
import { useWidgetFrameStyles } from './widget-frame.styles';

interface WidgetFrameProps {
	isOpen: boolean;
}

export const WidgetFrame: FC<WidgetFrameProps> = memo(({ isOpen, children }) => {
	const {
		appOptions: {
			fullScreen,
			events: { onWidgetLoad },
		},
	} = useContext(ConfigContext);

	const classes = useWidgetFrameStyles({ isOpen });
	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);

	const [content, setContent] = useState<JSX.Element>();
	const [mountPoint, setMountPoint] = useState<Document>();

	const frameClassName = classNames(
		fullScreen || isMobile ? classes.fullScreen : classes.root,
		!isOpen && classes.closedWidget,
		!isOpen && isMobile && classes.mobileHidden,
	);

	const dataTestingLabel = `gyant-web-widget-iframe-${isOpen ? 'open' : 'closed'}`;

	const ref = useRef<HTMLIFrameElement>(null);

	useEffect(() => {
		if (mountPoint) {
			const styleElement = mountPoint.createElement('style');
			const metaElement = document.createElement('meta');
			metaElement.setAttribute('httpEquiv', 'Content-Security-Policy');
			metaElement.setAttribute('content', "script-src 'self' 'unsafe-inline';");

			styleElement.innerHTML = FONTS_URL;
			mountPoint.head.prepend(styleElement);
			mountPoint.head.prepend(metaElement);

			const jss = create({
				plugins: [...jssPreset().plugins],
				insertionPoint: mountPoint.head,
			});

			onWidgetLoad(mountPoint);
			const content = (
				<StylesProvider jss={jss} sheetsManager={new Map()}>
					<DocumentContext.Provider value={{ document: mountPoint }}>{children}</DocumentContext.Provider>
				</StylesProvider>
			);
			setContent(content);
		}
	}, [mountPoint, isOpen]);

	// The fix for Safari, that triggers the load event before the iframe is attached to the DOM
	// https://github.com/facebook/react/issues/6541#issuecomment-361349395
	// The src attrubute added later, so the load event will be triggered when iframe is in DOM
	useEffect(() => {
		if (ref?.current) {
			ref.current.src = 'about:blank';
		}
	}, []);

	return (
		<iframe
			allow={'otp-credentials'}
			onLoad={(e) => {
				// when iframe is loaded we set his document as a mount point where the content will be injected with the createPortal
				// we can't rely only on iframe ref, because in safary iframe content is reset after the load event in complete
				// https://github.com/facebook/react/issues/22847#issuecomment-991394558
				if (e.currentTarget.contentWindow?.document) {
					setMountPoint(e.currentTarget.contentWindow.document);
				}
			}}
			title={'gyant-web-widget'}
			ref={ref}
			className={frameClassName}
			id={'gyant-web-widget-iframe'}
			data-testing-label={dataTestingLabel}>
			{mountPoint && content && createPortal(content, mountPoint.body)}
		</iframe>
	);
});
