import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const VectorWhiteIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={'M18 13H6C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11H18C18.55 11 19 11.45 19 12C19 12.55 18.55 13 18 13Z'}
			fill={'white'}
		/>
	</SvgIcon>
);
