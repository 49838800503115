import { createContext, FC, useReducer } from 'react';

import { LiveChatStatus } from '../models/live-chat.model';
import { LiveChatDoctorInfo, Message } from '../models/message.model';
import { constVoid, Lazy } from '../utils/function.utils';
import { Nullable } from '../utils/types.utils';
import { LiveChatConfigResponseModel } from './api-service/api-service.model';

interface ConfirmationModalConfig {
	action: Lazy<void>;
}

interface LiveChatUnreadMessagesAction {
	type: 'UNREAD_MESSAGES';
	payload: boolean;
}
interface LiveChatVisibilityAction {
	type: 'ACTIVE_STATE';
	payload: boolean;
}

interface TypingIndicatorAction {
	type: 'TYPING_INDICATOR';
	payload: boolean;
}

interface UpdateMessagesAction {
	type: 'MESSAGES';
	payload: Message[];
}
interface UpdateAgentInfoAction {
	type: 'AGENT_INFO';
	payload: LiveChatAgentData;
}
interface UpdateWaitingStatusAction {
	type: 'STATUS';
	payload: LiveChatStatus;
}
interface UpdateLiveChatSessionStatusAction {
	type: 'SET_SESSION_OVER_STATUS';
	payload: boolean;
}
interface UpdateLiveChatActiveStatusAction {
	type: 'SET_ACTIVE_STATUS';
	payload: boolean;
}
interface UpdateLiveChatConnectivityStatusAction {
	type: 'SET_CONNECTIVITY_LOST';
	payload: boolean;
}
interface UpdateLiveChatNewMessageIndexAction {
	type: 'SET_NEW_MESSAGE_INDEX';
	payload: Nullable<number>;
}
interface UpdateConfigAction {
	type: 'UPDATE_CONFIG';
	payload: LiveChatConfigResponseModel;
}
interface UpdateConfirmationModalConfigAction {
	type: 'CONFIRMATION_MODAL';
	payload: Nullable<ConfirmationModalConfig>;
}
interface CustomStateUpdateAction {
	type: 'CUSTOM';
	payload: Partial<LiveChatContextValue>;
}
interface LeaveLiveChatAction {
	type: 'LEAVE';
}
interface LeavingStatusLiveChatAction {
	type: 'LEAVING';
}

export type LiveChatAction =
	| UpdateMessagesAction
	| TypingIndicatorAction
	| LiveChatVisibilityAction
	| UpdateAgentInfoAction
	| UpdateWaitingStatusAction
	| UpdateConfigAction
	| UpdateConfirmationModalConfigAction
	| LeaveLiveChatAction
	| LeavingStatusLiveChatAction
	| CustomStateUpdateAction
	| UpdateLiveChatSessionStatusAction
	| UpdateLiveChatActiveStatusAction
	| UpdateLiveChatConnectivityStatusAction
	| UpdateLiveChatNewMessageIndexAction
	| LiveChatUnreadMessagesAction;
export interface LiveChatContextType {
	state: LiveChatContextValue;
	onUpdate(action: LiveChatAction): void;
}
export interface LiveChatContextValue {
	isLiveChatSessionOver: boolean;
	isConnectivityLost: boolean;
	isLeavingLiveChat: boolean;
	status: LiveChatStatus;
	isLiveChatActive: boolean;
	newMessagesIndex: Nullable<number>;
	messages: Message[];
	isTyping: boolean;
	isLiveChatShown: boolean;
	hasUnreadMessages: boolean;
	agentData: LiveChatAgentData;
	config?: LiveChatConfigResponseModel;
	confirmationModal: Nullable<ConfirmationModalConfig>;
}
export interface LiveChatAgentData extends LiveChatDoctorInfo {
	doctorId: string;
	agentImage?: string;
	isConnected: boolean;
}
export const DEFAULT_LIVE_CHAT_CONTEXT_VALUE: LiveChatContextValue = {
	isLiveChatSessionOver: false,
	isConnectivityLost: false,
	isLeavingLiveChat: false,
	isLiveChatActive: true,
	newMessagesIndex: null,
	status: 'WAITING',
	messages: [],
	isTyping: false,
	isLiveChatShown: false,
	hasUnreadMessages: false,
	confirmationModal: null,
	agentData: {
		displayName: 'Agent',
		doctorId: 'INITIAL',
		isConnected: false,
	},
};

export const DEFAULT_LIVE_CHAT_CONTEXT: LiveChatContextType = {
	state: DEFAULT_LIVE_CHAT_CONTEXT_VALUE,
	onUpdate: constVoid,
};
export const LiveChatContext = createContext<LiveChatContextType>(DEFAULT_LIVE_CHAT_CONTEXT);

const liveChatReducer = (state: LiveChatContextValue, action: LiveChatAction) => {
	switch (action.type) {
		case 'MESSAGES':
			return { ...state, messages: [...state.messages, ...action.payload] };
		case 'TYPING_INDICATOR':
			return { ...state, isTyping: action.payload };
		case 'ACTIVE_STATE':
			return { ...state, isLiveChatShown: action.payload };
		case 'UNREAD_MESSAGES':
			return { ...state, hasUnreadMessages: action.payload };
		case 'AGENT_INFO':
			return { ...state, agentData: action.payload };
		case 'STATUS':
			return { ...state, status: action.payload };
		case 'UPDATE_CONFIG':
			return { ...state, config: action.payload };
		case 'LEAVING':
			return { ...state, isLeavingLiveChat: true };
		case 'CONFIRMATION_MODAL':
			return { ...state, confirmationModal: action.payload };
		case 'SET_SESSION_OVER_STATUS':
			return { ...state, isLiveChatSessionOver: action.payload };
		case 'SET_CONNECTIVITY_LOST':
			return { ...state, isConnectivityLost: action.payload, isLiveChatSessionOver: action.payload };
		case 'CUSTOM':
			return { ...state, ...action.payload };
		case 'LEAVE':
			return DEFAULT_LIVE_CHAT_CONTEXT_VALUE;
		case 'SET_ACTIVE_STATUS':
			return { ...state, isLiveChatActive: action.payload };
		case 'SET_NEW_MESSAGE_INDEX':
			return { ...state, newMessagesIndex: action.payload };
	}
};

export const LiveChatProvider: FC = ({ children }) => {
	const [state, onUpdate] = useReducer(liveChatReducer, DEFAULT_LIVE_CHAT_CONTEXT_VALUE);

	return <LiveChatContext.Provider value={{ state, onUpdate }}>{children}</LiveChatContext.Provider>;
};
