import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useImageStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			boxShadow,
			colorScheme: {
				colorVariables: { colorMediumShadow },
			},
		},
	}) => ({
		root: {
			maxWidth: 410,
		},
		image: {
			display: 'block',
			borderRadius: sizes.size_1,
			maxWidth: '100%',
			boxShadow: boxShadow.angle.xsmall,
			border: `1px solid ${colorMediumShadow}`,
		},
	}),
);
