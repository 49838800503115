import { createContext } from 'react';

import { WebTrackerService } from '../services/web-trackers-service/web-tracker-service';
import { WEB_TRACKER_SERVICE_DEFAULT } from '../services/web-trackers-service/web-tracker-service.model';

export interface ServicesContextType {
	webTrackerService: WebTrackerService;
}

export const ServicesContext = createContext<ServicesContextType>({
	webTrackerService: WEB_TRACKER_SERVICE_DEFAULT,
});
