import { SvgIcon } from '@material-ui/core';
import classNames from 'classnames';
import { ElementType, FC } from 'react';

import { ColorVariables } from '../../models/app.model';
import { useIconStyles } from './icon.styles';

type IconSize = 'small' | 'medium' | 'large' | 'xLarge';

export type IconType =
	| 'decorIcon'
	| 'inputIcon'
	| 'chipIcon'
	| 'buttonIcon'
	| 'systemIconDefault'
	| 'systemIconAlert'
	| 'primaryButton'
	| 'colorBorder'
	| 'systemIconSuccess'
	| 'systemTextDefault';

export interface IconProps {
	icon: ElementType;
	size?: IconSize;
	iconType?: IconType;
	alt?: string;
	className?: string;
	viewBox?: string;
	dataTestingLabel?: string;
}

const COLOR_TYPE: Record<IconType, keyof ColorVariables> = {
	decorIcon: 'colorBorder',
	inputIcon: 'colorNote',
	chipIcon: 'colorText',
	buttonIcon: 'colorPrimary',
	systemIconDefault: 'colorSysDefault',
	systemIconAlert: 'colorSysAlert',
	primaryButton: 'colorHeadOnDark',
	colorBorder: 'colorBorder',
	systemIconSuccess: 'colorSysSuccess',
	systemTextDefault: 'colorSysTextDefault',
};

const SIZE_SETTINGS: Record<IconSize, number> = {
	small: 20,
	medium: 24,
	large: 32,
	xLarge: 48,
};

export const Icon: FC<IconProps> = ({
	icon,
	size = 'medium',
	iconType = 'buttonIcon',
	alt,
	className,
	viewBox,
	dataTestingLabel,
}) => {
	const classes = useIconStyles({ size: SIZE_SETTINGS[size], color: COLOR_TYPE[iconType] });

	return (
		<SvgIcon
			data-testing-label={dataTestingLabel}
			viewBox={viewBox}
			className={classNames(classes.iconStyle, className)}
			component={icon}
			{...(iconType !== 'decorIcon' ? { alt } : {})}
		/>
	);
};
