import classNames from 'classnames';
import { FC, useContext } from 'react';

import { ConfigContext } from '../../../context/config.context';
import { DocumentContext } from '../../../context/document.context';
import { LinksServiceContext } from '../../../context/links-service/links-service.context';
import { ServicesContext } from '../../../context/services.context';
import {
	Card,
	CardResponse,
	CarouselMessage,
	isClinicCards,
	isDiagnosisCards,
	isLocationCards,
	isPlacesCards,
	isProviderCards,
	isSimpleCards,
} from '../../../models/message.model';
import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Carousel } from '../../../ui-kit/carousel/carousel.component';
import { getResponseContext } from '../../../ui-kit/carousel/carousel.model';
import { CareVenueCard } from './cards/care-venue-card/care-venue-card.component';
import { CausesCard } from './cards/causes-card/causes-card.component';
import { ClinicCard } from './cards/clinic-card/clinic-card.component';
import { LocationCardComponent } from './cards/location-card/location-card.component';
import { PlacesCard } from './cards/places-card/places-card.component';
import { ProviderCard } from './cards/provider-card/provider-card.component';
import { CarouselAction } from './carousel-action/carousel-action.component';
import { validateAndExtractCommonCardResponse } from './carousel-renderer.model';
import { useCarouselRendererStyles } from './carousel-renderer.styles';

interface QuickResponseRendererProps {
	message: CarouselMessage;
	onResponse(text: string, orininalText: string): void;
	additionalComponent?: JSX.Element;
}

export const CarouselRenderer: FC<QuickResponseRendererProps> = ({ message, onResponse, additionalComponent }) => {
	const {
		webTrackerService: { sendEvent },
	} = useContext(ServicesContext);

	const {
		appConfig: {
			visualConfig: { chipsType, commonCarouselResponseFlows },
		},
	} = useContext(ConfigContext);

	const { document } = useContext(DocumentContext);
	const linksService = useContext(LinksServiceContext);

	const classes = useCarouselRendererStyles();

	const handleResponse = (nodeId: string, flowStep?: string) => (response: CardResponse, e: EventType) => {
		const context = getResponseContext(response.responseContext, nodeId);
		const { content, type, link } = response;

		if (link && (flowStep || type === 'webUrlExternalWithResponse') && type !== 'webUrlExternal') {
			linksService.openExternalLink(link, e, flowStep);
		}

		if (type === 'webUrlExternal' && link) {
			return linksService.openExternalLink(link, e, flowStep);
		}

		sendEvent('onUserSelectCarousel', e);
		onResponse(context, content);
	};

	const generateCarousel = (renderedCards: JSX.Element[], nodeId: string, secondaryActionResponse?: CardResponse) => {
		const rootWrapper = classNames(classes.root, !secondaryActionResponse && classes.noSecondaryButton);
		return (
			<div className={rootWrapper}>
				<Carousel key={`${renderedCards.length}_${nodeId}`} cards={renderedCards} sourceDocument={document} />

				{additionalComponent}
				{secondaryActionResponse?.content && (
					<CarouselAction response={secondaryActionResponse} onAction={handleResponse(nodeId)} />
				)}
			</div>
		);
	};

	const renderCarousel = (cards: Card[], flowStep: string) => {
		if (isLocationCards(cards)) {
			return (
				<div className={classes.locationRoot}>
					{cards.map((card, index) => (
						<LocationCardComponent
							key={index}
							card={card}
							onResponse={handleResponse(card.nodeId)}
							buttonsClassName={classNames(chipsType === 'stacked' && classes.stackedButtonsContainer)}
						/>
					))}
				</div>
			);
		}

		if (isProviderCards(cards)) {
			const secondaryActionResponse =
				cards[0].responses.length > 1 ? cards[0].responses[cards[0].responses.length - 1] : undefined;
			const renderedCards = cards.map((card, index) => (
				<ProviderCard key={index} card={card} onResponse={handleResponse(card.nodeId)} />
			));

			return generateCarousel(renderedCards, cards[0].nodeId, secondaryActionResponse);
		}

		if (isClinicCards(cards)) {
			const secondaryActionResponse =
				cards[0].responses.length > 1 ? cards[0].responses[cards[0].responses.length - 1] : undefined;

			const renderedCards = cards.map((card, index) => (
				<ClinicCard key={index} card={card} onResponse={handleResponse(card.nodeId)} />
			));

			return generateCarousel(renderedCards, cards[0].nodeId, secondaryActionResponse);
		}

		if (isPlacesCards(cards)) {
			const renderedCards = cards.map((card, index) => (
				<PlacesCard key={index} card={card} onResponse={handleResponse(card.nodeId, flowStep)} />
			));

			return generateCarousel(renderedCards, cards[0].nodeId);
		}

		if (isSimpleCards(cards)) {
			const { cards: processedCards, secondaryActionResponse } = validateAndExtractCommonCardResponse(
				cards,
				flowStep,
				commonCarouselResponseFlows,
			);
			const renderedCards = processedCards.map((card, index) => (
				<CareVenueCard key={index} card={card} onResponse={handleResponse(card.nodeId, flowStep)} />
			));

			return generateCarousel(renderedCards, cards[0].nodeId, secondaryActionResponse);
		}

		if (isDiagnosisCards(cards)) {
			const { cards: processedCards, secondaryActionResponse } = validateAndExtractCommonCardResponse(
				cards,
				flowStep,
				commonCarouselResponseFlows,
			);
			const renderedCards = processedCards.map((card, index) => (
				<CausesCard key={index} card={card} onResponse={handleResponse(card.nodeId)} />
			));

			return generateCarousel(renderedCards, cards[0].nodeId, secondaryActionResponse);
		}
	};
	return <div className={classes.root}>{renderCarousel(message.cards, message.flowStep)}</div>;
};
