import classNames from 'classnames';
import { FC, Fragment, useContext } from 'react';

import { LinksServiceContext } from '../../../context/links-service/links-service.context';
import { SessionContext } from '../../../context/session.context';
import { Chip } from '../../../ui-kit/chip/chip.component';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { UndoIcon } from '../../../ui-kit/icons/undo.icon';
import { constVoid, Lazy } from '../../../utils/function.utils';
import { useTextRendererStyles } from './text-renderer.styles';

const SITE_SEARCH_FLOW_STEP_ENTRY = 'site_search';

export interface TextRendererProps {
	message: string | JSX.Element;
	flowStep?: string;
	isUserMessage: boolean;
	isUndoable: boolean;
	onUndo?: Lazy<void>;
	additionalComponents?: (JSX.Element | undefined)[];
}

export const TextRenderer: FC<TextRendererProps> = ({
	message,
	flowStep,
	isUserMessage,
	isUndoable,
	onUndo = constVoid,
	additionalComponents,
}) => {
	const linksService = useContext(LinksServiceContext);
	const {
		state: { isWidgetDisabled },
	} = useContext(SessionContext);
	const classes = useTextRendererStyles();
	const className = classNames(classes.root, isUserMessage ? classes.userMessage : classes.botMessages);
	const wrapperClassName = classNames(
		classes.wrapper,
		isUserMessage ? classes.userMessageWrapper : classes.botMessageWrapper,
	);
	const renderedMessage =
		typeof message === 'string' && !isUserMessage ? linksService.parseLinksInText({ text: message, flowStep }) : message;
	const ariaLabel = `${isUserMessage ? 'user' : 'bot'} message`;

	const handleUndo = () => {
		onUndo();
	};

	const UndoIconComponent = <Icon size={'medium'} iconType={'colorBorder'} icon={UndoIcon} />;
	const siteSearchDataTestingLabel = flowStep?.includes(SITE_SEARCH_FLOW_STEP_ENTRY)
		? { 'data-testing-label': 'site-search-results' }
		: {};
	return (
		<Fragment>
			<div className={wrapperClassName}>
				<div className={className} aria-label={ariaLabel}>
					{isUserMessage ? (
						<Chip
							icon={UndoIconComponent}
							label={renderedMessage}
							size={'small'}
							isGhost={isUndoable}
							isDisabled={!isUndoable || isWidgetDisabled}
							isSelected={!isUndoable}
							onClick={handleUndo}
							dataTestingLabel={`user-message${isUndoable ? '' : '-disabled'}`}
							className={classes.customUserStyles}
						/>
					) : (
						<p {...siteSearchDataTestingLabel}>{renderedMessage}</p>
					)}
				</div>
			</div>
			{additionalComponents}
		</Fragment>
	);
};
