import { makeStyles } from '@material-ui/core';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../models/dimensions.model';

export const useDoctorSearchStyles = makeStyles(
	({ palette: { sizes, colorScheme }, typography: { smallViewPort, largeViewPort } }) => ({
		container: {
			width: '100%',
			margin: '4px 0 0',
			padding: 0,
			listStyle: 'none',
		},
		appointmentGroup: {
			width: '100%',
		},
		slotsContainer: {
			display: 'flex',
			flexDirection: 'column',
			padding: `${sizes.size_2}px 0 ${sizes.size_3}px`,
			listStyle: 'none',
			'& > li:not(:first-child)': {
				marginTop: sizes.size_1,
			},
		},
		numberOfDoctors: {
			...smallViewPort.typeCaption1,
		},
		filtersIcon: {
			fontSize: sizes.size_2,
			fontWeight: 700,
			letterSpacing: 0.19,
			fontFamily: colorScheme.fontFamilyRegular,
			'& svg': {
				marginRight: sizes.sizeHalf,
			},
			padding: 0,
		},
		filledFiltersIcon: {
			width: 12,
			height: 12,
			marginLeft: sizes.sizeHalf,
		},
		doctorCard: {
			marginBottom: sizes.size_1,
			padding: `${sizes.size_2}px ${sizes.size_2}px ${sizes.size_3}px`,
		},
		listHeader: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: `0 ${sizes.size_1}px ${sizes.size_2}px`,
		},
		showMoreButtonContainer: {
			textAlign: 'center',
			position: 'relative',
		},
		loadingIndicator: {
			position: 'absolute',
			top: sizes.size_3,
			left: '50%',
			transform: 'translate(-50%)',
		},
		showMoreButton: {
			padding: `${sizes.size_3} 0`,
			background: 'unset',
			boxShadow: 'unset',
			margin: '0 auto',
			textDecoration: 'underline',
			...smallViewPort.typeLink2,
			'&:hover': {
				backgroundColor: 'transparent',
				boxShadow: 'unset',
				textDecoration: 'underline',
			},
		},
		loadingButton: {
			visibility: 'hidden',
			pointerEvents: 'none',
		},
		navigationContainer: {
			minHeight: 92,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			position: 'sticky',
			top: 0,
			background: 'white',
			zIndex: 1,
			'& > span': {
				display: 'block',
				textAlign: 'center',
				...smallViewPort.typeHeading2,
				[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
					...largeViewPort.typeHeading2,
				},
			},
		},
		backButton: {
			position: 'absolute',
			left: -11,
			background: 'none',
			border: 0,
			boxShadow: 'none',
			width: sizes.size_6,
			height: sizes.size_5,
		},
		backIcon: {
			transform: 'rotate(180deg)',
		},
	}),
);
