import { FC } from 'react';

import { CardResponse } from '../../../../models/message.model';
import { EventType } from '../../../../services/web-trackers-service/web-tracker-service';
import { Chip } from '../../../../ui-kit/chip/chip.component';
import { useCarouselActionStyles } from './carousel-action.styles';

interface CarouselActionProps {
	response: CardResponse;
	onAction: (response: CardResponse, e: EventType) => void;
}

export const CarouselAction: FC<CarouselActionProps> = ({ response, onAction }) => {
	const classes = useCarouselActionStyles();

	return (
		<div className={classes.secondaryActionRoot}>
			<Chip
				label={response.content}
				dataTestingLabel={'carousel-card-secondary-action'}
				key={response.responseContext}
				onClick={(e: EventType) => {
					onAction(response, e);
				}}
			/>
		</div>
	);
};
