import { pipe } from 'fp-ts/lib/function';
import { chain, fromNullable, none, some, toUndefined } from 'fp-ts/lib/Option';
import { FC, memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { ConfigContext } from '../../context/config.context';
import { DocumentContext } from '../../context/document.context';
import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { DEFAULT_LIVE_CHAT_CONTEXT_VALUE, LiveChatContext } from '../../context/live-chat-context';
import { NotificationContext } from '../../context/notification.context';
import { ServicesContext } from '../../context/services.context';
import { SessionContext } from '../../context/session.context';
import { TranslationsContext } from '../../context/translations.context';
import { CookiesSettings } from '../../models/app.model';
import { HistoryMessage } from '../../models/conversation.model';
import { setLanguageOnLocalStorage, toLocale } from '../../models/languages.model';
import {
	getInputHiddenValue,
	getInputHiddenValueFromHistory,
	isDeliveryStatusMessage,
	isNotDeliveredStatusMessage,
	isParsedDataWithData,
	isTextMessage,
	isUIMessage,
	Message,
	MessageFromSocket,
	ParsedData,
} from '../../models/message.model';
import {
	ClientMessageAttachment,
	ClientMessageType,
	DEFAULT_WEB_SOCKET_CLIENT,
	WebSocketClient,
} from '../../models/socket.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { lastIndexOf } from '../../utils/array.utils';
import { getClientCookieName, getCookie, removeCookie, setCookie } from '../../utils/cookies.utils';
import { getHostEnvironment } from '../../utils/embed.utils';
import { Effect } from '../../utils/function.utils';
import { getOrFalse } from '../../utils/option.utils';
import { isUserMessage } from '../../utils/renderer-utils/renderer.utils';
import { createWebSocket, getSocketHost } from '../../utils/socket';
import { decodeFromBase64, uuid } from '../../utils/string.utils';
import { Nullable } from '../../utils/types.utils';
import { CollapsedWidgetButtonConfig } from '../collapsed-widget/collapsed-widget.model';
import {
	clearLiveChatStorageValues,
	isFeedbackLiveChatSessionOn,
	isLeaveChatCommand,
	isLeaveQueueCommand,
	isLiveChatCommand,
	isLiveChatMessages,
	LIVE_CHAT_CONNECTIVITY_LOST_CHAT_ENDED_MESSAGE,
	LIVE_CHAT_CONNECTIVITY_LOST_MESSAGE,
	setFeedbackQuestionsStep,
} from '../live-chat/live-chat.model';
import { WidgetWrapper } from './widget-wrapper.component';
import {
	addHotKeyForInputVisibility,
	addPostMessageListener,
	addTimeStampToMessages,
	checkAndExecuteSpecialActions,
	checkMessagesForExpiredSession,
	checkMetaDataAndSendToParent,
	conversationHistoryToHistoryMessages,
	DelayedMessageToSocket,
	filterOutliveChatMessages,
	findLastAgentMessageIndex,
	findLastMessageSpecialActions,
	getAutocompleteUri,
	getInitialStartingFlow,
	getMessagesShownInWidget,
	getTimeRemaining,
	handleUnauthorizedSessionError,
	historyMessageToMesage,
	OnSenMessageFunc,
	processClientOnBotMessageCallback,
	sendPostMessageToParent,
	sendTrackerBotMessageEvent,
	SESSION_EXPIRED_COMMAND,
	setLastClientMessageUndoable,
	splitMessagesForLiveChatAndWidget,
	toCreateAccountPayload,
	toGetConversationPayload,
	toSocketClientMessage,
	toUpdateProfilePayload,
	toUserCommand,
	toUserMessage,
} from './widget-wrapper.model';

interface WidgetWrapperContainerProps {
	onWidgetClose: Effect<EventType>;
	isOpen: boolean;
	isWidgetDisabled: boolean;
	collapsedWidgetActionItemSelected?: CollapsedWidgetButtonConfig;
}

interface AdditionalMessageData {
	autocompleteUri?: string;
	timeRemaining?: number;
}

const SOCKET_MESSAGE_DELAY_TIME_LIMIT = 10000;
let isMessaging = false;
let longTimeNoMessageTimeout: NodeJS.Timeout;

export const WidgetWrapperContainer: FC<WidgetWrapperContainerProps> = memo(
	({ onWidgetClose, isOpen, isWidgetDisabled, collapsedWidgetActionItemSelected }) => {
		const { i18n, t } = useTranslation();
		const {
			appConfig: {
				settings: { cookies, preLoadFirstMessage, postMessageToParent, progressBar },
			},
			appOptions: {
				client,
				fullScreen,
				disableTextInput,
				gyStFl,
				enableUndo,
				events: { onBotMessage },
				gyTesting,
				gyDebugging,
				patientInfo,
				sessionToken: sessionTokenFromOptions,
				disableWhenSessionExpired,
			},
		} = useContext(ConfigContext);
		const { createAccount, getConversations, logEvent, updateProfile, sendMessageSync } =
			useContext(APIServiceContext);
		const {
			webTrackerService: { sendEvent: sendTrackerEvent },
		} = useContext(ServicesContext);
		const { sendEvent } = useContext(EventsServiceContext);
		const { onUpdate: onSessionContextUpdate } = useContext(SessionContext);
		const {
			onUpdate,
			state: { isLiveChatShown, isLeavingLiveChat, status, isLiveChatSessionOver, messages: liveChatMessages },
		} = useContext(LiveChatContext);
		const { updateTranslations } = useContext(TranslationsContext);
		const { onUpdate: onNotificationStateUpdate } = useContext(NotificationContext);
		const { document: frameDocument } = useContext(DocumentContext);

		const [conversationHistory, setConversationHistory] = useState<HistoryMessage[] | null>(null);
		const [additionalData, setAdditionalData] = useState<AdditionalMessageData>({});

		const [sessionToken, setSessionToken] = useState('');
		const [shouldCheckCookie, setCheckCookieState] = useState(true);
		const [wsClientReady, setWsClientReadyState] = useState(false);
		const [wsClient, setWsClient] = useState<WebSocketClient | null>(null);
		const [messages, setMessages] = useState<Message[]>([]);
		const [delayedMessageToSocket, setDelayedMessageToSocket] = useState<Nullable<DelayedMessageToSocket>>(null);
		const [isNextMesssageLoading, handleNextMesssageLoadingState] = useState(true);

		const [isSessionInitialized, setSessionInitialized] = useState(false);
		const [isSessionExpired, setSessionExpired] = useState(false);
		// These are needed because once onMessage created, all state variables are memoized in closure and not updated
		const isLiveChatShownRef = useRef<boolean>();
		const isWaitingRef = useRef<boolean>();
		const isLeavingLiveChatRef = useRef<boolean>();
		const isLiveChatSessionOverRef = useRef<boolean>();
		const liveChatMessagesRef = useRef<Message[]>();
		const wsClientRef = useRef<WebSocketClient | null>();
		const disableIfSessionExpired = disableWhenSessionExpired || sessionTokenFromOptions.length > 0;
		isLiveChatShownRef.current = isLiveChatShown;
		isWaitingRef.current = status === 'WAITING';
		isLeavingLiveChatRef.current = isLeavingLiveChat;
		isLiveChatSessionOverRef.current = isLiveChatSessionOver;
		liveChatMessagesRef.current = liveChatMessages;
		wsClientRef.current = wsClient;

		const [isUndo, setIsUndo] = useState(false);
		const environment = getHostEnvironment(window);
		const clientCookieName = getClientCookieName(client);

		const [isInputHidden, setIsInputHidden] = useState(false);

		const setLocale = (language: string) => {
			i18n.changeLanguage(language);
			updateTranslations(toLocale(language), client);
		};

		const updateProfileRemote = (token: string, onSuccess: Effect<string>, onError?: any) =>
			updateProfile(toUpdateProfilePayload(token))
				.then(() => onSuccess(token))
				.catch(onError);

		const clearSession = (cookieNames: string[]) => {
			setSessionToken('');
			wsClientRef.current?.disconnect();
			setWsClient(DEFAULT_WEB_SOCKET_CLIENT);
			setWsClientReadyState(false);
			cookieNames.forEach(removeCookie);

			if (!cookies.disableWidgetOnCookieExpire && !disableIfSessionExpired) {
				handleNextMesssageLoadingState(true);
				onNotificationStateUpdate({ isNotificationShown: false });
				if (isLiveChatShownRef.current) {
					onUpdate({
						type: 'CUSTOM',
						payload: { ...DEFAULT_LIVE_CHAT_CONTEXT_VALUE, isLiveChatActive: false },
					});
					clearLiveChatStorageValues(client);
				}
				setMessages([]);
				setSessionInitialized(false);
				setCheckCookieState(true);
			}
		};

		const sendMessage = (
			text: string,
			originalText?: string,
			shouldShowInWidget = true,
			isUndoAction = false,
			attachments?: ClientMessageAttachment[],
			type: ClientMessageType = 'message',
			payload?: string,
		) => {
			const messageId = isLiveChatShown ? uuid() : '';
			const messageToSocket = isLiveChatCommand(text)
				? toUserCommand(text)
				: toSocketClientMessage(text, originalText, type, payload, attachments, messageId);

			if (shouldShowInWidget) {
				const clientMessage = toUserMessage(originalText || text, messageId);
				isLiveChatShownRef.current
					? onUpdate({
							type: 'MESSAGES',
							payload: [clientMessage],
						})
					: setMessages((messages) => [...messages, clientMessage]);
			} else {
				!isUndoAction && setMessages([]);
			}

			// set loading only if it is command to leave live-chat or normal widget message
			(!isLiveChatShown || isLeaveChatCommand(text) || isLeaveQueueCommand(text) || isLeavingLiveChat) &&
				handleNextMesssageLoadingState(true);

			wsClient && wsClient.send(messageToSocket);

			if (messages[messages.length - 1] && isTextMessage(messages[messages.length - 1]))
				window.localStorage.setItem(
					`${sessionToken}_lastBotMessage`,
					isUndoAction ? '' : messages[messages.length - 1].text,
				);

			setIsUndo(false);
		};
		const handleSendMessage: OnSenMessageFunc = (
			text,
			originalText,
			shouldShowInWidget,
			isUndoAction,
			attachments,
			type,
			payload,
		) => {
			if (isLiveChatShownRef.current || !isMessaging) {
				sendMessage(text, originalText, shouldShowInWidget, isUndoAction, attachments, type, payload);
				if (!isLiveChatShownRef.current) {
					isMessaging = true;
				}
			}
		};

		const onUndoMessage = () => {
			setMessages((messages) => {
				const lastClientMessageIndex = lastIndexOf(messages, isUserMessage);
				const previousClientMessageIndex = lastIndexOf(messages, isUserMessage, lastClientMessageIndex - 1);
				return previousClientMessageIndex !== -1 ? messages.slice(0, previousClientMessageIndex + 1) : [];
			});
			sendMessage('undo', 'undo', false, true);
			setIsUndo(true);
		};

		const mapParsedMessagesToMessages = (messages: Message[]): Message[] =>
			messages.filter(isNotDeliveredStatusMessage);

		const clearSessionByExpireTimeout = () => {
			sendPostMessageToParent(postMessageToParent, `${SESSION_EXPIRED_COMMAND}:${sessionToken}`);

			if (cookies.disableWidgetOnCookieExpire || disableIfSessionExpired) {
				onNotificationStateUpdate({
					isNotificationShown: true,
					notificationMessage: t('sessionExpiredLabel', 'This session has expired'),
				});
				handleNextMesssageLoadingState(false);
			}
			setSessionExpired(true);
			clearSession([clientCookieName]);
		};

		const handleNewSessionSuccess = (sessionToken: string) => {
			setSessionToken(sessionToken);
			setSessionExpired(false);
		};

		const onSocketMessage = (receivedParsedMessages: ParsedData<MessageFromSocket>[]): void => {
			receivedParsedMessages.forEach((parsedMessage) => {
				if (parsedMessage.parsingInfo.status === 'failure') {
					logEvent({
						origin: 'PARSING_ERROR',
						error: parsedMessage.parsingInfo.error,
						payload: { sessionToken, originalMessage: parsedMessage.parsingInfo.originalMessage },
					});
				}
			});
			const parsedSuccessfullyMessages = receivedParsedMessages
				.filter(isParsedDataWithData)
				.map((parsedMessage) => parsedMessage.data);

			// Check for session expired message
			const isSessionEpiredFromMessage = checkMessagesForExpiredSession(
				parsedSuccessfullyMessages,
				clearSessionByExpireTimeout,
			);

			const messagesWithoutErrors = parsedSuccessfullyMessages.filter(isUIMessage);
			if (!isSessionEpiredFromMessage && messagesWithoutErrors.length) {
				const lastMessage = messagesWithoutErrors[messagesWithoutErrors.length - 1];
				// Here we need to map ParsedMessagesFromSocket to Message
				const clearedMessageFromDeliveredStatus = mapParsedMessagesToMessages(messagesWithoutErrors);

				sendTrackerBotMessageEvent(clearedMessageFromDeliveredStatus, sendTrackerEvent);
				processClientOnBotMessageCallback(clearedMessageFromDeliveredStatus, onBotMessage);
				const messagesWithTimeStamp = addTimeStampToMessages(clearedMessageFromDeliveredStatus);
				const isLiveChatSessionOn = isLiveChatShownRef.current;
				const liveChatMessages = liveChatMessagesRef.current;
				const isLiveChatFeedbackSessionOn = isFeedbackLiveChatSessionOn(client);
				// Live chat is ON
				if (
					!isLeavingLiveChatRef.current &&
					(isLiveChatMessages(messagesWithTimeStamp) || isLiveChatSessionOn)
				) {
					// If agent was disconnected by timeout: next message is without doctorInfo
					if (
						!isLiveChatMessages(messagesWithTimeStamp) &&
						!isLiveChatSessionOverRef.current &&
						!isDeliveryStatusMessage(lastMessage)
					) {
						messagesWithTimeStamp.unshift(
							LIVE_CHAT_CONNECTIVITY_LOST_MESSAGE,
							LIVE_CHAT_CONNECTIVITY_LOST_CHAT_ENDED_MESSAGE,
						);
						onUpdate({
							type: 'SET_CONNECTIVITY_LOST',
							payload: true,
						});
						setIsInputHidden(true);
						setFeedbackQuestionsStep('on', client);
					} else {
						setIsInputHidden(isLiveChatFeedbackSessionOn);
					}

					if (isDeliveryStatusMessage(lastMessage) && liveChatMessages) {
						const sentMessageIndex = lastIndexOf(
							liveChatMessages,
							(liveChatMessage) => liveChatMessage.messageId === lastMessage.messageStatus.messageId,
						);
						if (sentMessageIndex !== -1) {
							liveChatMessages[sentMessageIndex].deliveredStatus = lastMessage.messageStatus.status;
						}
					}

					handleNextMesssageLoadingState(false);
					onUpdate({
						type: 'MESSAGES',
						payload: messagesWithTimeStamp,
					});
					!isLiveChatShownRef.current &&
						onUpdate({
							type: 'ACTIVE_STATE',
							payload: true,
						});
					if (isMessaging) {
						isMessaging = false;
					}
				} else {
					// Widget mode
					// If live chat started, agend didn't joined and we're unable to proceed with live-chat
					if (isLiveChatShownRef.current && isWaitingRef.current) {
						onUpdate({
							type: 'LEAVE',
						});
					}
					if (isDeliveryStatusMessage(lastMessage)) {
						return setMessages((messagesInState) => {
							const sentMessageIndex = lastIndexOf(
								messagesInState,
								(message) => message.messageId === lastMessage.messageStatus.messageId,
							);
							if (sentMessageIndex !== -1) {
								messagesInState[sentMessageIndex].deliveredStatus = lastMessage.messageStatus.status;
							}
							return messagesInState;
						});
					}
					// Send postMessage to the parent window if any metadata is received
					checkMetaDataAndSendToParent(messagesWithTimeStamp, postMessageToParent);
					setMessages((messagesInState) => {
						const isUndoableBotMessage =
							enableUndo && pipe(lastMessage.isUndoable, fromNullable, getOrFalse);

						const updatedMessages = isUndoableBotMessage
							? setLastClientMessageUndoable(messagesInState)
							: setLastClientMessageUndoable(messagesInState, true);

						const autocompleteUri = getAutocompleteUri(messagesWithTimeStamp);
						const timeRemaining = getTimeRemaining(messagesWithTimeStamp);

						setAdditionalData({
							autocompleteUri,
							timeRemaining,
						});

						setIsInputHidden(getInputHiddenValue(messagesWithTimeStamp));
						checkAndExecuteSpecialActions(setLocale)(messagesWithTimeStamp);
						handleNextMesssageLoadingState(false);

						isMessaging = false;
						return [...updatedMessages, ...messagesWithTimeStamp];
					});
				}
			}
		};

		const createSocketConnection = () => {
			const ws = createWebSocket({
				host: getSocketHost(),
				onMessage: onSocketMessage,
				onError: () => {
					onNotificationStateUpdate({
						isNotificationShown: true,
						notificationType: 'Off',
						notificationMessage: t('noConnectionLabel', 'No connection. Reconnecting...'),
					});
					setWsClientReadyState(false);
				},

				onClose: () => setWsClientReadyState(false),
				onReconnect: () => {
					onNotificationStateUpdate({ isNotificationShown: false });
					setWsClientReadyState(true);
				},
				onOpen: () => setWsClientReadyState(true),
				sendMessageSync,
				token: sessionToken,
			});
			setWsClient(ws);
		};

		useEffect(() => {
			if (delayedMessageToSocket && !isNextMesssageLoading) {
				sendMessage(
					delayedMessageToSocket.text,
					delayedMessageToSocket.originalText,
					delayedMessageToSocket.shouldShowInWidget,
				);
				setDelayedMessageToSocket(null);
			}

			if (isNextMesssageLoading && conversationHistory !== null && !gyTesting && !isLiveChatShown) {
				longTimeNoMessageTimeout = setTimeout(() => {
					logEvent({
						origin: 'NO_MESSAGE',
						error: 'No message from socket in last 10 seconds',
						payload: { lastMessageSent: messages[messages.length - 1], sessionToken },
					});
					if (isMessaging) {
						isMessaging = false;
					}
				}, SOCKET_MESSAGE_DELAY_TIME_LIMIT);
			} else {
				clearTimeout(longTimeNoMessageTimeout);
			}
		}, [delayedMessageToSocket, isNextMesssageLoading]);

		useEffect(() => {
			if (sessionToken) {
				(!wsClient || wsClient === DEFAULT_WEB_SOCKET_CLIENT) && createSocketConnection();
				if (!collapsedWidgetActionItemSelected && wsClientReady) {
					const getConversationPayload = toGetConversationPayload();
					getConversations(getConversationPayload)
						.then((parsedHistory) => {
							if (parsedHistory && parsedHistory.data) {
								const messages = conversationHistoryToHistoryMessages(parsedHistory.data);
								const messagesUI = historyMessageToMesage(messages);

								const isUndoableBotMessage = enableUndo && parsedHistory.data.context.isUndoable;
								const timeRemaining = getTimeRemaining(messages);

								const lastAgentMessage = messagesUI[findLastAgentMessageIndex(messagesUI)];
								const isLastAgentMessageLiveChatFeedback =
									lastAgentMessage?.metadata?.status === 'lastLiveChatFeedbackMessage';
								const isLiveChatSessionOn = parsedHistory.data.context.isLiveChatActive;
								const isLiveChatFeedbackSessionOn = isFeedbackLiveChatSessionOn(client);

								// If the gyStFL parameter is passed and the live chat is on, fetch the conversation
								if (gyStFl && !isLiveChatSessionOn) {
									// Remove the feedback questions if they are active to perform the flow jump correctly
									isLiveChatFeedbackSessionOn && setFeedbackQuestionsStep('off', client);
								} else {
									setAdditionalData({
										timeRemaining,
										autocompleteUri: parsedHistory.data.context.autocompleteUri,
									});

									if (
										isLiveChatShownRef.current ||
										isLiveChatFeedbackSessionOn ||
										isLiveChatSessionOn ||
										// edge case when user do a refresh while disconnected and the agent has ended the live chat
										isLastAgentMessageLiveChatFeedback
									) {
										const isLiveChatHasFinishedWhileDisconnected =
											(isLiveChatShownRef.current && !isLiveChatSessionOn) ||
											isLastAgentMessageLiveChatFeedback;

										if (isLiveChatHasFinishedWhileDisconnected) {
											setIsInputHidden(true);
											setFeedbackQuestionsStep('on', client);
										}
										const { liveChatMessages, widgetMessages } =
											splitMessagesForLiveChatAndWidget(messagesUI);

										onUpdate({
											type: 'CUSTOM',
											payload: {
												isLiveChatShown: true,
												messages: liveChatMessages,
												isLiveChatSessionOver: isLiveChatHasFinishedWhileDisconnected,
											},
										});
										setMessages(widgetMessages);
										isLiveChatFeedbackSessionOn && setIsInputHidden(true);
									} else {
										const updatedMessages = isUndoableBotMessage
											? setLastClientMessageUndoable(messagesUI)
											: messagesUI;
										const messagesWithoutLiveChat = filterOutliveChatMessages(updatedMessages);

										setIsInputHidden(getInputHiddenValueFromHistory(updatedMessages));
										setMessages(messagesWithoutLiveChat);
									}

									setConversationHistory(parsedHistory.data.utterances);
									findLastMessageSpecialActions(setLocale)(messagesUI);
									handleNextMesssageLoadingState(false);
								}
							} else {
								setConversationHistory([]);
							}
						})
						.catch(handleUnauthorizedSessionError(clearSessionByExpireTimeout));
				}
			}
		}, [sessionToken, wsClientReady]);

		useEffect(() => {
			addHotKeyForInputVisibility(() => setIsInputHidden((prev) => !prev), frameDocument);
			addPostMessageListener(onSocketMessage);
		}, []);

		useEffect(() => {
			if (collapsedWidgetActionItemSelected) {
				setDelayedMessageToSocket({
					text: collapsedWidgetActionItemSelected.payload,
					originalText: collapsedWidgetActionItemSelected.renderedTitle,
					shouldShowInWidget: true,
				});
			}
		}, [collapsedWidgetActionItemSelected?.startingFlow]);

		useEffect(() => {
			if (
				wsClientReady &&
				wsClient &&
				!isSessionInitialized &&
				(conversationHistory?.length === 0 ||
					(!conversationHistory && (collapsedWidgetActionItemSelected || gyStFl)))
			) {
				const customStartFlow = collapsedWidgetActionItemSelected?.startingFlow
					? `gyant start flow ${collapsedWidgetActionItemSelected?.startingFlow}`
					: collapsedWidgetActionItemSelected?.payload;

				const decodedFlowStep = decodeFromBase64(gyStFl, 'gyStFl parameter is not coded correctly in base64');
				const initialFlowStep = getInitialStartingFlow(decodedFlowStep, customStartFlow);

				if (collapsedWidgetActionItemSelected) {
					collapsedWidgetActionItemSelected.startingFlow
						? sendMessage(initialFlowStep, '👋', false)
						: sendMessage(initialFlowStep, collapsedWidgetActionItemSelected?.renderedTitle, true);
				} else if (!isLiveChatShown) {
					sendMessage(initialFlowStep, '👋', false);
				}

				if (collapsedWidgetActionItemSelected?.eventCallbackData) {
					sendEvent(collapsedWidgetActionItemSelected.eventCallbackData);
				}
				setSessionInitialized(true);
			}
		}, [conversationHistory?.length, wsClientReady, collapsedWidgetActionItemSelected]);

		const checkSessionCookie = (cookies: CookiesSettings) => {
			const { expireTimeout } = cookies;

			const clientCookie = getCookie(clientCookieName);
			const token = sessionTokenFromOptions || clientCookie || patientInfo?.sessionToken || sessionToken;

			if (token && !isSessionExpired) {
				updateProfileRemote(
					token,
					handleNewSessionSuccess,
					handleUnauthorizedSessionError(clearSessionByExpireTimeout),
				);
				cookies.enabled && setCookie(clientCookieName, token, expireTimeout);
			} else {
				const requestPayload = toCreateAccountPayload(
					client,
					i18n.language,
					gyTesting,
					gyDebugging,
					patientInfo,
				);
				createAccount(requestPayload)
					.then(({ sessionToken }) => {
						cookies.enabled && setCookie(clientCookieName, sessionToken, expireTimeout);
						updateProfileRemote(
							sessionToken,
							handleNewSessionSuccess,
							handleUnauthorizedSessionError(clearSessionByExpireTimeout),
						);
						setLanguageOnLocalStorage(i18n.language, sessionToken);
					})
					.catch((e) => {
						console.error('Error creating account', e);
						setTimeout(() => {
							clearSession([]);
						}, 1500);
					});
			}
		};

		useEffect(() => {
			if ((isOpen || preLoadFirstMessage) && shouldCheckCookie) {
				setSessionToken('');
				checkSessionCookie(cookies);
				setCheckCookieState(false);
			} else if (!isOpen && cookies.removeCookiesOnCollapse) {
				clearSession([clientCookieName]);
			}
		}, [isOpen, shouldCheckCookie, preLoadFirstMessage]);

		const shownMessages = useMemo(() => getMessagesShownInWidget(messages, sessionToken), [messages.length]);
		const shouldNotShowWidgetWrapper = !isOpen && cookies.removeCookiesOnCollapse;

		const progressIndicatorCounter = pipe(
			additionalData.timeRemaining,
			fromNullable,
			chain((timeRemaining) => (progressBar ? some(timeRemaining) : none)),
			toUndefined,
		);
		const isWidgetInactive = isWidgetDisabled || isSessionExpired || !wsClientReady;

		useEffect(() => {
			onSessionContextUpdate({
				isWidgetDisabled: isWidgetInactive,
				sessionToken,
				autocompleteUri: additionalData.autocompleteUri,
				isLoading: isNextMesssageLoading,
				environment,
				shownMessages,
				onSendMessage: handleSendMessage,
				handleUnauthorizedSessionError: handleUnauthorizedSessionError(clearSessionByExpireTimeout),
			});
		}, [
			isWidgetInactive,
			sessionToken,
			additionalData.autocompleteUri,
			isNextMesssageLoading,
			environment,
			shownMessages,
		]);

		return !shouldNotShowWidgetWrapper ? (
			<WidgetWrapper
				onWidgetClose={onWidgetClose}
				onSendMessage={handleSendMessage}
				onUndoMessage={onUndoMessage}
				messages={shownMessages}
				isFullScreen={fullScreen}
				isWidgetDisabled={isWidgetDisabled}
				isTextInputDisabled={disableTextInput || isWidgetInactive}
				isLoading={isNextMesssageLoading && !isLiveChatShown}
				isOpen={isOpen}
				isUndo={isUndo}
				timeRemaining={progressIndicatorCounter}
				isInputHidden={isInputHidden}
				isLiveChatShown={isLiveChatShown}
				isLeavingLiveChat={isLeavingLiveChat}
			/>
		) : null;
	},
);
