import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { Icon } from '../../../../ui-kit/icon/icon.component';
import { PhoneFilledIcon } from '../../../../ui-kit/icons/phone-filled.icon';
import { Link } from '../../../../ui-kit/link/link.component';
import { Effect } from '../../../../utils/function.utils';
import { AppointmentSumaryTrackerElementName } from '../../appointment-scheduling.model';
import { useAppointmentSummaryStyles } from '../appointment-summary.styles';

interface AppointmentSummaryContactProps {
	contact: string;
	onClickLink(link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
	onTrackevent?: Effect<AppointmentSumaryTrackerElementName>;
}

export const AppointmentSummaryContact: FC<AppointmentSummaryContactProps> = memo(
	({ contact, onTrackevent, onClickLink }) => {
		const classes = useAppointmentSummaryStyles();

		const contentWrapper = classNames(classes.section, classes.centerElements);
		const iconWrapper = classNames(classes.subIcon, classes.flexIcon);

		const contactLink = `tel:${contact}`;

		const handleClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			onTrackevent && onTrackevent('CONTACT');
			onClickLink(contactLink, e);
		};

		return (
			<div className={contentWrapper}>
				<Icon className={iconWrapper} icon={PhoneFilledIcon} size={'small'} iconType={'inputIcon'} />
				<Link
					linkType={'typeLink2'}
					href={contactLink}
					target={'_parent'}
					onClick={handleClickLink}
					data-testing-label={'appointment-summary-contact-widget-button'}
				>
					{contact}
				</Link>
			</div>
		);
	},
);
