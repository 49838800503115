import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { LARGE_VIEW_PORT_VALUE_WIDTH } from '../../../models/dimensions.model';

export const useAppointmentSummaryStyles = makeStyles<Theme>(
	({ palette: { colorScheme, borderRadius, sizes, boxShadow }, typography: { smallViewPort, largeViewPort } }) => ({
		root: {
			margin: `${sizes.size_2}px ${sizes.size_1}%`,
			backgroundColor: colorScheme.colorVariables.colorBgBody,
			color: colorScheme.colorVariables.colorText,
			borderRadius: borderRadius.card,
			boxShadow: boxShadow.angle.xsmall,
			border: `1px solid ${colorScheme.colorVariables.colorDarkShadow}`,
			position: 'relative',
			padding: `${sizes.size_2}px ${sizes.size_2}px ${sizes.size_3}px`,
			display: 'grid',
		},
		ableToCopy: {
			'&:hover': {
				cursor: 'copy',
			},
		},
		section: {
			margin: `0 ${sizes.size_1}px`,
			padding: `${sizes.size_2}px 0`,
			borderTop: `1px solid ${colorScheme.colorVariables.colorInactive}`,
			display: 'flex',
		},
		info: {
			width: '100%',
			...smallViewPort.typeCaption1,
			[`@media (min-width:${LARGE_VIEW_PORT_VALUE_WIDTH}px)`]: {
				...largeViewPort.typeCaption1,
			},
			'&:focus-visible': {
				outline: `2px solid ${colorScheme.colorVariables.colorPrimary}`,
				outlineOffset: 0,
				transition: 'outline 0s',
			},
		},
		subIcon: {
			marginRight: sizes.size_2,
		},
		centerElements: {
			alignItems: 'center',
		},
		flexIcon: {
			display: 'flex',
		},
		options: {
			display: 'flex',
			justifyContent: 'space-between',
		},
		mainAction: {
			width: '100%',
			marginRight: sizes.size_1,
		},
		textClosed: {
			overflow: 'hidden',
			lineClamp: 2,
		},
		infoWrapper: {
			width: '100%',
		},
		infoTruncated: {
			height: '45px',
			display: '-webkit-box',
			'-webkit-box-orient': 'vertical',
		},
		textOpened: {
			height: 'auto',
		},
		linkButton: {
			...smallViewPort.typeLink2,
			padding: 0,
			margin: 0,
			height: '20px',
			textDecoration: 'underline',
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
				textDecorationColor: colorScheme.colorVariables.colorPrimary,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
		},
	}),
);
