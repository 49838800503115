import makeStyles from '@material-ui/styles/makeStyles';

export const useEpicSchedulingComponentStyles = makeStyles({
	iframeContainer: {
		position: 'absolute',
		top: 50,
		right: 0,
		left: 0,
		bottom: 0,
	},
	iframe: {
		width: '100%',
		height: '100%',
	},
});
