import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { DoctorSearchOption, ProviderLocation } from '../../../models/message.model';
import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Icon } from '../../../ui-kit/icon/icon.component';
import { AssignmentIcon } from '../../../ui-kit/icons/assignment.icon';
import { DirectionsIcon } from '../../../ui-kit/icons/directions.icon';
import { GlobeIcon } from '../../../ui-kit/icons/globe.icon';
import { LocationFilledIcon } from '../../../ui-kit/icons/location-filled.icon';
import { PhoneFilledIcon } from '../../../ui-kit/icons/phone-filled.icon';
import { Link } from '../../../ui-kit/link/link.component';
import { createDistance } from '../../../utils/distance.utils';
import { Nullable } from '../../../utils/types.utils';
import { DoctorSearchCard } from '../doctor-search-card/doctor-search-card.component';
import { ProviderInfo, ProviderResponse } from '../doctor-search.model';
import { useDoctorSearchCardStyles, useDoctorSearchDetailsStyles } from './doctor-search-details.styles';

interface DoctorSearchDetailsProps {
	doctorInfo: ProviderInfo;
	options?: Nullable<Partial<DoctorSearchOption>>;
	onResponse: (response: ProviderResponse, e: EventType) => void;
}

export const DoctorSearchDetails: FC<DoctorSearchDetailsProps> = memo(({ doctorInfo, options, onResponse }) => {
	const { t } = useTranslation();
	const {
		data: { locations, phone, specialties, languages },
	} = doctorInfo;
	const doctorSearchCardClasses = useDoctorSearchCardStyles();
	const classes = useDoctorSearchDetailsStyles();

	const renderLocations = (locations: ProviderLocation[]): JSX.Element[] =>
		locations.map((location, index) => {
			const distanceLabel = location.distance
				? `${createDistance(location.distance, t)} ${t('doctorSearchDistance')}`
				: '';
			const locationHref = `https://www.google.com/maps/place/${location.latitude},${location.longitude}`;

			return (
				<li
					className={classes.locationItemRoot}
					key={location.address}
					data-testing-label={`doctor-search-location-item-row-${index}`}>
					<Icon icon={LocationFilledIcon} size={'small'} iconType={'inputIcon'} />
					<div className={classes.listItem}>
						<span
							className={classes.locationItemDistance}
							data-testing-label={`doctor-search-location-item-distance-${location.distance}`}>
							{distanceLabel}
						</span>
						<p data-testing-label={'doctor-search-location-item-address'}>{location.address}</p>
					</div>
					<Link
						linkType={'typeLink2'}
						href={locationHref}
						target={'_blank'}
						rel={'noreferrer'}
						data-testing-label={`doctor-search-details-location-link-${index}`}>
						<Icon icon={DirectionsIcon} size={'small'} iconType={'buttonIcon'} />
					</Link>
				</li>
			);
		});

	const renderPhone = (phone: string): JSX.Element => (
		<li className={classes.locationItemRoot} data-testing-label={'doctor-search-details-phone'}>
			<Icon icon={PhoneFilledIcon} size={'small'} iconType={'inputIcon'} alt={'call doctor'} />
			<Link
				linkType={'typeLink2'}
				href={`tel:${phone}`}
				target={'_parent'}
				className={classes.listItem}
				data-testing-label={'doctor-search-details-phone-link'}>
				{phone}
			</Link>
		</li>
	);

	const renderSpecialities = (specialties: string[]): JSX.Element => (
		<li className={classes.locationItemRoot} data-testing-label={'doctor-search-details-specialities'}>
			<Icon icon={AssignmentIcon} size={'small'} iconType={'inputIcon'} alt={'doctor specialities'} />
			<span className={classes.listItem}>{specialties.join(', ')}</span>
		</li>
	);

	const renderLanguages = (languages: string[]): JSX.Element => (
		<li className={classes.locationItemRoot} data-testing-label={'doctor-search-details-languages'}>
			<Icon icon={GlobeIcon} size={'small'} iconType={'inputIcon'} alt={'languages'} viewBox={'0 96 960 960'} />
			<span className={classes.listItem}>{languages.join(', ')}</span>
		</li>
	);

	return (
		<div className={classes.root}>
			<DoctorSearchCard
				card={doctorInfo}
				classes={doctorSearchCardClasses}
				onResponse={onResponse}
				withDistance={false}
				isDetailedView
				shouldHideAcceptingNewPatients={options?.hideAcceptingNewPatients}
				shouldHideAvailability={options?.hideAvailability}
			/>
			<ul className={classes.additionalInfoList} data-testing-label={'doctor-search-details'}>
				{locations.length && renderLocations(locations)}
				{phone && renderPhone(phone)}
				{specialties.length && renderSpecialities(specialties)}
				{languages.length && renderLanguages(languages)}
			</ul>
		</div>
	);
});
