import { format, getDaysInMonth, isValid, parse } from 'date-fns';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import i18n from '../../utils/i18.utils';
import { getTimeLocale } from '../../utils/time.utils';
import { Nullable } from '../../utils/types.utils';
import { Info } from '../info/info.component';
import { Input } from '../input/input.component';
import { ResponseWrapper } from '../response-wrapper/response-wrapper.component';
import { useDateInputStyles } from './date-input.styles';

interface DateInfo {
	day: string;
	month: string;
	year: string;
}

interface DateFormItemDetails {
	value: string;
	isValid: boolean;
	isDirty: boolean;
}
interface DateFormErrorDetails {
	label: string;
	min: number;
	max: number;
}

type DateInputFields = keyof DateInfo;
type DateFormInfo = Record<DateInputFields, DateFormItemDetails>;
type DateFormErrorsInfo = Record<DateInputFields, DateFormErrorDetails>;

const getInitialState = (): DateFormInfo => ({
	day: { value: '', isValid: true, isDirty: false },
	month: { value: '', isValid: true, isDirty: false },
	year: { value: '', isValid: true, isDirty: false },
});

const SHORT_VALUES = ['day', 'month'];
const FEBRUARY_MONTH_NUMBERS_STRINGS = ['2', '02'];

const validateDate = (stringDate: string, t: TFunction<'translation'>): string => {
	const [month, , year] = stringDate.split('-');
	const result = parse(stringDate, 'MM-dd-yyyy', new Date());
	const isValidDate = isValid(result);

	if (!isValidDate) {
		const date = new Date('1970-1-1');
		date.setMonth(+month - 1);
		date.setFullYear(+year);
		const errorLabelTranslation = FEBRUARY_MONTH_NUMBERS_STRINGS.includes(month)
			? 'dateInputFullDateFebruraryError'
			: 'dateInputFullDateError';
		const monthName = format(date, 'LLLL', { locale: getTimeLocale(i18n.language) });

		const daysInMonth = getDaysInMonth(date);
		return t(errorLabelTranslation, {
			month: monthName,
			days: daysInMonth,
			year,
		});
	}
	return '';
};

interface DateInputProps {
	shouldAutoFocus?: boolean;
	className?: string;
	onSubmit: Effect<string>;
}

export const DateInput: FC<DateInputProps> = ({ className, shouldAutoFocus, onSubmit }) => {
	const classes = useDateInputStyles();
	const [state, setState] = useState<DateFormInfo>(getInitialState);
	const [dateError, setDateError] = useState('');
	const {
		t,
		i18n: { language },
	} = useTranslation();

	const inputRef = useRef<Nullable<HTMLInputElement>>(null);

	const ERROR_DATA: DateFormErrorsInfo = useMemo(
		() => ({
			day: {
				label: t('day', 'Day'),
				min: 1,
				max: 31,
			},
			month: {
				label: t('month', 'Month'),
				min: 1,
				max: 12,
			},
			year: {
				label: t('year', 'Year'),
				min: 1900,
				max: 2100,
			},
		}),
		[language],
	);

	const submitDate = () => {
		const { day, month, year } = state;
		const dateString = `${month.value}-${day.value}-${year.value}`;
		const dateStringToSend = language === 'en' ? dateString : `${day.value}-${month.value}-${year.value}`;
		const validationErrorLabel = validateDate(dateString, t);

		if (validationErrorLabel) {
			setDateError(validationErrorLabel);
		} else {
			onSubmit(dateStringToSend);
		}
	};

	const validateEntry = (value: string, field: DateInputFields): boolean => {
		const errorInfo = ERROR_DATA[field];
		return +value >= errorInfo.min && +value <= errorInfo.max;
	};

	const onValueChange = (field: DateInputFields) => (e: ChangeEvent<HTMLInputElement>) => {
		if (dateError) {
			setDateError('');
		}
		const updateState = { ...state };
		updateState[field].value = e.currentTarget.value;
		if (updateState[field].isDirty) {
			updateState[field].isValid = validateEntry(e.currentTarget.value, field);
		}

		setState(updateState);
	};

	const createErrorMessage = (field: DateInputFields) => {
		const errorData = ERROR_DATA[field];
		return t('dateInputError', 'Please enter a valid {{label}} value between {{min}} and {{max}}.', errorData);
	};

	const checkForError = (state: DateFormInfo, dateError: string) => {
		let errorMessage = '';
		Object.entries(state).some(([field, details]) => {
			if (!details.isValid) {
				errorMessage = createErrorMessage(field as DateInputFields);
				return true;
			}
		});
		const finalErrorMessage = errorMessage || dateError;
		return (
			finalErrorMessage && (
				<Info
					status={'Alert'}
					label={finalErrorMessage}
					isSimple
					className={classes.errorMessage}
					dataTestingLabel={'date-input-error'}
				/>
			)
		);
	};

	const isSubmitButtinDisabled = Object.values(state).some(
		(entry, index) => (index === 2 ? entry.value.length < 4 : entry.value === '') || !entry.isValid,
	);

	const handleBlur = (field: DateInputFields) => () => {
		const updateState = { ...state };
		const value = updateState[field].value;

		if (!updateState[field].isDirty && value) {
			updateState[field].isDirty = true;
		}

		if (SHORT_VALUES.includes(field) && value.length === 1) {
			updateState[field].value = `0${value}`;
		}

		// validate value
		if (value) {
			updateState[field].isValid = validateEntry(value, field);
		}
		setState(updateState);
	};

	useEffect(() => {
		if (shouldAutoFocus) {
			inputRef.current?.focus();
		}
	}, [shouldAutoFocus]);

	return (
		<ResponseWrapper
			isDisabled={isSubmitButtinDisabled}
			buttonLabel={t('continue', 'Continue')}
			onSubmit={submitDate}
			className={className}
			dataTestingLabel={'date-input-root'}
		>
			<div className={classes.wrapper}>
				<Input
					inputRef={inputRef}
					onBlur={handleBlur('month')}
					value={state.month.value}
					error={!state.month.isValid}
					onChange={onValueChange('month')}
					type={'tel'}
					label={t('month', 'Month')}
					placeholder={'MM'}
					textLength={2}
					noFloatingNumbers
					className={classes.input}
					inputProps={{
						'data-testing-label': 'date-input-month',
					}}
				/>
				<Input
					onBlur={handleBlur('day')}
					value={state.day.value}
					error={!state.day.isValid}
					onChange={onValueChange('day')}
					type={'tel'}
					label={t('day', 'Day')}
					placeholder={'DD'}
					textLength={2}
					noFloatingNumbers
					className={classes.input}
					inputProps={{
						'data-testing-label': 'date-input-day',
					}}
				/>
				<Input
					onBlur={handleBlur('year')}
					value={state.year.value}
					error={!state.year.isValid}
					onChange={onValueChange('year')}
					type={'tel'}
					label={t('year', 'Year')}
					placeholder={'YYYY'}
					textLength={4}
					noFloatingNumbers
					className={classes.yearInput}
					inputProps={{
						'data-testing-label': 'date-input-year',
					}}
				/>
			</div>
			{checkForError(state, dateError)}
		</ResponseWrapper>
	);
};
