import { FC, useEffect, useRef } from 'react';
import { TFunction } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import { MAX_INPUT_MESSAGE_LENGTH } from '../../utils/string.utils';
import { Nullable } from '../../utils/types.utils';
import { formatPhoneNumber } from '../../utils/validations.utils';
import { InputValidationResult, InputWithValidation } from '../input-with-validation/input-with-validation.component';

const CORRECT_DOMAIN_REGEXP = new RegExp(/\.[a-zA-Z]{2,4}$/);
const EMAIL_VALIDATION_REGEXP = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

const runValidation = (value: string, t: TFunction<'translation'>): InputValidationResult => {
	if (!value.includes('@')) {
		return {
			isValid: false,
			displayWhileTyping: true,
			error: t('emailValidationAddSymbolMissing', "Please include the '@' symbol in the email address."),
		};
	}
	if (!CORRECT_DOMAIN_REGEXP.test(value)) {
		return {
			isValid: false,
			displayWhileTyping: true,
			error: t(
				'emailValidationIncorrectDomain',
				"Please enter a valid domain name in the email address, such as 'example.com'.",
			),
		};
	}
	if (!EMAIL_VALIDATION_REGEXP.test(value)) {
		return {
			isValid: false,
			displayWhileTyping: true,
			error: t(
				'emailValidationGenericError',
				"Please enter a valid email address. The format should be 'user@example.com'.",
			),
		};
	}
	return {
		isValid: true,
	};
};

const testingLabels = {
	root: 'email-input-component-root',
	input: 'email-input-component-input',
	error: 'email-input-component-error',
};

interface EmailInputProps {
	shouldAutoFocus?: boolean;
	className?: string;
	onSubmit: Effect<string>;
}

export const EmailInput: FC<EmailInputProps> = ({ className, shouldAutoFocus, onSubmit }) => {
	const inputRef = useRef<Nullable<HTMLInputElement>>(null);
	useEffect(() => {
		if (shouldAutoFocus) {
			inputRef.current?.focus();
		}
	}, [shouldAutoFocus]);
	return (
		<InputWithValidation
			inputRef={inputRef}
			shouldUseDirty
			type={'text'}
			label={'Email'}
			className={className}
			testingLabels={testingLabels}
			placeholder={'user@example.com'}
			onSubmit={onSubmit}
			validation={runValidation}
			onValidationSuccess={formatPhoneNumber}
			textLength={MAX_INPUT_MESSAGE_LENGTH}
		/>
	);
};
