import React, { FC } from 'react';

import { useSchedulingWrapperStyles } from './scheduling-wrapper.styles';

interface SchedulingWrapperProps {
	clientLogoURL: string;
}

export const SchedulingWrapper: FC<SchedulingWrapperProps> = ({ clientLogoURL, children }) => {
	const classes = useSchedulingWrapperStyles({ clientLogoURL });

	return (
		<div className={classes.root}>
			<header className={classes.header}>
				<div className={classes.logo} />
			</header>
			{children}
		</div>
	);
};
