import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import { ColorVariables } from '../../models/app.model';

interface IconStyleConfig {
	size: number;
	color: keyof ColorVariables;
}
export const useIconStyles = makeStyles<Theme, IconStyleConfig>(({ palette: { colorScheme } }) => ({
	iconStyle: {
		width: (props) => props.size,
		height: (props) => props.size,
		color: (props) => colorScheme.colorVariables[props.color],
		fill: (props) => colorScheme.colorVariables[props.color],
	},
}));
