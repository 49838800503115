import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useLocationCardStyles = makeStyles<Theme>(
	({
		typography: {
			smallViewPort: { typeHeading2 },
		},
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
	}) => ({
		carouselCardRoot: {
			minHeight: 'auto',
		},
		root: {
			padding: `0 ${sizes.size_1}px`,
			maxWidth: 380,
			margin: '0 auto',
		},
		title: {
			padding: `${sizes.size_2}px 0`,
			textAlign: 'center',
			margin: 0,
			...typeHeading2,
		},
		imageWrapper: {
			minHeight: 166,
			maxWidth: 380,
			borderBottom: `1px solid ${colorVariables.colorMediumShadow}`,
		},
		image: {
			width: '100%',
			display: 'block',
		},
		secondaryButtonContainer: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			padding: `${sizes.size_2}px ${sizes.size_1}% ${sizes.size_2}px`,
		},
	}),
);
