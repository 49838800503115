import classNames from 'classnames';
import { FC, Fragment, memo } from 'react';

import { ApptMessageLoading } from '../../../../ui-kit/icons/appt-message-loading';
import { useLoadingSlotsStyles } from './loading-slots.styles';

interface LoadingSlotsProps {
	className?: string;
	dataTestingLabel?: string;
}

export const LoadingSlots: FC<LoadingSlotsProps> = memo(({ className, dataTestingLabel = 'loading-slots' }) => {
	const classes = useLoadingSlotsStyles();
	const rootClassName = classNames(classes.root, className);
	const secondSlot = classNames(classes.emptySlot, classes.opacityMedium);
	const thirdSlot = classNames(classes.emptySlot, classes.opacityLow);

	return (
		<div className={rootClassName} data-testing-label={dataTestingLabel}>
			<ApptMessageLoading className={classes.botMessage} />
			<Fragment>
				<div className={classes.emptySlot} />
				<div className={secondSlot} />
				<div className={thirdSlot} />
			</Fragment>
		</div>
	);
});
