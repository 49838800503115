import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

import {
	getGradientLayerPositions,
	HEADER_HEIGHT_DESKTOP,
	HEADER_HEIGHT_MOBILE,
	MAX_WIDGET_WIDTH,
} from '../../utils/sizes.utils';

export const useWidgetWrapperStyles = makeStyles<
	Theme,
	{ isMobile: boolean; isFullScreen: boolean; gradientRotationValue: number }
>(
	({
		palette: {
			borderRadius,
			sizes,
			widgetBackground,
			visualConfig,
			colorScheme: {
				colorVariables,
				customStyle: { mainChat },
			},
		},
	}) => ({
		chatWrapper: {
			position: 'relative',
			width: '100%',
			height: '100%',
			overflow: 'hidden',
			maxWidth: MAX_WIDGET_WIDTH,
			margin: '0 auto',
			...mainChat,
		},
		root: {
			width: '100%',
			height: '100%',
			display: 'flex',
			flexDirection: 'column',
			overflow: 'hidden',
			position: 'relative',
			borderRadius: (props) => (props.isMobile || props.isFullScreen ? 0 : borderRadius.widget),
			border: `1px solid ${colorVariables.colorLightShadowNoOpacity}`,
			'&:before': {
				content: '""',
				position: 'absolute',
				top: getGradientLayerPositions(visualConfig.animateBackground),
				bottom: getGradientLayerPositions(visualConfig.animateBackground),
				left: getGradientLayerPositions(visualConfig.animateBackground),
				right: getGradientLayerPositions(visualConfig.animateBackground),
				transition: 'transform 0.4s ease-in-out',
				transform: (props) => `rotate(${props.gradientRotationValue}deg)`,
				zIndex: -1,
				...widgetBackground.gradient,
			},
			...widgetBackground.overlay,
		},
		hidden: {
			visibility: 'hidden',
			position: 'fixed',
			top: '100%',
		},
		modal: {
			padding: 50,
		},
		iframe: {
			width: '100%',
			height: '100%',
		},
		notification: {
			zIndex: 2,
			position: 'absolute',
			top: (props) => (props.isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP),
			left: `calc(${sizes.size_1}% - ${sizes.size_1}px)`,
			right: `calc(${sizes.size_1}% - ${sizes.size_1}px)`,
		},
		noBorder: {
			border: 'none',
		},
	}),
);

export const useSlideCSSTransitionStyles = makeStyles<Theme, { isMobile: boolean }>(
	({ palette: { transitionConfig } }) => ({
		enter: {
			top: (props) => `-${props.isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP}px`,
		},
		enterActive: {
			top: (props) => (props.isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP),
			transition: `all ${transitionConfig.transitionInDuration}ms`,
		},
		exit: {
			top: (props) => (props.isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP),
		},
		exitActive: {
			top: (props) => `-${props.isMobile ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP}px`,
			transition: `all ${transitionConfig.transitionOutDuration}ms`,
		},
	}),
);
