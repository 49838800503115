import { createContext, FC, useState } from 'react';

import { NotificationStatus } from '../models/notification.model';
import { constVoid } from '../utils/function.utils';

interface NotificationState {
	isNotificationShown: boolean;
	notificationType: NotificationStatus;
	notificationMessage: string;
}

export interface NotificationContextType {
	state: NotificationState;
	onUpdate(action: Partial<NotificationState>): void;
}

export const DEFAULT_NOTIFICATION_CONTEXT_STATE: NotificationState = {
	isNotificationShown: false,
	notificationType: 'Off',
	notificationMessage: 'No connection. Reconnecting...',
};

export const DEFAULT_NOTIFICATION_CONTEXT: NotificationContextType = {
	state: DEFAULT_NOTIFICATION_CONTEXT_STATE,
	onUpdate: constVoid,
};

export const NotificationContext = createContext<NotificationContextType>(DEFAULT_NOTIFICATION_CONTEXT);

export const NotificationProvider: FC = ({ children }) => {
	const [state, onStateUpdate] = useState(DEFAULT_NOTIFICATION_CONTEXT_STATE);

	const handleUpdate = (updates: Partial<NotificationState>): void => {
		onStateUpdate((prevState) => ({ ...prevState, ...updates }));
	};

	return (
		<NotificationContext.Provider value={{ state, onUpdate: handleUpdate }}>
			{children}
		</NotificationContext.Provider>
	);
};
