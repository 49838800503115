import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const VideoVisitIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M6 16H14V15.45C14 14.7167 13.6333 14.125 12.9 13.675C12.1667 13.225 11.2 13 10 13C8.8 13 7.83333 13.225 7.1 13.675C6.36667 14.125 6 14.7167 6 15.45V16ZM10 12C10.55 12 11.0208 11.8042 11.4125 11.4125C11.8042 11.0208 12 10.55 12 10C12 9.45 11.8042 8.97917 11.4125 8.5875C11.0208 8.19583 10.55 8 10 8C9.45 8 8.97917 8.19583 8.5875 8.5875C8.19583 8.97917 8 9.45 8 10C8 10.55 8.19583 11.0208 8.5875 11.4125C8.97917 11.8042 9.45 12 10 12ZM4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H16C16.55 4 17.0208 4.19583 17.4125 4.5875C17.8042 4.97917 18 5.45 18 6V10.5L21.15 7.35C21.3167 7.18333 21.5 7.14167 21.7 7.225C21.9 7.30833 22 7.46667 22 7.7V16.3C22 16.5333 21.9 16.6917 21.7 16.775C21.5 16.8583 21.3167 16.8167 21.15 16.65L18 13.5V18C18 18.55 17.8042 19.0208 17.4125 19.4125C17.0208 19.8042 16.55 20 16 20H4Z'
			}
		/>
	</SvgIcon>
);
