import React, { FC, Fragment, memo } from 'react';

import { useBlockSchedulingComponentStyles } from './blockit-scheduling.styles';

interface BlockItSettings {
	slug: string;
	specialtyId: string;
	locationId?: string;
	visitTypeId?: string;
}

interface BlockItSchedulingComponentProps {
	blockItSettings: BlockItSettings;
}

export const BlockItSchedulingComponent: FC<BlockItSchedulingComponentProps> = memo(({ blockItSettings }) => {
	const classes = useBlockSchedulingComponentStyles();
	const { slug, specialtyId, locationId, visitTypeId } = blockItSettings;

	return (
		<Fragment>
			<div
				id={'blockit-consumer-embed'}
				data-type={'search'}
				data-slug={slug}
				data-singlespecialtyid={specialtyId}
				data-locationid={locationId}
				data-visittypeid={visitTypeId}
				className={classes.blockItContainer}
			/>
			<script type={'text/javascript'} src={'https://app.blockitnow.com/js/embedded.js'} />
		</Fragment>
	);
});
