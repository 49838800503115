import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useDoctorSearchEmptyStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
			borderRadius,
		},
		typography: { smallViewPort },
	}) => ({
		root: {
			textAlign: 'center',
			padding: `${sizes.size_3}px ${sizes.size_3}px ${sizes.size_4}px`,
			background: colorVariables.colorSysBgDefault,
			borderRadius: borderRadius.card,
			'& > svg': {
				marginBottom: sizes.sizeHalf,
			},
		},
		title: {
			...smallViewPort.typeHeading1,
			margin: 0,
			fontSize: 16,
			color: colorVariables.colorSysTextDefault,
		},
		description: {
			...smallViewPort.typeSysText1,
		},
		linkButton: {
			...smallViewPort.typeLink2,
			padding: sizes.size_1,
			fontSize: sizes.size_2,
			display: 'block',
			margin: `0 auto ${sizes.size_3}px`,
			textDecoration: 'underline',
			color: colorVariables.colorSysTextDefault,
			'&:hover': {
				textDecoration: 'underline',
				backgroundColor: 'transparent',
				textDecorationColor: colorVariables.colorSysTextDefault,
				filter: 'brightness(96%)',
				transition: 'all .2s',
			},
		},
	}),
);
