import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useAvatarStyles = makeStyles<Theme, { iconPath?: string }>(
	({ palette: { colorScheme, borderRadius } }) => ({
		avatar: {
			width: 60,
			height: 60,
			backgroundColor: (props) => (props.iconPath ? 'inherit' : colorScheme.colorVariables.colorPrimary),
			borderRadius: borderRadius.avatar,
			backgroundImage: (props) => (props.iconPath ? `url(${props.iconPath})` : 'none'),
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 50,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			'& svg': {
				width: 40,
				height: 40,
			},
			...colorScheme.customStyle.header?.logo,
		},
	}),
);
