import { makeStyles } from '@material-ui/core';

export const useChipGroupStyles = makeStyles(({ palette: { sizes } }) => ({
	root: {
		display: 'flex',
		'& > div': {
			flex: 1,
		},
		'& > div:not(:last-child)': {
			marginRight: sizes.sizeHalf,
		},
		'& > div:not(:first-child):not(:last-child)': {
			borderRadius: 0,
		},
		'& > div:first-child': {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		'& > div:last-child': {
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
	},
	stackedRoot: {
		display: 'flex',
		flexDirection: 'column',
		'& > div:not(:last-child)': {
			marginBottom: sizes.sizeHalf,
		},
	},
}));
