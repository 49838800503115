import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../ui-kit/button/button.component';
import { ChipGroup } from '../../../ui-kit/chip-group/chip-group.component';
import { ChipMultiselect } from '../../../ui-kit/chip-multiselect/chip-multiselect.component';
import { Chip } from '../../../ui-kit/chip/chip.component';
import { Dropdown } from '../../../ui-kit/dropdown/dropdown.component';
import { isEqualArrays } from '../../../utils/array.utils';
import { Nullable } from '../../../utils/types.utils';
import { useDoctorSearchFilterStyles } from './doctor-search-filters.styles';

const isFilterTypeMulti = (type: DoctorSearchFilterType): boolean => type === 'CHECK_LIST' || type === 'MULTISELECT';

const selectedItemsCountString = (type: DoctorSearchFilterType, items: DoctorSearchFilterItem[]): string => {
	const selectedItemsCount = isFilterTypeMulti(type) ? items.filter((item) => item.isSelected).length : 0;
	return selectedItemsCount > 0 ? ` (${selectedItemsCount})` : '';
};

const getSelectedValue = (
	isSelected: boolean,
	isCurrentFilter: boolean,
	isMulti: boolean,
	isRequired: boolean,
	selectedFiltersNumber: number,
): boolean => {
	if (isCurrentFilter) {
		if (isMulti) {
			return isSelected && isRequired ? selectedFiltersNumber < 2 : !isSelected;
		}
		return isSelected ? isRequired : true;
	}
	return isMulti ? isSelected : false;
};

export type DoctorSearchFilterType = 'LIST' | 'CHECK_LIST' | 'SELECT' | 'MULTISELECT';

interface DoctorSearchFilterItem {
	key: string;
	label: string;
	isSelected: boolean;
	isDisabled: boolean;
}

export interface DoctorSearchFilterGroup {
	title: string;
	items: DoctorSearchFilterItem[];
	type: DoctorSearchFilterType;
	name?: string;
	placeholder?: string;
	required: boolean;
	isMulti: boolean;
}

interface DoctorSearchFiltersProps {
	initialFiltersList: DoctorSearchFilterGroup[];
	filtersList: DoctorSearchFilterGroup[];
	description: Nullable<string>;
	onUpdate(filters: DoctorSearchFilterGroup[], isReset?: boolean): void;
}

export const DoctorSearchFilters: FC<DoctorSearchFiltersProps> = ({
	initialFiltersList,
	filtersList,
	description,
	onUpdate,
}) => {
	const { t } = useTranslation();
	const classes = useDoctorSearchFilterStyles();

	const [filtersInState, setFiltersInState] = useState<DoctorSearchFilterGroup[]>(filtersList);

	const handleListFilterClick = (key: string, groupTitle: string, isMulti: boolean) => {
		const newFilters = filtersInState.map((filterGroup) => {
			if (filterGroup.title === groupTitle) {
				const selectedFilters = filterGroup.items.filter((filter) => filter.isSelected).length;
				const newFilterGroupItems = filterGroup.items.map((filterItem) => {
					const isEqualToSelectedFilter = filterItem.key === key;

					const isSelected = getSelectedValue(
						filterItem.isSelected,
						isEqualToSelectedFilter,
						isMulti,
						filterGroup.required,
						selectedFilters,
					);

					return {
						...filterItem,
						isSelected,
					};
				});
				return { ...filterGroup, items: newFilterGroupItems };
			}
			return filterGroup;
		});

		setFiltersInState(newFilters);
	};

	const handleFilterWithMultipleValues = (keys: string[], groupTitle: string) => {
		const newFilters = filtersInState.map((filterGroup) => {
			if (filterGroup.title === groupTitle) {
				const newFilterGroupItems = filterGroup.items.map((filterItem) => {
					const isEqualToSelectedFilter = keys.includes(filterItem.key);
					return {
						...filterItem,
						isSelected: isEqualToSelectedFilter,
					};
				});
				return { ...filterGroup, items: newFilterGroupItems };
			}
			return filterGroup;
		});
		setFiltersInState(newFilters);
	};

	const renderFiltersByType = ({ items, type, title, placeholder, isMulti, required }: DoctorSearchFilterGroup) => {
		switch (type) {
			case 'LIST':
				return (
					<ChipGroup>
						{items.map((filter) => (
							<Chip
								label={filter.label}
								isSelected={filter.isSelected}
								size={'small'}
								key={filter.key}
								onClick={() => handleListFilterClick(filter.key, title, isMulti)}
								dataTestingLabel={`doctor-search-filter-${title}-${filter.key}${
									filter.isSelected ? '-selected' : ''
								}`}
							/>
						))}
					</ChipGroup>
				);
			case 'CHECK_LIST':
				return (
					<div className={classes.multiFilters}>
						{items.map((filter) => (
							<ChipMultiselect
								isFullWidth
								isDisabled={filter.isDisabled}
								label={filter.label}
								isSelected={filter.isSelected}
								onClick={() => handleListFilterClick(filter.key, title, isMulti)}
								key={filter.key}
								dataTestingLabel={`doctor-search-filter-${title}-${filter.key}${
									filter.isSelected ? '-selected' : ''
								}`}
							/>
						))}
					</div>
				);
			case 'SELECT':
			case 'MULTISELECT':
				return (
					<div className={classes.multiFilters}>
						<Dropdown
							options={items.map((filter) => ({
								label: filter.label,
								value: filter.key,
								isSelected: filter.isSelected,
							}))}
							placeholder={required ? undefined : placeholder}
							dataTestingLabel={`doctor-search-filter-dropdown-${title}`}
							onChange={(values) => handleFilterWithMultipleValues(values, title)}
							multiple={isMulti}
							required={required}
						/>
					</div>
				);
		}
	};

	const renderFilterGroup = (filterGroup: DoctorSearchFilterGroup) => (
		<div
			className={classes.filterGroup}
			key={filterGroup.title}
			data-testing-label={`doctor-search-filter-group-${filterGroup.type}-${filterGroup.title}`}>
			<h3 className={classes.filterGroupTitle} data-testing-label={'doctor-search-filter-group-title'}>
				{`${filterGroup.title}${selectedItemsCountString(filterGroup.type, filterGroup.items)}`}
			</h3>
			{renderFiltersByType(filterGroup)}
		</div>
	);

	const isFiltersDifferentFromInitial = !isEqualArrays(filtersList, initialFiltersList);
	const isFiltersChanged = !isEqualArrays(filtersInState, filtersList);

	return (
		<Fragment>
			{description && (
				<p className={classes.description} data-testing-label={'doctor-search-filter-description'}>
					{description}
				</p>
			)}
			{filtersInState.map(renderFilterGroup)}
			<div className={classes.actions}>
				<Button
					color={'primary'}
					onClick={() => onUpdate(filtersInState)}
					disabled={!isFiltersChanged}
					className={classes.actionButton}
					data-testing-label={'doctor-search-filter-apply-button'}>
					{t('doctorSearchFiltersApply', 'Apply filters')}
				</Button>
				<Button
					color={'secondary'}
					onClick={() => onUpdate(initialFiltersList, true)}
					disabled={!isFiltersDifferentFromInitial}
					className={classes.actionButton}
					data-testing-label={'doctor-search-filter-reset-button'}>
					{t('doctorSearchFiltersReset', 'Reset filters')}
				</Button>
			</div>
		</Fragment>
	);
};
