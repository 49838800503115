import { makeStyles } from '@material-ui/core';

export const useDoctorSearchFilterStyles = makeStyles(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		filterGroup: {
			marginBottom: sizes.size_3,
		},

		filterGroupTitle: {
			...smallViewPort.typeNoteHeading,
			color: colorVariables.colorNote,
			marginBottom: sizes.size_1,
		},
		multiFilters: {
			display: 'flex',
			flexDirection: 'column',
			gap: sizes.sizeHalf,
		},
		actions: {
			display: 'flex',
			flex: 1,
			gap: sizes.size_1,
			position: 'sticky',
			bottom: 0,
			backgroundColor: 'white',
			alignItems: 'flex-end',
			paddingBottom: sizes.size_4,
		},
		actionButton: {
			flex: 1,
			height: sizes.size_6,
		},
		description: {
			...smallViewPort.typeNoteParagraph,
			marginBottom: 2,
			textAlign: 'center',
		},
	}),
);
