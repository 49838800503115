import { FC, useCallback, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Effect } from '../../utils/function.utils';
import { validateWeight } from '../../utils/validations.utils';
import { InputWithSelect, InputWithSelectOutputType } from '../input-with-select/input-with-select.component';
import { InputValidationResult } from '../input-with-validation/input-with-validation.component';

const units = ['lbs', 'kg'];
interface WeightInputProps {
	shouldAutoFocus?: boolean;
	onSubmit: Effect<string>;
}

export const WeightInput: FC<WeightInputProps> = ({ shouldAutoFocus, onSubmit }) => {
	const { t } = useTranslation();
	const [unit, setUnit] = useState(units[0]);

	const validationFunction = (value: string, t: TFunction): InputValidationResult => validateWeight(value, unit, t);
	const handleSubmit = useCallback(({ value }: InputWithSelectOutputType): void => onSubmit(value), [onSubmit]);

	return (
		<InputWithSelect
			shouldAutoFocus={shouldAutoFocus}
			testingLabels={{
				root: 'weight-input-root',
				input: 'weight-input',
				error: 'weight-input-error',
				select: 'weight-unit-selector',
			}}
			type={'label'}
			label={t('weightLabel', 'Weight')}
			units={units}
			placeholder={'150'}
			onSubmit={handleSubmit}
			onUnitChange={setUnit}
			validation={validationFunction}
		/>
	);
};
