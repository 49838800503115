import { memo, RefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import {
	ErrorResponseModel,
	GetProvidersResponseModel,
	ProvidersResponseModelSuccess,
} from '../../context/api-service/api-service.model';
import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { LinksServiceContext } from '../../context/links-service/links-service.context';
import { SessionContext } from '../../context/session.context';
import { DoctorSearchMetaData } from '../../models/message.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { isEqualArrays } from '../../utils/array.utils';
import { Nullable } from '../../utils/types.utils';
import { DoctorSearchFilterGroup } from './doctor-search-filters/doctor-search-filters.component';
import { DoctorSearch } from './doctor-search.component';
import {
	buildQueryString,
	createUIFiltersGroups,
	EMPTY_DOCTORS_LIST,
	FetchingDoctorsState,
	FiltersTypeUpdate,
	getEventNameByFiltersUpdateType,
	mapFilterModelToUIFilter,
	mapProviderResourceToProviderInfo,
	ProviderResponse,
} from './doctor-search.model';

interface DoctorSearchContainerProps {
	rootRef?: RefObject<HTMLDivElement>;
	data: DoctorSearchMetaData;
}

const isErrorApplyingFilters = (response: GetProvidersResponseModel): response is ErrorResponseModel =>
	'status' in response && response.status === 'error';

export const DoctorSearchContainer = memo(({ rootRef, data }: DoctorSearchContainerProps) => {
	const {
		state: { onSendMessage },
	} = useContext(SessionContext);
	const linksService = useContext(LinksServiceContext);
	const eventsService = useContext(EventsServiceContext);
	const { getProvidersApi } = useContext(APIServiceContext);

	const ref = useRef<HTMLDivElement>(null);
	const { sorting, filters, searchContextLabel, query, emptySlotsStep, noDoctorsStep, stepName } = data.data;
	const initialFiltersRef = useRef<DoctorSearchFilterGroup[]>([]);

	const [updatedFilters, setUpdatedFilters] = useState<DoctorSearchFilterGroup[]>();
	const [providersData, setProvidersData] = useState<Nullable<ProvidersResponseModelSuccess>>(null);
	const [fetchingDoctorsState, setFetchingDoctorsState] = useState<Nullable<FetchingDoctorsState>>('INITIAL');
	const [isErrorFetchingDoctors, setErrorFetchingDoctors] = useState(false);
	const [isEmptyByFilters, setIsEmptyByFilters] = useState(false);

	useEffect(() => {
		const mappedSortingConfig: DoctorSearchFilterGroup[] = sorting ? [mapFilterModelToUIFilter(sorting)] : [];
		const mappedFiltersConfig = createUIFiltersGroups(filters);

		initialFiltersRef.current = [...mappedSortingConfig, ...mappedFiltersConfig];
		setUpdatedFilters([...mappedSortingConfig, ...mappedFiltersConfig]);
	}, [data.data.filters.length]);

	const fetchProviders = async (queryString: string, searchType: FetchingDoctorsState): Promise<void> => {
		searchType !== 'INITIAL' && setFetchingDoctorsState(searchType);
		setErrorFetchingDoctors(false);
		if (searchType === 'NEW') {
			setProvidersData(null);
		}
		try {
			// Add jumping to the emptyFlowStep if it is a first fetch and a list of doctors is empty
			const startTimeStamp = new Date().getTime();
			const providersData: ProvidersResponseModelSuccess = await getProvidersApi(queryString).then((response) =>
				isErrorApplyingFilters(response) ? EMPTY_DOCTORS_LIST : response,
			);

			const endTimeStamp = new Date().getTime();
			eventsService.sendEvent({
				eventName: 'doctorSearchGetDoctors',
				data: {
					value: {
						searchTime: endTimeStamp - startTimeStamp,
					},
				},
			});

			if (searchType === 'INITIAL' && providersData.items.length === 0) {
				onSendMessage(
					// TODO: remove emptySlotsStep from model and test_func flow config, when BE is ready
					`gyant start flow ${noDoctorsStep || emptySlotsStep}`,
					undefined,
					false,
					false,
					undefined,
					'message',
				);

				eventsService.sendEvent({
					eventName: 'doctorSearchEmptyResultInitial',
				});
				setTimeout(() => {
					setFetchingDoctorsState(null);
				}, 2000);
			} else {
				setProvidersData((providersInState) =>
					providersInState
						? {
								paging: providersData.paging,
								items: [...providersInState.items, ...providersData.items],
							}
						: providersData,
				);
				setFetchingDoctorsState(null);
			}
		} catch (error) {
			setErrorFetchingDoctors(true);
			setFetchingDoctorsState(null);
			eventsService.sendEvent({
				eventName: 'doctorSearchErrorGetDoctors',
			});
		}
	};

	useEffect(() => {
		if (updatedFilters) {
			const queryStringWithFiltersParameters = buildQueryString(updatedFilters, query);
			fetchProviders(queryStringWithFiltersParameters, fetchingDoctorsState === 'INITIAL' ? 'INITIAL' : 'NEW');
		}
	}, [updatedFilters]);

	useEffect(() => {
		const isEmpty =
			!isErrorFetchingDoctors &&
			initialFiltersRef.current.length > 0 &&
			!!updatedFilters &&
			!isEqualArrays(updatedFilters, initialFiltersRef.current) &&
			providersData?.items.length === 0;
		if (isEmpty !== isEmptyByFilters) {
			setIsEmptyByFilters(isEmpty);
			if (isEmpty) {
				eventsService.sendEvent({
					eventName: 'doctorSearchEmptyResultByFilters',
				});
			}
		}
	}, [isErrorFetchingDoctors, updatedFilters, providersData]);

	const handleLoadMoreDoctors = () => {
		if (updatedFilters && providersData) {
			eventsService.sendEvent({
				eventName: 'doctorSearchShowMore',
			});
			const nextPageURLParam = `&page=${providersData.paging?.nextPage}`;
			const queryStringWithFiltersParameters = `${buildQueryString(updatedFilters, query)}${nextPageURLParam}`;
			fetchProviders(queryStringWithFiltersParameters, 'LOAD_MORE');
		}
	};

	const onResponse = (response: ProviderResponse, e: EventType): void => {
		switch (response.type) {
			case 'data':
				return onSendMessage(
					response.content,
					undefined,
					true,
					false,
					undefined,
					'data',
					response.responseContext,
				);
			case 'flowStep':
				return onSendMessage(response.responseContext, response.content, true, false, undefined, 'message');
			case 'webUrlExternal': {
				if (response.link) {
					return linksService.openExternalLink(response.link, e, stepName);
				}
			}
		}
	};

	const handleOpenDetailedView = useCallback(() => {
		eventsService.sendEvent({
			eventName: 'doctorSearchOpenDetails',
		});
	}, []);

	const handleUpdateFilters = useCallback(
		(filters: DoctorSearchFilterGroup[], type: FiltersTypeUpdate = 'FILTERS_APPLY') => {
			setUpdatedFilters(filters);
			eventsService.sendEvent({
				eventName: getEventNameByFiltersUpdateType(type),
				data: {
					value: {
						filters,
					},
				},
			});
		},
		[],
	);

	return (
		<div ref={ref}>
			<DoctorSearch
				options={data.data.options}
				totalNumberOfDoctors={providersData?.paging?.totalItems || 0}
				doctorsList={providersData?.items ? mapProviderResourceToProviderInfo(providersData.items) : []}
				initialFiltersList={initialFiltersRef.current}
				filtersList={updatedFilters || []}
				filtersDescription={searchContextLabel}
				rootRef={rootRef}
				shouldShowLoadingMask={fetchingDoctorsState === 'NEW' || fetchingDoctorsState === 'INITIAL'}
				isFetchingMoreDoctors={fetchingDoctorsState === 'LOAD_MORE'}
				isMoreDoctorsAvailable={!!providersData?.paging?.nextPage && !isErrorFetchingDoctors}
				isEmptyByFilters={isEmptyByFilters}
				isError={isErrorFetchingDoctors}
				onUpdateFilters={handleUpdateFilters}
				onResponse={onResponse}
				onLoadMoreDoctors={handleLoadMoreDoctors}
				onOpenDetailedView={handleOpenDetailedView}
			/>
		</div>
	);
});
