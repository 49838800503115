import React, { FC, memo, useState } from 'react';

import { MultiSelectResponses, QuickResponseCheckbox } from '../../../../models/message.model';
import { Button } from '../../../../ui-kit/button/button.component';
import { ChipMultiselect } from '../../../../ui-kit/chip-multiselect/chip-multiselect.component';
import { useMultiQuickResponsesRendererStyles } from './multi-select-renderer.styles';

interface MultiSelectQuickResponseRendererProps {
	isDisabled: boolean;
	responses: MultiSelectResponses[];
	onResponse: (text: string, orininalText: string, logData: string) => void;
}

const isSelectionExclusiveMulti =
	(responses: MultiSelectResponses[]) =>
	(selection: string): boolean =>
		responses.findIndex(
			(response) => response.responseContext === selection && response.buttonType === 'exclusiveMulti',
		) !== -1;

export const MultiSelectQuickResponseRenderer: FC<MultiSelectQuickResponseRendererProps> = memo(
	({ responses, isDisabled, onResponse }) => {
		const [selections, onSelectionsChange] = useState<string[]>([]);
		const classes = useMultiQuickResponsesRendererStyles();

		const isNoItemsSelected = selections.length === 0;

		const handleSend = (responseContext: string, responseContent: string) => {
			const selectedResponses = selections.sort().join(',');
			const response = `${responseContext}.${selectedResponses}`;

			const selectedLabels = selections
				.map((selection) => responses.find((response) => response.responseContext === selection)?.content)
				.join();

			const logData = `${selectedLabels}, ${responseContent}`;

			const originalText = responses
				.filter((response) => selections.includes(response.responseContext))
				.map((response) => response.content)
				.join(', ');

			onResponse(response, originalText, logData);
		};

		const handleCheck = (clickedItem: string, buttonType: QuickResponseCheckbox['buttonType']) => {
			const selectedIndex = selections.indexOf(clickedItem);

			if (selectedIndex !== -1) {
				const updatedSelections = selections.slice();
				updatedSelections.splice(selectedIndex, 1);
				onSelectionsChange(updatedSelections);
			} else if (buttonType === 'exclusiveMulti') {
				onSelectionsChange([clickedItem]);
			} else {
				const someExclusiveAlreadySelected = selections.some(isSelectionExclusiveMulti(responses));
				someExclusiveAlreadySelected
					? onSelectionsChange([clickedItem])
					: onSelectionsChange([...selections, clickedItem]);
			}
		};

		const renderResponses = (responses: MultiSelectResponses[]) =>
			responses.map((response, index) => {
				const isChecked = selections.includes(response.responseContext);
				switch (response.buttonType) {
					case 'multi':
					case 'exclusiveMulti':
						return (
							<li
								data-testing-label={'quick-response-multi-item'}
								className={classes.listItem}
								key={index}
							>
								<ChipMultiselect
									className={classes.customMultiselectItem}
									dataTestingLabel={`quick-response-multi-checkbox-item${
										isChecked ? '-selected' : ''
									}`}
									aria-checked={isChecked ? 'true' : 'false'}
									aria-label={response.content}
									label={response.content}
									onClick={() => handleCheck(response.responseContext, response.buttonType)}
									isSelected={isChecked}
									isFullWidth
								/>
							</li>
						);

					case 'button':
						return (
							<li key={index} className={classes.sendButton}>
								<Button
									className={classes.customSendButton}
									color={'primary'}
									isFullWidth
									data-testing-label={'quick-response-multi-submit-btn'}
									disabled={isNoItemsSelected || isDisabled}
									onClick={() => handleSend(response.responseContext, response.content)}
									key={response.responseContext}
								>
									{response.content}
								</Button>
							</li>
						);
				}
			});

		return <ul className={classes.root}>{renderResponses(responses)}</ul>;
	},
);
