import { BasicEventConfig } from './web-tracker-service.model';

interface GoogleAnalyticsEventConfig extends BasicEventConfig {
	trackerId: string;
}

export const initGoogleAnalytics = (id: string): void => {
	const script = document.createElement('script');
	script.innerText = `(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');ga('create', '${id}', 'auto');ga('send', 'pageview');`;
	document.head.prepend(script);
};

export const sendGoogleAnalyticsEvent = (eventConfig: GoogleAnalyticsEventConfig): void => {
	const { category, action, label, logEventsToConsole, trackerId } = eventConfig;
	let c = 0;
	const gaName = `tracker${c++}`;

	const eventToSend = {
		hitType: 'event',
		eventCategory: category,
		eventAction: action,
		eventLabel: label,
	};
	window.ga('create', trackerId, 'auto', { name: gaName });
	window.ga(`${gaName}.send`, eventToSend);

	if (logEventsToConsole) {
		const message = `Send Event: ${trackerId} ${category} ${action} ${label}`;
		console.log(`GyantTrackerGaHandler: ${message}`);
	}
};
