import classNames from 'classnames';
import { FC, useContext } from 'react';

import { ConfigContext } from '../../context/config.context';
import { AvatarIcon } from '../icons/avatar.icon';
import { useAvatarStyles } from './avatar.styles';

interface AvatarProps {
	className?: string;
	dataTestingLabel?: string;
}

export const Avatar: FC<AvatarProps> = ({ className, dataTestingLabel }) => {
	const {
		appConfig: { visualConfig },
	} = useContext(ConfigContext);
	const iconPath = visualConfig.headerAvatar;
	const classes = useAvatarStyles({ iconPath });
	const rootClassNames = classNames(classes.avatar, className);

	return (
		<div data-testing-label={dataTestingLabel} className={rootClassNames}>
			{!iconPath && <AvatarIcon />}
		</div>
	);
};
