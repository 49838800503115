import classNames from 'classnames';
import React, { FC, memo, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../ui-kit/button/button.component';
import { Icon } from '../../../../ui-kit/icon/icon.component';
import { NotesIcon } from '../../../../ui-kit/icons/notes.icon';
import { useAppointmentSummaryStyles } from '../appointment-summary.styles';

interface AppointmentSummaryLocationProps {
	additionalInformation: string;
	truncateInfoText?: boolean;
}

export const AppointmentSummaryAdditionalInfo: FC<AppointmentSummaryLocationProps> = memo(
	({ additionalInformation, truncateInfoText }) => {
		const { t } = useTranslation();
		const ref = useRef<HTMLDivElement>(null);
		const [showMoreButton, setShowMoreButton] = useState(false);
		const [isTextOpen, setIsTextOpen] = useState(false);
		const classes = useAppointmentSummaryStyles();

		const iconWrapper = classNames(classes.subIcon, classes.flexIcon);

		useLayoutEffect(() => {
			const element = ref.current;

			const shouldTruncateText = !!(
				element &&
				truncateInfoText &&
				(element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
			);
			setShowMoreButton(shouldTruncateText);
		}, []);

		const infoClassNames = classNames(
			classes.info,
			isTextOpen && classes.textOpened,
			truncateInfoText && classes.infoTruncated,
			showMoreButton && !isTextOpen && classes.textClosed,
		);

		const showMoreButtonText = isTextOpen ? t('showLess', 'Less...') : t('showMore', 'More...');

		return (
			<div className={classes.section} data-testing-label={'appointment-summary-additional-info'}>
				<Icon className={iconWrapper} icon={NotesIcon} size={'small'} iconType={'inputIcon'} />
				<div className={classes.infoWrapper}>
					<p
						ref={ref}
						className={infoClassNames}
						dangerouslySetInnerHTML={{ __html: additionalInformation }}
					/>
					{showMoreButton && (
						<Button
							data-testing-label={'appointment-summary-additional-info-show-more-button'}
							className={classes.linkButton}
							variant={'text'}
							size={'small'}
							onClick={() => setIsTextOpen(!isTextOpen)}
						>
							{showMoreButtonText}
						</Button>
					)}
				</div>
			</div>
		);
	},
);
