import { FC } from 'react';

import { Audio } from '../../../ui-kit/media/audio/audio.component';
import { Image } from '../../../ui-kit/media/image/image.component';
import { Video } from '../../../ui-kit/media/video/video.component';
import { useMediaStyles } from './media-renderer.styles';

export type MediaType = 'image' | 'video' | 'audio';

export interface ImageRendererProps {
	src: string;
	type: MediaType;
}

export const MediaRenderer: FC<ImageRendererProps> = ({ src, type }) => {
	const classes = useMediaStyles();

	const renderContent = (type: MediaType, src: string): JSX.Element => {
		switch (type) {
			case 'image':
				return <Image src={src} />;
			case 'audio':
				return <Audio src={src} />;
			case 'video':
				return <Video src={src} />;
		}
	};

	return (
		<div className={classes.root} data-testing-label={`media-renderer-${type}`}>
			{renderContent(type, src)}
		</div>
	);
};
