import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS } from '../localization/localization';

const detectionOptions = {
	order: [],
	caches: [],
};

i18n.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		detection: detectionOptions,
		resources: TRANSLATIONS,
		fallbackLng: {
			default: ['en'],
		},
	});

export default i18n;
