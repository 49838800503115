import {
	AppointmentAvailableDatesPayload,
	AppointmentAvailableDatesResponseModel,
	ScheduleDayModel,
} from '../../context/api-service/api-service.model';
import { EventAction } from '../../context/events-service/events-service.model';
import { TimeSlotItem } from '../../models/appointment.model';
import { AppointmentSchedulingMetaDataData } from '../../models/message.model';
import { Effect } from '../../utils/function.utils';
import {
	dateToFormattedDateString,
	getCalendarDayFromDate,
	getLastDayOfMonth,
	getUTCDate,
} from '../../utils/time.utils';

export interface AppointmentSchedulerTemplates {
	schedulerFootnote?: string;
	changeProvider?: string;
}

type AppointmentComponentType = 'APPOINTMENT_SCHEDULING' | 'APPOINTMENT_SUMMARY';

export type AppointmentSchedulerTrackerElementName =
	| 'DATEPICKER'
	| 'DATEPICKER_SLOT'
	| 'CALENDAR_CHANGE_MONTH'
	| 'CHANGE_PROVIDER_BUTTON'
	| 'SCHEDULE_APPOINTMENT_BUTTON'
	| 'NO_AVAILABLE_TIME_SLOTS'
	| 'ERROR_FETCH_TIME_SLOTS'
	| 'REFETCH_TIME_SLOTS';

export type AppointmentSumaryTrackerElementName =
	| 'MAKE_CHANGE_BUTTON'
	| 'ADD_CALENDAR'
	| 'CONTACT'
	| 'LOCATION_TEXT'
	| 'LOCATION_MAP';

interface AppointmentTrackingEventData {
	component: AppointmentComponentType;
	scope: string;
	flowId: string;
	stepName: string;
}

export const toGetAppointmentAvailableDatesPayload = (
	startDate: string,
	endDate: string,
	data: AppointmentSchedulingMetaDataData,
): AppointmentAvailableDatesPayload => ({
	startDate,
	endDate,
	data: { ...data.criteria },
});

export const monthHasAvailableSlots = (scheduleDay: ScheduleDayModel[]): boolean =>
	scheduleDay.some((day) => day.slots.length > 0);

export const filterDaysWithAvailableSlots = (daysWithSlots: Dailyslots[]): Dailyslots[] =>
	daysWithSlots.filter((dayWithSlots) => dayWithSlots.slots.length > 0);

type Month = string;

export interface Dailyslots {
	date: string;
	displayDate: string;
	slots: TimeSlotItem[];
}

export type SlotsDateBase = Record<Month, Dailyslots[]>;

export const toSlotsDateBase = (response: AppointmentAvailableDatesResponseModel, monthKey: string): SlotsDateBase => {
	const monthlySlots: SlotsDateBase = {};
	monthlySlots[monthKey] = [];

	response.schedule.forEach((day) => monthlySlots[monthKey].push(day));

	return monthlySlots;
};

export const getCalendarDaysWithSlots = (daysWithSlots: Dailyslots[]): string[] =>
	daysWithSlots.map((day) => getCalendarDayFromDate(getUTCDate(day.date)));

export const getSlotsRequestPayload = (
	initialDate: Date,
	data: AppointmentSchedulingMetaDataData,
): AppointmentAvailableDatesPayload => {
	const startDate = dateToFormattedDateString(initialDate);
	const endDate = dateToFormattedDateString(getLastDayOfMonth(initialDate));
	return toGetAppointmentAvailableDatesPayload(startDate, endDate, data);
};

export const createTrackingEventFunc =
	<K extends string>(eventData: AppointmentTrackingEventData, sendEvent: Effect<EventAction>) =>
	(elementName: K): void => {
		sendEvent({
			eventName: 'appointmentScheduler',
			data: {
				elementName,
				...eventData,
			},
		});
	};
