import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../ui-kit/button/button.component';
import { MenuOption, MenuOptions } from '../../../../ui-kit/menu-options/menu-options.component';
import { Effect } from '../../../../utils/function.utils';
import { AppointmentSumaryTrackerElementName } from '../../appointment-scheduling.model';
import { createICSCalendarUrl, ICSEventData } from '../appointment-summary.model';
import { useAppointmentSummaryStyles } from '../appointment-summary.styles';

interface AppointmentSummaryActionsProps {
	eventData: ICSEventData;
	options: MenuOption[];
	onTrackevent?: Effect<AppointmentSumaryTrackerElementName>;
}

export const AppointmentSummaryActions: FC<AppointmentSummaryActionsProps> = memo(
	({ eventData, options, onTrackevent }) => {
		const classes = useAppointmentSummaryStyles();
		const { t } = useTranslation();

		const handleAddToCalendar = () => {
			onTrackevent && onTrackevent('ADD_CALENDAR');
			window.open(createICSCalendarUrl(eventData));
		};

		return (
			<div className={classes.options}>
				<Button
					className={classes.mainAction}
					color={'secondary'}
					onClick={handleAddToCalendar}
					data-testing-label={'appointment-summary-add-to-calendar'}
				>
					{t('addCalendar', 'Add to Calendar')}
				</Button>
				<MenuOptions options={options} />
			</div>
		);
	},
);
