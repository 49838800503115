import { FC, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { APIServiceContext, APIServiceProvider } from '../../context/api-service/api-service.context';
import { ConfigContext } from '../../context/config.context';
import { EventsServiceContextProvider } from '../../context/events-service/events-service.context';
import { LinksServiceContextProvider } from '../../context/links-service/links-service.context';
import { ServicesContext } from '../../context/services.context';
import { SessionContextProvider } from '../../context/session.context';
import { TranslationsContext, TranslationsContextProvider } from '../../context/translations.context';
import { AppConfig } from '../../models/app.model';
import {
	BASE_LANGUAGE_PREFIX_LOCAL_STORAGE,
	getFirstBrowserLanguage,
	LanguageNameLowerCase,
	toLanguageLocale,
	toLocale,
} from '../../models/languages.model';
import { BlockItSchedulingContainer } from '../../pages/blockit-scheduling/block-it-scheduling.container';
import { EpicSchedulingContainer } from '../../pages/epic-scheduling/epic-scheduling.container';
import { createWebTrackerService, WebTrackerService } from '../../services/web-trackers-service/web-tracker-service';
import { WEB_TRACKER_SERVICE_DEFAULT } from '../../services/web-trackers-service/web-tracker-service.model';
import { getClientCookieName, getCookie } from '../../utils/cookies.utils';
import { getGyantEmbedHost, setFaviconsByEnvironmentInGyantHost } from '../../utils/embed.utils';
import '../../utils/i18.utils';
import { getUrlParameters } from '../../utils/url-parameters';
import { App } from './app.component';
import {
	getAppOptionsFromDifferentSources,
	InitialAppOption,
	isLanguageSettingExists,
	mapAppConfigResponseToAppConfig,
} from './app.model';

export const AppContainerRaw: FC<InitialAppOption> = memo((props) => {
	const { getConfig } = useContext(APIServiceContext);
	const { updateTranslations } = useContext(TranslationsContext);

	const [appConfig, appConfigStateHandler] = useState<AppConfig | null>(null);
	const [webTrackerService, webTrackerServiceHandler] = useState<WebTrackerService>(WEB_TRACKER_SERVICE_DEFAULT);

	const [modalUrl, handleModalUrlState] = useState<string>('');
	const [isModalOpen, handleModalOpenState] = useState<boolean>(false);

	const { i18n, t } = useTranslation();

	setFaviconsByEnvironmentInGyantHost(window);

	const appOptionsFromUrl = getUrlParameters(window.location.search);
	const appOptions = getAppOptionsFromDifferentSources(props, appOptionsFromUrl);

	const setInitialLanguage = (parsedAppConfig: AppConfig) => {
		const existingSessionTokenCookie = getCookie(getClientCookieName(appOptions.client));
		const languageLocalStorageKey = `${BASE_LANGUAGE_PREFIX_LOCAL_STORAGE}_${existingSessionTokenCookie}`;
		const savedLanguageFromLocalStorage = localStorage.getItem(languageLocalStorageKey);

		if (savedLanguageFromLocalStorage) {
			i18n.changeLanguage(savedLanguageFromLocalStorage);
			return savedLanguageFromLocalStorage;
		}

		if (appOptions.language) {
			const languageLocale = toLanguageLocale(appOptions.language);
			const localizationExists = isLanguageSettingExists(parsedAppConfig.languages, languageLocale);
			if (localizationExists) {
				i18n.changeLanguage(languageLocale);
				return languageLocale;
			}
		}

		if (parsedAppConfig.settings.useBrowserLanguage) {
			const browserLanguage = getFirstBrowserLanguage()?.split('-')[0] as LanguageNameLowerCase;
			const browserLanguageLocale = toLanguageLocale(browserLanguage);
			const language = isLanguageSettingExists(parsedAppConfig.languages, browserLanguageLocale)
				? browserLanguageLocale
				: parsedAppConfig.settings.defaultLanguage;

			i18n.changeLanguage(language);
			return language;
		}

		i18n.changeLanguage(parsedAppConfig.settings.defaultLanguage);
		return parsedAppConfig.settings.defaultLanguage;
	};
	const handleModalUrlUpdate = (url: string) => {
		handleModalUrlState(url);
		handleModalOpenState(true);
	};

	useEffect(() => {
		const beforeRequestTimestamp = new Date().getTime();

		getConfig(appOptions.client).then((data) => {
			if (data && data.active) {
				const afterRequestTimestamp = new Date().getTime();
				const parsedAppConfig = mapAppConfigResponseToAppConfig(data);
				const initialLanguage = setInitialLanguage(parsedAppConfig);

				updateTranslations(toLocale(initialLanguage), appOptions.client);

				const webTrackerService = createWebTrackerService(parsedAppConfig.webTracker);

				appConfigStateHandler(parsedAppConfig);
				webTrackerServiceHandler(webTrackerService);

				if (appOptions.gyTesting) {
					const currentTimestamp = new Date().getTime();
					console.log(`
						Get-config request: ${afterRequestTimestamp - beforeRequestTimestamp}ms
						Create UI: ${currentTimestamp - afterRequestTimestamp}ms
						Widget appearing time: ${currentTimestamp - appOptions.initTimestamp}ms
					`);
				}
				if (parsedAppConfig.documentTitle) {
					document.title = parsedAppConfig.documentTitle;
				}
			} else {
				console.warn(`${appOptions.client} client is not ${data ? 'active' : 'found'}`);
			}
		});
	}, []);

	return appConfig ? (
		<ConfigContext.Provider
			value={{
				appOptions,
				appConfig,
			}}>
			<ServicesContext.Provider value={{ webTrackerService }}>
				<EventsServiceContextProvider clientOrganization={appOptions.client}>
					<LinksServiceContextProvider
						linkServiceConfig={{
							settings: {
								clientName: appOptions.client,
								host: getGyantEmbedHost(window.document),
								confirmOpenLink: appConfig.settings.confirmOpenLink,
								linkTarget: appConfig.settings.linkTarget,
								confirmOpenLinkLabel: t('confirmOpenLink'),
							},
							onOpenModal: handleModalUrlUpdate,
							onDispatchEvent: webTrackerService.sendEvent,
						}}>
						<App
							modalUrl={modalUrl}
							isModalOpen={isModalOpen}
							onCloseModal={() => handleModalOpenState(false)}
						/>
					</LinksServiceContextProvider>
				</EventsServiceContextProvider>
			</ServicesContext.Provider>
		</ConfigContext.Provider>
	) : null;
});

export const AppContainer: FC<InitialAppOption> = (props) => (
	<SessionContextProvider client={props.client || props.org}>
		<APIServiceProvider>
			<TranslationsContextProvider>
				<BrowserRouter>
					<Switch>
						<Route path={'/epic-scheduling/:type/:clientName'} component={EpicSchedulingContainer} />
						<Route
							path={'/blockit-scheduling/:clientName/:clientUserId'}
							component={BlockItSchedulingContainer}
						/>
						<Route exact path={'*'}>
							<AppContainerRaw {...props} />
						</Route>
					</Switch>
				</BrowserRouter>
			</TranslationsContextProvider>
		</APIServiceProvider>
	</SessionContextProvider>
);
