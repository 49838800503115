import { createContext, FC, useContext } from 'react';

import { constVoid, Effect } from '../../utils/function.utils';
import { APIServiceContext } from '../api-service/api-service.context';
import { SessionContext } from '../session.context';
import { EventAction, sendEventServiceAction } from './events-service.model';

export interface EventsServiceType {
	sendEvent: Effect<EventAction>;
}

export const EVENTS_SERVICE_DEFAULT: EventsServiceType = {
	sendEvent: constVoid,
};

export const EventsServiceContext = createContext<EventsServiceType>(EVENTS_SERVICE_DEFAULT);

interface EventsServiceContextProviderProps {
	clientOrganization: string;
}

export const EventsServiceContextProvider: FC<EventsServiceContextProviderProps> = ({
	clientOrganization,
	children,
}) => {
	const {
		state: { sessionToken },
	} = useContext(SessionContext);

	const { sendEvent: sendEventApi, sendAnonymousEvent: sendAnonymousEventApi } = useContext(APIServiceContext);

	const sendEvent = (action: EventAction) =>
		sendEventServiceAction(action, clientOrganization, sendEventApi, sendAnonymousEventApi, sessionToken);

	return <EventsServiceContext.Provider value={{ sendEvent }}>{children}</EventsServiceContext.Provider>;
};
