import { FC } from 'react';

import { useLiveChatSystemInfoStyles } from './typing-indicator.styles';

export const TypingIndicator: FC = () => {
	const classes = useLiveChatSystemInfoStyles();

	return (
		<div className={classes.root} data-testing-label={'live-chat-typing-indicator'}>
			<div className={classes.dot} />
			<div className={classes.dot} />
			<div className={classes.dot} />
		</div>
	);
};
