import { makeStyles } from '@material-ui/core';

import { sizes } from '../../theme/theme.model';

export const useCalendarDayStyles = makeStyles({
	root: {
		width: 43,
		maxWidth: 43,
		height: sizes.size_5,
		minHeight: sizes.size_5,
		padding: 0,
		'& > span': {
			paddingTop: 11,
		},
	},
	inActive: {
		'&.Mui-disabled': {
			background: 'none',
			'&:focus-visible': {
				background: 'none',
			},
		},
	},
	empty: {
		width: 43,
		height: 40,
	},
});
