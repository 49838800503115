import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useAudioStyles = makeStyles<Theme>(
	({
		palette: {
			colorScheme: {
				colorVariables: { colorPrimary },
			},
		},
	}) => ({
		audio: {
			width: '100%',
			'&:focus-visible': {
				outline: `2px solid ${colorPrimary}`,
				outlineOffset: 2,
				transition: 'outline 0s',
				boxShadow: 'none',
			},
		},
	}),
);
