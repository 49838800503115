import axios, { AxiosRequestConfig, Method } from 'axios';

import { getGyantEmbedHost, isEmbedWidget } from '../utils/embed.utils';

interface ApiParams<D> {
	url: string;
	method?: Method;
	data?: D;
	headers?: AxiosRequestConfig['headers'];
	configs?: AxiosRequestConfig;
	isExternalUrl?: boolean;
}

export const api = async <D, R>({ url, method, data, headers, configs, isExternalUrl }: ApiParams<D>): Promise<R> => {
	const requestUrl = isExternalUrl ? url : isEmbedWidget() ? `${getGyantEmbedHost(window.document)}${url}` : url;

	return await axios({
		method: method || 'GET',
		url: requestUrl,
		data,
		headers,
		...configs,
	})
		.then((data) => data.data)
		.catch((err) => {
			console.log('Request error: ', err);
			return Promise.reject(err.response);
		});
};

export const apiWithLogger = async <D, R>(args: ApiParams<D>): Promise<R> => {
	try {
		return await api(args);
	} catch (error) {
		const url = '/api/log';
		const requestUrl = isEmbedWidget() ? `${getGyantEmbedHost(window.document)}${url}` : url;
		const apiError: any = error;
		axios.post(requestUrl, {
			origin: 'API_ERROR',
			error: apiError?.response ? JSON.stringify(apiError.response) : 'Unknown error',
			payload: JSON.stringify(args.data || 'No data payload'),
		});
		return Promise.reject(error);
	}
};
