import { useMediaQuery } from '@material-ui/core';
import classNames from 'classnames';
import { FC, Fragment, useContext, useEffect, useState } from 'react';

import { ConfigContext } from '../../context/config.context';
import { EventsServiceContext } from '../../context/events-service/events-service.context';
import { LiveChatContext } from '../../context/live-chat-context';
import { CollapsedWidgetType } from '../../models/app.model';
import { MOBILE_MEDIA_QUERY, SMALL_HEIGHT_MEDIA_QUERY } from '../../models/dimensions.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { StartingAnimationUI } from '../../utils/animations.utils';
import { AvatarButton } from '../avatar-button/avatar-button.component';
import { CollapsedWidgetContainer } from '../collapsed-widget/collapsed-widget.container';
import { CollapsedWidgetButtonConfig } from '../collapsed-widget/collapsed-widget.model';
import { getLiveChatActiveLocalStorage, isFeedbackLiveChatSessionOn } from '../live-chat/live-chat.model';
import { getSavedSurvey } from '../user-survey/helfulness-survey.model';
import { HelpfulnessSurveyExternalContainer } from '../user-survey/helpfulness-survey-external/helpfulness-survey-external.container';
import { useCustomChatButtonStyles } from './widget-button.styles';

export interface WidgetButtonProps {
	isWidgetClosed: boolean;
	isDisabled: boolean;
	isWidgetOpenedOnce: boolean;
	logo?: string;
	type: CollapsedWidgetType;
	shouldHideCollapsedWidget?: boolean;
	buttonAnimation?: StartingAnimationUI;
	onClick(e: EventType, collapsedWidgetSelectedActionItem?: CollapsedWidgetButtonConfig): void;
}

export const WidgetButton: FC<WidgetButtonProps> = ({
	isWidgetClosed,
	isWidgetOpenedOnce,
	isDisabled,
	shouldHideCollapsedWidget = false,
	logo,
	type,
	onClick,
}) => {
	const {
		appOptions: { fullScreen, client, gyAutoOpen, externalSurveyData },
	} = useContext(ConfigContext);

	const { sendEvent } = useContext(EventsServiceContext);
	const {
		state: { isLiveChatShown, newMessagesIndex },
		onUpdate,
	} = useContext(LiveChatContext);

	const [isWidgetButtonVisible, setWidgetButtonVisibility] = useState(true);
	const [isMinimized, setIsMinimized] = useState(false);

	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
	const isSmallHeight = useMediaQuery(SMALL_HEIGHT_MEDIA_QUERY);
	const customStyles = useCustomChatButtonStyles({ isMobile, fullScreen });

	const buttonClassName = classNames(
		customStyles.collapsedChatButton,
		(isSmallHeight || isMobile || fullScreen) && !isWidgetClosed && customStyles.displayNone,
		isWidgetClosed ? 'closed' : classNames('open', customStyles.openedChatButton),
		!isWidgetButtonVisible && customStyles.hidden,
	);

	const handleAvatarClick = (e: EventType) => {
		if (isMinimized) {
			setIsMinimized(false);
			sessionStorage.removeItem(`gyant_minimized_${client}`);
			sendEvent({
				eventName: 'minimizeButtonInteraction',
				data: {
					elementName: 'Expand',
				},
			});
		}
		onClick(e);
	};

	const shouldShowExternalSurvey =
		!gyAutoOpen && !isWidgetOpenedOnce && !fullScreen && !getSavedSurvey(client) && externalSurveyData;

	useEffect(() => {
		if (isLiveChatShown) {
			onUpdate({ type: 'SET_ACTIVE_STATUS', payload: !isWidgetClosed });
			if (isWidgetClosed && newMessagesIndex) {
				onUpdate({ type: 'SET_NEW_MESSAGE_INDEX', payload: null });
			}
		}
	}, [isWidgetClosed]);

	const shouldDisplayCollapsedWidget =
		!shouldHideCollapsedWidget &&
		!getLiveChatActiveLocalStorage(client) &&
		!isFeedbackLiveChatSessionOn(client) &&
		(!externalSurveyData || getSavedSurvey(client) !== 0);

	return (
		<Fragment>
			{shouldShowExternalSurvey && (
				<HelpfulnessSurveyExternalContainer mode={'integrated'} externalSurveyData={externalSurveyData} />
			)}
			{shouldDisplayCollapsedWidget && (
				<CollapsedWidgetContainer
					isMinimized={isMinimized}
					isWidgetOpen={!isWidgetClosed}
					isWidgetOpenedOnce={isWidgetOpenedOnce}
					onClick={onClick}
					onMinimize={() => setIsMinimized(true)}
					onWidgetButtonHide={() => setWidgetButtonVisibility(false)}
				/>
			)}
			<AvatarButton
				hasNotifications={!!newMessagesIndex}
				isMinimized={isMinimized}
				isDisabled={isDisabled}
				size={type}
				logo={logo}
				isCollapsed={isWidgetClosed}
				onClick={handleAvatarClick}
				className={buttonClassName}
				tabIndex={0}
				id={'toggle-widget-button'}
				dataTestingLabel={'open-toggle-widget-btn'}
			/>
		</Fragment>
	);
};
