import { OutlinedInput, OutlinedInputProps } from '@material-ui/core';
import { ChangeEvent, FC, useCallback } from 'react';

import { Lazy } from '../../utils/function.utils';
import { useInputStyles } from './input.styles';

interface KeyboardEvent {
	key: string;
	preventDefault: Lazy<void>;
}
const FLOATING_NUMBER_SYMBOLS = ['.', 'e'];
const NUMBER_REGEXP = /^\d+$/;

type InputProps = OutlinedInputProps & {
	className?: string;
	textLength?: number;
	noFloatingNumbers?: boolean;
};

export const Input: FC<InputProps> = ({
	className,
	onChange,
	textLength = Number.MAX_SAFE_INTEGER,
	noFloatingNumbers,
	...props
}) => {
	const inputClasses = useInputStyles({ withLabel: !!props.label });
	const classes = { ...inputClasses, ...props.classes };

	const handleValueChange = useCallback(
		(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const value = e.currentTarget.value;

			if (value.length <= textLength) {
				onChange && onChange(e);
			}
		},
		[onChange, textLength],
	);

	const handleKey = useCallback(
		(event: KeyboardEvent): void => {
			if (
				event.key !== 'Enter' &&
				props.type === 'number' &&
				((noFloatingNumbers && FLOATING_NUMBER_SYMBOLS.includes(event.key)) || !NUMBER_REGEXP.test(event.key))
			) {
				event.preventDefault();
			}
		},
		[props.type, noFloatingNumbers],
	);

	return (
		<OutlinedInput
			{...props}
			onChange={handleValueChange}
			classes={classes}
			className={className}
			onKeyPress={handleKey}
		/>
	);
};
