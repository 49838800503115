import queryString from 'query-string';

import { AppOptions, DEFAULT_APP_OPTIONS, InitialAppOptionsKeys } from '../models/app.model';
import { DeepPartial } from './types.utils';

const isAllowedOptionParameter = (key: string): key is InitialAppOptionsKeys =>
	[...Object.keys(DEFAULT_APP_OPTIONS), 'language'].includes(key);

export const getUrlParameters = (searchString: string): DeepPartial<AppOptions> => {
	const parsedParameters = queryString.parse(searchString, { parseBooleans: true, parseNumbers: true });

	return Object.keys(parsedParameters)
		.filter(isAllowedOptionParameter)
		.reduce((obj, key) => {
			obj[key] = parsedParameters[key];
			return obj;
		}, {});
};
