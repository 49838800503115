import { SiteImproveTracker, Tracker } from '../../models/trackers.model';
import { BasicEventConfig } from './web-tracker-service.model';

interface SiteImproveEventConfig extends BasicEventConfig {
	trackerId: string;
}

export const isSiteImproveTracker = (tracker: Tracker): tracker is SiteImproveTracker => tracker.type === 'siteImprove';

export const initSiteImprove = (trackerId: string): void => {
	const script = document.createElement('script');
	script.async = true;
	script.src = `https://siteimproveanalytics.com/js/siteanalyze_${trackerId}.js`;
	document.head.prepend(script);
};

export const sendSiteImproveEvent = (eventConfig: SiteImproveEventConfig): void => {
	const { category, action, label, trackerId, logEventsToConsole } = eventConfig;
	if ((window as any)._sz) {
		(window as any)._sz.push(['event', category, action, label]);
	}

	if (logEventsToConsole) {
		const message = `Send Event: ${trackerId} ${category} ${action} ${label}`;
		console.log(`GyantTrackerSiteImproveHandler: ${message}`);
	}
};
