import classNames from 'classnames';
import { addMinutes } from 'date-fns';
import React, { FC, Fragment, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EventType } from '../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../ui-kit/button/button.component';
import { dateToFormattedTimeString, dateToFullDateStringFormat } from '../../../utils/time.utils';
import { Nullable } from '../../../utils/types.utils';
import {
	AppointmentDateTimeValues,
	createICSCalendarUrl,
	generateICSEventData,
	SummaryLocation,
	SummaryTemplates,
} from '../../appointment-scheduling/appointment-summary/appointment-summary.model';
import { AppointmentSummaryAdditionalInfo } from '../../appointment-scheduling/appointment-summary/summary-additional-info/summary-additional-info.component';
import { AppointmentSummaryContact } from '../../appointment-scheduling/appointment-summary/summary-contact/summary-contact.component';
import { AppointmentSummaryLocation } from '../../appointment-scheduling/appointment-summary/summary-location/summary-location.component';
import { getAppointmentDate } from '../appointment-manager.model';
import { CancelAppointment } from '../cancel-appointment/cancel-appointment.component';
import { useAppointmentDetailsStyles } from './appointment-details.style';

interface AppointmentDetailsProps {
	title: string;
	appointmentValues: AppointmentDateTimeValues;
	location?: Nullable<SummaryLocation>;
	contact?: Nullable<string>;
	templates: SummaryTemplates;
	isCancelable: boolean;
	isRescheduable: boolean;
	onClickLink?(url: string, event: EventType): void;
	onReschedule(): void;
	onCancel(reason: string): void;
	onAddToCalendar(): void;
}

export const AppointmentDetailsComponent: FC<AppointmentDetailsProps> = memo(
	({
		title,
		appointmentValues,
		location,
		contact,
		templates,
		isCancelable,
		isRescheduable,
		onClickLink,
		onReschedule,
		onCancel,
		onAddToCalendar,
	}) => {
		const { t } = useTranslation();
		const [isCancelAppointmentOpen, setIsCancelAppointmentOpen] = useState(false);
		const { appointmentDate, appointmentDuration } = appointmentValues;
		const { additionalInformation } = templates;
		const classes = useAppointmentDetailsStyles();

		const handleClickLink = (link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
			onClickLink && onClickLink(link, e);
		};

		const handleAddToCalendar = () => {
			const eventData = generateICSEventData(title, appointmentValues, templates, location);
			onAddToCalendar();
			window.open(createICSCalendarUrl(eventData));
		};

		const appointmentStartTime = getAppointmentDate(appointmentDate);

		const appointmentTimeWindow = appointmentDuration
			? `${dateToFormattedTimeString(appointmentStartTime)} - ${dateToFormattedTimeString(
					addMinutes(appointmentStartTime, appointmentDuration),
				)}`
			: dateToFormattedTimeString(appointmentStartTime);

		const primaryButtonClassNames = classNames(classes.button, classes.primaryButton);
		const cancelButtonClassNames = classNames(classes.button, classes.cancelButton);

		const renderInfo = () => (
			<Fragment>
				<span className={classes.title} data-testing-label={'appointment-details-title'}>
					{title}
				</span>
				<span className={classes.date}>{dateToFullDateStringFormat(appointmentStartTime)}</span>
				<span className={classes.time}>{appointmentTimeWindow}</span>
			</Fragment>
		);

		const renderContent = () => (
			<Fragment>
				{location && <AppointmentSummaryLocation location={location} onClickLink={handleClickLink} />}
				{contact && <AppointmentSummaryContact contact={contact} onClickLink={handleClickLink} />}
				{additionalInformation && (
					<AppointmentSummaryAdditionalInfo additionalInformation={additionalInformation} truncateInfoText />
				)}
			</Fragment>
		);

		const renderControls = () => (
			<div className={classes.options}>
				<Button
					className={primaryButtonClassNames}
					color={'secondary'}
					onClick={handleAddToCalendar}
					data-testing-label={'appointment-button-add-calendar'}
				>
					{t('addCalendar', 'Add to Calendar')}
				</Button>
				<Button
					className={primaryButtonClassNames}
					color={'secondary'}
					onClick={onReschedule}
					disabled={!isRescheduable}
					data-testing-label={'appointment-button-reschedule'}
				>
					{t('rescheduleAppointment', 'Reschedule Appointment')}
				</Button>
				<Button
					className={cancelButtonClassNames}
					color={'secondary'}
					onClick={() => setIsCancelAppointmentOpen(true)}
					disabled={!isCancelable}
					data-testing-label={'appointment-button-cancel'}
				>
					{t('cancelAppointment', 'Cancel Appointment')}
				</Button>
			</div>
		);

		const renderCancel = (): JSX.Element => (
			<CancelAppointment
				isOpen={isCancelAppointmentOpen}
				onCancel={onCancel}
				onClose={() => setIsCancelAppointmentOpen(false)}
			/>
		);

		return (
			<div className={classes.root} data-testing-label={'appointment-manager-details'}>
				{renderInfo()}
				{renderContent()}
				{renderControls()}
				{isCancelable && renderCancel()}
			</div>
		);
	},
);
