import { Nullable } from './types.utils';

export type Effect<A> = (a: A) => void;

export interface Lazy<A> {
	(): A;
}

export const constVoid: Lazy<void> = () => void 0;
export const constVoidAsync: Lazy<Promise<any>> = () => Promise.resolve(void 0);

export const useDefaultIfUndefined = <A>(defaultValue: A, value?: A): A => (value === undefined ? defaultValue : value);

export const wrapIntoArray = <A>(arg: A): A[] => [arg];

export const notNull = <A>(arg: Nullable<A>): arg is A => arg !== null;
