import { makeStyles } from '@material-ui/core';

export const useTooltipStyles = makeStyles(
	({
		palette: {
			colorScheme: { colorVariables },
		},
	}) => ({
		root: {
			'&:focus-visible': {
				outlineOffset: 2,
				outline: `2px solid ${colorVariables.colorPrimary}`,
			},
		},
	}),
);
