import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useHeaderStyles = makeStyles<Theme, { isMobile: boolean }>(({ palette: { colorScheme, sizes } }) => ({
	root: {
		background: colorScheme.colorVariables.colorBgSurface,
		zIndex: 4,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		padding: (props) =>
			props.isMobile
				? `${sizes.size_2}px ${sizes.size_2}px 0px ${sizes.size_2}px`
				: `${sizes.size_3}px ${sizes.size_3}px 0px ${sizes.size_3}px`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
}));
