import classNames from 'classnames';
import React, { FC, memo } from 'react';

import { Icon } from '../../../../ui-kit/icon/icon.component';
import { DirectionsIcon } from '../../../../ui-kit/icons/directions.icon';
import { LocationFilledIcon } from '../../../../ui-kit/icons/location-filled.icon';
import { Link } from '../../../../ui-kit/link/link.component';
import { handleKeyPress } from '../../../../utils/a11y.utils';
import { Effect } from '../../../../utils/function.utils';
import { getOrEmtyString } from '../../../../utils/option.utils';
import { AppointmentSumaryTrackerElementName } from '../../appointment-scheduling.model';
import { copyContentValue, generateMapsURL, getFormattedLocation, SummaryLocation } from '../appointment-summary.model';
import { useAppointmentSummaryStyles } from '../appointment-summary.styles';

interface AppointmentSummaryLocationProps {
	location: SummaryLocation;
	onClickLink(link: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
	onTrackevent?: Effect<AppointmentSumaryTrackerElementName>;
}

export const AppointmentSummaryLocation: FC<AppointmentSummaryLocationProps> = memo(
	({ location, onClickLink, onTrackevent }) => {
		const classes = useAppointmentSummaryStyles();
		const copiableClassNames = classNames(classes.info, classes.ableToCopy);

		const locationAddres = `${getOrEmtyString(location?.city)} ${getOrEmtyString(
			location?.stateCode,
		)} ${getOrEmtyString(location?.zip)}`;

		const locationInfoText = getFormattedLocation(location);
		const locationMapsHref = generateMapsURL(locationInfoText);

		const handleClickLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
			onTrackevent && onTrackevent('LOCATION_MAP');
			onClickLink(locationMapsHref, e);
		};

		const handleContentCopy = () => {
			copyContentValue(locationInfoText);
			onTrackevent && onTrackevent('LOCATION_TEXT');
		};
		return (
			<div className={classes.section}>
				<div className={classes.subIcon}>
					<Icon icon={LocationFilledIcon} size={'small'} iconType={'inputIcon'} />
				</div>
				<div
					aria-label={'Copy location text'}
					data-testing-label={'appointment-summary-location'}
					role={'button'}
					tabIndex={0}
					className={copiableClassNames}
					onClick={handleContentCopy}
					onKeyPress={handleKeyPress(handleContentCopy)}
				>
					{location.name && <p>{location.name}</p>}
					{location.street && <p>{location.street}</p>}
					<p>{locationAddres}</p>
				</div>
				<div>
					<Link
						data-testing-label={'appointment-summary-location-widget-button'}
						href={locationMapsHref}
						target={'_blank'}
						rel={'noreferrer'}
						onClick={handleClickLink}
						ariaLabel={'map'}
					>
						<Icon icon={DirectionsIcon} size={'medium'} iconType={'buttonIcon'} />
					</Link>
				</div>
			</div>
		);
	},
);
