import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingIndicator } from '../../../../../ui-kit/loading-indicator/loading-indicator.component';
import { useLocationFinderIndicatorStyles } from './location-finder-indicator.styles';

export const LocationFinderIndicator: FC = () => {
	const classes = useLocationFinderIndicatorStyles();
	const { t } = useTranslation();
	return (
		<div className={classes.root}>
			<div className={classes.findingIndicator}>
				<LoadingIndicator size={'large'} />
			</div>
			<div className={classes.label}>{t('gettingLocation', 'Getting location...')}</div>
		</div>
	);
};
