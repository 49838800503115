import { Predicate } from 'fp-ts/lib/function';

import { isNonEmpty } from './object.utils';

export const lastIndexOf = <T>(array: T[], predicate: Predicate<T>, index?: number): number => {
	const startingIndex = index ?? array.length - 1;
	for (let i = startingIndex; i >= 0; i--) {
		const item = array[i];

		if (predicate(item)) {
			return i;
		}
	}
	return -1;
};

export const renderArraySeparatedWithComma = (list: string[]): string =>
	list.length > 1 ? list.join(', ') : list.join();

export const filterEmptyObjects = <T extends Record<string, any>>(arr: T[]): T[] => arr.filter(isNonEmpty);

export const isEqualArrays = (arr1: unknown[], arr2: unknown[]): boolean =>
	JSON.stringify(arr1) === JSON.stringify(arr2);

export const reduceToLastElement = <T>(array: T[]): T[] => array.slice(-1);
