import React, { FC, memo } from 'react';

import { dateToFormattedTimeString, dateToFullDateStringFormat } from '../../../../utils/time.utils';
import { useAppointmentSummaryInfoStyles } from './summary-info.styles';

interface AppointmentSummaryInfoProps {
	appointmentDate: string;
	appointmentTime: string;
	providerNameLabel: string;
}

export const AppointmentSummaryInfo: FC<AppointmentSummaryInfoProps> = memo(
	({ appointmentDate, appointmentTime, providerNameLabel }) => {
		const classes = useAppointmentSummaryInfoStyles();

		const scheduledApointmentDate = new Date(`${appointmentDate.replace(/[/]/g, '-')}T${appointmentTime}`);

		return (
			<div className={classes.mainSubSection}>
				<div className={classes.scheduleTime}>
					<p>{dateToFormattedTimeString(scheduledApointmentDate)}</p>
					<p>{dateToFullDateStringFormat(scheduledApointmentDate)}</p>
				</div>
				<p className={classes.providerName}>{providerNameLabel}</p>
			</div>
		);
	},
);
