import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { feedbackCSSTransitionStyles, useLiveChatFeedbackStyles } from './feedback.styles';

export interface LiveChatFeedbackProps {
	question: ReactNode;
	shouldAnimateFeedback: boolean;
}

const timeoutValue = {
	enter: 600,
	exit: 600,
};

export const LiveChatFeedback: FC<LiveChatFeedbackProps> = ({ question, shouldAnimateFeedback }) => {
	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	const classes = useLiveChatFeedbackStyles({ oldElementHeight: height });
	const animation = feedbackCSSTransitionStyles({ timeout: timeoutValue });

	useEffect(() => {
		setHeight(ref?.current?.offsetHeight ? ref?.current?.offsetHeight + 18 : 18);
	}, [question]);

	return (
		<div className={classes.root}>
			<CSSTransition
				in={shouldAnimateFeedback}
				classNames={animation}
				mountOnEnter
				unmountOnExit
				timeout={timeoutValue}>
				<div ref={ref}>{question}</div>
			</CSSTransition>
		</div>
	);
};
