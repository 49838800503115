import { SendEventApi } from '../../context/api-service/api-service.model';
import { sendEventServiceAction } from '../../context/events-service/events-service.model';

type TrackingSurveyType = 'helpful' | 'unhelpful';

export const getTrackingRating = (value: number): TrackingSurveyType => {
	const emojis = {
		1: 'unhelpful',
		2: 'helpful',
	};
	return emojis[value];
};

export const sendFeedbackSurvey = (
	eventName: 'HELPFULNESS_SURVEY_EXTERNAL' | 'HELPFULNESS_SURVEY',
	rating: number,
	flowStep: string,
	client: string,
	sessionToken: string,
	onEvent: SendEventApi,
	sendAnonymousEvent: SendEventApi,
): void => {
	const payload = {
		eventName,
		data: {
			elementName: getTrackingRating(rating),
			flowStep,
		},
	};
	sendEventServiceAction(payload, client, onEvent, sendAnonymousEvent, sessionToken);
};

export const getHelpfulnessSurveySessionName = (client: string): string => `gyant-helpfulness-survey_${client}`;

export const getSavedSurvey = (client: string): number =>
	Number(sessionStorage.getItem(getHelpfulnessSurveySessionName(client)));

export const setSavedSurvey = (client: string, rating: number): void => {
	sessionStorage.setItem(getHelpfulnessSurveySessionName(client), rating.toString());
};

export const THUMBS_ANIMATION_TIME = 1000;

export const SURVEY_EXTERNAL_TIMEOUT = 200;
