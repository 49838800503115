import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageAbbreviation, LanguageName } from '../../models/languages.model';
import { Effect } from '../../utils/function.utils';
import { ChipMultiselect } from '../chip-multiselect/chip-multiselect.component';
import { useLanguageSelectorStyles } from './language-selector.styles';

export interface LanguageSelectorItem {
	language: LanguageAbbreviation;
	label: LanguageName;
}

interface LanguageSelectorProps {
	languages: LanguageSelectorItem[];
	selectedLanguage: LanguageAbbreviation;
	onLanguageChange: Effect<LanguageAbbreviation>;
	isDisabled: boolean;
}

export const LanguageSelector: FC<LanguageSelectorProps> = memo(
	({ languages, selectedLanguage, onLanguageChange, isDisabled }) => {
		const classes = useLanguageSelectorStyles();
		const { t } = useTranslation();

		return (
			<div className={classes.root}>
				<ul className={classes.list} role={'menu'} id={'languages-menu'}>
					<h3 className={classes.title}>{t('languageSelectorTitle', 'Language')}</h3>
					{languages.map((language) => {
						const isSelected = language.language === selectedLanguage;
						return (
							<li className={classes.listItem} aria-label={language.label} key={language.language}>
								<ChipMultiselect
									dataTestingLabel={`language-selector-language-item-${language.language}${
										isSelected ? '-selected' : ''
									}`}
									label={language.label}
									onClick={() => {
										if (!isSelected && !isDisabled) {
											onLanguageChange(language.language);
										}
									}}
									isSelected={isSelected}
									isFullWidth
									isDisabled={isDisabled}
								/>
							</li>
						);
					})}
				</ul>
			</div>
		);
	},
);
