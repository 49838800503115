import { WithStyles, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useRemainingTimeIndicatorStyles } from './remaining-time-indicator.styles';

type ProgressBarProps = {
	timeRemaining: number;
} & WithStyles<typeof useRemainingTimeIndicatorStyles>;

export const RemainingTimeIndicator = withStyles(useRemainingTimeIndicatorStyles)(({
	timeRemaining,
	classes,
}: ProgressBarProps) => {
	const { t } = useTranslation();
	const minutes = timeRemaining / 60;
	const remainingTimeLabel =
		minutes < 1
			? t('progressBarRemainingTimeLessThanMinute', 'Less than 1 min left')
			: t('progressBarRemainingTime', { minutes: Math.ceil(minutes) });
	return (
		<div className={classes.root} data-testing-label={'remaining-time-indicator'}>
			{remainingTimeLabel}
		</div>
	);
});
