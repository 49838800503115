import { FC, Fragment, memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { LiveChatConfigResponseModel } from '../../context/api-service/api-service.model';
import { ConfigContext } from '../../context/config.context';
import { DocumentContext } from '../../context/document.context';
import { LiveChatContext } from '../../context/live-chat-context';
import { SessionContext } from '../../context/session.context';
import { isLongHistoryLiveChat } from '../../models/conversation.model';
import { toLocale } from '../../models/languages.model';
import {
	isMessageWithLiveChatAgentInfo,
	isMessageWithStartTypingTimeoutMetadata,
	isQuickResponseMessage,
	Message,
} from '../../models/message.model';
import { lastIndexOf } from '../../utils/array.utils';
import { Lazy } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';
import { QuickResponseRenderer } from '../message-renderers/quick-responses-renderer/quick-responses-renderer.component';
import { TextRenderer } from '../message-renderers/text-renderer/text-renderer.component';
import { LiveChatConfirmationModal } from './components/confirmation-modal/confirmation-modal.component';
import { LiveChatComponent } from './live-chat.component';
import {
	getFeedBackQuestion,
	getIsAgentTypingIndicator,
	getMessagesForChat,
	isAgentConnectedMessage,
	isAgentJoinedMessage,
	isAgentJoinedMessageExists,
	isAgentLeftMessage,
	isChatFinishedByAgentMessage,
	isFeedbackLiveChatSessionOn,
	isLeaveChatCommand,
	isLeaveQueueCommand,
	isLiveChatConnectionErrorSet,
	isNonTypingCommand,
	LiveChatLocalTemplates,
	prepareLiveChatUIMessages,
	setFeedbackQuestionsStep,
	setLiveChatActiveLocalStorage,
	setLiveChatConnectionError,
} from './live-chat.model';

let typingIndicatorTimeout: NodeJS.Timeout;
export const LiveChatContainer: FC = memo(() => {
	const {
		appOptions: { client },
	} = useContext(ConfigContext);

	const {
		state: {
			config,
			messages,
			agentData,
			status,
			confirmationModal,
			isLiveChatShown,
			isLiveChatSessionOver,
			isConnectivityLost,
			isLeavingLiveChat,
			isLiveChatActive,
			newMessagesIndex,
		},
		onUpdate,
	} = useContext(LiveChatContext);

	const { document } = useContext(DocumentContext);
	const {
		state: { onSendMessage },
	} = useContext(SessionContext);
	const { getLiveChatConfig } = useContext(APIServiceContext);
	const {
		i18n: { language },
		t,
	} = useTranslation();

	const locale = toLocale(language);

	const [prevMessagesLength, setPrevMessagesLength] = useState(0);
	const [shouldAnimateFeedback, setShouldAnimateFeedback] = useState(false);
	const [feedbackQuestion, setFeedbackQuestion] = useState<Nullable<JSX.Element>>();
	const [savedQuestions, setSavedQuestions] = useState<Message[]>();
	const [isAgentTyping, setIsAgentTyping] = useState(false);

	const clearTypingIndicator = () => {
		setIsAgentTyping(false);
	};

	useEffect(() => {
		const isTyping = getIsAgentTypingIndicator(messages);
		setIsAgentTyping(isTyping);

		if (!isTyping && typingIndicatorTimeout) {
			clearTimeout(typingIndicatorTimeout);
		}

		const lastMessage = messages[messages.length - 1];

		if (isMessageWithStartTypingTimeoutMetadata(lastMessage)) {
			if (typingIndicatorTimeout) {
				clearTimeout(typingIndicatorTimeout);
			}
			const timeout = lastMessage.metadata.data.timeout;
			typingIndicatorTimeout = setTimeout(clearTypingIndicator, timeout);
		}
	}, [messages]);

	useEffect(() => {
		const isLiveChatConnectionLostInSessionStorage = isLiveChatConnectionErrorSet(client);

		getLiveChatConfig({ client, locale })
			.then((config) => {
				onUpdate({
					type: 'CUSTOM',
					payload: { config, isConnectivityLost: isLiveChatConnectionLostInSessionStorage },
				});
			})
			.catch(() => console.error('Error while fetching Live chat config.'));
	}, []);

	useEffect(() => {
		if (isConnectivityLost) {
			setLiveChatConnectionError(client);
		}
	}, [isConnectivityLost]);

	useEffect(() => {
		const isLiveChatSessionOverInSessionStorage = isFeedbackLiveChatSessionOn(client);

		if (status === 'WAITING') {
			setLiveChatActiveLocalStorage(client, true);
		}

		if (!isLiveChatShown) {
			onUpdate({
				type: 'ACTIVE_STATE',
				payload: true,
			});
		}

		if (isAgentLeftMessage(messages)) {
			onUpdate({
				type: 'CUSTOM',
				payload: { agentData: { ...agentData, isConnected: false }, status: 'TRANSFERING' },
			});
		}

		if ((status === 'TRANSFERING' && isAgentJoinedMessage(messages)) || isLongHistoryLiveChat(messages)) {
			onUpdate({
				type: 'STATUS',
				payload: 'CONNECTED',
			});
		}
		// if chat session is over and we do a refresh
		if (isLiveChatSessionOver && status === 'WAITING') {
			onUpdate({
				type: 'CUSTOM',
				payload: {
					agentData: { ...agentData, isConnected: false },
					status: 'CONNECTED',
				},
			});
		}
		if (!isLiveChatActive && !newMessagesIndex) {
			// set the index of the unread message
			const numberOfNewMessages = messages.length - prevMessagesLength;
			const newMessages = messages.slice(-numberOfNewMessages);
			const agentNonTypingMessage = newMessages.findIndex(isNonTypingCommand);

			if (agentNonTypingMessage !== -1) {
				onUpdate({
					type: 'SET_NEW_MESSAGE_INDEX',
					payload: prevMessagesLength + agentNonTypingMessage,
				});
			}
		}
		if (
			isLeaveChatCommand(messages[messages.length - 1]?.text || '') ||
			isChatFinishedByAgentMessage(messages) ||
			isConnectivityLost
		) {
			setFeedbackQuestionsStep('on', client);
			onUpdate({
				type: 'CUSTOM',
				payload: {
					agentData: { ...agentData, isConnected: false },
					isLiveChatSessionOver: true,
					status: 'CONNECTED',
				},
			});
		}
		if (isLeaveQueueCommand(messages[messages.length - 1]?.text || '')) {
			setLiveChatActiveLocalStorage(client, false);
			onUpdate({
				type: 'LEAVE',
			});
		}

		if (status === 'WAITING' && (isAgentJoinedMessageExists(messages) || isAgentConnectedMessage(messages))) {
			setLiveChatActiveLocalStorage(client, true);
			onUpdate({ type: 'STATUS', payload: 'CONNECTED' });
		}
		const lastMessageWithDoctorInfoIndex = lastIndexOf(
			messages,
			(m) => m.type === 'text' && !!m.doctorInfo && (!!m.doctorInfo.firstName || !!m.doctorInfo.displayName),
		);
		const liveChatMessageWithAgentInfo = messages[lastMessageWithDoctorInfoIndex];

		if (liveChatMessageWithAgentInfo && isMessageWithLiveChatAgentInfo(liveChatMessageWithAgentInfo)) {
			setLiveChatActiveLocalStorage(client, true);
			if (agentData.doctorId === 'INITIAL' || liveChatMessageWithAgentInfo.doctorId !== agentData.doctorId) {
				onUpdate({
					type: 'AGENT_INFO',
					payload: {
						doctorId: liveChatMessageWithAgentInfo.doctorId,
						isConnected: !isLiveChatSessionOverInSessionStorage,
						...liveChatMessageWithAgentInfo.doctorInfo,
					},
				});
			}
		}
		if (!isLiveChatSessionOver) {
			if (isLiveChatSessionOverInSessionStorage) {
				onUpdate({
					type: 'SET_SESSION_OVER_STATUS',
					payload: true,
				});
			}
			setPrevMessagesLength(messages.length);
		}
	}, [messages, status]);

	const leaveLiveChat = () => {
		onUpdate({ type: 'LEAVING' });
		setFeedbackQuestionsStep('off', client);
		setLiveChatConnectionError(client, true);
		setTimeout(() => {
			onUpdate({ type: 'LEAVE' });
		}, 800);
	};

	const closeConfirmationModalHandler = () => {
		onUpdate({ type: 'CONFIRMATION_MODAL', payload: null });
	};
	const onAcceptConfirmationModalHandler = (action: Lazy<void>) => () => {
		action();
		closeConfirmationModalHandler();
	};
	const container = document?.getElementById('chatWrapper');

	const getFeedbackComponent = (questions: Message[]): JSX.Element | null => {
		const [textMessage, QRMessage] = questions;
		const isLastFeedbackQuestion = textMessage?.metadata?.status === 'lastLiveChatFeedbackMessage';

		if (questions.length && isQuickResponseMessage(QRMessage)) {
			const questionResponseHandler = (
				text: string,
				originalText?: string,
				shouldShowInWidget?: boolean,
				isUndoAction?: boolean,
			) => {
				onUpdate({ type: 'SET_NEW_MESSAGE_INDEX', payload: null });
				isLastFeedbackQuestion ? setTimeout(() => leaveLiveChat(), 200) : setShouldAnimateFeedback(false);
				return onSendMessage(text, originalText, shouldShowInWidget, isUndoAction);
			};

			return (
				<Fragment>
					{textMessage && (
						<TextRenderer
							message={textMessage.text}
							flowStep={textMessage.flowStep}
							isUserMessage={false}
							isUndoable={false}
						/>
					)}
					<QuickResponseRenderer
						key={QRMessage.text}
						message={QRMessage}
						onResponse={questionResponseHandler}
					/>
				</Fragment>
			);
		}
		return null;
	};

	useEffect(() => {
		if (isLiveChatSessionOver) {
			const questions = getFeedBackQuestion(messages);
			if (questions && (questions !== savedQuestions || isConnectivityLost)) {
				setSavedQuestions(questions);
				setShouldAnimateFeedback(true);
				setFeedbackQuestion(getFeedbackComponent(questions));
			}
			setLiveChatActiveLocalStorage(client, false);
		}
	}, [messages, isLiveChatSessionOver, isConnectivityLost]);

	// if chat is in feedback question step, do not show more messages in chat, only in feedback component
	const messagesForChat = getMessagesForChat(messages, isLiveChatSessionOver, prevMessagesLength);

	const getTemplates = (configTemplates?: LiveChatConfigResponseModel['templates']): LiveChatLocalTemplates => ({
		agentEnteredChat: configTemplates?.agentEnteredChat || 'has entered the chat',
		agentLeftChat: configTemplates?.agentLeftChat || 'left the chat',
		startedChat: t('liveChatStartedMessage', 'You started a chat'),
		endedChat: t('liveChatEndedMessage', 'Chat ended'),
		startingChatDescription:
			configTemplates?.startingChatDescription ||
			t(
				'liveChatStartingDescription',
				'Use live chat to ask any questions. It usually takes a few minutes to connect.',
			),
	});

	const templates = getTemplates(config?.templates);

	return (
		<Fragment>
			{container && confirmationModal && (
				<LiveChatConfirmationModal
					container={container}
					title={t('liveChatConfirmationModalTitle', 'Would you like to end Live Chat?')}
					description={t(
						'liveChatConfirmationModalDescription',
						'Restarting the virtual assistant will end Live Chat.',
					)}
					isOpen
					onAccept={onAcceptConfirmationModalHandler(confirmationModal.action)}
					onDecline={closeConfirmationModalHandler}
				/>
			)}
			<LiveChatComponent
				messages={prepareLiveChatUIMessages(messagesForChat, agentData, newMessagesIndex, templates)}
				feedBackQuestion={feedbackQuestion}
				isTyping={isAgentTyping}
				shouldAnimateFeedback={shouldAnimateFeedback}
				isWaiting={status === 'WAITING'}
				isLeavingLiveChat={isLeavingLiveChat}
				isLiveChatSessionOver={isLiveChatSessionOver}
				startingChatDescription={templates.startingChatDescription}
			/>
		</Fragment>
	);
});
