import { makeStyles } from '@material-ui/core';

export const useResponseWrapperStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	button: {
		marginTop: 12,
	},
});
