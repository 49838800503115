import { makeStyles } from '@material-ui/core';

export const useLiveChatSystemInfoStyles = makeStyles(
	({
		palette: {
			sizes,
			borderRadius,
			colorScheme: { colorVariables },
		},
	}) => ({
		'@keyframes blinker': {
			'20%': {
				opacity: 0.2,
			},
			'40%': {
				opacity: 1,
			},
			'60%': {
				opacity: 0.2,
			},
			'80%': {
				opacity: 1,
			},
		},
		root: {
			width: sizes.size_8,
			maxWidth: sizes.size_8,
			height: sizes.size_5,
			maxHeight: sizes.size_5,
			backgroundColor: colorVariables.colorInactive,
			padding: `${sizes.sizeHalf}px ${sizes.size_2}px`,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: borderRadius.button,
		},
		dot: {
			backgroundColor: colorVariables.colorNote,
			width: 7,
			height: 7,
			borderRadius: '50%',
			animation: '$blinker 1.3s linear infinite',
			'&:not(:last-child)': {
				marginRight: 5,
			},
			'&:nth-child(2)': {
				animationDelay: '0.1s',
			},
			'&:nth-child(3)': {
				animationDelay: '0.2s',
			},
		},
	}),
);
