import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useDoctorSearchCardStyles = makeStyles<Theme>(({ palette: { sizes }, typography: { smallViewPort } }) => ({
	root: {
		textAlign: 'center',
		height: 'initial',
	},
	ratingRoot: {
		justifyContent: 'center',
	},
	additionalInfoRoot: {
		alignItems: 'center',
	},
	providerTitle: {
		margin: 0,
		...smallViewPort.typeHeading1,
	},
	providerImage: {
		width: sizes.size_10,
		height: sizes.size_10,
	},
	specialitiesRoot: {
		...smallViewPort.body,
	},
}));
export const useDoctorSearchDetailsStyles = makeStyles<Theme>(
	({
		palette: {
			sizes,
			colorScheme: { colorVariables },
		},
		typography: { smallViewPort },
	}) => ({
		additionalInfoList: {
			listStyleType: 'none',
			margin: 0,
			padding: `${sizes.size_3}px 0 0`,
		},
		locationItemRoot: {
			display: 'flex',
			padding: `${sizes.size_2}px ${sizes.size_1}px`,
			borderTop: `1px solid ${colorVariables.colorInactive}`,
			'&:last-child': {
				borderBottom: `1px solid ${colorVariables.colorInactive}`,
			},
			...smallViewPort.typeCaption1,
		},
		listItem: {
			paddingLeft: sizes.size_2,
			flex: 1,
		},
		locationItemDistance: {
			fontStyle: 'italic',
			...smallViewPort.typeNoteParagraph,
		},
	}),
);
