import { makeStyles } from '@material-ui/core';

export const useErrorStateStyles = makeStyles(
	({ palette: { sizes, colorScheme, borderRadius }, typography: { smallViewPort } }) => ({
		root: {
			background: colorScheme.colorVariables.colorSysBgDefault,
			borderRadius: borderRadius.card,
			textAlign: 'center',
			padding: `${sizes.size_3}px ${sizes.size_4}px ${sizes.size_4}px`,
			margin: `${sizes.size_3}px ${sizes.size_1}%`,
			display: 'grid',
			'& > svg': {
				margin: '0 auto',
			},
			...smallViewPort.typeHeading1,
		},
		paddingWrapper: {
			paddingTop: sizes.size_1,
		},
		header: {
			...smallViewPort.typeSysHeading1,
			paddingTop: sizes.size_1,
		},
		text: {
			...smallViewPort.typeSysText1,
		},
		defaultButton: {
			marginTop: sizes.size_1,
			padding: 0,
			background: 'unset',
			boxShadow: 'unset',
			width: 'fit-content',
			margin: '0 auto',
			'&:hover': {
				background: 'unset',
				boxShadow: 'unset',
			},
		},
		link: {
			...smallViewPort.typeSysLink1,
		},
	}),
);
