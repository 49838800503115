import classNames from 'classnames';
import { FC } from 'react';

import { Button } from '../../../../ui-kit/button/button.component';
import { Lazy } from '../../../../utils/function.utils';
import { useLiveChatSystemInfoStyles } from './sytem-info.styles';

interface SystemInfoActionData {
	label: string;
	onAction: Lazy<void>;
}
export interface SystemInfoProps {
	text: string;
	isUnreadMessage?: boolean;
	action?: SystemInfoActionData;
	className?: string;
}

export const SystemInfo: FC<SystemInfoProps> = ({ text, action, isUnreadMessage, className }) => {
	const classes = useLiveChatSystemInfoStyles();
	const rootClassName = classNames(classes.root, className);

	return (
		<div className={rootClassName} data-testing-label={'live-chat-system-message'}>
			{isUnreadMessage && <span className={classes.unreadMessage} data-testing-label={'unread-message-sign'} />}
			<h5 className={classes.text}>{text}</h5>
			{action && (
				<Button variant={'text'} size={'medium'} onClick={action.onAction} className={classes.actionButton}>
					{action.label}
				</Button>
			)}
		</div>
	);
};
