import { FC, useContext } from 'react';

import { ServicesContext } from '../../context/services.context';
import { MenuItemAction } from '../../models/menu.model';
import { Button } from '../button/button.component';
import { useActionMenuStyle } from './actions-menu.styles';

interface ActionsMenuProps {
	menuItems: MenuItemAction[];
	onMenuSelect: (text: string, orininalText: string, shouldShowInWidget?: boolean) => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({ menuItems, onMenuSelect }) => {
	const {
		webTrackerService: { sendEvent },
	} = useContext(ServicesContext);

	const menuStyle = useActionMenuStyle();

	const getButtonColor = (index: number) => (!index ? 'primary' : 'secondary');

	const createItems = (menuItems: MenuItemAction[]): JSX.Element[] =>
		menuItems.map((menuItem, index) => {
			switch (menuItem.type) {
				case 'postback':
					return (
						<li className={menuStyle.itemStyle} key={menuItem.title}>
							<Button
								color={getButtonColor(index)}
								className={menuStyle.buttonStyle}
								size={'medium'}
								data-testing-label={`menu-item-postback-${menuItem.payload}`}
								aria-label={menuItem.title}
								onClick={(event) => {
									sendEvent('onMenuOptionsClick', event);
									onMenuSelect(menuItem.payload, menuItem.title, menuItem.showInChat);
								}}
							>
								{menuItem.title}
							</Button>
						</li>
					);
				case 'web_url':
					return (
						<li className={menuStyle.itemStyle} key={menuItem.title}>
							<Button
								color={getButtonColor(index)}
								className={menuStyle.buttonStyle}
								size={'medium'}
								data-testing-label={`menu-item-web_url_${menuItem.url}`}
								aria-label={menuItem.title}
								onClick={(event: any) => {
									sendEvent('onMenuOptionsClick', event);
									window.open(menuItem.url, '_blank');
								}}
							>
								{menuItem.title}
							</Button>
						</li>
					);
			}
		});

	const menu = createItems(menuItems);

	return (
		<ul data-testing-label={'action-menu-list'} className={menuStyle.root}>
			{menu}
		</ul>
	);
};
