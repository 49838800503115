import DateFnsUtils from '@date-io/date-fns';
import { DatePicker as MUIDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { WrapperVariant } from '@material-ui/pickers/wrappers/Wrapper';
import classNames from 'classnames';
import { format } from 'date-fns';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { constVoid, Effect } from '../../utils/function.utils';
import i18n from '../../utils/i18.utils';
import {
	dateToFormattedDateString,
	getFullDateStringFormatForCurrentLocale,
	getTimeLocale,
	getUTCDate,
} from '../../utils/time.utils';
import { Nullable } from '../../utils/types.utils';
import { Icon } from '../icon/icon.component';
import { ArrowDownIcon } from '../icons/arrow-down.icon';
import { CalendarLoadingLoading } from '../icons/calendar-loading';
import { CalendarIcon } from '../icons/calendar.icon';
import { CalendarDay, DayStatus } from './calendarDay/calendar-day.component';
import { useInlineDatePickerStyles, usePopoverStyles } from './date-picker.styles';

interface DatePickerProps {
	selectedDate: Nullable<Date>;
	availableDays: string[];
	noAvailableDaysLabel?: string;
	isDisabled: boolean;
	isNextMonthAvailable: boolean;
	variant?: WrapperVariant;
	onDateChage: Effect<Date>;
	onMonthChange(date: Date): Promise<void>;
	onStateChange?: Effect<boolean>;
	language?: string;
	label?: string;
}

const getDayStatus = (
	day: Nullable<Date>,
	selectedDate: Nullable<Date>,
	dayInCurrentMonth: boolean,
	availableDays: string[],
): DayStatus => {
	const currentDate = getUTCDate(new Date());
	if (day && dayInCurrentMonth) {
		if (availableDays.includes(format(day, 'dd')) && day.getTime() >= currentDate.getTime()) {
			if (selectedDate?.toDateString() === day.toDateString()) {
				return 'SELECTED';
			}
			if (currentDate.toDateString() === day.toDateString()) {
				return 'TODAY';
			}
			return 'ACTIVE';
		}
		return 'INACTIVE';
	}
	return 'EMPTY';
};

export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>(
	(
		{
			selectedDate,
			availableDays,
			noAvailableDaysLabel,
			isDisabled,
			language = 'en',
			label,
			isNextMonthAvailable,
			onStateChange = constVoid,
			onDateChage,
			onMonthChange,
		},
		ref,
	) => {
		const [isPickerOpen, setIsPickerOpenState] = useState(false);
		const [isMonthLoading, setIsMonthLoadingState] = useState(false);

		const { t } = useTranslation();

		const classes = useInlineDatePickerStyles({ isPickerOpen, isDisabled });

		const popoverClasses = usePopoverStyles();

		const handleDateChange = (value: Nullable<Date>) => {
			if (value && availableDays.includes(format(value, 'dd'))) {
				onDateChage(value);
				setIsPickerOpenState(false);
			}
		};

		const handleMonthChange = (date: Nullable<Date>): Promise<any> => {
			setIsMonthLoadingState(true);
			return date ? onMonthChange(date) : new Promise(constVoid);
		};

		const handleOpenStateChange = (state: boolean) => {
			setIsPickerOpenState(state);
			onStateChange(state);
		};

		const renderDay = (day: Nullable<Date>, selectedCalendarDate: Nullable<Date>, dayInCurrentMonth: boolean) =>
			day ? (
				<CalendarDay
					data-testing-label={`calendar-day-${dateToFormattedDateString(day)}`}
					status={getDayStatus(day, selectedDate, dayInCurrentMonth, availableDays)}
					date={day}
					onClick={handleDateChange}
				/>
			) : (
				<CalendarDay status={'EMPTY'} date={getUTCDate(new Date())} onClick={handleDateChange} />
			);
		const inputClassName = classNames(!selectedDate && classes.noDateSelected);
		const containerClassNames = classNames(classes.pickerContainer, isDisabled && classes.disabled);
		const arrowIconClassName = classNames(classes.arrowIcon, isPickerOpen && classes.arrowUpIcon);

		useEffect(() => {
			setIsMonthLoadingState(false);
		}, [availableDays]);

		const emptyLabel = t('date', 'Date');
		const rootClassName = classNames(
			classes.datePickerRoot,
			!(label && selectedDate) && classes.datePickerEmptyRoot,
		);

		const dateFormatted = useMemo(getFullDateStringFormatForCurrentLocale, [i18n.language]);

		return (
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={getTimeLocale(language)}>
				<div className={containerClassNames} data-testing-label={'calendar-widget-wrapper'}>
					<Icon
						icon={CalendarIcon}
						size={'large'}
						iconType={isDisabled ? 'decorIcon' : 'buttonIcon'}
						alt={'date picker icon'}
						className={classes.calendarIcon}
					/>
					<Icon
						icon={ArrowDownIcon}
						iconType={'decorIcon'}
						alt={'arrow date picker icon'}
						className={arrowIconClassName}
					/>
					<MUIDatePicker
						data-testing-label={'date-picker-root'}
						label={selectedDate ? label : undefined}
						disabled={isDisabled}
						autoOk
						disablePast
						allowKeyboardControl
						disableToolbar
						format={dateFormatted}
						loadingIndicator={<CalendarLoadingLoading className={classes.calendarLoading} />}
						onOpen={() => handleOpenStateChange(true)}
						onClose={() => handleOpenStateChange(false)}
						className={rootClassName}
						emptyLabel={emptyLabel}
						value={selectedDate}
						onChange={constVoid}
						onMonthChange={handleMonthChange}
						inputVariant={'outlined'}
						variant={'inline'}
						renderDay={renderDay}
						minDateMessage={t('datePickerMinDateErrorLabel', 'Selected date can’t be before today')}
						leftArrowIcon={
							<Icon
								dataTestingLabel={'date-picker-prev-month-widget-button'}
								icon={ArrowDownIcon}
								size={'large'}
								iconType={'buttonIcon'}
								alt={'previous month icon'}
								className={classes.arrowLeft}
							/>
						}
						rightArrowIcon={
							<Icon
								dataTestingLabel={`date-picker-next-month-widget-button${
									!isNextMonthAvailable ? '-disabled' : ''
								}`}
								icon={ArrowDownIcon}
								size={'large'}
								iconType={'buttonIcon'}
								alt={'next month icon'}
								className={classes.arrowRight}
							/>
						}
						leftArrowButtonProps={{
							'aria-label': 'previous month',
							disableRipple: true,
							classes: {
								root: classNames(
									classes.monthArrow,
									classes.buttonArrowLeft,
									isMonthLoading && classes.disabledMonthButton,
								),
							},
						}}
						rightArrowButtonProps={{
							'aria-label': 'next month',
							disableRipple: true,
							classes: {
								root: classNames(
									classes.monthArrow,
									classes.buttonArrowRight,
									(isMonthLoading || !isNextMonthAvailable) && classes.disabledMonthButton,
								),
							},
						}}
						inputProps={{
							className: inputClassName,
							ref,
						}}
						PopoverProps={{
							open: isPickerOpen,
							disableScrollLock: true,
							classes: popoverClasses,
							anchorOrigin: {
								vertical: 'bottom',
								horizontal: 'center',
							},
							transformOrigin: {
								vertical: 'top',
								horizontal: 'center',
							},
						}}
					/>
					{!isDisabled && !availableDays.length && noAvailableDaysLabel && (
						<span
							className={classes.noAvailableSlotsLabel}
							data-testing-label={'date-picker-no-available-days-label'}
						>
							{noAvailableDaysLabel}
						</span>
					)}
				</div>
			</MuiPickersUtilsProvider>
		);
	},
);
