import classNames from 'classnames';
import { FC, FormEvent, ReactNode } from 'react';

import { Lazy } from '../../utils/function.utils';
import { Button } from '../button/button.component';
import { useResponseWrapperStyles } from './response-wrapper.styles';

interface ResponseWrapperProps {
	children: ReactNode;
	isDisabled: boolean;
	buttonLabel: string;
	className?: string;
	dataTestingLabel?: string;
	onSubmit: Lazy<void>;
}

export const ResponseWrapper: FC<ResponseWrapperProps> = ({
	className,
	isDisabled,
	buttonLabel,
	dataTestingLabel,
	children,
	onSubmit,
}) => {
	const classes = useResponseWrapperStyles();

	const handleFormSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		onSubmit();
	};

	return (
		<form
			onSubmit={handleFormSubmit}
			className={classNames(classes.root, className)}
			data-testing-label={dataTestingLabel}
		>
			{children}
			<Button
				type={'submit'}
				color={'primary'}
				disabled={isDisabled}
				data-testing-label={'response-type-send-button'}
				className={classes.button}
			>
				{buttonLabel}
			</Button>
		</form>
	);
};
