import { useMediaQuery, useTheme } from '@material-ui/core';
import classNames from 'classnames';
import { FC, memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { NotificationContext } from '../../context/notification.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import {
	isMessageWithPasswordResetMetadata,
	isSomeCustomUIComponentByResponseType,
	Message,
} from '../../models/message.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { Info } from '../../ui-kit/info/info.component';
import { lastIndexOf } from '../../utils/array.utils';
import { Effect } from '../../utils/function.utils';
import { isUserMessage, renderComponentsByType } from '../../utils/renderer-utils/renderer.utils';
import { ANIMATED_GRADIENT_ROTATION_ANGLE } from '../../utils/sizes.utils';
import { WidgetContent } from '../widget-content/widget-content.component';
import { WidgetFooter } from '../widget-footer/widget-footer.component';
import { WidgetHeader } from '../widget-header/widget-header.component';
import { getLiveChatStatus, OnSenMessageFunc } from './widget-wrapper.model';
import { useSlideCSSTransitionStyles, useWidgetWrapperStyles } from './widget-wrapper.styles';

export interface WidgetWrapperProps {
	onWidgetClose: Effect<EventType>;
	onUndoMessage: Effect<number>;
	onSendMessage: OnSenMessageFunc;
	isFullScreen: boolean;
	isLoading: boolean;
	isWidgetDisabled: boolean;
	isTextInputDisabled: boolean;
	messages: Message[];
	isOpen: boolean;
	isUndo: boolean;
	timeRemaining?: number;
	isLiveChatShown: boolean;
	isLeavingLiveChat: boolean;
	isInputHidden: boolean;
}

interface WidgetContentMessages {
	userMessage?: JSX.Element;
	botMessages: JSX.Element[];
}

export const WidgetWrapper: FC<WidgetWrapperProps> = memo(
	({
		onWidgetClose,
		onSendMessage,
		onUndoMessage,
		isFullScreen,
		isLoading,
		isTextInputDisabled,
		isWidgetDisabled,
		messages,
		isOpen,
		isUndo,
		timeRemaining,
		isInputHidden,
		isLiveChatShown,
		isLeavingLiveChat,
	}) => {
		const { state } = useContext(NotificationContext);
		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
		const [gradientRotationValue, setGradientRotationValue] = useState(-ANIMATED_GRADIENT_ROTATION_ANGLE);

		const {
			palette: {
				transitionConfig,
				visualConfig: { animateBackground },
			},
		} = useTheme();

		const classesSlideTransition = useSlideCSSTransitionStyles({ isMobile });
		const classes = useWidgetWrapperStyles({
			isMobile,
			isFullScreen,
			gradientRotationValue: animateBackground ? gradientRotationValue : 0,
		});
		const rootStyle = classNames(classes.root, (isFullScreen || isMobile) && classes.noBorder);

		const ref = useRef<HTMLDivElement>(null);

		const lastMessage = messages[messages.length - 1];

		const getMessagesShownInWidget = useCallback(
			(messages: Message[]): WidgetContentMessages => {
				if (lastMessage) {
					if (lastMessage.incoming) {
						return {
							userMessage: renderComponentsByType([lastMessage], onSendMessage, onUndoMessage, ref)[0],
							botMessages: [],
						};
					}

					const lastClientMessageIndex = lastIndexOf(messages, isUserMessage);

					return lastClientMessageIndex === -1 || messages[lastClientMessageIndex].type === 'command'
						? {
								botMessages: renderComponentsByType(messages, onSendMessage, onUndoMessage, ref),
							}
						: {
								userMessage: renderComponentsByType(
									[messages[lastClientMessageIndex]],
									onSendMessage,
									onUndoMessage,
									ref,
								)[0],
								botMessages: renderComponentsByType(
									messages.slice(lastClientMessageIndex + 1),
									onSendMessage,
									onUndoMessage,
									ref,
								),
							};
				}
				return {
					botMessages: [],
				};
			},
			[messages],
		);

		useEffect(() => {
			if (isLoading && animateBackground) {
				setGradientRotationValue((value) => {
					const nextValueDiff = isUndo ? -ANIMATED_GRADIENT_ROTATION_ANGLE : ANIMATED_GRADIENT_ROTATION_ANGLE;
					return value + nextValueDiff;
				});
			}
		}, [isLoading, isUndo, animateBackground]);

		const { userMessage, botMessages } = getMessagesShownInWidget(messages);

		const isLastBotTextMessage =
			messages.length > 0 &&
			lastMessage.type === 'text' &&
			!isSomeCustomUIComponentByResponseType(lastMessage.responseType) &&
			!isMessageWithPasswordResetMetadata(lastMessage);

		const timeout = {
			enter: transitionConfig.transitionInDuration,
			exit: transitionConfig.transitionOutDuration,
		};

		return (
			<div className={classes.chatWrapper} ref={ref} id={'chatWrapper'}>
				<section className={rootStyle}>
					<CSSTransition
						in={state.isNotificationShown}
						classNames={classesSlideTransition}
						mountOnEnter
						unmountOnExit
						timeout={timeout}>
						<Info
							status={state.notificationType}
							label={state.notificationMessage}
							className={classes.notification}
							dataTestingLabel={'notification'}
						/>
					</CSSTransition>
					<WidgetHeader
						onWidgetClose={onWidgetClose}
						isOpen={isOpen}
						rootRef={ref}
						liveChatVisibilityStatus={getLiveChatStatus(isLiveChatShown, isLeavingLiveChat)}
					/>
					<WidgetContent
						isUndo={isUndo}
						userMessage={userMessage}
						botMessages={botMessages}
						isLoading={isLoading}
						isLastBotTextMessage={isLastBotTextMessage}
						isTimeRemainingShown={!!timeRemaining}
						isInputShown={!isInputHidden}
						isLiveChatShown={isLiveChatShown}
					/>
					<WidgetFooter
						shouldShowUserInput={!isInputHidden}
						isTextInputDisabled={isTextInputDisabled || isLoading || isWidgetDisabled}
						isOpen={isOpen}
						timeRemaining={timeRemaining}
					/>
				</section>
			</div>
		);
	},
);
