import { pipe } from 'fp-ts/lib/function';
import { fromNullable, map, toNullable } from 'fp-ts/lib/Option';
import { memo, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { APIServiceContext } from '../../context/api-service/api-service.context';
import { SchedulingWrapper } from '../scheduling-wrapper/scheduling-wrapper';
import { EpicSchedulingComponent } from './epic-scheduling.component';

interface EpicSchedulingData {
	clientLogoURL: string;
	iframeURL: string;
}

export const EpicSchedulingContainer = memo(() => {
	const { type, clientName } = useParams<{ type: string; clientName: string }>();
	const { getEpicSchedulingIframeUrl } = useContext(APIServiceContext);

	const [epicData, setEpicData] = useState<EpicSchedulingData>();
	const location = useLocation();

	useEffect(() => {
		getEpicSchedulingIframeUrl({ type, clientName, params: location.search })
			.then(setEpicData)
			.catch(() => {
				'Error while fetching Epic Scheduling data.';
			});
	}, []);

	return pipe(
		fromNullable(epicData),
		map(({ clientLogoURL, iframeURL }) => (
			<SchedulingWrapper clientLogoURL={clientLogoURL}>
				<EpicSchedulingComponent iframeURL={iframeURL} />
			</SchedulingWrapper>
		)),
		toNullable,
	);
});
