import { CardResponse } from '../../../models/message.model';

interface HasResponses {
	responses: CardResponse[];
}

interface ExtractedCommonCardResponses<C> {
	cards: C[];
	secondaryActionResponse?: CardResponse;
}

export const validateAndExtractCommonCardResponse = <C extends HasResponses>(
	cards: C[],
	flowStep: string,
	commonCarouselResponseFlows: string[],
): ExtractedCommonCardResponses<C> => {
	const shouldExtractCommonCardResponse = commonCarouselResponseFlows.some((flow) => flowStep.includes(flow));

	const isEveryCardHasMinTwoResponses = cards.every((card) => card.responses.length > 1);

	const secondaryActionResponse =
		shouldExtractCommonCardResponse && isEveryCardHasMinTwoResponses
			? cards[0].responses[cards[0].responses.length - 1]
			: undefined;

	const processedCards =
		shouldExtractCommonCardResponse && isEveryCardHasMinTwoResponses
			? cards.map((card) => {
					const extractedResponses = card.responses.slice(0, card.responses.length - 1);
					return { ...card, responses: extractedResponses };
				})
			: cards;
	return { cards: processedCards, secondaryActionResponse };
};
