import { last } from 'fp-ts/lib/Array';
import { constant, not, pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Option';

import { LiveChatAgentData } from '../../context/live-chat-context';
import { isMessageWithLiveChatAgentInfo, LiveChatCommand, Message } from '../../models/message.model';
import { lastIndexOf } from '../../utils/array.utils';
import { dateToFormattedHoursMinutesTimeString } from '../../utils/time.utils';
import { Nullable } from '../../utils/types.utils';
import { LiveChatMessageAdditionalInfo } from './components/message/message.component';

export const LIVE_CHAT_CONNECTIVITY_LOST_MESSAGE: Message = {
	type: 'text',
	incoming: false,
	content: '',
	text: 'Connection lost',
	metadata: { status: 'agentLeft' },
};
export const LIVE_CHAT_CONNECTIVITY_LOST_CHAT_ENDED_MESSAGE: Message = {
	type: 'text',
	incoming: false,
	content: '',
	text: '',
	metadata: { status: 'endLiveChat' },
};

const TYPING_INDICATOR_STATUS = ['startTyping', 'endTyping'];
export const RETRY_MESSAGES_COMMAND = 'retryMessages';
export const LIVE_CHAT_COMMANDS = [...TYPING_INDICATOR_STATUS, 'endLiveChat', 'leaveQueue', RETRY_MESSAGES_COMMAND];

type LiveChatFeedbackValue = 'on' | 'off';
const LIVE_CHAT_FEEDBACK_STEP = 'gyant_live_chat_feedback_step';
const LIVE_CHAT_CONNECTION_ERROR = 'gyant_live_chat_connection_error';
const LIVE_CHAT_ACTIVE_LOCAL_STORAGE_KEY_PREFIX = 'gyant_live_chat_active';

type LiveChatMessageType = 'SYSTEM' | 'AGENT' | 'USER';
export type LiveChatUIMessage = Omit<Message, 'timeStamp'> & {
	liveChatMessageType: LiveChatMessageType;
	timeStamp: string;
	isUnreadMessage: boolean;
	agentDisplayName?: string;
};

export interface LiveChatLocalTemplates {
	agentEnteredChat: string;
	agentLeftChat: string;
	startedChat: string;
	endedChat: string;
	startingChatDescription: string;
}

export const isNonTypingCommand = (message: Message): boolean =>
	!TYPING_INDICATOR_STATUS.includes(message.metadata?.status || '');

const formatMessageTimeStamp = (messageTime?: Date): string =>
	messageTime ? dateToFormattedHoursMinutesTimeString(messageTime) : '';

export const getIsAgentTypingIndicator = (messages: Message[]): boolean =>
	pipe(
		messages,
		last,
		fold(constant(false), (message) => message.metadata?.status === 'startTyping'),
	);

export const isUserTypingCommand = (message: Message): boolean =>
	message.type === 'command' && message.incoming && TYPING_INDICATOR_STATUS.includes(message.text);

export const isRetryMessagesCommand = (message: Message): boolean =>
	message.type === 'command' && message.incoming && message.text === RETRY_MESSAGES_COMMAND;

const LIVE_CHAT_METADATA_STATUSES = ['endLiveChat', 'agentJoined', 'startTyping', 'endTyping', 'startLiveChat'];
export const isLiveChatMessages = (messages: Message[]): boolean =>
	messages.some(
		(message) =>
			('doctorInfo' in message && message.doctorInfo) ||
			(message.metadata?.status && LIVE_CHAT_METADATA_STATUSES.includes(message.metadata.status)),
	);

export const isAgentConnectedMessage = (messages: Message[]): boolean =>
	messages.some(
		(message) =>
			'doctorInfo' in message &&
			message.doctorInfo &&
			(message.doctorInfo.displayName || message.doctorInfo.firstName),
	);

export const prepareLiveChatUIMessages = (
	messages: Message[],
	agentData: LiveChatAgentData,
	newMessageIndex: Nullable<number>,
	templates: LiveChatLocalTemplates,
): LiveChatUIMessage[] =>
	messages
		.map((message, index) => {
			const isUnreadMessage = index === newMessageIndex;
			return { ...message, isUnreadMessage };
		})
		.filter((message) => !TYPING_INDICATOR_STATUS.includes(message.metadata?.status || ''))
		.filter(not(isUserTypingCommand))
		.filter(not(isRetryMessagesCommand))
		.filter((message) => message.type !== 'quickResponses')
		.map((message) => {
			const agentDisplayName = isMessageWithLiveChatAgentInfo(message)
				? message.doctorInfo.displayName
				: agentData.displayName;
			if (message.metadata?.status === 'endLiveChat') {
				return {
					...message,
					liveChatMessageType: 'SYSTEM',
					text: 'Chat ended',
					timeStamp: formatMessageTimeStamp(message.timeStamp),
				};
			}
			if (
				message.incoming &&
				(message.type === 'text' || message.type === 'command') &&
				(message.text === 'endLiveChat' || message.text === 'leaveQueue')
			) {
				return {
					...message,
					liveChatMessageType: 'SYSTEM',
					text: `You ${templates.agentLeftChat}`,
					timeStamp: formatMessageTimeStamp(message.timeStamp),
				};
			}
			if (message.metadata?.status === 'agentJoined') {
				return {
					...message,
					liveChatMessageType: 'SYSTEM',
					text: `${agentDisplayName} ${templates.agentEnteredChat}`,
					timeStamp: formatMessageTimeStamp(message.timeStamp),
				};
			}
			if (message.metadata?.status === 'agentLeft') {
				return {
					...message,
					liveChatMessageType: 'SYSTEM',
					text: message.text || `${agentDisplayName} ${templates.agentLeftChat}`,
					timeStamp: formatMessageTimeStamp(message.timeStamp),
				};
			}
			if (message.metadata?.status === 'startLiveChat') {
				return {
					...message,
					liveChatMessageType: 'SYSTEM',
					text: 'You started a chat',
					timeStamp: formatMessageTimeStamp(message.timeStamp),
				};
			}
			return {
				...message,
				liveChatMessageType: message.incoming ? 'USER' : 'AGENT',
				timeStamp: formatMessageTimeStamp(message.timeStamp),
				agentDisplayName,
			};
		});
export const getFeedBackQuestion = (messages: Message[]): Message[] => {
	const lastFeedbackQuestionIndex = lastIndexOf(messages, (message) => message.type === 'quickResponses');
	return [messages[lastFeedbackQuestionIndex - 1], messages[lastFeedbackQuestionIndex]].filter(Boolean);
};

export const getNumberOfFeedBackQuestions = (messages: Message[]): number =>
	messages.filter((message) => message.type === 'quickResponses').length;

export const isLiveChatCommand = (value: string): value is LiveChatCommand => LIVE_CHAT_COMMANDS.includes(value);
export const isLeaveChatCommand = (value: string): boolean => value === 'endLiveChat';
export const isLeaveQueueCommand = (value: string): boolean => value === 'leaveQueue';

export const isAgentJoinedMessageExists = (messages: Message[]): boolean =>
	messages.some((message) => message.metadata?.status === 'agentJoined');

export const isAgentJoinedMessage = (messages: Message[]): boolean =>
	messages[messages.length - 1]?.metadata?.status === 'agentJoined';

export const isAgentLeftMessage = (messages: Message[]): boolean =>
	messages[messages.length - 1]?.metadata?.status === 'agentLeft';

export const isChatFinishedByAgentMessage = (messages: Message[]): boolean =>
	messages[messages.length - 1]?.metadata?.status === 'endLiveChat';

export const setFeedbackQuestionsStep = (value: LiveChatFeedbackValue, client: string): void => {
	const localStorageVariableName = `${LIVE_CHAT_FEEDBACK_STEP}_${client}`;
	value === 'on'
		? localStorage.setItem(localStorageVariableName, value)
		: localStorage.removeItem(localStorageVariableName);
};

export const isFeedbackLiveChatSessionOn = (client: string): boolean => {
	const value = localStorage.getItem(`${LIVE_CHAT_FEEDBACK_STEP}_${client}`);
	return value === 'on';
};

export const setLiveChatConnectionError = (client: string, isDeleting = false): void => {
	const localStorageVariableName = `${LIVE_CHAT_CONNECTION_ERROR}_${client}`;
	isDeleting
		? localStorage.removeItem(localStorageVariableName)
		: localStorage.setItem(localStorageVariableName, '1');
};

export const isLiveChatConnectionErrorSet = (client: string): boolean => {
	const value = localStorage.getItem(`${LIVE_CHAT_CONNECTION_ERROR}_${client}`);
	return !!value;
};

export const getMessagesForChat = (
	messages: Message[],
	isLiveChatSessionOver: boolean,
	prevMessagesLength: number,
): Message[] => {
	if (isLiveChatSessionOver) {
		const firstFeedbackQuestion = messages.findIndex((m) => m.type === 'quickResponses');
		return firstFeedbackQuestion === -1
			? messages.slice(0, prevMessagesLength)
			: messages.slice(0, firstFeedbackQuestion - 1);
	}
	return messages;
};

export const setLiveChatActiveLocalStorage = (client: string, status: boolean): void => {
	const localStorageVariableName = `${LIVE_CHAT_ACTIVE_LOCAL_STORAGE_KEY_PREFIX}_${client}`;
	status ? localStorage.setItem(localStorageVariableName, '1') : localStorage.removeItem(localStorageVariableName);
};

export const getLiveChatActiveLocalStorage = (client: string): boolean =>
	localStorage.getItem(`${LIVE_CHAT_ACTIVE_LOCAL_STORAGE_KEY_PREFIX}_${client}`) === '1';

export const clearLiveChatStorageValues = (client: string): void => {
	setFeedbackQuestionsStep('off', client);
	setLiveChatConnectionError(client, true);
	setLiveChatActiveLocalStorage(client, false);
};

export const getAdditionalInfo = (
	messages: LiveChatUIMessage[],
	index: number,
): LiveChatMessageAdditionalInfo | undefined => {
	const currentMessage = messages[index];
	const isLastMessage = index === messages.length - 1;
	const isNextMessageOfDifferentType =
		messages[index + 1] && currentMessage.liveChatMessageType !== messages[index + 1].liveChatMessageType;
	if (isLastMessage || isNextMessageOfDifferentType) {
		return { time: currentMessage.timeStamp, agentName: currentMessage.agentDisplayName };
	}
};

export const setTimerForScrollContentDown = (ref: React.RefObject<HTMLDivElement>, timeout: number): void => {
	setTimeout(() => {
		ref.current?.scrollTo({ top: 100000, behavior: 'smooth' });
	}, timeout);
};

export const getLastUserMessageId = (messages: LiveChatUIMessage[]): string | undefined =>
	messages
		.slice()
		.reverse()
		.find((message) => message.liveChatMessageType === 'USER')?.messageId;

export const isProcessingSending = (messages: LiveChatUIMessage[]): boolean =>
	messages.some((message) => message.deliveredStatus === 'sending');
