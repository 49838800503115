import { Effect } from './function.utils';

const ACTION_KEYBOARD_KEYS = [' ', 'Spacebar', 'Enter'];
// we can't avoid using any here, because different events are used for events tracing service
export const handleKeyPress =
	(eventCallback: Effect<any>) =>
	(event: { key: string }): void => {
		if (ACTION_KEYBOARD_KEYS.includes(event.key)) {
			eventCallback(event);
		}
	};
