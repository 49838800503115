import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const CloseIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M12 13.3998L7.09999 18.2998C6.91665 18.4831 6.68332 18.5748 6.39999 18.5748C6.11665 18.5748 5.88332 18.4831 5.69999 18.2998C5.51665 18.1165 5.42499 17.8831 5.42499 17.5998C5.42499 17.3165 5.51665 17.0831 5.69999 16.8998L10.6 11.9998L5.69999 7.0998C5.51665 6.91647 5.42499 6.68314 5.42499 6.3998C5.42499 6.11647 5.51665 5.88314 5.69999 5.6998C5.88332 5.51647 6.11665 5.4248 6.39999 5.4248C6.68332 5.4248 6.91665 5.51647 7.09999 5.6998L12 10.5998L16.9 5.6998C17.0833 5.51647 17.3167 5.4248 17.6 5.4248C17.8833 5.4248 18.1167 5.51647 18.3 5.6998C18.4833 5.88314 18.575 6.11647 18.575 6.3998C18.575 6.68314 18.4833 6.91647 18.3 7.0998L13.4 11.9998L18.3 16.8998C18.4833 17.0831 18.575 17.3165 18.575 17.5998C18.575 17.8831 18.4833 18.1165 18.3 18.2998C18.1167 18.4831 17.8833 18.5748 17.6 18.5748C17.3167 18.5748 17.0833 18.4831 16.9 18.2998L12 13.3998Z'
			}
		/>
	</SvgIcon>
);
