import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const CalendarUnavailableIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M12 16.4L10.4 18C10.2167 18.1833 9.98333 18.275 9.7 18.275C9.41667 18.275 9.18333 18.1833 9 18C8.81667 17.8167 8.725 17.5833 8.725 17.3C8.725 17.0167 8.81667 16.7833 9 16.6L10.6 15L9 13.4C8.81667 13.2167 8.725 12.9833 8.725 12.7C8.725 12.4167 8.81667 12.1833 9 12C9.18333 11.8167 9.41667 11.725 9.7 11.725C9.98333 11.725 10.2167 11.8167 10.4 12L12 13.6L13.6 12C13.7833 11.8167 14.0167 11.725 14.3 11.725C14.5833 11.725 14.8167 11.8167 15 12C15.1833 12.1833 15.275 12.4167 15.275 12.7C15.275 12.9833 15.1833 13.2167 15 13.4L13.4 15L15 16.6C15.1833 16.7833 15.275 17.0167 15.275 17.3C15.275 17.5833 15.1833 17.8167 15 18C14.8167 18.1833 14.5833 18.275 14.3 18.275C14.0167 18.275 13.7833 18.1833 13.6 18L12 16.4ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V3C6 2.71667 6.09583 2.47917 6.2875 2.2875C6.47917 2.09583 6.71667 2 7 2C7.28333 2 7.52083 2.09583 7.7125 2.2875C7.90417 2.47917 8 2.71667 8 3V4H16V3C16 2.71667 16.0958 2.47917 16.2875 2.2875C16.4792 2.09583 16.7167 2 17 2C17.2833 2 17.5208 2.09583 17.7125 2.2875C17.9042 2.47917 18 2.71667 18 3V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20Z'
			}
		/>
	</SvgIcon>
);
