import React, { FC, memo } from 'react';

import { useEpicSchedulingComponentStyles } from './epic-scheduling.styles';

interface EpicSchedulingComponentProps {
	iframeURL: string;
}

export const EpicSchedulingComponent: FC<EpicSchedulingComponentProps> = memo(({ iframeURL }) => {
	const classes = useEpicSchedulingComponentStyles();

	return (
		<div className={classes.iframeContainer}>
			<iframe
				title={'epicSchedulingComponent'}
				id={'scheduling-frame'}
				src={iframeURL}
				frameBorder={'0'}
				className={classes.iframe}
			/>
		</div>
	);
});
