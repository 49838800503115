import { OutlinedInputProps } from '@material-ui/core';
import classNames from 'classnames';
import { FC, useState } from 'react';

import { Button } from '../button/button.component';
import { Icon } from '../icon/icon.component';
import { VisibilityOffIcon } from '../icons/visibility-off.icon';
import { VisibilityOnIcon } from '../icons/visibility-on.icon';
import { Input } from '../input/input.component';
import { useInputInputPasswordStyles } from './input-password.styles';

export const InputPassword: FC<OutlinedInputProps> = (props) => {
	const classes = useInputInputPasswordStyles();
	const [isPasswordVisible, setPasswordVisible] = useState(false);

	return (
		<Input
			{...props}
			fullWidth
			error={props.error}
			className={classNames(classes.root, props.className)}
			value={props.value}
			type={isPasswordVisible ? 'text' : 'password'}
			onChange={props.onChange}
			onFocus={props.onFocus}
			placeholder={props.placeholder}
			label={props.label}
			endAdornment={
				<Button
					variant={'text'}
					onClick={() => setPasswordVisible(!isPasswordVisible)}
					className={classes.adornment}
					data-testing-label={isPasswordVisible ? 'hide-password' : 'show-password'}>
					<Icon
						iconType={'systemIconDefault'}
						icon={isPasswordVisible ? VisibilityOnIcon : VisibilityOffIcon}
					/>
				</Button>
			}
		/>
	);
};
