import { CSSProperties } from '@material-ui/styles';
import { AxiosProgressEvent, CancelToken } from 'axios';

import { ChipsType, CollapsedWidgetType, ColorVariables } from '../../models/app.model';
import { TimeSlotItem } from '../../models/appointment.model';
import { ConversationContext, ConversationHistory, ConversationMetadata } from '../../models/conversation.model';
import {
	LanguageAbbreviation,
	LanguageName,
	LanguageNameLowerCase,
	LocalizationModel,
} from '../../models/languages.model';
import { ParsedData } from '../../models/message.model';
import { ClientMessage } from '../../models/socket.model';
import { Tracker } from '../../models/trackers.model';
import { DoctorSearchProvider } from '../../ui/doctor-search/doctor-search.model';
import { AnimatedElement, AnimationId } from '../../utils/animations.utils';
import { Effect } from '../../utils/function.utils';
import { Nullable } from '../../utils/types.utils';

export type AutocompleteContext = 'providers' | 'specialties' | 'symptoms';
interface AutocompleteResultItem {
	label: string;
	value: string;
}

export interface CreateAccountPayload {
	client: string;
	hostname: string;
	locale: string;
	startUrl: string;
	triageEnabled?: boolean;
	version: string;
	patientId?: string;
	name?: string;
	gender?: string;
	age?: number;
	sessionToken?: string;
	testing?: boolean;
	debugging?: boolean;
}

export interface UpdateProfilePayload {
	sessionToken: string;
	openedChatWidget: boolean;
	version: string;
	currentUrl: string;
}

export interface GetConversationPayload {
	maxUtterances: number;
}

export interface CollapsedWidgetConfigPayload {
	client: string;
	locale: string;
	pathname: string;
}

export interface LiveChatConfigPayload {
	client: string;
	locale: string;
}

export interface UserSurveyFeedbackPayload {
	name: string;
	displayedUserSurvey: boolean;
	flowId: string;
	stepName: string;
	rating?: string;
	text?: string;
	dismissedSurvey?: boolean;
}

export interface AppointmentAvailableDatesPayload {
	startDate: string;
	endDate: string;
	data: Record<string, unknown>;
}

export interface GyantEventPayload {
	eventData: string;
	sessionToken?: string;
}

export interface FutureAppointmentsPayload {
	sessionToken: string;
}

export interface EpicSchedulingIframePayload {
	type: string;
	clientName: string;
	params: string;
}

export interface BlockItSchedulingSettingsPayload {
	clientName: string;
	clientUserId: string;
}

interface UploadImagePayload {
	formData: FormData;
	onUploadProgress?: Effect<AxiosProgressEvent>;
	cancelToken?: CancelToken;
	nodeId?: string;
}

export interface FutureAppointmentCancelData {
	contactID: string;
	contactIDType: string;
	patientID: string;
	patientIDType: string;
	type: string;
	reason: string;
	comment: string;
}

export interface CancelAppointmentPayload {
	cancelData: Nullable<FutureAppointmentCancelData>;
}

export interface ScheduleDayModel {
	date: string;
	displayDate: string;
	slots: TimeSlotItem[];
}

export interface AppointmentAvailableDatesResponseModel {
	provider: {
		id: string;
		name: string;
		type: string;
	};
	schedule: ScheduleDayModel[];
	hasMoreSlots: boolean;
}

export interface FutureAppointmentData {
	identifier: { id: string; type: string };
	timezone: string;
	appointmentTime: string; // UTC
	date: string;
	time: string;
	provider: { name: string; identifier: { id: string; type: string } }; // object with provider information
	location: {
		name: string;
		phoneNumber: string;
		address: { street: string; city: string; postalCode: string; stateCode: string };
		identifier: { id: string; type: string };
	}; // object with location/department information
	isSurgery: boolean;
	surgeryTimeOfDay: string | null;
	cancelType: 'no' | 'direct' | 'request';
	cancelRequestSent: boolean;
	duration: number; // Duration in minutes
	isConfirmed: boolean;
	confirmInstant: Nullable<string>;
	canConfirm: boolean;
	canReschedule: Nullable<boolean>;
	patientInstructions: string;
	isArrivalSameTime: boolean;
	arrivalTime: string;
	arrivalReason: string;
	visitType: { name: string; identifier: { id: string; type: string } };
}

export interface FutureAppointment {
	appointment: FutureAppointmentData;
	cancelData: Nullable<FutureAppointmentCancelData>;
}

export interface FutureAppointmentRescheduleData extends FutureAppointment {
	providerName: string;
	description: string;
	formattedDateTime: {
		date: string;
		time: string;
	};
}

export interface FutureAppointmentResponseModel {
	result: string;
	appointments: FutureAppointment[];
}

export interface CancelAppointmentResponseModel {
	result: string;
	message?: string;
}

export interface CreateAccountResponseModel {
	code: string;
	minVersion: string;
	sessionToken: string;
	userId: string;
}

export interface UpdateProfileResponseModel {
	ok: string;
}

export interface SyncMessageResponseModel {
	messages: any[];
}

export interface AutocompleteResponseModel {
	term: string;
	context: AutocompleteContext;
	results: AutocompleteResultItem[];
}
export type ScoreLevel = '1' | '2' | '3' | '4' | '5';
export interface Score {
	value: ScoreLevel;
	template: string; // Name (string below the emoji) - "Dissatisfied"
}

export interface UserSurveyConfigResponseModel {
	enabled: boolean;
	name: string;
	showOnlyOnce: boolean;
	timer: number;
	showFreeTextInput: boolean;
	templates: {
		scores: Score[];
		title: string;
		thankYou: string;
		textInput: string;
		doneButton: string;
	};
}

export interface MinimizeButtonConfigResponseModel {
	oncePerSession: boolean;
	delay: number;
}

export interface DismissButtonConfigResponseModel {
	dismissTooltip?: boolean;
	dismissCollapsedWidget?: boolean;
	dismissOpenToggle?: boolean;
	delay?: number;
}
export interface CTAButtonConfigResponseModel {
	id: string;
	title: string;
	payload?: string;
	icon?: string;
	style?: Record<string, unknown>;
	class?: string;
}

export interface Utterance {
	attachments: string[];
	incoming: boolean;
	originalText: string;
	requestId: string;
	text: string;
	timestamp: string;
	_id: string;
}

export interface ConversationResponseModel {
	context: ConversationContext;
	metadata: ConversationMetadata;
	treatments: Record<string, unknown>[]; // TODO: investigate type
	utterances: Utterance[];
}

export interface EpicSchedulingResponseModel {
	clientLogoURL: string;
	iframeURL: string;
}

export interface BlockItSettingsModel {
	slug: string;
	specialtyId: string;
	locationId?: string;
	visitTypeId?: string;
}
export interface BlockItSchedulingResponseModel {
	blockIt: BlockItSettingsModel;
	clientLogoURL: string;
}
export interface MenuTextModel {
	title: string;
	text: string;
}

export interface LanguageModel {
	label: LanguageAbbreviation;
	language: LanguageNameLowerCase;
	locale: LocalizationModel;
	text: LanguageName;
	tooltip: string;
	menu?: MenuTextModel;
}
export type LocaleModel = 'ar_AR' | 'en_US' | 'de_DE' | 'ru_RU' | 'es_LA' | 'pt_PT' | 'default';

interface MenuItemWebUrlModel {
	title: string;
	type: 'web_url';
	url: string;
}

interface MenuItemPostbackModel {
	payload: string;
	showInChat?: boolean;
	title: string;
	type: 'postback';
}

export type MenuItemActionModel = MenuItemWebUrlModel | MenuItemPostbackModel;

export interface MenuItemModel {
	locale: LocaleModel;
	call_to_actions: MenuItemActionModel[];
}

interface AutoOpen {
	afterMs: number;
	desktop: boolean;
	mobile: boolean;
}

interface CookiesSettingsModel {
	expireTimeout: number;
	removeCookiesOnCollapse: boolean;
	enabled: boolean;
	disableWidgetOnCookieExpire: boolean;
}
interface TooltipSettingsModel {
	keepTooltipVisible: boolean;
	showTooltipOncePerSession: boolean;
}

export interface SettingsModel {
	autoOpen: AutoOpen;
	cookies: Partial<CookiesSettingsModel>;
	default_language: LanguageNameLowerCase;
	maxMessageDelayMs: number;
	progressBar: boolean;
	tooltip: string;
	tooltipVisibleTimeMs: number;
	tooltipSettings: Partial<TooltipSettingsModel>;
	useBrowserLanguage: boolean;
	postMessageToParent: boolean;
	preLoadFirstMessage: boolean;
	links: {
		confirmOpenLink: boolean;
		linkTarget: string;
	};
	fullscreen: {
		addCloseBtn: false;
	};
}

export interface StartingAnimation {
	animationID: AnimationId;
	delayMs: number;
	durationMs: number;
	element: AnimatedElement;
	repeatCount: number;
	repeatFunction: string;
}

export type WebTrackerUIEventActionModel = 'onCloseChat' | 'onMenuIconClick' | 'onMenuOptionsClick' | 'onOpenChat';
export type WebTrackerChatEventActionModel =
	| 'onBotMessage'
	| 'onUserClickLink'
	| 'onUserClickPhone'
	| 'onUserMessage'
	| 'onUserSelect'
	| 'onUserSelectCarousel';

export interface WebTrackerEventBodyModel {
	enabled: boolean;
	action: string;
	label: string;
	matchResponses?: Record<
		string,
		{
			action: string;
			label: string;
		}
	>;
}

export type WebTrackerUIEvent = Partial<{ [key in WebTrackerUIEventActionModel]: WebTrackerEventBodyModel }>;
export type WebTrackerChatEvent = Partial<{ [key in WebTrackerChatEventActionModel]: WebTrackerEventBodyModel }>;

export interface WebTrackerModel {
	// TODO: investigate type
	category: string; // 'Chatbot';
	logEventsToConsole: boolean;
	trackers: Tracker[];
	ui: WebTrackerUIEvent;
	chat: WebTrackerChatEvent;
}

interface LibsModel {
	fancybox: boolean;
}

export interface VisualConfigModel {
	defaultFontSize?: number;
	radiusType?: string;
	widgetBackground?: string;
	animateBackground?: boolean;
	collapsedWidgetType?: CollapsedWidgetType;
	headerAvatar?: string;
	buttonAvatar?: string;
	assistantName?: string;
	chipsType?: ChipsType;
	commonCarouselResponseFlows?: string[];
}

export interface CustomStyle {
	botMessages: Record<string, any>;
	userMessage: Record<string, any>;
	collapsedChatButton: Record<string, any>;
	openedChatButton: Record<string, any>;
	quickResponseButtons: {
		main: Record<string, any>;
		external: Record<string, any>;
	};
	multiselect: {
		main?: Record<string, any>;
		item?: Record<string, any>;
		sendButton?: Record<string, any>;
	};
	poweredBy: Record<string, any>; // TODO: integrate when component will be implemented
	mainChat: Record<string, any>;
	content: Record<string, any>;
	tooltip: {
		container?: Record<string, any>;
		component?: Record<string, any>;
	};
	global: Record<string, any>;
	progressBar: Record<string, any>;
	carousel: {
		title?: Record<string, any>;
		responseButton?: Record<string, any>;
	};
	iframe: {
		fullScreen?: Record<string, any>;
		notFullScreen?: Record<string, any>;
	};
	footer: Record<string, any>;
	userInput: Record<string, any>;
	sendButton: Record<string, any>;
	header: {
		main?: Record<string, any>;
		logo?: Record<string, any>;
		closeButton?: Record<string, any>;
	};
	menu: {
		main?: Record<string, any>;
		overlay?: Record<string, any>;
		transition?: Record<string, any>;
		button?: Record<string, any>;
	};
}

export interface ColorSchemeModel {
	lightest: string;
	light: string;
	mediumLight: string;
	lightBrandBackground: string;
	mediumDark: string;
	dark: string;
	darkest: string;
	imagesBasePathClient: string;
	logoBG: string;
	logoBGWidth: string;
	logoBGHeight: string;
	mobileLogoBgWidth: string;
	mobileLogoBgHeight: string;
	botAvatar?: string;
	chatWindowHeight?: string;
	chatWindowBottomPosition?: string;
	chatWindowRightPosition?: string;
	customSassCode?: string;
	customStyle?: Partial<CustomStyle>;
	providerBubbleColor?: string;
	providerFontColor?: string;
	fontFamilyRegular?: string;
	fontFamilyMedium?: string;
	mobileHeaderHeight?: string;
	mobileHeaderLogoWidth?: string;
	mobileHeaderLogoHeight?: string;
	brandColor?: string;
	colorPrimary?: string;
	customColorVariables?: Partial<ColorVariables>;
}

export interface AppConfigResponseModel {
	active: boolean;
	clientJsFileExists: boolean;
	languages: Nullable<LanguageModel[]>;
	libs?: LibsModel;
	menu: Nullable<MenuItemModel[]>;
	settings: Nullable<Partial<SettingsModel>>;
	startingAnimations?: StartingAnimation[];
	webTrackers: Nullable<WebTrackerModel>;
	colorScheme: Nullable<ColorSchemeModel>;
	visualConfig: Nullable<VisualConfigModel>;
	documentTitle: Nullable<string>;
}

export interface CollapsedWidgetCallActionItemStyle {
	appearance?: CSSProperties;
	onMouseOver?: CSSProperties;
}
export interface CollapsedWidgetCallActionItem {
	payload: string;
	renderedTitle: string;
	title: string;
	style?: CollapsedWidgetCallActionItemStyle;
}
export interface CollapsedWidgetVisibilityConfig {
	durationMs?: number;
	showComponentOncePerSession?: boolean;
}
export interface CollapsedWidgetConfigResponseModel {
	callToActions: CollapsedWidgetCallActionItem[];
	clientName: string;
	deviceType: 'desktop' | 'mobile';
	enabled: boolean;
	name: string;
	startingFlow?: string;
	visibility?: CollapsedWidgetVisibilityConfig;
	configSource: Nullable<string>;
}

export interface LiveChatCallToActionConfig {
	title: string;
	commandValue: string;
	icon: string;
	renderedTitle: string;
}
interface LiveChatLeaveQueueConfig {
	commandValue: string;
	delay: number;
}

export interface LiveChatTemplates {
	agentTitlePlaceholder: string;
	agentNamePlaceholder: string;
	agentEnteredChat: string;
	agentLeftChat: string;
	chattingWith: string;
	leaveQueue: string;
	startingChatDescription: string;
	alternativeWaitingMessage: string;
}
interface LiveChatAgentConfig {
	avatarImageURLPlaceholder: string;
}
export interface LiveChatConfigResponseModel {
	agent?: LiveChatAgentConfig;
	enabled: boolean;
	name: string;
	callToActions: LiveChatCallToActionConfig[];
	leaveQueue: LiveChatLeaveQueueConfig;
	clientName: string;
	templates: Partial<LiveChatTemplates>;
}

type LogErrorOrigin = 'NO_MESSAGE' | 'API_ERROR' | 'PARSING_ERROR';
export interface ErrorLogData {
	error: unknown;
	origin: LogErrorOrigin;
	payload?: unknown;
}
interface ProvidersPagingModel {
	currentPage: number;
	nextPage: Nullable<number>;
	pageItems: number;
	totalItems: number;
}

export interface ProviderAction {
	type: 'message' | 'data' | 'link';
	label: string;
	value: string;
	flowStep?: Nullable<string>;
}

export interface ProviderResource {
	provider: DoctorSearchProvider;
	actions: ProviderAction[];
}

export interface ProvidersResponseModelSuccess {
	paging: Nullable<ProvidersPagingModel>;
	items: ProviderResource[];
}

export interface ErrorResponseModel {
	details: unknown[];
	message: string;
	status: 'error';
	statusCode: number;
}

interface SuccessResponseModel {
	status: 'ok';
}

export type TranslationsConfigResponseModel = Record<string, string>;

type APIResponseModel = SuccessResponseModel | ErrorResponseModel;

export type GetProvidersResponseModel = ProvidersResponseModelSuccess | ErrorResponseModel;
export type ChangePasswordResponseModel = APIResponseModel;

export type GetConfigApi = (client: string) => Promise<Nullable<AppConfigResponseModel>>;
export type CreateAccountApi = (payload: CreateAccountPayload) => Promise<CreateAccountResponseModel>;
export type UpdateProfileApi = (payload: UpdateProfilePayload) => Promise<UpdateProfileResponseModel>;
export type GetConversationApi = (
	payload: GetConversationPayload,
) => Promise<Nullable<ParsedData<ConversationHistory>>>;
export type SendMessageSyncApi = (payload: ClientMessage) => Promise<any[]>;
export type GetAutocompleteSuggestionsApi = (uri: string) => Promise<AutocompleteResponseModel>;
export type GetCollapsedWidgetConfigApi = (
	payload: CollapsedWidgetConfigPayload,
) => Promise<CollapsedWidgetConfigResponseModel>;
export type GetMinimizeButtonConfigApi = (payload: string) => Promise<MinimizeButtonConfigResponseModel>;
export type GetDismissButtonConfigApi = (payload: string) => Promise<DismissButtonConfigResponseModel>;
export type GetCTAButtonConfigApi = (payload: string) => Promise<CTAButtonConfigResponseModel[]>;
export type GetLiveChatConfigApi = (payload: LiveChatConfigPayload) => Promise<LiveChatConfigResponseModel>;
export type GetAppointmentAvailableDatesApi = (
	payload: AppointmentAvailableDatesPayload,
) => Promise<AppointmentAvailableDatesResponseModel>;
export type GetFutureAppointmentsApi = () => Promise<FutureAppointmentResponseModel>;
export type CancelAppointmentApi = (payload: CancelAppointmentPayload) => Promise<CancelAppointmentResponseModel>;
export type GetEpicSchedulingIframeUrlApi = (
	payload: EpicSchedulingIframePayload,
) => Promise<EpicSchedulingResponseModel>;
export type GetBlockItSchedulingSettingsApi = (
	payload: BlockItSchedulingSettingsPayload,
) => Promise<BlockItSchedulingResponseModel>;
export type SendEventApi = (payload: GyantEventPayload) => Promise<void>;
export type LogEventApi = (payload: ErrorLogData) => Promise<void>;
export type UploadImagesApi = (payload: UploadImagePayload) => Promise<unknown>;
export type GetProvidersApi = (query: string) => Promise<GetProvidersResponseModel>;
export type ChangePasswordApi = (payload: string) => Promise<ChangePasswordResponseModel>;
export type GetTranslationsApi = (client: string, locale: string) => Promise<TranslationsConfigResponseModel>;

export const isApiResponseWithErrorMessage = (response: { status?: string }): response is ErrorResponseModel =>
	'status' in response && response.status === 'error';
