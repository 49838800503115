import classNames from 'classnames';
import { FC, RefObject, useEffect, useRef, useState } from 'react';

import { useChipGroupStyles } from './chip-group.styles';

interface ChipGroupProps {
	isStacked?: boolean;
}

const shouldSwitchToStack = (ref: RefObject<HTMLDivElement>) => {
	const width = ref.current?.clientWidth || 0;
	const scrollWidth = ref.current?.scrollWidth || 0;
	return scrollWidth > width;
};

export const ChipGroup: FC<ChipGroupProps> = ({ children, isStacked }) => {
	const ref = useRef<HTMLDivElement>(null);
	const classes = useChipGroupStyles();
	const [isStackedState, setStackedState] = useState(isStacked);

	const handleResize = () => {
		const shouldSwitch = !isStacked ? shouldSwitchToStack(ref) : true;
		setStackedState(shouldSwitch);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [isStacked]);

	return (
		<div ref={ref} className={classNames(isStackedState ? classes.stackedRoot : classes.root)}>
			{children}
		</div>
	);
};
