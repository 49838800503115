import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useLanguageSelectorStyles = makeStyles<Theme>(({ palette: { sizes }, typography }) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: 0,
		width: '100%',
	},
	title: {
		margin: `0 0 ${sizes.size_1}px`,
		paddingLeft: sizes.size_2,
		...typography.smallViewPort.typeHeading2,
	},
	list: {
		padding: 0,
		margin: 0,
		width: '100%',
		listStyleType: 'none',
	},
	listItem: {
		'&:not(:last-child)': {
			marginBottom: sizes.sizeHalf,
		},
	},
}));
