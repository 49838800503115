import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const WarningIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M2.72479 21C2.54146 21 2.37479 20.9542 2.22479 20.8625C2.07479 20.7708 1.95812 20.65 1.87479 20.5C1.79146 20.35 1.74562 20.1875 1.73729 20.0125C1.72896 19.8375 1.77479 19.6667 1.87479 19.5L11.1248 3.5C11.2248 3.33333 11.354 3.20833 11.5123 3.125C11.6706 3.04167 11.8331 3 11.9998 3C12.1665 3 12.329 3.04167 12.4873 3.125C12.6456 3.20833 12.7748 3.33333 12.8748 3.5L22.1248 19.5C22.2248 19.6667 22.2706 19.8375 22.2623 20.0125C22.254 20.1875 22.2081 20.35 22.1248 20.5C22.0415 20.65 21.9248 20.7708 21.7748 20.8625C21.6248 20.9542 21.4581 21 21.2748 21H2.72479ZM11.9998 18C12.2831 18 12.5206 17.9042 12.7123 17.7125C12.904 17.5208 12.9998 17.2833 12.9998 17C12.9998 16.7167 12.904 16.4792 12.7123 16.2875C12.5206 16.0958 12.2831 16 11.9998 16C11.7165 16 11.479 16.0958 11.2873 16.2875C11.0956 16.4792 10.9998 16.7167 10.9998 17C10.9998 17.2833 11.0956 17.5208 11.2873 17.7125C11.479 17.9042 11.7165 18 11.9998 18ZM11.9998 15C12.2831 15 12.5206 14.9042 12.7123 14.7125C12.904 14.5208 12.9998 14.2833 12.9998 14V11C12.9998 10.7167 12.904 10.4792 12.7123 10.2875C12.5206 10.0958 12.2831 10 11.9998 10C11.7165 10 11.479 10.0958 11.2873 10.2875C11.0956 10.4792 10.9998 10.7167 10.9998 11V14C10.9998 14.2833 11.0956 14.5208 11.2873 14.7125C11.479 14.9042 11.7165 15 11.9998 15Z'
			}
		/>
	</SvgIcon>
);
