import { Modal } from '@material-ui/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../ui-kit/button/button.component';
import { Lazy } from '../../../../utils/function.utils';
import { useLiveChatConfirmationModalStyles } from './confirmation-modal.styles';

export interface LiveChatConfirmationModalProps {
	isOpen: boolean;
	title: string;
	description: string;
	onAccept: Lazy<void>;
	onDecline: Lazy<void>;
	container?: Element;
}

export const LiveChatConfirmationModal: FC<LiveChatConfirmationModalProps> = ({
	isOpen,
	title,
	description,
	container,
	onAccept,
	onDecline,
}) => {
	const classes = useLiveChatConfirmationModalStyles();
	const { t } = useTranslation();

	return (
		<Modal container={container} disableAutoFocus open={isOpen} className={classes.modal} onClose={onDecline}>
			<div className={classes.root}>
				<h4 className={classes.title}> {title}</h4>
				{description && <p className={classes.description}>{description}</p>}
				<div className={classes.actions}>
					<Button
						onClick={onAccept}
						color={'secondary'}
						className={classes.action}
						data-testing-label={'confirmation-modal-btn-yes'}
					>
						{t('liveChatConfirmationModalYesLabel', 'Yes')}
					</Button>
					<Button
						onClick={onDecline}
						color={'secondary'}
						className={classes.action}
						data-testing-label={'confirmation-modal-btn-no'}
					>
						{t('liveChatConfirmationModalNoLabel', 'No')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
