import { useMediaQuery, useTheme } from '@material-ui/core';
import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MenuTextModel } from '../../context/api-service/api-service.model';
import { SessionContext } from '../../context/session.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { LanguageAbbreviation } from '../../models/languages.model';
import { MenuItemAction } from '../../models/menu.model';
import { EventType } from '../../services/web-trackers-service/web-tracker-service';
import { ActionsMenu } from '../../ui-kit/actions-menu/actions-menu.component';
import { IconButton } from '../../ui-kit/icon-button/icon-button.component';
import { Icon } from '../../ui-kit/icon/icon.component';
import { FabricLogoIcon } from '../../ui-kit/icons/fabric.icon';
import { MenuIcon } from '../../ui-kit/icons/menu.icon';
import { LanguageSelector, LanguageSelectorItem } from '../../ui-kit/language-selector/language-selector.component';
import { Link } from '../../ui-kit/link/link.component';
import { StackPanel } from '../../ui-kit/stack-panel /stack-panel.component';
import { constVoid, Effect } from '../../utils/function.utils';
import { LiveChatStatusControlContainer } from '../live-chat/components/status-control/status-control.container';
import { MenuHeader } from './widget-menu-header/widget-menu-header.component';
import { useContainerMenuStyle, useFooterWidgetMenuStyle } from './widget-menu.styles';

interface MenuProps {
	menuText: MenuTextModel;
	menuItems: MenuItemAction[];
	languages: LanguageSelectorItem[];
	selectedLanguage: LanguageAbbreviation;
	hasOverlay?: boolean;
	isDisabled: boolean;
	isLiveChatOn?: boolean;
	rootRef?: React.RefObject<HTMLDivElement>;
	onLanguageChange: Effect<LanguageAbbreviation>;
	onMenuSelect: (text: string, orininalText: string, shouldShowInWidget?: boolean) => void;
	onStateChange?: (state: boolean, e?: EventType) => void;
}

export const Menu: FC<MenuProps> = ({
	menuText,
	hasOverlay,
	isDisabled,
	isLiveChatOn,
	menuItems,
	onMenuSelect,
	languages,
	selectedLanguage,
	onLanguageChange,
	onStateChange = constVoid,
	rootRef,
}) => {
	const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
	const {
		state: { isLoading },
	} = useContext(SessionContext);
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const [isSelecting, setIsSelecting] = useState<boolean>(false);

	const containerClasses = useContainerMenuStyle({ isMobile: isMobile });
	const footerClasses = useFooterWidgetMenuStyle();

	const {
		palette: {
			colorScheme: {
				customStyle: { menu },
			},
		},
	} = useTheme();

	useEffect(() => {
		setIsSelecting(isLoading);
	}, [isLoading]);

	const openMenu = (e?: EventType) => {
		onStateChange(true, e);
		setIsOpen(true);
	};

	const closeMenu = (e?: EventType) => {
		onStateChange(false, e);
		setIsOpen(false);
	};

	const onActionMenuSelect = (text: string, orininalText: string, shouldShowInWidget?: boolean) => {
		onMenuSelect(text, orininalText, shouldShowInWidget);
		!isLiveChatOn && closeMenu();
	};

	const handleLanguageChange = (lng: LanguageAbbreviation) => {
		setIsSelecting(true);
		onLanguageChange(lng);
	};

	const renderLiveChatSection = () => (
		<div className={containerClasses.actions}>
			<h3 className={containerClasses.liveChatTitle}>{t('liveChatMainMenuTitle', 'Live Chat')}</h3>
			<LiveChatStatusControlContainer onClick={closeMenu} />
		</div>
	);

	return (
		<Fragment>
			<IconButton
				data-testing-label={'open-menu-btn'}
				aria-label={'menu'}
				onClick={openMenu}
				disabled={isDisabled}
				className={containerClasses.menuButton}>
				<Icon icon={MenuIcon} size={'large'} iconType={'buttonIcon'} alt={'menu'} />
			</IconButton>
			<StackPanel
				isOpen={isOpen}
				onClose={closeMenu}
				hasOverlay={hasOverlay}
				rootRef={rootRef}
				customStyle={menu}>
				<MenuHeader onClose={closeMenu} />
				<div className={containerClasses.root}>
					<div className={containerClasses.text}>
						<h1 data-testing-label={'menu-text-title'} className={containerClasses.title}>
							{menuText.title}
						</h1>
						<p data-testing-label={'menu-text'}>{menuText.text}</p>
					</div>
					{menuItems.length > 0 && (
						<div className={containerClasses.actions}>
							<ActionsMenu menuItems={menuItems} onMenuSelect={onActionMenuSelect} />
						</div>
					)}
					{isLiveChatOn && renderLiveChatSection()}
					{languages.length > 0 && (
						<div className={containerClasses.selector}>
							<LanguageSelector
								languages={languages}
								selectedLanguage={selectedLanguage}
								onLanguageChange={handleLanguageChange}
								isDisabled={isSelecting || !!isLiveChatOn}
							/>
						</div>
					)}
					<footer className={footerClasses.root}>
						<span className={footerClasses.text}>Powered by </span>
						<Link
							href={'https://www.fabrichealth.com'}
							linkType={'typeNoteLink'}
							target={'_blank'}
							className={footerClasses.link}>
							<FabricLogoIcon className={footerClasses.svg} />
						</Link>
					</footer>
				</div>
			</StackPanel>
		</Fragment>
	);
};
