import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const ArrowRightIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M12.6 11.9998L8.69999 8.0998C8.51665 7.91647 8.42499 7.68314 8.42499 7.3998C8.42499 7.11647 8.51665 6.88314 8.69999 6.6998C8.88332 6.51647 9.11665 6.4248 9.39999 6.4248C9.68332 6.4248 9.91665 6.51647 10.1 6.6998L14.7 11.2998C14.8 11.3998 14.8708 11.5081 14.9125 11.6248C14.9542 11.7415 14.975 11.8665 14.975 11.9998C14.975 12.1331 14.9542 12.2581 14.9125 12.3748C14.8708 12.4915 14.8 12.5998 14.7 12.6998L10.1 17.2998C9.91665 17.4831 9.68332 17.5748 9.39999 17.5748C9.11665 17.5748 8.88332 17.4831 8.69999 17.2998C8.51665 17.1165 8.42499 16.8831 8.42499 16.5998C8.42499 16.3165 8.51665 16.0831 8.69999 15.8998L12.6 11.9998Z'
			}
		/>
	</SvgIcon>
);
