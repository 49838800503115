import { useMediaQuery, useTheme } from '@material-ui/core';
import Portal from '@material-ui/core/Portal';
import { FC, memo, RefObject, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ConfigContext } from '../../context/config.context';
import { MOBILE_MEDIA_QUERY } from '../../models/dimensions.model';
import { Lazy } from '../../utils/function.utils';
import { overlayEffect, panelAnimationStyles, useStackPanelStyles } from './stack-panel.styles';

export interface StackPanelCustomStyle {
	main: Record<string, any>;
	overlay: Record<string, any>;
	transition: Record<string, any>;
}
export interface StackPanelProps {
	isOpen: boolean;
	onClose: Lazy<void>;
	hasOverlay?: boolean;
	testingLabel?: string;
	rootRef?: RefObject<HTMLDivElement>;
	customStyle?: Partial<StackPanelCustomStyle>;
}

export const StackPanel: FC<StackPanelProps> = memo(
	({ isOpen = false, onClose, hasOverlay = true, children, rootRef, customStyle, testingLabel }) => {
		const isMobile = useMediaQuery(MOBILE_MEDIA_QUERY);
		const {
			palette: { transitionConfig },
		} = useTheme();
		const {
			appOptions: { fullScreen },
		} = useContext(ConfigContext);

		const classes = useStackPanelStyles(customStyle)({ isMobile: isMobile, isFullScreen: fullScreen });
		const overlayTransition = overlayEffect();
		const panelAnimation = panelAnimationStyles(customStyle)();

		const DEFAULT_TIMEOUT = {
			enter: transitionConfig.transitionInDuration,
			exit: transitionConfig.transitionOutDuration,
		};

		const createOverlay = () => (
			<CSSTransition
				in={isOpen}
				classNames={overlayTransition}
				mountOnEnter
				unmountOnExit
				timeout={DEFAULT_TIMEOUT}
				data-testing-label={'menu-overlay'}>
				<div
					role={'tooltip'}
					aria-label={'overlay'}
					className={classes.overlay}
					onClick={onClose}
					aria-hidden
				/>
			</CSSTransition>
		);

		return (
			<Portal container={rootRef?.current}>
				<CSSTransition
					in={isOpen}
					classNames={panelAnimation}
					mountOnEnter
					unmountOnExit
					timeout={DEFAULT_TIMEOUT}>
					<nav className={classes.stackPanelStyle} data-testing-label={testingLabel || 'stack-panel'}>
						{children}
					</nav>
				</CSSTransition>
				{hasOverlay && createOverlay()}
			</Portal>
		);
	},
);
