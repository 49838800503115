import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EmojiGoodIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 32 32'} width={'32'} height={'32'} {...props}>
		<g stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
			<g>
				<polygon points={'0 0 32 0 32 32 0 32'}></polygon>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'20.6666667'} cy={'12.6666667'} r={'2'}></circle>
				<circle fill={'currentColor'} fillRule={'nonzero'} cx={'11.3333333'} cy={'12.6666667'} r={'2'}></circle>
				<path
					d={
						'M15.9866667,2.66666667 C8.62666667,2.66666667 2.66666667,8.64 2.66666667,16 C2.66666667,23.36 8.62666667,29.3333333 15.9866667,29.3333333 C23.36,29.3333333 29.3333333,23.36 29.3333333,16 C29.3333333,8.64 23.36,2.66666667 15.9866667,2.66666667 Z M16,26.6666667 C10.1066667,26.6666667 5.33333333,21.8933333 5.33333333,16 C5.33333333,10.1066667 10.1066667,5.33333333 16,5.33333333 C21.8933333,5.33333333 26.6666667,10.1066667 26.6666667,16 C26.6666667,21.8933333 21.8933333,26.6666667 16,26.6666667 Z M21.88,18.52 C21.4133333,18.2266667 20.7866667,18.3733333 20.5066667,18.84 C19.52,20.4 17.84,21.3333333 16,21.3333333 C14.16,21.3333333 12.48,20.4 11.4933333,18.8266667 C11.2,18.36 10.5866667,18.2133333 10.12,18.5066667 C9.65333333,18.8 9.50666667,19.4133333 9.8,19.88 C11.16,22.0533333 13.4666667,23.3333333 16,23.3333333 C18.5333333,23.3333333 20.84,22.04 22.2,19.8933333 C22.4933333,19.4266667 22.3466667,18.8133333 21.88,18.52 Z'
					}
					fill={'currentColor'}
					fillRule={'nonzero'}
				></path>
			</g>
		</g>
	</SvgIcon>
);
