import { createStyles, Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles/withStyles';

export const useRemainingTimeIndicatorStyles = ({
	palette: { colorScheme, sizes },
	typography: { smallViewPort },
}: Theme): StyleRules<any, string> =>
	createStyles({
		root: {
			position: 'relative',
			paddingTop: sizes.size_1,
			zIndex: 2,
			textAlign: 'center',
			...smallViewPort.typeNoteParagraph,
			marginBottom: 0,
			...colorScheme.customStyle.progressBar,
		},
	});
