import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const PersonOffIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M19.0751 21.9002L17.1501 20.0002H6.00011C5.45011 20.0002 4.97928 19.8044 4.58761 19.4127C4.19594 19.021 4.00011 18.5502 4.00011 18.0002V17.2002C4.00011 16.6335 4.14594 16.1127 4.43761 15.6377C4.72928 15.1627 5.11678 14.8002 5.60011 14.5502C6.35011 14.1669 7.11261 13.8585 7.88761 13.6252C8.66261 13.3919 9.45011 13.2169 10.2501 13.1002L2.07511 4.9252C1.87511 4.7252 1.77928 4.4877 1.78761 4.2127C1.79594 3.9377 1.90011 3.7002 2.10011 3.5002C2.30011 3.3002 2.53761 3.2002 2.81261 3.2002C3.08761 3.2002 3.32511 3.3002 3.52511 3.5002L20.5001 20.5002C20.7001 20.7002 20.8001 20.9335 20.8001 21.2002C20.8001 21.4669 20.7001 21.7002 20.5001 21.9002C20.3001 22.1002 20.0626 22.2002 19.7876 22.2002C19.5126 22.2002 19.2751 22.1002 19.0751 21.9002ZM18.4001 14.5502C18.8834 14.7835 19.2668 15.1377 19.5501 15.6127C19.8334 16.0877 19.9834 16.6002 20.0001 17.1502L16.6501 13.8002C16.9501 13.9169 17.2459 14.0335 17.5376 14.1502C17.8293 14.2669 18.1168 14.4002 18.4001 14.5502ZM14.2001 11.3502L8.65011 5.8002C9.03344 5.23353 9.51678 4.79186 10.1001 4.4752C10.6834 4.15853 11.3168 4.0002 12.0001 4.0002C13.1001 4.0002 14.0418 4.39186 14.8251 5.1752C15.6084 5.95853 16.0001 6.9002 16.0001 8.0002C16.0001 8.68353 15.8418 9.31686 15.5251 9.9002C15.2084 10.4835 14.7668 10.9669 14.2001 11.3502Z'
			}
		/>
	</SvgIcon>
);
