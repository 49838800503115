import React from 'react';
import { ListChildComponentProps, VariableSizeList } from 'react-window';

const LINE_HEIGHT = 49.5;
const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current !== null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	return React.cloneElement(data[index], {
		style,
	});
}

export const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData = React.Children.toArray(children);

	const itemCount = itemData.length;

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * LINE_HEIGHT;
		}
		return itemData.length * LINE_HEIGHT;
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2}
					width={'100%'}
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType={'ul'}
					itemSize={() => LINE_HEIGHT}
					overscanCount={5}
					itemCount={itemCount}>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});
