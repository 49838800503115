import classnames from 'classnames';
import { FC, Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { CardResponse, ClinicCard as ClinicCardType } from '../../../../../models/message.model';
import { EventType } from '../../../../../services/web-trackers-service/web-tracker-service';
import { Button } from '../../../../../ui-kit/button/button.component';
import { CarouselCard } from '../../../../../ui-kit/carousel/carousel-card/carousel-card.component';
import { Icon } from '../../../../../ui-kit/icon/icon.component';
import { ClockWatchIcon } from '../../../../../ui-kit/icons/clock-watch.icon';
import { LocationFilledIcon } from '../../../../../ui-kit/icons/location-filled.icon';
import { PhoneFilledIcon } from '../../../../../ui-kit/icons/phone-filled.icon';
import { SandWatchIcon } from '../../../../../ui-kit/icons/sand-watch.icon';
import { Link } from '../../../../../ui-kit/link/link.component';
import { createDistance } from '../../../../../utils/distance.utils';
import { Nullable } from '../../../../../utils/types.utils';
import { useClinicCardStyles } from './clinic-card.styles';

interface ClinicCardProps {
	card: ClinicCardType;
	onResponse: (response: CardResponse, e: EventType) => void;
}

export const ClinicCard: FC<ClinicCardProps> = memo(({ card, onResponse }) => {
	const classes = useClinicCardStyles();
	const { t } = useTranslation();
	const { data, responses } = card;
	const {
		name,
		disposition,
		address,
		addressPlaceholder,
		link,
		distance,
		phone,
		phonePlaceholder,
		waitingTimeString,
		openingHours,
	} = data;

	const renderHeader = (name: string, disposition?: Nullable<string>): JSX.Element => (
		<header>
			{disposition && <span className={classes.dispositionTitle}>{disposition}</span>}
			<h4 className={classes.clinicTitle}>{name}</h4>
		</header>
	);

	const createAddress = (address: string): JSX.Element => <span>{address}</span>;

	const renderDistanceAndAddress = (
		address?: string,
		addressPlaceholder?: Nullable<string>,
		distance?: Nullable<number>,
	) => (
		<div className={classes.withSeparator}>
			{distance && <span>{createDistance(distance, t)}</span>}
			{address && createAddress(address)}
			{!address && addressPlaceholder && <span>{addressPlaceholder}</span>}
		</div>
	);

	const renderContact = (phone?: Nullable<string>, phonePlaceholder?: Nullable<string>): JSX.Element => (
		<Fragment>
			{phone && (
				<Fragment>
					<strong>P</strong>
					<span> {phone}</span>
				</Fragment>
			)}
			{!phone && phonePlaceholder && <span>{phonePlaceholder}</span>}
		</Fragment>
	);

	const getOpeningHoursLabel = (disposition?: Nullable<string>, openingHours?: Nullable<string>) => {
		if (openingHours) {
			return openingHours;
		}
		if (disposition) {
			return disposition === 'Emergency' ? t('emergencyClinicOpen', 'Open 24 hours') : '';
		}

		return null;
	};

	const createOpeningHours = (disposition?: Nullable<string>, openingHours?: Nullable<string>) => {
		const label = getOpeningHoursLabel(disposition, openingHours);

		return (
			label && (
				<span className={classnames(classes.additionalInfoRoot, classes.centeredInlineFlex)}>
					<Icon icon={ClockWatchIcon} size={'small'} iconType={'decorIcon'} alt={'opening hours icon'} />
					<span> {label}</span>
				</span>
			)
		);
	};

	const createWaitingTime = (waitingTimeString?: Nullable<string>) =>
		waitingTimeString && (
			<span className={classnames(classes.additionalInfoRoot, classes.centeredInlineFlex)}>
				<Icon icon={SandWatchIcon} size={'small'} iconType={'decorIcon'} alt={'waiting time icon'} />
				<span>{waitingTimeString}</span>
			</span>
		);

	const renderAdditionalInfo = (
		disposition?: Nullable<string>,
		openingHours?: Nullable<string>,
		waitingTimeString?: Nullable<string>,
	) => {
		if (disposition || openingHours || waitingTimeString) {
			const openingHoursLabel = createOpeningHours(disposition, openingHours);
			const waitingTimeLabel = createWaitingTime(waitingTimeString);

			return (
				<div className={classes.section}>
					{openingHoursLabel} {waitingTimeLabel}
				</div>
			);
		}
	};

	const createActions = (
		responses: CardResponse[],
		phone?: Nullable<string>,
		link?: Nullable<string>,
	): JSX.Element => {
		const mapLink = link && (
			<Link
				linkType={'typeLink2'}
				href={link}
				target={'_blank'}
				rel={'noreferrer'}
				variant={'button'}
				className={classes.additionalActionLink}>
				<Icon icon={LocationFilledIcon} size={'large'} iconType={'buttonIcon'} alt={'open map icon'} />
				<span className={classes.actionLinkLabel}>{t('map', 'Map')}</span>
			</Link>
		);

		const phoneLink = phone && (
			<Link
				linkType={'typeLink2'}
				href={`tel:${phone}`}
				target={'_parent'}
				variant={'button'}
				className={classes.additionalActionLink}>
				<Icon icon={PhoneFilledIcon} size={'large'} iconType={'buttonIcon'} alt={'call icon'} />
				<span className={classes.actionLinkLabel}>{t('call', 'Call')}</span>
			</Link>
		);

		const responsesToMainActions = responses.length > 1 ? responses.slice(0, -1) : responses;

		return (
			<div className={classes.actionsRoot}>
				{responsesToMainActions.map((response, index) => (
					<Button
						key={index}
						color={'secondary'}
						onClick={(e) => onResponse(response, e)}
						data-testing-label={'carousel-clinic-card-main-action'}>
						{response.content}
					</Button>
				))}
				{(phone || link) && (
					<div className={classes.additionlActionsWrapper}>
						{phoneLink}
						{mapLink}
					</div>
				)}
			</div>
		);
	};

	return (
		<CarouselCard>
			<div className={classes.root}>
				<div className={classes.section}>{renderHeader(name, disposition)}</div>
				<div className={classes.section}>{renderDistanceAndAddress(address, addressPlaceholder, distance)}</div>
				<div className={classes.section}>{renderContact(phone, phonePlaceholder)}</div>
				{renderAdditionalInfo(disposition, openingHours, waitingTimeString)}
			</div>
			{createActions(responses, phone, link)}
		</CarouselCard>
	);
});
