import { Theme } from '@material-ui/core';
import makeStyles from '@material-ui/styles/makeStyles';

export const useContainerMenuStyle = makeStyles<Theme, { isMobile: boolean }>(
	({
		typography: { smallViewPort },
		palette: {
			sizes,
			colorScheme: { customStyle },
		},
	}) => ({
		root: {
			padding: `0 ${sizes.size_2}px ${sizes.size_2}px`,
			position: 'relative',
			overflowX: 'hidden',
			overflowY: 'auto',
			height: '100%',
			marginTop: (props) => (props.isMobile ? 76 : 84),
			flexDirection: 'column',
			display: 'flex',
		},
		text: {
			width: '100%',
			listStyleType: 'none',
			marginTop: sizes.size_4,
			padding: `0px ${sizes.size_2}px ${sizes.size_3}px ${sizes.size_2}px`,
		},
		title: {
			...smallViewPort.typeTitle1,
			margin: `0px 0px ${sizes.size_1}px 0px`,
		},
		actions: {
			paddingBottom: sizes.size_3,
		},
		selector: {
			paddingBottom: sizes.size_3,
		},
		liveChatTitle: {
			margin: `0 0 ${sizes.size_1}px`,
			paddingLeft: sizes.size_2,
			...smallViewPort.typeHeading2,
		},
		menuButton: {
			...customStyle.menu?.button,
		},
	}),
);

export const useFooterWidgetMenuStyle = makeStyles<Theme>(({ typography: { smallViewPort }, palette: { sizes } }) => ({
	root: {
		marginTop: 'auto',
		alignSelf: 'flex-start',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	text: {
		...smallViewPort.typeNoteParagraph,
		marginRight: sizes.sizeHalf,
		fontSize: 10,
	},
	svg: {
		marginLeft: 2,
		width: 'auto',
		height: 'auto',
	},
	link: {
		marginTop: -sizes.sizeHalf,
	},
}));
