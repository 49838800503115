import classNames from 'classnames';
import { FC } from 'react';

import { EnvironmentType } from '../../models/app.model';
import { useEnvironmentLabelStyles } from './environment-label.styles';

interface EnvironmentLabelData {
	color: string;
	bgColor: string;
	label: string;
}

interface EnvironmentLabelProps {
	environment: EnvironmentType;
	className?: string;
}

export const EnvironmentLabel: FC<EnvironmentLabelProps> = ({ environment, className }) => {
	const getEnvLabelData = (environment: EnvironmentType): EnvironmentLabelData => {
		switch (environment) {
			case 'local':
				return {
					color: 'HSLA(0, 0%, 0%, 0.8)',
					bgColor: 'HSL(36, 100%, 78%)',
					label: 'local',
				};
			case 'recovery':
				return {
					color: 'HSLA(0, 0%, 0%, 0.8)',
					bgColor: 'HSL(36, 100%, 78%)',
					label: 'recovery',
				};
			case 'development':
				return {
					color: 'HSL(0, 0%, 100%)',
					bgColor: 'HSL(232, 100%, 65%)',
					label: 'dev',
				};
			case 'staging':
				return {
					color: 'HSL(0, 0%, 100%)',
					bgColor: 'HSL(0, 0%, 28%)',
					label: 'staging',
				};
			case 'production':
				return {
					color: 'transparent',
					bgColor: 'transparent',
					label: 'prod',
				};
		}
	};

	const data = getEnvLabelData(environment);
	const classes = useEnvironmentLabelStyles({ bgColor: data.bgColor, color: data.color });
	const rootClassName = classNames(classes.root, className);

	return environment === 'production' ? null : <span className={rootClassName}>{data.label}</span>;
};
