import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const UndoIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M8.00005 18.9998C7.71672 18.9998 7.47922 18.904 7.28755 18.7123C7.09588 18.5206 7.00005 18.2831 7.00005 17.9998C7.00005 17.7165 7.09588 17.479 7.28755 17.2873C7.47922 17.0956 7.71672 16.9998 8.00005 16.9998H14.1C15.15 16.9998 16.0625 16.6665 16.8375 15.9998C17.6125 15.3331 18 14.4998 18 13.4998C18 12.4998 17.6125 11.6665 16.8375 10.9998C16.0625 10.3331 15.15 9.9998 14.1 9.9998H7.80005L9.70005 11.8998C9.88338 12.0831 9.97505 12.3165 9.97505 12.5998C9.97505 12.8831 9.88338 13.1165 9.70005 13.2998C9.51672 13.4831 9.28338 13.5748 9.00005 13.5748C8.71672 13.5748 8.48338 13.4831 8.30005 13.2998L4.70005 9.6998C4.60005 9.5998 4.52922 9.49147 4.48755 9.3748C4.44588 9.25814 4.42505 9.13314 4.42505 8.9998C4.42505 8.86647 4.44588 8.74147 4.48755 8.6248C4.52922 8.50814 4.60005 8.3998 4.70005 8.2998L8.30005 4.6998C8.48338 4.51647 8.71672 4.4248 9.00005 4.4248C9.28338 4.4248 9.51672 4.51647 9.70005 4.6998C9.88338 4.88314 9.97505 5.11647 9.97505 5.3998C9.97505 5.68314 9.88338 5.91647 9.70005 6.0998L7.80005 7.9998H14.1C15.7167 7.9998 17.1042 8.5248 18.2625 9.5748C19.4209 10.6248 20 11.9331 20 13.4998C20 15.0665 19.4209 16.3748 18.2625 17.4248C17.1042 18.4748 15.7167 18.9998 14.1 18.9998H8.00005Z'
			}
		/>
	</SvgIcon>
);
