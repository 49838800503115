import classNames from 'classnames';
import { FC, memo, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { LinksServiceContext } from '../../context/links-service/links-service.context';
import { SessionContext } from '../../context/session.context';
import { Nullable } from '../../utils/types.utils';
import { LiveChatFeedback } from './components/feedback/feedback.component';
import { LiveChatGreetingCard } from './components/greeting-card/greeting-card.component';
import { LiveChatMessage } from './components/message/message.component';
import { LiveChatStatusControlContainer } from './components/status-control/status-control.container';
import { SystemInfo } from './components/sytem-info/sytem-info.component';
import { TypingIndicator } from './components/typing-indicator/typing-indicator.component';
import {
	getAdditionalInfo,
	getLastUserMessageId,
	isProcessingSending,
	LiveChatUIMessage,
	RETRY_MESSAGES_COMMAND,
	setTimerForScrollContentDown,
} from './live-chat.model';
import {
	feedbackCSSTransitionStyles,
	messageCSSTransitionStyles,
	useLiveChatStyles,
	wellcomeCardCSSTransitionStyles,
} from './live-chat.styles';

interface LiveChatComponentProps {
	messages: LiveChatUIMessage[];
	isTyping: boolean;
	isWaiting: boolean;
	feedBackQuestion: Nullable<ReactNode>;
	shouldAnimateFeedback: boolean;
	isLeavingLiveChat: boolean;
	isLiveChatSessionOver: boolean;
	startingChatDescription: string;
}

export const LiveChatComponent: FC<LiveChatComponentProps> = memo(
	({
		isTyping,
		isWaiting,
		feedBackQuestion,
		messages,
		shouldAnimateFeedback,
		isLeavingLiveChat,
		isLiveChatSessionOver,
		startingChatDescription,
	}) => {
		const {
			state: { onSendMessage },
		} = useContext(SessionContext);
		const { parseLinksInText } = useContext(LinksServiceContext);
		const classes = useLiveChatStyles({ isLiveChatSessionOver });
		const messageTransitionClasses = messageCSSTransitionStyles();
		const welcomeCardTransitionClasses = wellcomeCardCSSTransitionStyles();
		const feedbackCardTransitionClasses = feedbackCSSTransitionStyles();
		const { t } = useTranslation();
		const ref = useRef<HTMLDivElement>(null);
		const [prevMessagesLength, setPrevMessagesLength] = useState(0);
		const [lastMessageIDFromUser, setLastMessageIDFromUser] = useState<string>();
		const [isRetryingMessages, setIsRetryingMessages] = useState(false);

		const onRetryMessages = () => onSendMessage(RETRY_MESSAGES_COMMAND, RETRY_MESSAGES_COMMAND, false);

		const renderMessages = (messages: LiveChatUIMessage[]) =>
			messages.map((message, index) => {
				const additionalInfo = getAdditionalInfo(messages, index);

				const isNextMessageOfSameType =
					messages[index + 1] && message.liveChatMessageType === messages[index + 1].liveChatMessageType;
				switch (message.liveChatMessageType) {
					case 'SYSTEM':
						return (
							<SystemInfo
								className={classes.systemInfoMessage}
								text={message.text}
								key={`${message.text}_${index}_${message.liveChatMessageType}`}
								isUnreadMessage={message.isUnreadMessage}
							/>
						);
					case 'AGENT':
						return (
							<LiveChatMessage
								className={classNames(
									classes.message,
									classes.agentMessageAnimation,
									!isNextMessageOfSameType && classes.lastAgentMessage,
								)}
								additionalInfo={additionalInfo}
								text={parseLinksInText({ text: message.text, className: classes.agentLink })}
								type={'agent'}
								key={`${message.text}_${index}_${message.liveChatMessageType}`}
								isUnreadMessage={message.isUnreadMessage}
							/>
						);
					case 'USER':
						return (
							<LiveChatMessage
								className={classNames(
									classes.message,
									classes.userMessage,
									!isNextMessageOfSameType && classes.lastUserMessage,
								)}
								text={parseLinksInText({
									text: message.text,
									className: classes.userLink,
								})}
								type={'user'}
								key={`${message.text}_${index}_${message.liveChatMessageType}`}
								isUnreadMessage={message.isUnreadMessage}
								isUndelivered={message.deliveredStatus === 'undelivered'}
								onRetryMessages={onRetryMessages}
								isSending={message.deliveredStatus === 'sending'}
								showRetry={
									!isRetryingMessages &&
									!isLiveChatSessionOver &&
									lastMessageIDFromUser === message.messageId
								}
							/>
						);
				}
			});

		useEffect(() => {
			setLastMessageIDFromUser(getLastUserMessageId(messages));
			setIsRetryingMessages(isProcessingSending(messages));

			if ((!prevMessagesLength && messages.length) || prevMessagesLength > messages.length) {
				setPrevMessagesLength(messages.length);
				setTimerForScrollContentDown(ref, 700);
			} else if (messages.length !== prevMessagesLength) {
				setPrevMessagesLength(messages.length);
				setTimerForScrollContentDown(ref, 300);
			}
			if (isTyping) {
				setTimerForScrollContentDown(ref, 150);
			}
		}, [messages, isTyping]);

		const rootClassName = classNames(classes.root, isLeavingLiveChat && classes.removeLiveChat);
		const statusControlClassName = classNames(
			classes.statusControl,
			isLiveChatSessionOver && classes.statusControlHidden,
		);

		return (
			<div className={rootClassName} id={'live-chat'}>
				<LiveChatStatusControlContainer className={statusControlClassName} />
				<CSSTransition in={isWaiting} classNames={welcomeCardTransitionClasses} unmountOnExit timeout={1000}>
					<div className={classes.flexWrapper}>
						<LiveChatGreetingCard
							className={classes.greeting}
							title={t('liveChatStartingTitle', 'Starting Live Chat')}
							description={startingChatDescription}
							mode={'welcome'}
						/>
					</div>
				</CSSTransition>

				<div className={classes.content} ref={ref}>
					{renderMessages(messages)}
					<CSSTransition in={isTyping} classNames={messageTransitionClasses} unmountOnExit timeout={300}>
						<TypingIndicator />
					</CSSTransition>

					<CSSTransition
						in={isLiveChatSessionOver}
						classNames={feedbackCardTransitionClasses}
						unmountOnExit
						timeout={300}>
						<LiveChatFeedback question={feedBackQuestion} shouldAnimateFeedback={shouldAnimateFeedback} />
					</CSSTransition>
				</div>
			</div>
		);
	},
);
