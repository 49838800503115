import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const NoImageIcon = (props: SvgIconProps): JSX.Element => (
	<SvgIcon viewBox={'0 0 24 24'} {...props}>
		<path
			d={
				'M21.0002 5V16C21.0002 16.3333 20.896 16.5833 20.6877 16.75C20.4794 16.9167 20.2502 17 20.0002 17C19.7502 17 19.521 16.9125 19.3127 16.7375C19.1044 16.5625 19.0002 16.3083 19.0002 15.975V5H8.0002C7.66686 5 7.41686 4.89583 7.2502 4.6875C7.08353 4.47917 7.0002 4.25 7.0002 4C7.0002 3.75 7.08353 3.52083 7.2502 3.3125C7.41686 3.10417 7.66686 3 8.0002 3H19.0002C19.5502 3 20.021 3.19583 20.4127 3.5875C20.8044 3.97917 21.0002 4.45 21.0002 5ZM5.0002 21C4.4502 21 3.97936 20.8042 3.5877 20.4125C3.19603 20.0208 3.0002 19.55 3.0002 19V5.8L2.1002 4.9C1.91686 4.71667 1.8252 4.48333 1.8252 4.2C1.8252 3.91667 1.91686 3.68333 2.1002 3.5C2.28353 3.31667 2.51686 3.225 2.8002 3.225C3.08353 3.225 3.31686 3.31667 3.5002 3.5L20.5002 20.5C20.6835 20.6833 20.7752 20.9167 20.7752 21.2C20.7752 21.4833 20.6835 21.7167 20.5002 21.9C20.3169 22.0833 20.0835 22.175 19.8002 22.175C19.5169 22.175 19.2835 22.0833 19.1002 21.9L18.2002 21H5.0002ZM14.1752 17H7.0002C6.8002 17 6.6502 16.9083 6.5502 16.725C6.4502 16.5417 6.46686 16.3667 6.6002 16.2L8.6002 13.525C8.7002 13.3917 8.83353 13.325 9.0002 13.325C9.16686 13.325 9.3002 13.3917 9.4002 13.525L11.2502 16L12.0752 14.9L5.0002 7.825V19H16.1752L14.1752 17Z'
			}
		/>
	</SvgIcon>
);
