import classNames from 'classnames';
import { FC, memo } from 'react';

import { Button } from '../../../../ui-kit/button/button.component';
import { Lazy } from '../../../../utils/function.utils';
import { useErrorStateStyles } from './error-state.styles';

interface ErrorAction {
	text: string;
	action: Lazy<void>;
}

interface ErrorStateProps {
	text: string;
	header?: string;
	icon: JSX.Element;
	link?: ErrorAction;
	testingLabel?: string;
}

export const ErrorState: FC<ErrorStateProps> = memo(({ icon, link, text, header, testingLabel }) => {
	const classes = useErrorStateStyles();

	const textClass = classNames(classes.text, !header && classes.paddingWrapper);
	return (
		<div className={classes.root} data-testing-label={testingLabel}>
			{icon}
			{header && <p className={classes.header}>{header}</p>}
			<p className={textClass}>{text}</p>
			{link && (
				<Button
					className={classes.defaultButton}
					onClick={() => link.action()}
					data-testing-label={'next-available-error-state-button'}
				>
					<span className={classes.link}>{link.text}</span>
				</Button>
			)}
		</div>
	);
});
