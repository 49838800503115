import { InputAdornment, useMediaQuery } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteProps } from '@material-ui/lab';
import { identity } from 'fp-ts/lib/function';
import React from 'react';
import { ChangeEvent, FC, KeyboardEvent, memo, useContext, useEffect, useRef, useState } from 'react';

import { LiveChatContext } from '../../context/live-chat-context';
import { INPUT_LINE_HEIGHT, SMALL_HEIGHT_MEDIA_QUERY } from '../../models/dimensions.model';
import { constVoid, Effect, Lazy } from '../../utils/function.utils';
import { HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '../../utils/sizes.utils';
import { highlightSubstring, isBlank, isNotBlank, MAX_INPUT_MESSAGE_LENGTH } from '../../utils/string.utils';
import { Nullable } from '../../utils/types.utils';
import { IconButton } from '../icon-button/icon-button.component';
import { Icon } from '../icon/icon.component';
import { CloseIcon } from '../icons/close.icon';
import { FullArrowUpIcon } from '../icons/full-arrow-up.icon';
import { SearchIcon } from '../icons/search.icon';
import { useAutocompleteStyles, useInputAutocompleteStyles, useTextInputClasses } from './input-autocomplete.styles';
import { ListboxComponent } from './virtualized-list.component';

export interface AutocompleteOption {
	label: string;
	value: string;
}

interface InputAutocompleteProps
	extends Omit<AutocompleteProps<AutocompleteOption, false, false, false>, 'renderInput'> {
	value: Nullable<AutocompleteOption>;
	isLoading: boolean;
	isTextInputDisabled: boolean;
	shouldFocusTextInput?: boolean;
	placeholder?: string;
	isSendFreeTextDisabled?: boolean;
	onValueChange: (value: string, reason?: AutocompleteInputChangeReason) => void;
	onOptionSelect: Effect<AutocompleteOption>;
	onClear: Lazy<void>;
	onFocus?: Lazy<void>;
	onSendMessage: (text: string, orininalText?: string, shouldShowInWidget?: boolean) => void;
}

export const InputAutocomplete: FC<InputAutocompleteProps> = memo(
	({
		value,
		inputValue,
		options,
		isTextInputDisabled,
		shouldFocusTextInput,
		placeholder,
		isSendFreeTextDisabled = false,
		onClear,
		onOptionSelect,
		onValueChange,
		onSendMessage,
		onFocus = constVoid,
	}) => {
		const [isSendButtonVisible, handleSendButtonVisibilityState] = useState(false);
		const [isFocused, handleFocusedState] = useState(false);
		const [inputMaxRows, setInputMaxRows] = useState(1);

		const {
			state: { isLiveChatShown },
		} = useContext(LiveChatContext);

		const inputAutocompleteClasses = useInputAutocompleteStyles({
			isFocused,
			isLiveChatShown,
			hasOptions: options.length > 0,
			isSendFreeTextDisabled,
		});
		const classes = useAutocompleteStyles();
		const inputClasses = useTextInputClasses();
		const inputRef = useRef<Nullable<HTMLInputElement>>(null);

		const handleBlurState = () => {
			handleFocusedState(false);
			!inputValue && handleSendButtonVisibilityState(false);
		};

		const handleSendMessage = (inputValue?: string) => {
			inputValue && isNotBlank(inputValue) && onSendMessage(inputValue);
			if (isFocused) {
				inputRef.current?.blur();
			}
			if (!isLiveChatShown) {
				handleSendButtonVisibilityState(false);
				handleBlurState();
			}
		};

		const onKeyPressInInput = (e: KeyboardEvent<HTMLDivElement>) => {
			if (e.key === 'Enter') {
				e.preventDefault();
				if (inputValue && isNotBlank(inputValue) && !isSendFreeTextDisabled) {
					handleSendMessage(inputValue);
				}
			}
		};

		const renderOption = (option: AutocompleteOption) => (
			<span data-testing-label={'input-autocomplete-option'}>
				{inputValue ? highlightSubstring(option.label, inputValue, classes.highlighted) : option.label}
			</span>
		);

		const endAdornment =
			isSendFreeTextDisabled && inputValue ? (
				<InputAdornment position={'end'}>
					<IconButton
						className={classes.clearButton}
						aria-label={'clear'}
						onClick={onClear}
						data-testing-label={'user-input-clear-btn'}>
						<Icon size={'small'} iconType={'decorIcon'} icon={CloseIcon} />
					</IconButton>
				</InputAdornment>
			) : null;

		const handleFocusState = () => {
			onFocus();
			handleFocusedState(true);
			handleSendButtonVisibilityState(true);
		};

		const handleValueChange = (
			e: ChangeEvent<Record<string, unknown>>,
			value: string,
			reason: AutocompleteInputChangeReason,
		) => {
			handleSendButtonVisibilityState(true);
			onValueChange(value, reason);
		};

		const handleAutocompleteChange = (autocompleteValue: AutocompleteOption | null) => {
			handleBlurState();
			autocompleteValue && onOptionSelect(autocompleteValue);
		};

		const isSmallHeight = useMediaQuery(SMALL_HEIGHT_MEDIA_QUERY);

		useEffect(() => {
			if (shouldFocusTextInput) {
				setTimeout(() => {
					shouldFocusTextInput && inputRef.current?.focus();
				}, 400);
			}
		}, [shouldFocusTextInput]);

		useEffect(() => {
			const inputRect = inputRef.current?.getBoundingClientRect();
			const inputBottom = inputRect?.bottom || 0;
			const headerHeight = isSmallHeight ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP;
			const maxHeight = inputBottom - headerHeight;
			const maximumRows = Math.floor((maxHeight - 12) / INPUT_LINE_HEIGHT);

			setInputMaxRows(maximumRows);
		}, [isSmallHeight]);

		const virtualizedProps =
			options.length > 200
				? { ListboxComponent: ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>> }
				: {};

		const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
			if (options.length === 0) {
				// Allow default behavior for arrow keys when no autocomplete options are shown
				event.stopPropagation();
			}
		};

		return (
			<Autocomplete
				disabled={isTextInputDisabled}
				value={value}
				options={options}
				selectOnFocus={false}
				inputValue={inputValue}
				clearOnBlur={false}
				clearText={'clear'}
				classes={inputAutocompleteClasses}
				getOptionLabel={(option) => option.label}
				onChange={(e, v) => handleAutocompleteChange(v)}
				onInputChange={handleValueChange}
				noOptionsText={'No matching options'}
				disablePortal
				filterOptions={identity}
				renderOption={renderOption}
				{...virtualizedProps}
				renderInput={(params) => (
					<div className={classes.adornmentContainer}>
						{isSendFreeTextDisabled && (
							<div className={classes.searchIconAdornment} data-testing-label={'user-input-search-icon'}>
								<Icon iconType={'decorIcon'} icon={SearchIcon} />
							</div>
						)}
						<TextField
							inputRef={inputRef}
							multiline
							maxRows={isFocused ? inputMaxRows : 1}
							onFocus={handleFocusState}
							onBlur={handleBlurState}
							name={'user message'}
							data-testing-label={'user-input-container'}
							{...params}
							type={'search'}
							onKeyPress={onKeyPressInInput}
							onKeyDown={handleKeyDown}
							placeholder={placeholder}
							inputProps={{ ...params.inputProps, maxLength: MAX_INPUT_MESSAGE_LENGTH }}
							InputProps={{
								...params.InputProps,
								disableUnderline: true,
								classes: inputClasses,
								endAdornment,
							}}
						/>
						{isSendButtonVisible && !isSendFreeTextDisabled && (
							<IconButton
								className={classes.sendButton}
								data-testing-label={'user-input-send-btn'}
								aria-label={'send'}
								disabled={!inputValue || isBlank(inputValue) || isTextInputDisabled}
								onClick={() => handleSendMessage(inputValue)}>
								<Icon
									size={'large'}
									iconType={inputValue ? 'buttonIcon' : 'inputIcon'}
									icon={FullArrowUpIcon}
								/>
							</IconButton>
						)}
					</div>
				)}
			/>
		);
	},
);
